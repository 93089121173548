import { TransactionResponse } from "@ethersproject/abstract-provider";
import { PopulatedTransaction, Signature } from "ethers";
import BigNumber from "Services/BigNumber";
import BaseMarketplaceContractAdapter from "./core/BaseErc721ContractAdapter";
import IErc721ContractFunctions from "./core/IErc721ContractFunctions";
import { ISecurityPolicy } from "./core/Erc721Dto";
import { PaymentRequest } from "./CollectioACERC20RB170124Contract";
import CollectioACERC20150124Contract from "./CollectioACERC20150124Contract";

export default class CollectioACERC20150124ContractAdapter<T extends CollectioACERC20150124Contract>
	extends BaseMarketplaceContractAdapter<T>
	implements IErc721ContractFunctions
{
	public constructor(contract: T) {
		super(contract, {
			estimateGasPrice: true,
			approve: true,
			setApprovalForAll: true,
			getApproved: true,
			isApprovedForAll: true,
			createApproveTx: true,
			createApproveForAllTx: true,
			getTotalSupply: true,
			getMaxTotalSupply: true,
			getMintAvailableSupply: true,
			getPrivateSalePrice: true,
			getPublicSalePrice: true,
			getPrivateSaleHardCap: true,
			getPublicSaleHardCap: true,
			getMaxPrivateSaleMintPerTx: true,
			getMaxPublicSaleMintPerTx: true,
			getPrivateSaleMintingAmountByUserAddress: true,
			getPublicSaleMintingAmountByUserAddress: true,
			getTotalSoldPublicSale: true,
			getTotalSoldPrivateSale: true,
			getTotalAvailableForAirDrop: true,
			burn: true,
			balanceOf: true,
			getPrivateMintCountForUserAddress: true,
			getPublicMintCountForUserAddress: true,
			transferFrom: true,
			createMintPrivateSaleTx: true,
			createMintPublicSaleTx: true,
			maxPrivateSaleMintPerTx: true,
			maxPublicSaleMintPerTx: true,
			getSecurityPolicy: false,
			getWhitelistedOperators: false,
			isOperatorWhitelisted: false,
			paymentAssetAddress: true,
			getMaxTotalInvestmentHardcap: false,
			getInvestmentHardcapPerInvestor: false,
			getMinimumInvestmentAmount: false,
			getPaymentToken: false,
			getTotalInvestmentAmountRaised: false,
			createInitializeInvestmentTx: false,
			createIncreaseInvestmentTx: false,
			getInvestmentAmount: false,
			redeemBurn: false,
			createRedeemBurnTx: false,
			addressToNonce: false,
		});
	}

	public async redeemBurn(tokenId: number, payment: PaymentRequest): Promise<TransactionResponse> {
		throw new Error("Method not implemented.");
	}

	public async estimateGasPrice(tx: PopulatedTransaction): Promise<BigNumber> {
		return this.contract.estimateGasPrice(tx);
	}
	public async approve(operator: string, tokenId: number): Promise<TransactionResponse> {
		return this.contract.approve(operator, tokenId);
	}
	public async setApprovalForAll(operator: string, approved: boolean): Promise<TransactionResponse> {
		return this.contract.setApprovalForAll(operator, approved);
	}
	public async getApproved(tokenId: number): Promise<string> {
		return this.contract.getApproved(tokenId);
	}
	public async isApprovedForAll(owner: string, operator: string): Promise<boolean> {
		return this.contract.isApprovedForAll(owner, operator);
	}
	public async createApproveTx(operator: string, tokenId: number): Promise<PopulatedTransaction> {
		return this.contract.createApproveTx(operator, tokenId);
	}
	public async createApproveForAllTx(operator: string, approved: boolean): Promise<PopulatedTransaction> {
		return this.contract.createApproveForAllTx(operator, approved);
	}
	public async getTotalSupply(): Promise<number> {
		return this.contract.getTotalSupply();
	}
	public async getMaxTotalSupply(): Promise<number> {
		return this.contract.getMaxTotalSupply();
	}
	public async getMintAvailableSupply(): Promise<number> {
		return this.contract.getMintAvailableSupply();
	}
	public async getPrivateSalePrice(): Promise<BigNumber> {
		return this.contract.getPrivateSalePrice();
	}
	public async getPublicSalePrice(): Promise<BigNumber> {
		return this.contract.getPublicSalePrice();
	}
	public async getPrivateSaleHardCap(): Promise<number> {
		return this.contract.getPrivateSaleHardCap();
	}
	public async getPublicSaleHardCap(): Promise<number> {
		return this.contract.getPublicSaleHardCap();
	}
	public async getMaxPrivateSaleMintPerTx(): Promise<number> {
		return this.contract.getMaxPrivateSaleMintPerTx();
	}
	public async getMaxPublicSaleMintPerTx(): Promise<number> {
		return this.contract.getMaxPublicSaleMintPerTx();
	}
	public async getPrivateSaleMintingAmountByUserAddress(userAddress: string): Promise<number> {
		return this.contract.getPrivateSaleMintingAmountByUserAddress(userAddress);
	}
	public async getPublicSaleMintingAmountByUserAddress(userAddress: string): Promise<number> {
		return this.contract.getPublicSaleMintingAmountByUserAddress(userAddress);
	}
	public async getTotalSoldPublicSale(): Promise<number> {
		return this.contract.getTotalSoldPublicSale();
	}
	public async getTotalSoldPrivateSale(): Promise<number> {
		return this.contract.getTotalSoldPrivateSale();
	}
	public async getTotalAvailableForAirDrop(): Promise<number> {
		return this.contract.getTotalAvailableForAirDrop();
	}
	public async burn(tokenId: number): Promise<TransactionResponse> {
		return this.contract.burn(tokenId);
	}
	public async balanceOf(address: string): Promise<number> {
		return this.contract.balanceOf(address);
	}
	public async getPrivateMintCountForUserAddress(address: string): Promise<number> {
		return this.contract.getPrivateMintCountForUserAddress(address);
	}
	public async getPublicMintCountForUserAddress(address: string): Promise<number> {
		return this.contract.getPublicMintCountForUserAddress(address);
	}
	public async transferFrom(from: string, to: string, tokenId: number): Promise<TransactionResponse> {
		return this.contract.transferFrom(from, to, tokenId);
	}
	public async createMintPrivateSaleTx(
		amountToMint: number,
		splitSignature: Signature,
		recipientAddress: string,
		value: string,
	): Promise<PopulatedTransaction> {
		return this.contract.createMintPrivateSaleTx(amountToMint, splitSignature, recipientAddress, value);
	}
	public async createMintPublicSaleTx(amountToMint: number, recipientAddress: string, value: string): Promise<PopulatedTransaction> {
		return this.contract.createMintPublicSaleTx(amountToMint, recipientAddress, value);
	}
	public async maxPublicSaleMintPerTx(): Promise<BigNumber> {
		return this.contract.maxPublicSaleMintPerTx();
	}
	public async maxPrivateSaleMintPerTx(): Promise<BigNumber> {
		return this.contract.maxPrivateSaleMintPerTx();
	}
	public async getSecurityPolicy(): Promise<ISecurityPolicy> {
		throw new Error("Method not implemented.");
	}

	public async getWhitelistedOperators(): Promise<string[]> {
		throw new Error("Method not implemented.");
	}

	public async isOperatorWhitelisted(operator: string): Promise<boolean> {
		throw new Error("Method not implemented.");
	}

	public async paymentAssetAddress(): Promise<string> {
		return this.contract.paymentAssetAddress();
	}

	public async getMaxTotalInvestmentHardcap(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}

	public async getInvestmentHardcapPerInvestor(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}

	public async getMinimumInvestmentAmount(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}

	public async getPaymentToken(): Promise<string> {
		throw new Error("Method not implemented.");
	}

	public async getTotalInvestmentAmountRaised(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}

	public async createInitializeInvestmentTx(
		recipientAddress: string,
		amount: BigNumber,
		splitSignature: Signature,
	): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}

	public async createIncreaseInvestmentTx(tokenId: number, amount: BigNumber, splitSignature: Signature): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}

	public async getInvestmentAmount(tokenId: number): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}

	public async createRedeemBurnTx(tokenId: number, payment: PaymentRequest): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}

	public async addressToNonce(address: string): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
}
