import configHelper from "common/helpers/config";
import React from "react";
import ThemeMode, { EThemeModeType } from "Stores/ThemeMode";
import classes from "./classes.module.scss";

type IProps = {
	onLoaded?: () => void;
};

type IState = {
	svgContent: string | null;
};

export default class Logo extends React.Component<IProps, IState> {
	private removeOnSwitch = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			svgContent: null,
		};
		this.onUpdateTheme = this.onUpdateTheme.bind(this);
	}

	public override render(): JSX.Element | null {
		if (!this.state.svgContent) return null;
		return <div className={classes["logo"]} dangerouslySetInnerHTML={{ __html: this.state.svgContent }} />;
	}

	public override async componentDidMount() {
		this.removeOnSwitch = ThemeMode.getInstance().onSwitch(this.onUpdateTheme);
		await this.onUpdateTheme(ThemeMode.getInstance().type);
		this.props.onLoaded?.();
	}

	public override componentWillUnmount() {
		this.removeOnSwitch();
	}

	private async onUpdateTheme(type: EThemeModeType) {
		const svgContent = await this.getSvgContent(type);
		this.setState({ svgContent });
	}

	private getSrcAppLogo(type?: EThemeModeType) {
		if (type === EThemeModeType.LIGHT) {
			return "assets/images/logos/light.svg";
		}
		return "assets/images/logos/dark.svg";
	}

	private async getSvgContent(type?: EThemeModeType) {
		return await configHelper.fetchSvg(this.getSrcAppLogo(type));
	}
}
