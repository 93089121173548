import Typography from "Components/Elements/Typography";
import React from "react";
import { NavLink, To } from "react-router-dom";

import classes from "./classes.module.scss";

type IState = {};
type IProps = {
	icon?: JSX.Element;
	name?: string | JSX.Element;
	to: To;
	style?: React.CSSProperties;
	size?: "small" | "medium";
	variant?: "classic" | "menu-item";
	onClick?: () => void;
};

export default class NavLinkElement extends React.Component<IProps, IState> {
	static defaultProps = {
		size: "medium",
		variant: "classic",
	};

	constructor(props: IProps) {
		super(props);
		this.activeLink = this.activeLink.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<NavLink
				style={this.props.style}
				className={this.activeLink}
				to={this.props.to}
				data-variant={this.props.variant}
				onClick={this.props.onClick}>
				<div className={classes["content"]}>
					{this.props.icon}
					<Typography color="neutral" type="p" size={this.props.size} weight="semibold" className={classes["link"]}>
						{this.props.name}
					</Typography>
				</div>
			</NavLink>
		);
	}

	private activeLink({ isActive }: { isActive: boolean }) {
		return isActive ? [classes["route"], classes["active-route"]].join(" ") : classes["route"];
	}
}
