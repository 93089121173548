import BigNumber from "Services/BigNumber";
import { ethers } from "ethers";
import { ISetAuctionProps } from "./IncarusErc20Contract";
import BaseAuctionContract from "./BaseAuctionContract";

export default class IncarusErc20WhitelistContract extends BaseAuctionContract {
	public async setAuction({
		assetAddress,
		assetTokenId,
		biddingAssetAddress,
		reservePrice,
		buyNowPrice,
		auctionBiddingPeriod,
		auctionExpirationTimestamp,
		assetClass,
	}: ISetAuctionProps): Promise<ethers.providers.TransactionResponse> {
		return this.contract["setAuction"](
			assetAddress,
			assetTokenId,
			biddingAssetAddress,
			reservePrice.toString(10),
			buyNowPrice.toString(10),
			auctionBiddingPeriod,
			auctionExpirationTimestamp,
			assetClass,
		);
	}

	public get platformFee(): number {
		return Number(this.config?.["platformFee"]) || 0;
	}

	public async setBidOrderWithWhitelist(
		auctionId: number,
		amount: BigNumber,
		signature: ethers.Signature,
		uuid: string,
		deadline: string,
	): Promise<ethers.providers.TransactionResponse> {
		return this.contract["setBidOrderWithWhitelist"](auctionId, amount.toString(10), signature, uuid, deadline);
	}

	public async createBidOrderTxWithWhitelist(
		auctionId: number,
		amount: BigNumber,
		signature: ethers.Signature,
		uuid: string,
		deadline: string,
	): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["setBidOrderWithWhitelist"]!(auctionId, amount.toString(10), signature, uuid, deadline);
	}

	public async cancelAuction(auctionId: number): Promise<ethers.providers.TransactionResponse> {
		return this.contract["cancelAuction"](auctionId);
	}

	public async executeBuyNowWithWhitelist(
		auctionId: number,
		amount: BigNumber,
		signature: ethers.Signature,
		uuid: string,
		deadline: string,
		buyerAddress: string,
	): Promise<ethers.providers.TransactionResponse> {
		return this.contract["executeBuyNowWithWhitelist"](auctionId, amount.toString(10), signature, uuid, deadline, buyerAddress);
	}

	public async createBuyNowTxWithWhitelist(
		auctionId: number,
		amount: BigNumber,
		signature: ethers.Signature,
		uuid: string,
		deadline: string,
		buyerAddress: string,
	): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["executeBuyNowWithWhitelist"]!(
			auctionId,
			amount.toString(10),
			signature,
			uuid,
			deadline,
			buyerAddress,
		);
	}

	public async finalizeAuction(auctionId: number): Promise<ethers.providers.TransactionResponse> {
		return this.contract["finalizeAuction"](auctionId);
	}

	public async createFinalizeAuctionTx(auctionId: number): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["finalizeAuction"]!(auctionId);
	}

	public async updateAuctionBiddingPeriod(
		auctionId: number,
		auctionBiddingPeriod: number,
	): Promise<ethers.providers.TransactionResponse> {
		return this.contract["updateAuctionExpirationTimestamp"](auctionId, auctionBiddingPeriod);
	}
	public async updateAuctionExpirationTimestamp(
		auctionId: number,
		auctionExpirationTimestamp: number,
	): Promise<ethers.providers.TransactionResponse> {
		return this.contract["updateAuctionExpirationTimestamp"](auctionId, auctionExpirationTimestamp);
	}

	public async updateReservePriceAuction(auctionId: number, reservePrice: BigNumber): Promise<ethers.providers.TransactionResponse> {
		return this.contract["updateReservePriceAuction"](auctionId, reservePrice.toString(10));
	}
	public async updateAuctionBuyNowPrice(auctionId: number, buyNowPrice: BigNumber): Promise<ethers.providers.TransactionResponse> {
		return this.contract["updateAuctionBuyNowPrice"](auctionId, buyNowPrice.toString(10));
	}
}
