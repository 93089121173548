import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import React from "react";
import classes from "./classes.module.scss";
import Typography from "Components/Elements/Typography";
import I18n from "Components/Elements/I18n";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";

type IProps = {
	placeholder: string;
	textButton: string;
};

export default class NewsLetterLink extends React.Component<IProps> {
	public override render(): JSX.Element | null {
		return (
			<div className={classes["root"]}>
				{/* <InputField name="email" placeholder={this.props.placeholder} isEmail/> */}
				<Typography type="p" size="small" weight="medium">
					{I18n.translate("footer.newsletter.get_latest_updates")}
				</Typography>
				<a href={IsModuleEnabled.get().Footer.props.Newsletter.props.NewsletterLink.path} target="_blank" rel="noreferrer">
					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY}>
						{this.props.textButton}
					</Button>
				</a>
			</div>
		);
	}
}
