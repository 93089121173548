export enum AbiType {
	ROYALTIES_STORAGE = "ROYALTIES_STORAGE",
	ROYALTIES_STORAGE_29_05_23 = "ROYALTIES_STORAGE_29_05_23",
	AUCTION_PRIMARY_ERC20 = "AUCTION_PRIMARY_ERC20",
	AUCTION_PRIMARY_ERC20_WHITELIST = "AUCTION_PRIMARY_ERC20_WHITELIST",
	AUCTION_PRIMARY_NATIVE = "AUCTION_PRIMARY_NATIVE",
	AUCTION_PRIMARY_NATIVE_WHITELIST = "AUCTION_PRIMARY_NATIVE_WHITELIST",
	AUCTION_SECONDARY_ERC20 = "AUCTION_SECONDARY_ERC20",
	AUCTION_SECONDARY_NATIVE = "AUCTION_SECONDARY_NATIVE",
	MARKETPLACE_V2 = "MARKETPLACE",
	MARKETPLACE_29_05_23 = "MARKETPLACE_29_05_23",
	ERC_721_STANDARD = "ERC_721_STANDARD",
	COLLECTIO_WITH_FREE_MINT = "COLLECTIO_WITH_FREE_MINT",
	COLLECTIO_A_DEL_03_07_23 = "COLLECTIO_A_DEL_03_07_23",
	COLLECTIO_A_DEL_ERC20_21_08_23 = "COLLECTIO_A_DEL_ERC20_21_08_23",
	COLLECTIO_A_C_30_08_23 = "COLLECTIO_A_C_30_08_23",
	COLLECTIO_A_C_ERC20_RB_17_01_24 = "COLLECTIO_A_C_ERC20_RB_17_01_24",
	COLLECTIO_A_C_SBT_17_01_24 = "COLLECTIO_A_C_SBT_17_01_24",
	COLLECTIO_A_C_ERC20_15_01_24 = "COLLECTIO_A_C_ERC20_15_01_24",
	CREATOR_TOKEN_TRANSFER_VALIDATOR_31_08_23 = "CREATOR_TOKEN_TRANSFER_VALIDATOR_31_08_23",
	ERC20 = "ERC20",
	ARCA_16_03_23 = "ARCA_16_03_23",
	OXELTA_SALE_17_11_23 = "OXELTA_SALE_17_11_23",
}
