import { validateContractAddress } from "@taquito/utils";
import { isEmail, isNotEmpty, isNumberString, isString, maxLength, minLength } from "class-validator";
import { isAddress } from "ethers/lib/utils";

import AppUser from "Api/Back/AppUser";
import BigNumber from "Services/BigNumber";

const Validators = {
	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the value is not empty
	 */
	required: {
		validate: isNotEmpty,
		message: "validation_messages.field_required",
	},

	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the value is a number
	 */
	numbersOnly: {
		validate: isNumberString,
		message: "validation_messages.only_numbers",
	},

	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the value is a number
	 */
	intOnly: {
		validate: function (value: string) {
			const regex = /^[0-9]*$/;
			return regex.test(value);
		},
		message: "validation_messages.only_integers",
	},

	/**
	 * **Parameters** : number
	 *
	 * This validator verifies the number is not below the parameter
	 */
	minNumber: {
		validate: (value: string, minVal: number) => {
			if (minVal === undefined) return true;
			return Number(value) > Number(minVal);
		},
		message: "validation_messages.above_min",
	},

	/**
	 * **Parameters** : number
	 *
	 * This validator verifies the number is not above the parameter
	 */
	maxNumber: {
		validate: (value: string, maxVal: number) => {
			if (maxVal === undefined) return true;
			return Number(value) < Number(maxVal);
		},
		message: "validation_messages.below_max",
	},

	/**
	 * **Parameters** : BigNumber
	 *
	 * This validator verifies the BigNumber is not above the parameter
	 */
	maxAmount: {
		validate: (value: string, maxVal: BigNumber, decimals: number) => maxVal.gte(BigNumber.from(value, decimals)),
		message: "validation_messages.above_max_wallet_funds",
	},

	/**
	 * **Parameters** : BigNumber
	 *
	 * This validator verifies the BigNumber is not bellow the parameter
	 */
	minAmount: {
		validate: (value: string, minVal: BigNumber, decimals: number) => minVal.lte(BigNumber.from(value, decimals)),
		message: "validation_messages.need_to_be_above_current_bid",
	},

	/**
	 * **Parameters** : number
	 *
	 * This validator verifies the string minimum length is conform to the parameter
	 */
	minFieldLength: {
		validate: minLength,
		message: "validation_messages.min_length",
	},

	/**
	 * **Parameters** : number
	 *
	 * This validator verifies the string maximum length is conform to the parameter
	 */
	maxFieldLength: {
		validate: maxLength,
		message: "validation_messages.max_length",
	},
	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the input's value is a string.
	 */
	isString: {
		validate: (value: string) => isString(value),
		message: "validation_messages.only_letters",
	},

	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the input's value is conform to the tag regex.
	 */
	isTag: {
		validate: function (value: string) {
			const regex = /^[a-zA-Z0-9][a-zA-Z0-9 ]*(,[a-zA-Z0-9][a-zA-Z0-9 ]*)*$/;
			const isValid = regex.test(value);
			if (!isValid) return false;

			const splittedTag = value.split(",");
			if (splittedTag.length !== new Set(splittedTag).size) {
				return false;
			}

			return true;
		},
		message: "validation_messages.not_valid_tag",
	},
	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the input's value is a valid email.
	 *
	 * If the **input is empty, it is considered valid**. If you do not wish this
	 * to happen please refer to the `required` validator.
	 */
	isEmail: {
		validate: (value: string) => (Boolean(value) ? isEmail(value) : true),
		message: "validation_messages.invalid_email",
	},

	isPseudo: {
		validate: (value: string) => {
			const pseudoRegex = /^[a-zA-Z][a-zA-Z0-9_-]{2,19}$/;
			return pseudoRegex.test(value);
		},
		message: "validation_messages.is_pseudo",
	},

	noSpaceInString: {
		validate: (value: string) => {
			const regex = /^\S*$/;
			return regex.test(value);
		},
		message: "validation_messages.no_space_in_string",
	},

	isPositiveNumber: {
		validate: (value: string) => {
			let nbr = parseFloat(value);
			return !(isNaN(nbr) || nbr <= 0);
		},
		message: "validation_messages.positive_number",
	},

	floatPrecision: {
		validate: (value: string, precision: number) => {
			// If value is not a float
			if (isNaN(parseFloat(value))) return false;
			let splittedValue = value.split(".");
			// If there is no decimals
			if (!splittedValue[1]) return true;
			// If there is more decimals than the required precision
			if (splittedValue[1].length > precision) return false;
			return true;
		},
		message: "validation_messages.float_precision",
	},

	isValidAddress: {
		validate: (value: string) => {
			return value === "" || isAddress(value);
		},
		message: "validation_messages.invalid_wallet_address",
	},

	isUrl: {
		validate: (value: string, root: string | string[]) => {
			try {
				const url = new URL(value);
				if (root) {
					if (typeof root === "string") {
						return url.hostname === root || url.hostname === `www.${root}`;
					} else {
						return root.some((r) => url.hostname === r || url.hostname === `www.${r}`);
					}
				}
				return true;
			} catch (e) {
				return false;
			}
		},
		message: "validation_messages.invalid_url",
	},

	isUniqueUsername: {
		validate: async (value: string, current: string) => {
			try {
				const users = await AppUser.getInstance().getUsers({ userName: value });
				if (!users.metadata.count) return true;
				if (users.data.length > 1) return false;
				if (users.data[0]?.userName === current) return true;
				return false;
			} catch {
				return true;
			}
		},
		message: "validation_messages.unique_username",
	},

	isUniqueEmail: {
		validate: async (value: string, actual: string) => {
			try {
				const users = await AppUser.getInstance().getUsers({ email: value });
				if (!users.metadata.count) return true;
				if (users.data.length > 1) return false;
				if (users.data[0]?.email === actual) return true;
				return false;
			} catch {
				return true;
			}
		},
		message: "validation_messages.unique_email",
	},

	isContractAddress: {
		validate: (value: string) => {
			return value === "" || validateContractAddress(value) === 3;
		},
		message: "validation_messages.contract_address",
	},
};

export default Validators;
export type IValidationTypes = Partial<
	Record<
		keyof typeof Validators,
		| boolean
		| Partial<{
				message: string;
				args: any[];
				isErrored: (errored: boolean) => void;
		  }>
	>
>;
