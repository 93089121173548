import React from "react";
import I18n from "Components/Elements/I18n";
import Typography from "Components/Elements/Typography";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import TokenPrice from "Components/Materials/TokenPrice";
import BigNumber from "Services/BigNumber";

type IProps = {
	commissionFee: number;
	token?: AppTokenSupportEntity;
	listingPrice?: BigNumber;
	creatorRoyalty: number;
};
export default class SummaryComponent extends React.Component<IProps> {
	override render(): JSX.Element | null {
		return (
			<div className={classNames(classes["summary"], classes["border"])}>
				<Typography type="p" size="large" weight="semibold">
					<I18n map="modals.list_item.summary.title" />
				</Typography>

				<div className={classes["summary-rows"]}>
					<div className={classes["summary-row"]}>
						<Typography type="p" size="small" weight="semibold">
							<I18n map="modals.list_item.summary.listing_price" />
						</Typography>
						<div className={classes["row"]}>
							<TokenPrice
								price={this.props.listingPrice}
								token={this.props.token}
								conversionConfig={{ size: "small", direction: "row" }}
								disabledTokenPicture
							/>
						</div>
					</div>
					<div className={classes["summary-row"]}>
						<Typography type="p" size="small" weight="semibold">
							<I18n map="modals.list_item.summary.platform_fees" />
						</Typography>
						<Typography type="p" size="small" weight="regular">
							{`${this.props.commissionFee}%`}
						</Typography>
					</div>
					<div className={classes["summary-row"]}>
						<Typography type="p" size="small" weight="semibold">
							<I18n map="modals.list_item.summary.creator_royalties" />
						</Typography>
						<Typography type="p" size="small" weight="regular">
							{`${this.props.creatorRoyalty}%`}
						</Typography>
					</div>
				</div>
			</div>
		);
	}
}
