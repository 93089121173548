import { ArrowPathIcon, ArrowTopRightOnSquareIcon, XCircleIcon } from "@heroicons/react/24/outline";

import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import CheckCircleSuccessOutlinedIcon from "Components/Elements/Icons/CheckCircleSuccessOutlinedIcon";
import Loader from "Components/Elements/Loader";
import Typography from "Components/Elements/Typography";
import ConfigStore from "Stores/ConfigStore";

import { ethers } from "ethers";
import Toasts from "Stores/Toasts";

import classes from "./classes.module.scss";

async function processTransaction(transaction: () => Promise<ethers.providers.TransactionResponse>) {
	const closePendingToast = createPendingToast();

	return transaction()
		.then(async (transactionResponse) => {
			const transactionReceipt = await transactionResponse.wait();
			closePendingToast();
			createSuccessToast(transactionReceipt);
		})
		.catch((error) => {
			console.error(error);
			closePendingToast();
			createErrorToast(transaction);
			return Promise.reject(error);
		});
}

function createPendingToast() {
	return Toasts.getInstance().open({
		icon: (
			<div className={classes["loader"]}>
				<Loader />
			</div>
		),
		title: (
			<Typography type="p" size="medium" weight="medium">
				<I18n map="toasts.tx.pending.title" />
			</Typography>
		),
		text: (
			<Typography type="p" size="small" weight="regular">
				<I18n map="toasts.tx.pending.content" />
			</Typography>
		),
		closable: false,
		time: 600000, // We want it to be almost infinite but since the Toast doesn't support it, we hack it like this (=== 10min)
	});
}

function createSuccessToast(tx: ethers.providers.TransactionReceipt) {
	return Toasts.getInstance().open({
		icon: <CheckCircleSuccessOutlinedIcon />,
		title: (
			<a className={classes["title"]} href={getTxLink(tx)} target="_blank" rel="noreferrer">
				<div className={classes["container"]}>
					<Typography type="p" size="medium" weight="medium">
						<I18n map="toasts.tx.confirmed" />
					</Typography>
					<ArrowTopRightOnSquareIcon />
				</div>
			</a>
		),
		time: 5000,
	});
}

function createErrorToast(transaction: () => Promise<ethers.providers.TransactionResponse>) {
	return Toasts.getInstance().open({
		icon: <XCircleIcon className={classes["error"]} />,
		title: (
			<Typography className={classes["error"]} type="p" size="medium" weight="medium">
				<I18n map="toasts.tx.failed.title" />
			</Typography>
		),
		button: (
			<Button
				fullwidth
				onClick={() => retryOnError(transaction)}
				startIcon={<ArrowPathIcon />}
				variant={EButtonVariant.OUTLINED}
				color={EButtonColor.NEUTRAL}>
				{I18n.translate("toasts.tx.failed.button")}
			</Button>
		),
		time: 5000,
	});
}

async function retryOnError(transaction: () => Promise<ethers.providers.TransactionResponse>) {
	await TransactionToast.processTransaction(transaction);
}

function getTxLink(tx: any) {
	return `${ConfigStore.getInstance().config.blockchain.ethereum.blockExplorer}tx/${tx.transactionHash}`;
}

const TransactionToast = { processTransaction };
export default TransactionToast;
