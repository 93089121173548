import { OrderByDirection } from "common/enums/OrderByDirection";
import { Entity } from "common/models";
import { BigNumberString } from "Services/BigNumber";

import { AppBidEntity } from "./appBid";
import { AppContractEntity } from "./appContract";
import { AppSaleEntity } from "./appSale";
import { AppTokenSupportEntity } from "./appTokenSupport";
import { AppUserEntity } from "./appUser";

export enum AuctionType {
	PRIMARY = "PRIMARY",
	SECONDARY = "SECONDARY",
}

export enum AuctionStatus {
	LIVE = "LIVE",
	BIDDING = "BIDDING",
	EXPIRED = "EXPIRED",
	FINALIZING = "FINALIZING",
	FINALIZED = "FINALIZED",
	CANCELLED = "CANCELLED",
	BOUGHT = "BOUGHT",
}

export type AppAuctionEntity = Entity & {
	seller?: AppUserEntity;
	winner?: AppUserEntity | null;
	reservePrice: BigNumberString;
	buyNowPrice: BigNumberString;
	biddingPeriodInSeconds: number;
	expirationTime?: Date;
	appSale?: AppSaleEntity | null;
	appTokenSupport: AppTokenSupportEntity;
	type: AuctionType;
	status: AuctionStatus;
	onchainId: number;
	isPrivate: boolean;
	startedAt: Date;
	endedAt?: Date;
	endingAt?: Date;
	appContract?: AppContractEntity;
	highestBid?: AppBidEntity;
};

export type AppAuctionOrderBy = {
	appSale?: { appNft?: { tokenId?: OrderByDirection } };
	highestBid?: { amount?: OrderByDirection; bidder?: { appWallet?: { userAddress?: OrderByDirection } } };
	expirationTime?: OrderByDirection;
	winner?: { appWallet?: { userAddress?: OrderByDirection } };
};
