import React from "react";

import { AppNftEntity } from "Entities/appNft";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import BigNumber from "Services/BigNumber";

import Typography from "../../../../Elements/Typography";
import TokenPrice from "../../../TokenPrice";
import classes from "./classes.module.scss";
import configHelper from "common/helpers/config";

type IProps = {
	nft: AppNftEntity | null;
	price?: {
		value?: BigNumber;
		token?: AppTokenSupportEntity;
	};
};

export default class PaymentModalHeader extends React.Component<IProps> {
	private readonly defaultThumbnail = configHelper.getDefaultThumbnail();
	public override render() {
		const { nft } = this.props;

		return (
			<div className={classes["row"]}>
				<div className={classes["left"]}>
					<div className={classes["nftImage"]} style={{ backgroundImage: `url(${nft?.image?.url || this.defaultThumbnail})` }} />
					<div>
						<Typography type="p" size="small" weight="regular" color="neutral">
							{nft?.appCollection.appCollectionConfig.name}
						</Typography>
						<div className={classes["color"]}>
							<Typography type="p" size="medium" weight="semibold">
								{nft?.name}
							</Typography>
						</div>
					</div>
				</div>
				{this.props.price && (
					<TokenPrice
						price={this.props.price.value}
						token={this.props.price.token}
						conversionConfig={{ direction: "column", size: "small" }}
						typo={{ type: "p", size: "large", weight: "semibold" }}
					/>
				)}
			</div>
		);
	}
}
