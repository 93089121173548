import { PaperAirplaneIcon, QrCodeIcon } from "@heroicons/react/24/outline";
import { PlusSmallIcon } from "@heroicons/react/24/solid";
import AlertInfo, { EAlertVariants } from "Components/Elements/Alert";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import Typography from "Components/Elements/Typography";
import WalletBalanceLine from "Components/Elements/WalletBalanceLine";
import AddFunds2ModalStore from "Stores/Modals/AddFunds2ModalStore";
import UserMenuStatus from "Stores/UserMenuStatus";
import { IBalances } from "Stores/WalletBalanceStore";
import { useModal } from "hooks/useModal";
import React, { ReactElement } from "react";
import classes from "./classes.module.scss";

type IProps = {
	openChooseCryptoModal: () => void;
	openReceiveCryptoModal: () => void;
	balances: IBalances;
	hasNoTokens: boolean;
};

type IPropsClass = IProps & {
	showModal: (content: ReactElement) => void;
	hideModal: () => void;
};

class WalletSectionClass extends React.Component<IPropsClass> {
	constructor(props: IPropsClass) {
		super(props);
		this.showAddFundsModal = this.showAddFundsModal.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<div className={classes["my-wallet-container"]}>
					<Typography type="h6Mobile" weight="semibold">
						{I18n.translate("header.my_wallet")}
					</Typography>
					<IsModuleEnabled from={IsModuleEnabled.get().PaymentCreditCard}>
						<Button
							variant={EButtonVariant.TEXT}
							color={EButtonColor.PRIMARY}
							startIcon={<PlusSmallIcon />}
							onClick={this.showAddFundsModal}
							size={EButtonSize.SMALL}>
							{I18n.translate("header.add_funds")}
						</Button>
					</IsModuleEnabled>
				</div>
				<div className={classes["send-receive-container"]}>
					<Button
						variant={EButtonVariant.OUTLINED}
						size={EButtonSize.MEDIUM}
						color={EButtonColor.NEUTRAL}
						disabled={!IsModuleEnabled.get().ReceiveCrypto.enabled}
						onClick={this.props.openChooseCryptoModal}
						fullwidth
						startIcon={
							<div className={classes["paper-airplane"]}>
								<PaperAirplaneIcon />
							</div>
						}>
						{I18n.translate("header.send")}
					</Button>
					<Button
						variant={EButtonVariant.OUTLINED}
						size={EButtonSize.MEDIUM}
						color={EButtonColor.NEUTRAL}
						disabled={!IsModuleEnabled.get().SendCrypto.enabled}
						startIcon={<QrCodeIcon />}
						fullwidth
						onClick={this.props.openReceiveCryptoModal}>
						{I18n.translate("header.receive")}
					</Button>
				</div>
				<div className={classes["tokens-container"]}>
					{this.props.hasNoTokens && (
						<AlertInfo variant={EAlertVariants.IMPORTANT} className={classes["no-tokens-alert"]}>
							<I18n map="header.no_asset" />
						</AlertInfo>
					)}
					{!this.props.hasNoTokens &&
						Object.entries(this.props.balances)
							.filter(([key, balance]) => !balance.balance.isZero())
							.map(([key, balance]) => <WalletBalanceLine balance={balance} key={key} />)}
				</div>
			</div>
		);
	}

	private async showAddFundsModal() {
		AddFunds2ModalStore.getInstance().openModal({});
		UserMenuStatus.getInstance().close();
	}
}

export default function WalletSection(props: IProps): JSX.Element {
	const { showModal, hideModal } = useModal();

	return <WalletSectionClass {...props} showModal={showModal} hideModal={hideModal} />;
}
