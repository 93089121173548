import BaseApiService from "Api/BaseApiService";
import ConfigStore from "Stores/ConfigStore";
import { EntityId } from "common/models";
import { ethers } from "ethers";

export type WhitelistSignature = {
	address: string;
	signatureHash: string;
	splitSignature: ethers.Signature;
};

export default class AppWhitelist extends BaseApiService {
	private static instance: AppWhitelist;

	private getBaseUrl(appCollectionPhaseId: EntityId): string {
		return this.backUrl
			.concat("/")
			.concat(ConfigStore.getInstance().config.app)
			.concat("/app-collection-phases")
			.concat(`/${appCollectionPhaseId.toString()}`)
			.concat("/app-whitelist");
	}

	private constructor() {
		super();
		AppWhitelist.instance = this;
	}

	public static getInstance(reset?: boolean) {
		if (!AppWhitelist.instance || reset) return new this();
		return AppWhitelist.instance;
	}

	public async isWhitelisted(appCollectionPhaseId: EntityId): Promise<boolean> {
		const url = new URL(this.getBaseUrl(appCollectionPhaseId).concat("/app-whitelist-addresses"));

		try {
			return await this.getRequest<boolean>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signMessage(appCollectionPhaseId: EntityId): Promise<WhitelistSignature> {
		const url = new URL(this.getBaseUrl(appCollectionPhaseId).concat("/sign-message"));

		try {
			return await this.getRequest<WhitelistSignature>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async autoWhitelistAndSignMessage(appCollectionPhaseId: EntityId): Promise<WhitelistSignature> {
		const url = new URL(this.getBaseUrl(appCollectionPhaseId).concat("/auto-whitelist"));

		try {
			return await this.postRequest<WhitelistSignature>(url);
		} catch (error) {
			this.onError(error);
			return Promise.reject(error);
		}
	}
}
