import { OrderByDirection } from "common/enums/OrderByDirection";
import { AppActivitiesOrderBy } from "Entities/appActivity";
import { AppAuctionOrderBy } from "Entities/appAuction";
import { AppNftsOrderBy } from "Entities/appNft";
import { AppOrderOrderBy } from "Entities/appOrder";

export type PaginationMetadata = {
	count: number;
	limit: number;
	offset: number;
	total: number;
};

export type PaginatedResult<T> = {
	data: T[];
	metadata: PaginationMetadata;
};

type DefaultOrderBy = {
	createdAt: OrderByDirection;
};

export type IOrderBy = AppOrderOrderBy | AppAuctionOrderBy | AppActivitiesOrderBy | AppNftsOrderBy | DefaultOrderBy;

export type PaginationParam = {
	limit?: number;
	offset?: number;
	orderBy?: IOrderBy;
};

export default class PaginationHelper {
	private constructor() {}

	public static populateUrl(url: URL, pagination?: PaginationParam) {
		if (pagination) {
			if (pagination.limit) {
				url.searchParams.append("limit", pagination.limit.toString());
			}
			if (pagination.offset) {
				url.searchParams.append("offset", pagination.offset.toString());
			}
			if (pagination.orderBy) {
				url.searchParams.append("orderBy", JSON.stringify(pagination.orderBy));
			}
		}
	}

	public static getEmptyPaginatedResult<T>(limit?: number, offset?: number): PaginatedResult<T> {
		return {
			data: [],
			metadata: {
				count: 0,
				limit: limit ?? 0,
				offset: offset ?? 0,
				total: 0,
			},
		};
	}
}
