import { TransactionResponse } from "@ethersproject/abstract-provider";
import { PopulatedTransaction, Signature } from "ethers";
import BigNumber from "Services/BigNumber";
import Erc721ACInvestmentContract from "./Erc721ACInvestmentContract";
import BaseMarketplaceContractAdapter from "./core/BaseErc721ContractAdapter";
import { ISecurityPolicy } from "./core/Erc721Dto";
import IErc721ContractFunctions from "./core/IErc721ContractFunctions";
import { PaymentRequest } from "./CollectioACERC20RB170124Contract";

export default class Erc721ACInvestmentContractAdapter<T extends Erc721ACInvestmentContract>
	extends BaseMarketplaceContractAdapter<T>
	implements IErc721ContractFunctions
{
	public constructor(contract: T) {
		super(contract, {
			estimateGasPrice: true,
			approve: true,
			setApprovalForAll: true,
			getApproved: true,
			isApprovedForAll: true,
			createApproveTx: true,
			createApproveForAllTx: true,
			getTotalSupply: true,
			getMaxTotalSupply: true,
			getMintAvailableSupply: false,
			getPrivateSalePrice: false,
			getPublicSalePrice: false,
			getPrivateSaleHardCap: false,
			getPublicSaleHardCap: false,
			getMaxPrivateSaleMintPerTx: false,
			getMaxPublicSaleMintPerTx: false,
			getPrivateSaleMintingAmountByUserAddress: false,
			getPublicSaleMintingAmountByUserAddress: false,
			getTotalSoldPublicSale: false,
			getTotalSoldPrivateSale: false,
			getTotalAvailableForAirDrop: false,
			burn: false,
			balanceOf: true,
			getPrivateMintCountForUserAddress: false,
			getPublicMintCountForUserAddress: false,
			transferFrom: true,
			createMintPrivateSaleTx: false,
			createMintPublicSaleTx: false,
			maxPrivateSaleMintPerTx: false,
			maxPublicSaleMintPerTx: false,
			getSecurityPolicy: true,
			getWhitelistedOperators: true,
			isOperatorWhitelisted: true,
			paymentAssetAddress: false,
			getMaxTotalInvestmentHardcap: true,
			getInvestmentHardcapPerInvestor: true,
			getMinimumInvestmentAmount: true,
			getPaymentToken: true,
			getTotalInvestmentAmountRaised: true,
			createInitializeInvestmentTx: true,
			createIncreaseInvestmentTx: true,
			getInvestmentAmount: true,
			redeemBurn: false,
			createRedeemBurnTx: false,
			addressToNonce: false,
		});
	}
	public async paymentAssetAddress(): Promise<string> {
		throw new Error("Method not implemented.");
	}
	public async maxPublicSaleMintPerTx(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
	public async maxPrivateSaleMintPerTx(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
	public async getTotalSoldPublicSale(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async getTotalSoldPrivateSale(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async getTotalAvailableForAirDrop(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async burn(tokenId: number): Promise<TransactionResponse> {
		throw new Error("Method not implemented.");
	}
	public async getMintAvailableSupply(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async getPrivateSalePrice(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
	public async getPublicSalePrice(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
	public async getMaxPrivateSaleMintPerTx(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async getMaxPublicSaleMintPerTx(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async getPrivateSaleHardCap(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async getPublicSaleHardCap(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async getPrivateSaleMintingAmountByUserAddress(userAddress: string): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async getPublicSaleMintingAmountByUserAddress(userAddress: string): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async createMintPublicSaleTx(amountToMint: number, recipientAddress: string, value: string): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}
	public async createMintPrivateSaleTx(
		amountToMint: number,
		splitSignature: Signature,
		recipientAddress: string,
		value: string,
	): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}
	public async getPublicMintCountForUserAddress(address: string): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async getPrivateMintCountForUserAddress(address: string): Promise<number> {
		throw new Error("Method not implemented.");
	}
	public async estimateGasPrice(tx: PopulatedTransaction): Promise<BigNumber> {
		return this.contract.estimateGasPrice(tx);
	}
	public async approve(operator: string, tokenId: number): Promise<TransactionResponse> {
		return this.contract.approve(operator, tokenId);
	}
	public async setApprovalForAll(operator: string, approved: boolean): Promise<TransactionResponse> {
		return this.contract.setApprovalForAll(operator, approved);
	}
	public async getApproved(tokenId: number): Promise<string> {
		return this.contract.getApproved(tokenId);
	}
	public async isApprovedForAll(owner: string, operator: string): Promise<boolean> {
		return this.contract.isApprovedForAll(owner, operator);
	}
	public async createApproveTx(operator: string, tokenId: number): Promise<PopulatedTransaction> {
		return this.contract.createApproveTx(operator, tokenId);
	}
	public async createApproveForAllTx(operator: string, approved: boolean): Promise<PopulatedTransaction> {
		return this.contract.createApproveForAllTx(operator, approved);
	}
	public async getTotalSupply(): Promise<number> {
		return this.contract.getTotalSupply();
	}
	public async getMaxTotalSupply(): Promise<number> {
		return this.contract.getMaxTotalSupply();
	}
	public async getMaxTotalInvestmentHardcap(): Promise<BigNumber> {
		return this.contract.getMaxTotalInvestmentHardcap();
	}
	public async getMinimumInvestmentAmount(): Promise<BigNumber> {
		return this.contract.getMinimumInvestmentAmount();
	}
	public async getInvestmentHardcapPerInvestor(): Promise<BigNumber> {
		return this.contract.getInvestmentHardcapPerInvestor();
	}
	public async getPaymentToken(): Promise<string> {
		return this.contract.getPaymentToken();
	}
	public async getTotalInvestmentAmountRaised(): Promise<BigNumber> {
		return this.contract.getTotalInvestmentAmountRaised();
	}
	public async balanceOf(address: string): Promise<number> {
		return this.contract.balanceOf(address);
	}
	public async transferFrom(from: string, to: string, tokenId: number): Promise<TransactionResponse> {
		return this.contract.transferFrom(from, to, tokenId);
	}
	public async createInitializeInvestmentTx(
		recipientAddress: string,
		amount: BigNumber,
		splitSignature: Signature,
	): Promise<PopulatedTransaction> {
		return this.contract.createInitializeInvestmentTx(recipientAddress, amount, splitSignature);
	}
	public async createIncreaseInvestmentTx(tokenId: number, amount: BigNumber, splitSignature: Signature): Promise<PopulatedTransaction> {
		return this.contract.createIncreaseInvestmentTx(tokenId, amount, splitSignature);
	}

	public async getInvestmentAmount(tokenId: number): Promise<BigNumber> {
		return this.contract.getInvestmentAmount(tokenId);
	}
	public async getSecurityPolicy(): Promise<ISecurityPolicy> {
		return this.contract.getSecurityPolicy();
	}

	public async getWhitelistedOperators(): Promise<string[]> {
		return this.contract.getWhitelistedOperators();
	}

	public async isOperatorWhitelisted(operator: string): Promise<boolean> {
		return this.contract.isOperatorWhitelisted(operator);
	}

	public async redeemBurn(tokenId: number, payment: PaymentRequest): Promise<TransactionResponse> {
		throw new Error("Method not implemented.");
	}

	public async createRedeemBurnTx(tokenId: number, payment: PaymentRequest): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}

	public async addressToNonce(userAddressToGetCurrentNonceFrom: string): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
}
