import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import React from "react";
import Form from "../../../Elements/Form";
import classes from "./classes.module.scss";
import Typography from "Components/Elements/Typography";
import I18n from "Components/Elements/I18n";

type IProps = {
	placeholder: string;
	textButton: string;
};

export default class NewsLetterInput extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
	}

	public override render(): JSX.Element | null {
		return (
			<Form onSubmit={this.onSubmit} className={classes["root"]}>
				<Typography type="p" size="small" weight="medium">
					{I18n.translate("footer.newsletter.get_latest_updates")}
				</Typography>
				<div className={classes["input-container"]}>
					<div className={classes["input"]}></div>
					{/* <InputField name="email" placeholder={this.props.placeholder} isEmail/> */}
					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY}>
						{this.props.textButton}
					</Button>
				</div>
			</Form>
		);
	}

	private onSubmit() {
		return;
	}
}
