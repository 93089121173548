import Event from "events";

class EventEmitter extends Event {}

export default class SearchPreviewStore {
	private static ctx: SearchPreviewStore;
	private _isSearchPreviewShowed: boolean | null = null;
	private readonly event = new EventEmitter();

	private constructor() {
		SearchPreviewStore.ctx = this;
	}
    
	public static getInstance() {
		if (!SearchPreviewStore.ctx) return new this();
		return SearchPreviewStore.ctx;
	}

	public get isPreviewShowed() {
		return this._isSearchPreviewShowed;
	}

	public onChange(callback: (isShowed: boolean) => void) {
        this.event.on("change", callback);
        return () => {
            this.event.off("change", callback);
        };
	}

	public switch(isShowed: boolean) {
		if (isShowed === this.isPreviewShowed) return;
		this._isSearchPreviewShowed = isShowed;
        this.event.emit("change", this._isSearchPreviewShowed);
	}
}
