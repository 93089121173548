import AModalStore, { EModal } from "./AModalStore";
import BigNumber from "Services/BigNumber";
import { ECollectionPhaseName } from "Entities/appCollectionPhase";
import { EntityId } from "common/models";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import { AppCollectionEntity } from "Entities/appCollection";

export type ICompleteMintModalProps = {
	phaseId: EntityId;
	phaseName: ECollectionPhaseName;
	amountToMint: number;
	appCollection: AppCollectionEntity;
	price: BigNumber;
	collectionPhaseId: EntityId;
	appTokenSupport: AppTokenSupportEntity;
	onCompleteMint?: () => void;
};
export default class CompleteMintModalStore extends AModalStore<ICompleteMintModalProps> {
	private static ctx: CompleteMintModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		CompleteMintModalStore.ctx = this;
	}

	public static getInstance() {
		if (!CompleteMintModalStore.ctx) return new this();
		return CompleteMintModalStore.ctx;
	}

	public openModal(props: ICompleteMintModalProps) {
		this.close();
		this.open(EModal.COMPLETE_MINT, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
