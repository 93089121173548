import TokenPrice from "Components/Materials/TokenPrice";
import React from "react";
import { IBalance } from "Stores/WalletBalanceStore";
import Typography from "../Typography";
import classes from "./classes.module.scss"

type IProps = {
    balance: IBalance;
};

export default class WalletBalanceLine extends React.Component<IProps> {
	public override render(): React.ReactNode {
        const balance = this.props.balance;

		return (
			<div className={classes["root"]}>
				<div className={classes["name-container"]}>
					<div className={classes["logo-container"]}>
						<img
							className={classes["logo"]}
							width={25}
							height={25}
							src={balance.token.picture}
							alt={balance.token.name}
						/>
					</div>
					<div className={classes["name"]}>
						<Typography type="p" size="small" weight="semibold">
							{balance.token.name}
						</Typography>
						<Typography type="p" size="small" weight="regular" color="neutral">
							{balance.token.name}
						</Typography>
					</div>
				</div>
				<TokenPrice
					price={balance.balance}
					token={balance.token}
					conversionConfig={{ size: "small", direction: "column" }}
					disabledTokenPicture
				/>
			</div>
		);
	}
}
