import React from "react";
import LanguageModeButton from "Components/Elements/Buttons/LanguageModeButton";
import classes from "./classes.module.scss";
import Typography from "Components/Elements/Typography";
import I18n from "Components/Elements/I18n";
import ConfigStore from "Stores/ConfigStore";
import I18nStore from "Stores/I18nStore";
import classNames from "classnames";
import { AppUserEntity } from "Entities/appUser";
import UserMenuStatus, { EOpeningState } from "Stores/UserMenuStatus";

type IProps = {
	user: AppUserEntity | null;
	className?: string;
};

type IState = {
	isLanguagesMenuOpen: boolean;
};

export default class LanguageModeSwitcher extends React.Component<IProps, IState> {
	private removeOnUserMenuStatusChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			isLanguagesMenuOpen: false,
		};
		this.toggleLanguagesMenu = this.toggleLanguagesMenu.bind(this);
		this.onUserMenuStatusChange = this.onUserMenuStatusChange.bind(this);
		this.closeMenuIfNeeded = this.closeMenuIfNeeded.bind(this);
	}

	public override render(): JSX.Element {
		const supportedLanguages = ConfigStore.getInstance().config.languages.available;
		return (
			<div className={classNames(classes["root"], this.props.className)}>
				<LanguageModeButton onClick={this.toggleLanguagesMenu} />
				{this.state.isLanguagesMenuOpen && <div className={classes["background"]} onClick={this.toggleLanguagesMenu} />}
				<div
					className={classNames(classes["container"], {
						[classes["container-closed"]!]: !this.state.isLanguagesMenuOpen,
					})}>
					<Typography type="p" size="small" weight="semibold" className={classes["title"]}>
						<I18n map={"common.supported_languages"} />
					</Typography>
					<ul className={classes["container-ul"]}>
						{supportedLanguages.map((language) => (
							<li key={language} className={classes["container-li"]} onClick={(e) => this.onSelect(language)}>
								<Typography type="p" size="small" weight="regular">
									<I18n map={`common.languages.${language}`} />
								</Typography>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}

	public override componentDidMount(): void {
		this.removeOnUserMenuStatusChange = UserMenuStatus.getInstance().onSwitch(this.onUserMenuStatusChange);
	}

	public override componentWillUnmount(): void {
		this.removeOnUserMenuStatusChange();
	}

	private onUserMenuStatusChange(status: EOpeningState): void {
		if (status === EOpeningState.OPENED) {
			this.setState({ isLanguagesMenuOpen: false });
		}
	}

	private closeMenuIfNeeded(): void {
		const isSmallViewport = window.innerWidth <= 768;
		const isUserConnected = this.props.user !== null;

		// Si on est sur un petit écran, que l'utilisateur n'est pas connecté, que le menu utilisateur est ouvert et que le menu des langues est ouvert, on ferme le menu utilisateur
		if (
			isSmallViewport &&
			!isUserConnected &&
			UserMenuStatus.getInstance().status === EOpeningState.OPENED &&
			this.state.isLanguagesMenuOpen
		) {
			UserMenuStatus.getInstance().close();
		}
	}

	private toggleLanguagesMenu(): void {
		this.setState({ isLanguagesMenuOpen: !this.state.isLanguagesMenuOpen }, this.closeMenuIfNeeded);
	}

	private onSelect(language: string): void {
		I18nStore.getInstance().toggleTo(language);
		window.location.reload();
	}
}
