import { ethers } from "ethers";

import BaseApiService from "Api/BaseApiService";
import PaginationHelper, { PaginatedResult, PaginationParam } from "common/pagination";
import ConfigStore from "Stores/ConfigStore";

import { AppAuctionEntity, AuctionStatus } from "Entities/appAuction";
import { AppBidEntity } from "Entities/appBid";

type BidOnAuction = {
	signature: ethers.Signature;
	data: {
		uuid: string;
		whitelistedAddress: string;
		deadline: string;
	};
};
export default class AppAuction extends BaseApiService {
	private static instance: AppAuction;

	private constructor() {
		super();
		AppAuction.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-auctions");
	}

	public static getInstance(reset?: boolean) {
		if (!AppAuction.instance || reset) return new this();
		return AppAuction.instance;
	}

	public async getOneById(auctionId: number): Promise<AppAuctionEntity> {
		const url = new URL(this.baseUrl.concat(`/${auctionId}`));
		try {
			return await this.getRequest<AppAuctionEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async bidOnAuction(auctionId: number): Promise<BidOnAuction> {
		const url = new URL(this.baseUrl.concat(`/${auctionId}/bid`));
		try {
			return await this.postRequest<BidOnAuction>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getBidsByAuctionId(auctionId: number): Promise<PaginatedResult<AppBidEntity>> {
		const url = new URL(this.baseUrl.concat(`/${auctionId}/app-bids`));
		try {
			return await this.getRequest<PaginatedResult<AppBidEntity>>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getAuctionsBySellerAddress(
		sellerAddress: string,
		statuses?: AuctionStatus[],
		pagination?: PaginationParam,
	): Promise<PaginatedResult<AppAuctionEntity>> {
		const url = new URL(this.baseUrl);
		PaginationHelper.populateUrl(url, pagination);
		url.searchParams.append("sellerAddress", sellerAddress);

		if (statuses) {
			url.searchParams.append("statuses", JSON.stringify(statuses));
		}

		try {
			return await this.getRequest<PaginatedResult<AppAuctionEntity>>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getAuctionsByBidderAddress(
		bidderAddress: string,
		statuses?: AuctionStatus[],
		pagination?: PaginationParam,
	): Promise<PaginatedResult<AppAuctionEntity>> {
		const url = new URL(this.baseUrl);
		PaginationHelper.populateUrl(url, pagination);
		url.searchParams.append("bidderAddress", bidderAddress);

		if (statuses) {
			url.searchParams.append("statuses", JSON.stringify(statuses));
		}

		try {
			return await this.getRequest<PaginatedResult<AppAuctionEntity>>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getAuctionsByWinnerAddress(
		winnerAddress: string,
		statuses?: AuctionStatus[],
		pagination?: PaginationParam,
	): Promise<PaginatedResult<AppAuctionEntity>> {
		const url = new URL(this.baseUrl);
		PaginationHelper.populateUrl(url, pagination);
		url.searchParams.append("winnerAddress", winnerAddress);

		if (statuses) {
			url.searchParams.append("statuses", JSON.stringify(statuses));
		}

		try {
			return await this.getRequest<PaginatedResult<AppAuctionEntity>>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	// TODO: Maybe to remove
	// public async getAuctionsByNft(
	// 	collectionAddress: string,
	// 	tokenId: number,
	// ): Promise<PaginatedResult<AppAuctionEntity>> {
	// 	const url = new URL(
	// 		this.backUrl
	// 			.concat(`/${ConfigStore.getInstance().config.app}`)
	// 			.concat(`/app-collections/${collectionAddress}`)
	// 			.concat(`/app-nfts/${tokenId}`)
	// 			.concat(`/app-auctions`),
	// 	);
	// 	try {
	// 		return await this.getRequest<PaginatedResult<AppAuctionEntity>>(url);
	// 	} catch (err) {
	// 		this.onError(err);
	// 		return Promise.reject(err);
	// 	}
	// }
}
