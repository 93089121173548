import BigNumberJs from "bignumber.js";

export enum BigNumberWrapperRoundingMode {
	ROUND_UP,
	ROUND_DOWN,
	ROUND_CEIL,
	ROUND_FLOOR,
	ROUND_HALF_UP,
	ROUND_HALF_DOWN,
	ROUND_HALF_EVEN,
	ROUND_HALF_CEIL,
	ROUND_HALF_FLOOR,
}

export type BigNumberString = string;
export default class BigNumber {
	constructor(private bigNumber: BigNumberJs) {}

	public shiftLeft(n: number): BigNumber {
		return new BigNumber(this.bigNumber.shiftedBy(-n));
	}

	public from(value: string, unitName: any) {
		return BigNumber.from(value, unitName);
	}

	// https://mikemcl.github.io/bignumber.js/#rounding-mode
	public toFixed(decimals: number, roundingMode: BigNumberWrapperRoundingMode): string {
		return this.bigNumber.toFixed(decimals, roundingMode);
	}

	public static from(value?: string, base?: number) {
		if (!value) return new BigNumber(new BigNumberJs(0));
		if (base) return new BigNumber(new BigNumberJs(value).times(10 ** base));
		return new BigNumber(new BigNumberJs(value));
	}

	public getBigNumber() {
		return this.bigNumber;
	}

	public add(other: BigNumber) {
		return new BigNumber(this.bigNumber.plus(other.getBigNumber()));
	}

	public sub(other: BigNumber) {
		return new BigNumber(this.bigNumber.minus(other.getBigNumber()));
	}

	public mul(other: BigNumber | number) {
		if (other instanceof BigNumber) {
			return new BigNumber(this.bigNumber.times(other.getBigNumber()));
		}
		return new BigNumber(this.bigNumber.times(other));
	}
	public div(other: BigNumber) {
		return new BigNumber(this.bigNumber.div(other.getBigNumber()));
	}

	public static min(...xs: BigNumber[]): BigNumber {
		return new BigNumber(BigNumberJs.min(...xs.map((x) => x.getBigNumber())));
	}

	public static max(...xs: BigNumber[]): BigNumber {
		return new BigNumber(BigNumberJs.max(...xs.map((x) => x.getBigNumber())));
	}

	public clamp(min: BigNumber, max: BigNumber): BigNumber {
		return new BigNumber(BigNumberJs.min(BigNumberJs.max(this.bigNumber, min.getBigNumber()), max.getBigNumber()));
	}

	public eq(other: BigNumber) {
		return this.bigNumber.eq(other.getBigNumber());
	}

	public gt(other: BigNumber) {
		return this.bigNumber.gt(other.getBigNumber());
	}

	public gte(other: BigNumber) {
		return this.bigNumber.gte(other.getBigNumber());
	}

	public lt(other: BigNumber) {
		return this.bigNumber.lt(other.getBigNumber());
	}

	public lte(other: BigNumber) {
		return this.bigNumber.lte(other.getBigNumber());
	}

	public isZero() {
		return this.bigNumber.isZero();
	}

	public toNumber(decimals: number = 18) {
		return this.bigNumber.div(10 ** decimals).toNumber();
	}

	public removeDecimals(decimals: number = 6) {
		return new BigNumber(this.bigNumber.idiv(10 ** decimals));
	}

	public toString(base?: number) {
		return this.bigNumber.toString(base);
	}

	public toFormat(decimalPlaces: number, roundingMode: BigNumberJs.RoundingMode, format?: BigNumberJs.Format): string {
		return this.bigNumber.toFormat(decimalPlaces, roundingMode, format);
	}

	private getFormatWithRoundingMode(
		precision: number,
		maxFixedFormatLength: number,
		roundingMode: BigNumberJs.RoundingMode,
		format?: BigNumberJs.Format,
	): string | undefined {
		if (this.bigNumber.isNaN()) return undefined;
		if (this.shouldUseExponentialFormat(maxFixedFormatLength)) {
			return this.bigNumber.toExponential(precision, roundingMode);
		} else {
			const value = this.bigNumber.toFormat(precision, roundingMode, format);
			return this.trimUselessZeros(value);
		}
	}

	private shouldUseExponentialFormat(maxFixedFormatLength: number) {
		const unitsQuantity = this.bigNumber.toFixed().toString().split(".")[0]?.length || 0;
		const zerosAfterDotQuantityOfNumberUnderOne = /^0\.(0+)$/i.exec(this.bigNumber.toFixed().toString())?.[1]?.length || 0;
		const exponent = this.bigNumber.e || 0;

		return (
			unitsQuantity > maxFixedFormatLength ||
			zerosAfterDotQuantityOfNumberUnderOne > maxFixedFormatLength ||
			// for example 1e+8
			exponent > maxFixedFormatLength ||
			// for example 1e-7
			exponent <= 1 - maxFixedFormatLength
		);
	}

	private trimUselessZeros(formatted: string): string {
		const units = /^(.+)\./i.exec("" + formatted)?.[1] || formatted;
		const decimals = /(\.[0-9]+)$/i.exec("" + formatted)?.[1];
		const trimmedDecimals = (Number(decimals) || 0).toString().split(".")[1];

		const trimmedResult = `${units}${trimmedDecimals ? `.${trimmedDecimals}` : ""}`;
		return trimmedResult;
	}

	public getFormatRoundFloor(precision: number = 4, maxFixedFormatLength: number = 8, format?: BigNumberJs.Format): string | undefined {
		return this.getFormatWithRoundingMode(precision, maxFixedFormatLength, BigNumberJs.ROUND_FLOOR, format);
	}

	public getFormatRoundCeil(precision: number = 4, maxFixedFormatLength: number = 8, format?: BigNumberJs.Format): string | undefined {
		return this.getFormatWithRoundingMode(precision, maxFixedFormatLength, BigNumberJs.ROUND_CEIL, format);
	}

	public getFormatWithoutExponential(decimals = 18): string {
		return this.bigNumber.div(10 ** decimals).toString(10);
	}
}
