import Event from "events";

export enum EOpeningState {
	CLOSED = "closed",
	OPENED = "opened",
}

class EventEmitter extends Event {}

export default class HeaderStatus {
	private static ctx: HeaderStatus;

	private _status: EOpeningState = EOpeningState.OPENED;
	private readonly event = new EventEmitter();

	private constructor() {
		HeaderStatus.ctx = this;
	}

	public static getInstance() {
		if (!HeaderStatus.ctx) return new this();
		return HeaderStatus.ctx;
	}

	public get status() {
		return this._status;
	}

	public set status(status: EOpeningState) {
		this._status = status;
		this.event.emit("switch-header", this._status);
	}

	/**
	 * @returns removelistener callback
	 */
	public onSwitch(callback: (status: EOpeningState) => void) {
		this.event.on("switch-header", callback);
		return () => {
			this.event.off("switch-header", callback);
		};
	}
}
