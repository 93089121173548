import { EAppConfigHomepage } from "Entities/appConfig";

export class ConfigValidator {
	public static validateHomepage(homepage: unknown): EAppConfigHomepage | undefined {
		if (this.isEAppConfigHomepage(homepage)) {
			return homepage;
		}
		console.error(`Invalid homepage, expected one of ${Object.values(EAppConfigHomepage).join(", ")}, got ${homepage}`);
		return;
	}

	private static isEAppConfigHomepage(homepage: unknown): homepage is EAppConfigHomepage {
		return Object.values(EAppConfigHomepage).includes(homepage as EAppConfigHomepage);
	}
}
