import classNames from "classnames";

// Components
import Typography from "../Typography";

// Assets
import { ReactComponent as InfoIcon } from "assets/images/icons/information.svg";
import { ReactComponent as DangerIcon } from "assets/images/icons/danger.svg";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

// Styles
import classes from "./classes.module.scss";

export enum EAlertVariants {
	DEFAULT = "default",
	IMPORTANT = "important",
	WARNING = "warning",
	ERROR = "error",
}

type IProps = {
	variant: EAlertVariants;
	children?: React.ReactNode;
	className?: string;
};

export default function AlertInfo(props: IProps) {
	return (
		<div className={classNames(classes["root"], classes[props.variant], props.className)}>
			{renderIcon()}
			<Typography size="xsmall" type="p" weight="regular" className={classes["text"]}>
				{props.children}
			</Typography>
		</div>
	);
	function renderIcon() {
		if (props.variant === EAlertVariants.ERROR) {
			return <DangerIcon className={classes["icon"]} />;
		}
		if (props.variant === EAlertVariants.WARNING) {
			return <ExclamationCircleIcon width={24} height={24} className={classes["icon"]} />;
		}
		return <InfoIcon className={classes["icon"]} />;
	}
}
