import BaseApiService from "Api/BaseApiService";
import { AppKYCEntity } from "Entities/appKYC";

import ConfigStore from "Stores/ConfigStore";

export default class AppKYC extends BaseApiService {
	private static instance: AppKYC;

	private constructor() {
		super();
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-kycs");
	}

	public static getInstance(reset?: boolean) {
		return (AppKYC.instance = !AppKYC.instance || reset ? new AppKYC() : AppKYC.instance);
	}

	public async getOrCreate(): Promise<AppKYCEntity> {
		const url = new URL(this.baseUrl);
		try {
			return await this.postRequest<AppKYCEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async refreshVerificationStatus(sessionId: string): Promise<AppKYCEntity> {
		const url = new URL(`${this.baseUrl}/${sessionId}`);
		try {
			return await this.patchRequest<AppKYCEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
