import React from "react";

import Typography from "../../Typography";
import classes from "./classes.module.scss";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";

type IProps = {
	key?: any;
	token: AppTokenSupportEntity;
	onClick?: () => void;
};

export default class WalletOptionButton extends React.Component<IProps> {
	public override render(): JSX.Element {
		const { token } = this.props;
		return (
			<div className={classes["root"]} onClick={this.props.onClick}>
				<div className={classes["left"]}>
					<img src={this.props.token?.picture} alt="token icon" />
					<Typography type="p" size="medium" weight="semibold">
						{token?.symbol}
					</Typography>
				</div>
			</div>
		);
	}
}
