import { jwtDecode } from "jwt-decode";

import AppAuth from "Api/Back/AppAuth";
import ModalHelper from "common/helpers/modal";
import { DecodedAccessToken, JwtPair } from "Entities/accessToken";
import JwtStore from "Stores/JwtStore";
import TokenSupports from "Stores/TokenSupports";
import TopMenuStatus from "Stores/TopMenuStatus";
import UserMenuStatus from "Stores/UserMenuStatus";
import UserStore from "Stores/UserStore";
import WalletBalanceStore from "Stores/WalletBalanceStore";
import WalletStore from "Stores/WalletStore";

import CookieService from "./CookieStorageService";
import { IWalletData } from "./Wallet/IWalletInterface";
import { UserConnectionStatus } from "common/enums/UserConnectionStatus";
import ConnectWalletButtonStore from "Stores/ConnectWalletButtonStore";
import AuthConfigStore from "Stores/AuthConfigStore";

export default class StoreWorkflow {
	private static instance: StoreWorkflow;
	private readonly appAuth = AppAuth.getInstance();
	private readonly jwtStore = JwtStore.getInstance();
	private readonly userStore = UserStore.getInstance();
	private readonly cookieStorageService = CookieService.getInstance();
	private readonly authConfigStore = AuthConfigStore.getInstance();

	private constructor() {
		StoreWorkflow.instance = this;
		this.initEvents();
	}

	public static getInstance() {
		if (!StoreWorkflow.instance) return new this();
		return StoreWorkflow.instance;
	}

	public closeOnTopLayouts() {
		TopMenuStatus.getInstance().close();
		UserMenuStatus.getInstance().close();
	}

	private initEvents() {
		setInterval(async () => {
			await TokenSupports.getInstance().fetchTokenSupports();
		}, 300000);
		setInterval(async () => {
			await WalletBalanceStore.getInstance().updateBalances(TokenSupports.getInstance().getTokenSupports());
		}, 150000);
		this.onJwtPairChange();
		this.onWalletChange();
		this.onTokenSupportsChange();
		this.onUrlChange();
		this.onUserChange();
	}

	private onUrlChange() {
		window.addEventListener("popstate", () => {
			ModalHelper.closeAllModals();
		});
	}

	// Important: authentication workflow
	// => onJwtPairChange is responsible of setting user in UserStore
	// => onWalletChange is responsible of setting jwtPair, then this event will trigger the onJwtPairChange
	private onJwtPairChange() {
		this.jwtStore.onChange(async (jwtPair?: JwtPair) => {
			if (!jwtPair) {
				this.userStore.setUser(null, UserConnectionStatus.NOT_CONNECTED);
				return;
			}
			const decodedToken = jwtDecode<DecodedAccessToken>(jwtPair.accessToken);
			if (decodedToken.userAddress) {
				const user = await AppAuth.getInstance().getAuthenticatedUser();
				this.userStore.setUser(user, UserConnectionStatus.CONNECTED);
			}
			this.authConfigStore.setDetails({
				...this.authConfigStore.details,
				hasJwt: true,
			});
		});
	}

	private onWalletChange() {
		WalletStore.getInstance().onChange(async (walletData: IWalletData) => {
			try {
				if (!walletData.userAddress) {
					JwtStore.getInstance().setJwtPair((await this.appAuth.presign()).jwtPair);
					this.cookieStorageService.items.userAddress.delete();
					return;
				}
				try {
					await WalletBalanceStore.getInstance().updateBalances(TokenSupports.getInstance().getTokenSupports());
				} catch (e) {
					console.error("ERROR IN BALANCE UPDATE", e);
				}

				const jwtPair = JwtStore.getInstance().getPair();
				if (!jwtPair.accessToken) {
					await this.appAuth.presign();
				}

				const userAddress = this.cookieStorageService.items.userAddress.get();

				if (userAddress !== walletData.userAddress) {
					const accessToken = this.cookieStorageService.items.accessToken.get(walletData.userAddress);
					const refreshToken = this.cookieStorageService.items.refreshToken.get(walletData.userAddress);
					if (accessToken && refreshToken) {
						await this.appAuth.verifyToken(false);
						const decodedToken = jwtDecode<DecodedAccessToken>(accessToken);
						if (decodedToken.userAddress) {
							const user = await AppAuth.getInstance().getAuthenticatedUser();
							this.userStore.setUser(user, UserConnectionStatus.CONNECTED);
							this.jwtStore.setJwtPair({ accessToken, refreshToken }, decodedToken.userAddress);
							this.cookieStorageService.items.userAddress.set(walletData.userAddress);
						} else {
							try {
								await this.userStore.signInWallet(walletData.userAddress);
							} catch (err) {
								try {
									await UserStore.getInstance().signOut(false);
								} catch (err) {
									console.error(err);
								}
							}
						}
					} else {
						JwtStore.getInstance().setJwtPair((await this.appAuth.presign()).jwtPair);
						try {
							await this.userStore.signInWallet(walletData.userAddress);
						} catch (err) {
							try {
								await UserStore.getInstance().signOut(false);
							} catch (err) {
								console.error(err);
							}
						}
					}
				}
			} catch (e) {
				console.error(e);
			}
		});
	}

	private onUserChange() {
		UserStore.getInstance().onChange(async () => {
			ConnectWalletButtonStore.getInstance().isLoading = false;
		});

		WalletStore.getInstance().onChange(async (walletData) => {
			if (!walletData.userAddress) {
				ConnectWalletButtonStore.getInstance().isLoading = false;
				return;
			}
		});
	}

	private onTokenSupportsChange() {
		TokenSupports.getInstance().onChange(async () => {
			await WalletBalanceStore.getInstance().updateBalances(TokenSupports.getInstance().getTokenSupports());
		});
	}
}
