import React from "react";
import classNames from "classnames";

// Hooks
import { SocialMediaIconName, socialMediaIcons, useSocialMediaIcon } from "hooks/useSocialMediaIcon";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	name: string;
	href: string;
};

export default function SocialMediaButton(props: IProps) {
	const validNames = Object.keys(socialMediaIcons);
	if (!validNames.includes(props.name)) {
		throw new Error(`Invalid social media name: ${props.name}`);
	}
	const SvgIcon = useSocialMediaIcon(props.name as SocialMediaIconName);
	const fill = props.name === "externalWebsite" ? false : true;

	return (
		<a
			className={classNames(classes["root"], {
				[classes["fill"]!]: fill,
				[classes["stroke"]!]: !fill,
			})}
			href={props.href}
			target="_blank"
			rel="noreferrer"
			key={props.name}
			aria-label={`Visit our ${props.name} page`}
			>
			
			<SvgIcon />
		</a>
	);
}
