import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import { AppRuleEntity } from "Entities/appRule";
import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import JwtStore from "Stores/JwtStore";

export enum RulesMode {
	OPTIONAL = "optional",
	NECESSARY = "necessary",
}

type IProps = {
	isPage?: boolean;
	mode: RulesMode;
	rules: Omit<AppRuleEntity, "id">[];
	children: ReactNode;
	redirectTo?: string;
};

type IState = {
	show: boolean;
};

export default class Rules extends React.Component<IProps, IState> {
	private removeOnUserChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			show: this.getShowValue(),
		};
	}

	public override render() {
		if (!this.state.show && this.props.isPage) {
			return <Navigate to={this.props.redirectTo ?? IsModuleEnabled.get().pages.Home.props.path} />;
		}

		if (!JwtStore.getInstance().getAccessToken() || !this.state.show) {
			return null;
		}

		return this.props.children;
	}

	public override componentDidMount() {
		this.removeOnUserChange = JwtStore.getInstance().onChange(() => this.onUserRolesChange());
	}

	public override componentWillUnmount() {
		this.removeOnUserChange();
	}

	private onUserRolesChange() {
		this.setState({
			show: this.getShowValue(),
		});
	}

	private getShowValue() {
		if (this.props.mode === RulesMode.NECESSARY) {
			return this.props.rules.every((rule) => JwtStore.getInstance().hasRule(rule.name, rule.action));
		}
		return !!this.props.rules.find((rule) => JwtStore.getInstance().hasRule(rule.name, rule.action));
	}
}
