
import AModalStore, { EModal } from "./AModalStore";
import { ISettleAuctionModalProps as IProps } from "Components/Materials/Modals/SettleAuctionModal";

export default class SettleAuctionModalStore extends AModalStore<IProps> {
	private static ctx: SettleAuctionModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		SettleAuctionModalStore.ctx = this;
	}

	public static getInstance() {
		if (!SettleAuctionModalStore.ctx) return new this();
		return SettleAuctionModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.SETTLE_AUCTION, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
