import React from "react";

import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import BigNumber from "Services/BigNumber";

import Typography from "../../Elements/Typography";
import { IProps as ITypoProps } from "../../Elements/Typography";
import classes from "./classes.module.scss";
import configHelper from "common/helpers/config";
import classNames from "classnames";

type IProps = {
	price?: BigNumber;
	token?: AppTokenSupportEntity | null;
	typo?: Omit<ITypoProps, "children" | "className">;
	precision?: number;
	conversionConfig?: { size: "small" | "xsmall"; direction: "row" | "column" };
	disabledTokenPicture?: boolean;
	className?: string;
};

type IState = {
	convertedPrice: BigNumber;
};

export default class TokenPrice extends React.Component<IProps, IState> {
	private readonly defaultThumbnail = configHelper.getDefaultThumbnail();
	constructor(props: IProps) {
		super(props);
		this.state = {
			convertedPrice: BigNumber.from("0"),
		};
	}

	public override render(): JSX.Element {
		return (
			<div
				className={classNames(classes["root"], this.props.className)}
				data-conversion-direction={this.props.conversionConfig?.direction}>
				<div className={classes["token-price"]}>
					{!this.props.disabledTokenPicture && <img src={this.props.token?.picture || this.defaultThumbnail} alt="token icon" />}
					<Typography
						type={this.props.typo?.type ?? "p"}
						size={this.props.typo?.size ?? "small"}
						color={this.props.typo?.color ?? "neutral"}
						weight={this.props.typo?.weight ?? "semibold"}>
						{this.props.token && this.props.price
							? this.props.price.shiftLeft(this.props.token.decimals).getFormatRoundFloor(this.props.precision)
							: ""}{" "}
						{this.getFormattedTokenSymbol()}
					</Typography>
				</div>
				{this.getConvertedPrice()}
			</div>
		);
	}

	private getFormattedTokenSymbol() {
		if (!this.props.token) return;
		const isSymbolMoreThanSixChar = this.props.token.symbol.length > 6;
		return isSymbolMoreThanSixChar ? this.props.token.symbol.slice(0, 6) + "..." : this.props.token.symbol;
	}

	private getConvertedPrice() {
		if (!this.props.conversionConfig) return;
		const convertedPrice =
			this.props.token && this.props.price
				? this.props.price
						?.mul(this.props.token.USDRatio ?? 1)
						.shiftLeft(this.props.token?.decimals)
						.getFormatRoundFloor(2)
				: NaN;

		return (
			<Typography type="p" size="small" weight="regular" color="neutral">
				{convertedPrice ? "$".concat(convertedPrice.toString()) : "-"}
			</Typography>
		);
	}
}
