import React from "react";
import classes from "./classes.module.scss";
import WidgetContainer from "Components/Elements/WidgetContainer";
import { AppNftEntity } from "Entities/appNft";
import BigNumber from "Services/BigNumber";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import IconButton from "Components/Elements/IconButton";
import Cross from "Components/Elements/Icons/Cross";
import { AppCollectionEntity } from "Entities/appCollection";
import { WertPaymentStatusEventListenersParams, WertPositionEventListenersParams } from "common/helpers/wert";
import { EWidgetType } from "common/enums/Wert";

export type IWertModalProps = {
	widgetType: EWidgetType;
	nft?: AppNftEntity;
	signature?: string;
	amountToPay?: BigNumber;
	token?: AppTokenSupportEntity;
	appCollection?: AppCollectionEntity;
	onPositionChange?: (data: WertPositionEventListenersParams) => void;
	onPaymentStatusChange?: (data: WertPaymentStatusEventListenersParams) => void;
	onClose: () => void;
};

type IProps = IWertModalProps;

export default class WertModal extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);
		this.close = this.close.bind(this);
	}

	public override render(): JSX.Element | null {
		return (
			<div className={classes["root"]}>
				<IconButton icon={<Cross />} className={classes["cross"]} onClick={this.close} />
				<WidgetContainer paymentInfo={this.props} />
			</div>
		);
	}

	private close() {
		this.props.onClose();
	}
}
