import { Entity } from "common/models";

import { AppAuctionEntity } from "./appAuction";
import { AppNftEntity } from "./appNft";
import { AppOrderEntity } from "./appOrder";

export type AppLastSaleEntity = Entity & {
	appNft?: AppNftEntity;
} & (AppAuctionOfAppSale | AppOrderOfAppSale);

type AppAuctionOfAppSale = {
	appAuction: AppAuctionEntity;
};

type AppOrderOfAppSale = {
	appOrder: AppOrderEntity;
};

export function isAuction(appSale: AppLastSaleEntity): appSale is AppLastSaleEntity & AppAuctionOfAppSale {
	const appAuction = (appSale as AppLastSaleEntity & AppAuctionOfAppSale).appAuction;
	return appAuction !== undefined && appAuction !== null;
}

export function isOrder(appSale: AppLastSaleEntity): appSale is AppLastSaleEntity & AppOrderOfAppSale {
	const appOrder = (appSale as AppLastSaleEntity & AppOrderOfAppSale).appOrder;
	return appOrder !== undefined && appOrder !== null;
}
 