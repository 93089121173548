import BaseApiService from "Api/BaseApiService";
import ConfigStore from "Stores/ConfigStore";

import { AppContractEntity, ContractType } from "Entities/appContract";
import PaginationHelper, { PaginationParam } from "common/pagination";

export type IContractsFilter = {
	type?: ContractType;
};

export default class AppContract extends BaseApiService {
	private static instance: AppContract;

	private constructor() {
		super();
		AppContract.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-contracts");
	}

	public static getInstance(reset?: boolean) {
		if (!AppContract.instance || reset) return new this();
		return AppContract.instance;
	}

	public async getContracts({
		pagination,
		filter,
	}: {
		pagination?: PaginationParam;
		filter?: IContractsFilter;
	}): Promise<AppContractEntity[]> {
		const url = new URL(this.baseUrl);

		PaginationHelper.populateUrl(url, pagination);

		if (filter) {
			const filters = Object.entries(filter);
			for (const [key, value] of filters) {
				if (value === undefined) continue;

				url.searchParams.append(key, value);
			}
		}

		try {
			return await this.getRequest<AppContractEntity[]>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getContractByAddress(contractAddress: string): Promise<AppContractEntity | null> {
		const url = new URL(this.baseUrl.concat("/").concat(contractAddress));
		try {
			return await this.getRequest<AppContractEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
