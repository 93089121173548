import { ClockIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { ethers, TypedDataDomain } from "ethers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AppOrder, { CreateOrderParams } from "Api/Back/AppOrder";
import { TokenProtocol } from "common/enums/TokenProcotol";
import ToastHelper from "common/helpers/Toast";
import TransactionHelper from "common/helpers/transaction";
import TransactionToast from "common/helpers/TransactionToast";
import SelectorBar, { EChooseButtonSelected } from "Components/Elements/Buttons/ChooseButton";
import ToggleButton from "Components/Elements/Buttons/ToggleButton";
import I18n from "Components/Elements/I18n";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import PaymentModal, { EApprovedPaymentButtonState } from "Components/Elements/Modals/PaymentModal";
import { IOption } from "Components/Elements/Select";
import Typography from "Components/Elements/Typography";
import AmountFieldMaterial from "Components/Materials/Inputs/AmountFieldMaterial";
import { ContractType } from "Entities/appContract";
import { AppNftEntity } from "Entities/appNft";
import { EAssetClass, Order } from "Entities/appOrder";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import { ETokenSymbol } from "common/enums/currency";
import BigNumber from "Services/BigNumber";
import ConfigStore from "Stores/ConfigStore";
import DeprecatedContractStore from "Stores/ContractStores/DeprecatedContractStore";
import TokenSupports from "Stores/TokenSupports";
import UserStore from "Stores/UserStore";
import WalletStore from "Stores/WalletStore";
import SelectDuration from "../../SelectDuration";
import classes from "./classes.module.scss";
import PotentialEarningsComponent from "./Components/PotentialEarnings";
import SummaryComponent from "./Components/Summary";
import MarketplaceContractStore from "Stores/ContractStores/MarketplaceContractStore";
import Erc721ContractStore from "Stores/ContractStores/Erc721ContractStore";
import RoyaltiesContractStore from "Stores/ContractStores/RoyaltiesContractStore";
import { match } from "ts-pattern";
import assert from "assert";
import { useModal } from "hooks/useModal";
import { EConfirmationStatus, ListingConfirmationModal } from "Components/Elements/Modals/ConfirmationModal";
import ListingConfirmationSummary, {
	IListingConfirmationSummaryProps,
} from "Components/Elements/Modals/ConfirmationModal/ListingConfirmationSummary";
import useTokenSupport from "hooks/useTokenSupport";
import configHelper from "common/helpers/config";

export enum EListingType {
	FIXED_PRICE = "fixed_price",
	AUCTION = "auction",
}

enum ETxExecutionStatus {
	SUCCESS = "SUCCESS",
	FAILURE = "FAILURE",
}

type IListingModalProps = {
	nft: AppNftEntity;
	onClose: () => void;
};

export default function ListingModal(props: IListingModalProps) {
	const _commissionFeeConvertBase = 100;

	const { showModal, hideModal } = useModal();
	const [isListingButtonClicked, setIsListingButtonClicked] = useState(false);
	const [isListingTxExecuted, setIsListingTxExecuted] = useState(false);
	const [listingTxExecutionStatus, setListingTxExecutionStatus] = useState<ETxExecutionStatus | null>(null);
	const [listingType, setListingType] = useState(EListingType.FIXED_PRICE);
	const [fixedPrice, setFixedPrice] = useState("");
	const [auctionPrice, setAuctionPrice] = useState("");
	const [approvedPaymentButtonState, setApprovedPaymentButtonState] = useState(EApprovedPaymentButtonState.DISABLED);
	const [isBuyItNowEnabled, setIsBuyItNowEnabled] = useState(false);
	const [commissionFee, setCommissionFee] = useState(0);
	const [creatorRoyalty, setCreatorRoyalty] = useState(0);
	const [potentialFixedPriceEarnings, setPotentialFixedPriceEarnings] = useState(BigNumber.from("0"));
	const [potentialAuctionEarnings, setPotentialAuctionEarnings] = useState(BigNumber.from("0"));
	const [duration, setDuration] = useState<IOption | undefined>(undefined);
	const [token, setToken] = useState<AppTokenSupportEntity | undefined>(undefined);
	const [tx, setTx] = useState<ethers.PopulatedTransaction | undefined>(undefined);
	const { tokens } = useTokenSupport();

	const canCompleteListingBeEnabled = useMemo(() => {
		return Boolean(Number(fixedPrice) > 0 && duration && token);
	}, [fixedPrice, duration, token]);

	const createOrder = useCallback(async () => {
		if (!canCompleteListingBeEnabled) {
			const errorMessage = !fixedPrice || fixedPrice === "0" ? "Please enter a price" : "Please fill all the fields";
			ToastHelper.createErrorToast({ title: "Error", text: errorMessage });
			return;
		}
		setIsListingButtonClicked(true);

		const nft = props.nft;
		const tokenAddress = token?.address || "0x0000000000000000000000000000000000000000"; // 0x0 for MATIC token
		const seller = UserStore.getInstance().getUser();
		if (!token || !seller || !nft) return;

		const enrichedMarketplaceContract = await MarketplaceContractStore.getInstance().getEnrichedMarketplaceContract();
		const domain: TypedDataDomain = {
			name: "Marketplace",
			version: "1",
			chainId: ConfigStore.getInstance().config.blockchain.ethereum.chainId,
			verifyingContract: enrichedMarketplaceContract.contractAdapter.contract.address,
		};

		const types = {
			Order: [
				{ name: "sellerAddress", type: "address" },
				{ name: "sellTokenAddress", type: "address" },
				{ name: "sellTokenId", type: "uint256" },
				{ name: "sellTokenAmount", type: "uint256" },
				{ name: "askTokenAddress", type: "address" },
				{ name: "askTokenId", type: "uint256" },
				{ name: "askTokenAmount", type: "uint256" },
				{ name: "startTime", type: "uint256" },
				{ name: "expirationTime", type: "uint256" },
				{ name: "salt", type: "uint256" },
				{ name: "sellAssetClass", type: "uint8" },
				{ name: "askAssetClass", type: "uint8" },
			],
		};
		const startDate = new Date();
		const startTime = Math.floor(startDate.getTime() / 1000);

		const expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + Number(duration?.value));
		const expirationTime = Math.floor(expirationDate.getTime() / 1000);
		const collectionAddress = props.nft.appCollection.appContract?.address;
		if (!collectionAddress) return;

		const order: Order = {
			sellerAddress: seller.appWallet!.userAddress,
			sellTokenAddress: collectionAddress,
			sellTokenId: nft.tokenId,
			sellTokenAmount: "1",
			askTokenAddress: tokenAddress,
			askTokenId: 0,
			askTokenAmount: BigNumber.from(fixedPrice, token.decimals).toString(10),
			startTime,
			expirationTime,
			salt: 0,
			sellAssetClass: EAssetClass.ERC721,
			askAssetClass: token?.protocol === TokenProtocol.NATIVE ? EAssetClass.ETH : EAssetClass.ERC20,
		};
		const orderHash = ethers.utils._TypedDataEncoder.hash(domain, types, order);

		try {
			const orderSignature = await WalletStore.getInstance().signTypedData(domain, types, order);
			const createOrderParams: CreateOrderParams = {
				orderSignature,
				orderHash,
				order,
			};

			await AppOrder.getInstance().create(createOrderParams);
			setIsListingTxExecuted(true);
			setListingTxExecutionStatus(ETxExecutionStatus.SUCCESS);
		} catch (error) {
			setIsListingButtonClicked(false);
			setListingTxExecutionStatus(ETxExecutionStatus.FAILURE);
			console.error(error);
		}
	}, [canCompleteListingBeEnabled, props.nft, duration, fixedPrice, token]);

	const hasUserApprovedPayment = useCallback(async () => {
		const collectionAddress = props.nft.appCollection.appContract?.address;
		if (!collectionAddress) return false;

		const nftContractAddress = collectionAddress;
		const nftTokenId = props.nft.tokenId;
		if (!nftContractAddress || nftTokenId === undefined) return false;

		const enrichedMarketplaceContract = await MarketplaceContractStore.getInstance().getEnrichedMarketplaceContract();
		try {
			return await TransactionHelper.checkApproveForNft(
				nftContractAddress,
				nftTokenId,
				enrichedMarketplaceContract.contractAdapter.contract.address,
			);
		} catch (e) {
			console.error(e);
			return false;
		}
	}, [props.nft]);

	const approvePayment = useCallback(async () => {
		setApprovedPaymentButtonState(EApprovedPaymentButtonState.LOADING);
		try {
			if (!(await hasUserApprovedPayment())) {
				await TransactionToast.processTransaction(() => WalletStore.getInstance().sendTransaction(tx));
			}
			setApprovedPaymentButtonState(EApprovedPaymentButtonState.APPROVED);
		} catch (error) {
			console.error(error);
			setApprovedPaymentButtonState(EApprovedPaymentButtonState.CLICKABLE);
		}
	}, [hasUserApprovedPayment, tx]);

	const getAddFundsMessage = useCallback(() => {
		return (
			<>
				<I18n map={"modals.complete_purchase.info_1"} vars={{ tokenName: token?.name ?? "MATIC" }} />
				<strong>
					<I18n map="modals.place_bid.add_funds" />
				</strong>
			</>
		);
	}, [token]);

	const updateListingType = useCallback((selected: "left" | "right") => {
		setListingType(selected === "left" ? EListingType.FIXED_PRICE : EListingType.AUCTION);
		setIsBuyItNowEnabled(false);
		setFixedPrice("");
		setAuctionPrice("");
	}, []);

	const setMarketplaceContractFee = useCallback(async () => {
		const enrichedMarketplaceContract = await MarketplaceContractStore.getInstance().getEnrichedMarketplaceContract();

		const appContractCommissionFee = enrichedMarketplaceContract.contractEntity.config?.["commissionFee"];
		if (typeof appContractCommissionFee !== "number") return;

		const commissionFee = appContractCommissionFee / _commissionFeeConvertBase;
		setCommissionFee(commissionFee);
	}, []);

	const setAuctionContractFee = useCallback(async () => {
		const isTokenNative = token?.protocol === TokenProtocol.NATIVE;
		const enrichedAuctionContract = await DeprecatedContractStore.getInstance().getEnrichedContractByType(
			isTokenNative ? ContractType.INCARUS_SECONDARY : ContractType.INCARUS_ERC20_SECONDARY,
		);
		if (!enrichedAuctionContract) return;
		const appAuctionContractCommissionFee = enrichedAuctionContract.contractEntity.config?.["commissionFee"];
		assert(typeof appAuctionContractCommissionFee === "number", "Commission fee is not a number");

		const commissionFee = appAuctionContractCommissionFee / _commissionFeeConvertBase;
		setCommissionFee(commissionFee);
	}, [token]);

	const updateCreatorRoyalty = useCallback(async () => {
		const nft = props.nft;
		if (!nft) return;
		const collectionAddress = nft.appCollection.appContract?.address;
		if (!collectionAddress) return;

		const fixedPriceAsBigNumber = BigNumber.from(fixedPrice, token?.decimals);
		const enrichesRoyaltiesContract = await RoyaltiesContractStore.getInstance().getEnrichedRoyaltiesContract();

		const royalties = enrichesRoyaltiesContract.contractAdapter.canUse("getRoyalties")
			? await enrichesRoyaltiesContract.contractAdapter.getRoyalties(collectionAddress, nft.tokenId, fixedPriceAsBigNumber)
			: undefined;
		assert(royalties, "Royalties is undefined");

		const creatorRoyaltyAmount = royalties.amounts[0];
		const creatorRoyaltyPercentage =
			creatorRoyaltyAmount && fixedPriceAsBigNumber && fixedPriceAsBigNumber.gt(BigNumber.from("0"))
				? Number(creatorRoyaltyAmount.mul(100).div(fixedPriceAsBigNumber).getFormatRoundFloor())
				: 0;

		setCreatorRoyalty(creatorRoyaltyPercentage);
	}, [fixedPrice, token, props.nft, setCreatorRoyalty]);

	const setPlatformFee = useCallback(async () => {
		if (listingType === EListingType.FIXED_PRICE) {
			await setMarketplaceContractFee();
		} else {
			await setAuctionContractFee();
		}
		updateCreatorRoyalty();
	}, [listingType, setAuctionContractFee, setMarketplaceContractFee, updateCreatorRoyalty]);

	const getPotentialEarningFixedPrice = useCallback(() => {
		if (!fixedPrice) {
			setPotentialFixedPriceEarnings(BigNumber.from("0", token?.decimals));
			return;
		}

		const fixedPriceAsBigNumber = BigNumber.from(fixedPrice, token?.decimals);

		const totalPercentageFee = commissionFee + creatorRoyalty;
		const totalFee = fixedPriceAsBigNumber.mul(totalPercentageFee).div(BigNumber.from("100"));
		const potentialEarning = fixedPriceAsBigNumber.sub(totalFee);

		setPotentialFixedPriceEarnings(potentialEarning);
	}, [commissionFee, creatorRoyalty, fixedPrice, token]);

	const updateApprovedPaymentButtonStateIfUserHasNotApprovedPayment = useCallback(async () => {
		// If user has already approve payment, we don't need to update the button state
		if (!(await hasUserApprovedPayment())) {
			setApprovedPaymentButtonState(
				canCompleteListingBeEnabled ? EApprovedPaymentButtonState.CLICKABLE : EApprovedPaymentButtonState.DISABLED,
			);
		}
	}, [canCompleteListingBeEnabled, hasUserApprovedPayment]);

	const onTokenChange = useCallback(
		async (token: AppTokenSupportEntity) => {
			setToken(token);
			await setPlatformFee();
			getPotentialEarningFixedPrice();
			// TODO: add potential earning for auction function
		},
		[getPotentialEarningFixedPrice, setPlatformFee],
	);

	const onPriceChange = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			const { value, name } = e.currentTarget;

			match(name)
				.with("fixedPrice", () => setFixedPrice(value))
				.otherwise(() => {});

			await setPlatformFee();
			getPotentialEarningFixedPrice();
			// TODO: add potential earning for auction function
		},
		[getPotentialEarningFixedPrice, setPlatformFee],
	);

	const onToggleBuyNow = useCallback(() => {
		setIsBuyItNowEnabled((isBuyItNowEnabled) => !isBuyItNowEnabled);
		setFixedPrice(""); // reset price because this variable is used for buy now price and for creating an order
		getPotentialEarningFixedPrice();
	}, [getPotentialEarningFixedPrice]);

	const onSelectChange = useCallback(async (selectOption: IOption) => {
		setDuration(selectOption);
	}, []);

	const getAuctionView = useCallback(() => {
		return (
			<>
				<AmountFieldMaterial
					token={token}
					name="auctionPrice"
					label={I18n.translate("modals.list_item.actions.set_price")}
					displayConvertedAmount
					tokenSelect={{
						onTokenChange: onTokenChange,
						tokens: tokens,
					}}
					onChange={onPriceChange}
				/>
				<div className={classes["row"]}>
					<Typography>
						<I18n map="buttons.buy_now" />
					</Typography>
					<ToggleButton size="medium" onChange={onToggleBuyNow} />
				</div>
				{listingType === EListingType.AUCTION && isBuyItNowEnabled && (
					<AmountFieldMaterial
						token={token}
						name="fixedPrice"
						label={I18n.translate("modals.list_item.actions.set_instant_price")}
						displayConvertedAmount
						tokenSelect={{
							onTokenChange: onTokenChange,
							tokens: tokens,
						}}
						onChange={onPriceChange}
					/>
				)}
				<SelectDuration duration={duration} onChange={onSelectChange} />
			</>
		);
	}, [token, onTokenChange, tokens, onPriceChange, onToggleBuyNow, listingType, isBuyItNowEnabled, duration, onSelectChange]);

	const getFixedPriceView = useCallback(() => {
		return (
			<>
				<AmountFieldMaterial
					name="fixedPrice"
					label={I18n.translate("modals.list_item.actions.set_price")}
					displayConvertedAmount
					token={token}
					tokenSelect={{
						onTokenChange: onTokenChange,
						tokens: tokens,
					}}
					disableValidation
					value={fixedPrice}
					onChange={onPriceChange}
				/>
				<SelectDuration duration={duration} onChange={onSelectChange} />
			</>
		);
	}, [duration, fixedPrice, onPriceChange, onSelectChange, onTokenChange, token, tokens]);

	const header: JSX.Element = (
		<span>
			<I18n map="modals.list_item.title" />
		</span>
	);
	const nft = props.nft;

	const setDefaultToken = useCallback(() => {
		const token = TokenSupports.getInstance().getTokenByName(ETokenSymbol.MATIC);
		setToken(token);
	}, []);

	const initUserApprovedPayment = useCallback(async () => {
		setApprovedPaymentButtonState(
			(await hasUserApprovedPayment()) ? EApprovedPaymentButtonState.APPROVED : EApprovedPaymentButtonState.DISABLED,
		);
	}, [hasUserApprovedPayment]);

	const createApproveTx = useCallback(async () => {
		const erc721ContractAddress = props.nft.appCollection.appContract?.address;
		if (!erc721ContractAddress) return;

		const enrichedErc721Contract = await Erc721ContractStore.getInstance().getEnrichedContractByAddress(erc721ContractAddress);

		const marketplaceEnrichedContract = await MarketplaceContractStore.getInstance().getEnrichedMarketplaceContract();
		try {
			const tx = await TransactionHelper.createApproveAllNftTx(
				enrichedErc721Contract.contractAdapter.contract.address,
				marketplaceEnrichedContract.contractAdapter.contract.address,
			);
			if (!tx) throw new Error("Transaction undefined for approve payment");

			setTx(tx);
		} catch (e) {
			console.error(e);
		}
	}, [props.nft.appCollection.appContract?.address]);

	const showSuccessPurchaseConfirmationModal = useCallback(() => {
		assert(token, "Token is undefined");
		assert(duration, "Duration is undefined");

		const summary: IListingConfirmationSummaryProps = {
			tokenAmount: fixedPrice,
			tokenSymbol: token.symbol,
			usdAmount:
				BigNumber.from(fixedPrice, token.decimals).mul(token.USDRatio).shiftLeft(token.decimals).getFormatRoundFloor(2) ?? "-",
			duration: duration.label,
		};

		showModal(
			<ListingConfirmationModal
				isOpen
				status={EConfirmationStatus.SUCCESS}
				headerImageUrl={props.nft.image?.url ?? configHelper.getDefaultThumbnail()}
				onClose={hideModal}
				summary={<ListingConfirmationSummary {...summary} />}
			/>,
		);
	}, [token, fixedPrice, duration, props.nft, showModal, hideModal]);

	const showFailurePurchaseConfirmationModal = useCallback(() => {
		showModal(
			<ListingConfirmationModal
				isOpen
				status={EConfirmationStatus.FAILURE}
				headerImageUrl={props.nft.image?.url ?? configHelper.getDefaultThumbnail()}
				onClose={hideModal}
			/>,
		);
	}, [props.nft, showModal, hideModal]);

	useEffect(() => {
		setDefaultToken();
	}, [setDefaultToken]);

	useEffect(() => {
		updateApprovedPaymentButtonStateIfUserHasNotApprovedPayment();
	}, [updateApprovedPaymentButtonStateIfUserHasNotApprovedPayment]);

	useEffect(() => {
		(async () => {
			await setPlatformFee();
			createApproveTx();
		})();
	}, [createApproveTx, setPlatformFee]);

	useEffect(() => WalletStore.getInstance().onChange(initUserApprovedPayment), [initUserApprovedPayment]);

	useEffect(() => {
		initUserApprovedPayment();
	}, [initUserApprovedPayment]);

	useEffect(() => {
		getPotentialEarningFixedPrice();
	}, [creatorRoyalty, getPotentialEarningFixedPrice]);

	useEffect(() => {
		match(listingTxExecutionStatus)
			.with(null, () => {})
			.with(ETxExecutionStatus.SUCCESS, () => {
				props.onClose();
				showSuccessPurchaseConfirmationModal();
			})
			.with(ETxExecutionStatus.FAILURE, () => {
				props.onClose();
				showFailurePurchaseConfirmationModal();
			})
			.exhaustive();
	}, [listingTxExecutionStatus, props, showFailurePurchaseConfirmationModal, showSuccessPurchaseConfirmationModal]);

	return (
		<PaymentModal
			isConfirmTxExecuted={isListingTxExecuted}
			isConfirmButtonClicked={isListingButtonClicked}
			isOpen
			header={header}
			closeBtn
			onClose={props.onClose}
			onConfirm={createOrder}
			confirmText={<I18n map="modals.list_item.actions.complete_listing" />}
			approvePayment={approvePayment}
			appTokenSupport={token}
			approvePaymentText={<I18n map="buttons.approve_listing" />}
			errorMessageAddFunds={getAddFundsMessage()}
			isAddFundsDisplayed={false}
			stateApprovePaymentButton={approvedPaymentButtonState}
			openAddFundsModal={() => {}}
			isConfirmButtonDisabled={!canCompleteListingBeEnabled}
			nft={nft}>
			<div className={classes["root"]}>
				<IsModuleEnabled from={IsModuleEnabled.get().Modals.props.hasAuctionAndFixedPricePurchase}>
					<SelectorBar
						disabledRightButton={!IsModuleEnabled.get().Modals.props.CreateAuction.enabled}
						startIconLeft={<CurrencyDollarIcon />}
						startIconRight={<ClockIcon />}
						leftText="modals.list_item.sale_type.fixed_price"
						rightText="modals.list_item.sale_type.auctions"
						selected={listingType === EListingType.FIXED_PRICE ? EChooseButtonSelected.LEFT : EChooseButtonSelected.RIGHT}
						onChange={updateListingType}
						unDisplayRightButton={!IsModuleEnabled.get().Auctions.enabled}
					/>
				</IsModuleEnabled>

				{listingType === EListingType.FIXED_PRICE && getFixedPriceView()}
				<IsModuleEnabled from={IsModuleEnabled.get().Auctions}>
					{listingType === EListingType.AUCTION && getAuctionView()}
				</IsModuleEnabled>
				<SummaryComponent
					commissionFee={commissionFee}
					listingPrice={BigNumber.from(fixedPrice, token?.decimals)}
					token={token}
					creatorRoyalty={creatorRoyalty}
				/>
				<PotentialEarningsComponent
					isBuyNowEnabled={isBuyItNowEnabled}
					potentialEarningFixedPrice={potentialFixedPriceEarnings}
					potentialEarningAuction={potentialAuctionEarnings}
					token={token}
					listingType={listingType}
				/>
			</div>
		</PaymentModal>
	);
}
