import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	activeDefault?: boolean;
	onChange?: (active: boolean) => void;
	size: "small" | "medium";
};

type IState = {
	active: boolean;
};

export default class ToggleButton extends React.Component<IProps, IState> {
	static defaultProps: IProps = {
		size: "small",
	};

	constructor(props: IProps) {
		super(props);
		this.state = {
			active: this.props.activeDefault ?? false,
		};

		this.switch = this.switch.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<div data-active={this.state.active.toString()} data-size={this.props.size} className={classes["root"]} onClick={this.switch}>
				<div className={classes["point"]} />
			</div>
		);
	}

	private switch() {
		this.setState(
			(prevState) => ({
				active: !prevState.active,
			}),
			() => this.props.onChange?.(this.state.active),
		);
	}
}
