import ModalHelper from "common/helpers/modal";
import AModalStore, { EModal } from "./AModalStore";

type IProps = {}

export default class SynapsModalStore extends AModalStore<IProps> {
	private static ctx: SynapsModalStore;

	public constructor() {
		super();
		SynapsModalStore.ctx = this;
	}

	public static getInstance() {
		if (!SynapsModalStore.ctx) return new this();
		return SynapsModalStore.ctx;
	}

	public async openModal(props: IProps) {
		this.close();
		ModalHelper.closeAllModals();
		this.open(EModal.SYNAPS, props);
	}

	public closeModal() {
		this.close();
	}
}
