import { ethers } from "ethers";
import RoyaltiesStorage290523Contract from "./RoyaltiesStorage290523Contract";
import BigNumber from "Services/BigNumber";
import BaseRoyaltiesContractAdapter from "./core/BaseRoyaltiesContractAdapter";
import IRoyaltiesContractFunctions from "./core/IRoyaltiesContractFunctions";

export default class RoyaltiesStorage290523ContractAdapter<T extends RoyaltiesStorage290523Contract>
	extends BaseRoyaltiesContractAdapter<T>
	implements IRoyaltiesContractFunctions
{
	public constructor(contract: T) {
		super(contract, {
			estimateGasPrice: true,
			getRoyalties: true,
		});
	}

	public async estimateGasPrice(tx: ethers.PopulatedTransaction): Promise<BigNumber> {
		return this.contract.estimateGasPrice(tx);
	}
	public async getRoyalties(
		tokenAddress: string,
		tokenId: number,
		salePrice: BigNumber,
	): Promise<{ amounts: BigNumber[]; recipients: string[] }> {
		return this.contract.getRoyalties(tokenAddress, tokenId, salePrice);
	}
}
