import BaseErc721Contract from "./BaseErc721Contract";
import CollectioAC300823Contract from "../CollectioAC300823Contract";
import CollectioAC300823ContractAdapter from "../CollectioAC300823ContractAdapter";
import CollectioADel030723Contract from "../CollectioADel030723Contract";
import CollectioADel030723ContractAdapter from "../CollectioADel030723ContractAdapter";
import CollectioADelERC20210823Contract from "../CollectioADelERC20210823Contract";
import CollectioADelERC20210823ContractAdapter from "../CollectioADelERC20210823ContractAdapter";
import CollectioWithFreeMintContract from "../CollectioWithFreeMintContract";
import CollectioWithFreeMintContractAdapter from "../CollectioWithFreeMintContractAdapter";
import Erc721StandardContract from "../Erc721StandardContract";
import Erc721StandardContractAdapter from "../Erc721StandardContractAdapter";
import BaseErc721ContractAdapter from "./BaseErc721ContractAdapter";
import Erc721ACInvestmentContract from "../Erc721ACInvestmentContract";
import Erc721ACInvestmentContractAdapter from "../Erc721ACInvestmentContractAdapter";
import CollectioACERC20RB170124Contract from "../CollectioACERC20RB170124Contract";
import CollectioACERC20RB170124ContractAdapter from "../CollectioACERC20RB170124ContractAdapter";
import CollectioACERC20150124Contract from "../CollectioACERC20150124Contract";
import CollectioACERC20150124ContractAdapter from "../CollectioACERC20150124ContractAdapter";

export default abstract class Erc721ContractAdapterFactory {
	public static build<T extends BaseErc721Contract>(contract: T): BaseErc721ContractAdapter<T> {
		if (contract.is(CollectioAC300823Contract)) return new CollectioAC300823ContractAdapter(contract);
		if (contract.is(CollectioADel030723Contract)) return new CollectioADel030723ContractAdapter(contract);
		if (contract.is(CollectioADelERC20210823Contract)) return new CollectioADelERC20210823ContractAdapter(contract);
		if (contract.is(CollectioWithFreeMintContract)) return new CollectioWithFreeMintContractAdapter(contract);
		if (contract.is(Erc721StandardContract)) return new Erc721StandardContractAdapter(contract);
		if (contract.is(Erc721ACInvestmentContract)) return new Erc721ACInvestmentContractAdapter(contract);
		if (contract.is(CollectioACERC20RB170124Contract)) return new CollectioACERC20RB170124ContractAdapter(contract);
		if (contract.is(CollectioACERC20150124Contract)) return new CollectioACERC20150124ContractAdapter(contract);

		throw new Error("Unknown abi type");
	}
}
