import classNames from "classnames";
import React from "react";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	icon: JSX.Element;
	onClick?: () => void;
	size?: "large" | "medium" | "small";
	active?: boolean;
	fill?: boolean;
};
type IState = {};

export default class IconButton extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		return (
			<div
				className={classNames([classes["root"], classes[this.props.size ?? "medium"]])}
				onClick={this.props.onClick}
				data-active={this.props.active}
				data-fill={this.props.fill}>
				{this.props.icon}
			</div>
		);
	}
}
