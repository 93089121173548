import classNames from "classnames";
import React from "react";
import classes from "./classes.module.scss";
import { ReactComponent as CheckboxChecked } from "assets/images/icons/checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "assets/images/icons/checkbox-unchecked.svg";

type IProps = {
	checked?: boolean;
	onChange?: (checked: boolean) => void;
};

type IState = {
	checked: boolean;
};

export default class Checkbox extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			checked: this.props.checked ?? false,
		};
		this.onChange = this.onChange.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<label className={classNames(classes["root"])}>
				<span className={classes["checkbox"]}>
					<input type={"checkbox"} checked={this.state.checked} onChange={this.onChange} />

					{this.state.checked && <CheckboxChecked className={classes["checked-icon"]} />}
					{!this.state.checked && <CheckboxUnchecked className={classes["unchecked-icon"]} />}
				</span>
			</label>
		);
	}

	private onChange(e: React.SyntheticEvent<HTMLInputElement>) {
		this.props.onChange && this.props.onChange(e.currentTarget.checked);
		this.setState({
			checked: e.currentTarget.checked,
		});
	}

	static getDerivedStateFromProps(props: IProps, state: IState) {
		if (props.checked !== undefined) {
			return {
				checked: props.checked,
			};
		}
		return null;
	}
}
