import React from "react";

import AddressShort from "Components/Elements/AddressShort";
import MockedTokenIcon from "Components/Elements/Icons/MockedTokenIcon";
import Typography from "Components/Elements/Typography";
import { ETokenSymbol } from "common/enums/currency";

import { Link, To } from "react-router-dom";
import classes from "./classes.module.scss";
import configHelper from "common/helpers/config";
import SearchPreviewStore from "Stores/SearchPreviewStore";
import classNames from "classnames";

type IProps = { item: ISearchPreviewItem; label: "Collections" | "NFTs" | "Users"; togglePreview: (showPreview: boolean) => void };

export type ISearchPreviewItem = {
	id: number;
	title: string;
	subtitle: string;
	redirectLink: To;
	image?: string;
	price?: { currency: ETokenSymbol; value?: number };
};

type IState = {};

export default class SearchPreviewItem extends React.Component<IProps, IState> {
	private readonly defaultAvatar = configHelper.getDefaultAvatar();

	public override render(): JSX.Element {
		const { title, subtitle, image, price } = this.props.item;
		return (
			<Link
				className={classNames(classes["root"], "search-preview-item")}
				to={this.props.item.redirectLink}
				onClick={this.handleItemClick}>
				<div className={classes["left"]}>
					<img src={image || this.defaultAvatar} alt={title} data-is-user={this.props.label === "Users"}></img>
					<div className={classes["title-container"]}>
						<Typography type="p" size="small" weight="semibold">
							{title}
						</Typography>
						{this.props.label === "Users" ? (
							this.getUserAddress(subtitle)
						) : (
							<Typography type="p" size="small" weight="regular">
								{subtitle}
							</Typography>
						)}
					</div>
				</div>
				{price && (
					<div className={classes["right"]}>
						<MockedTokenIcon symbol={price.currency} />
						<Typography>{price.value ?? "-"}</Typography>
					</div>
				)}
			</Link>
		);
	}

	private getUserAddress(subtitle: string) {
		return <AddressShort type="p" size="small" weight="regular" text={subtitle} />;
	}

	private handleItemClick() {
		window.scrollTo(0, 0);
		SearchPreviewStore.getInstance().switch(false);
	}
}
