import AModalStore, { EModal } from "./AModalStore";
import { IAmountToSendModalProps as  IProps } from "Components/Materials/Modals/SendCryptoModals/AmountToSendModal";

export default class AmountToSendModalStore extends AModalStore<IProps> {
	private static ctx: AmountToSendModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		AmountToSendModalStore.ctx = this;
	}

	public static getInstance() {
		if (!AmountToSendModalStore.ctx) return new this();
		return AmountToSendModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.AMOUNT_TO_SEND, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
