import I18n from "Components/Elements/I18n";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import React from "react";
import NavLinkElement from "../../../Elements/NavLinkElement";
import Typography from "Components/Elements/Typography";
import classes from "./classes.module.scss";

export default class NavLinks extends React.Component {
	public override render(): JSX.Element | null {
		const headerLinks = IsModuleEnabled.get().Header.props.HeaderLinks as {
			I18nKey: string;
			link: string;
			enabled: boolean;
			isExternalLink: boolean;
		}[];

		if (!headerLinks) return null;

		return (
			<>
				{headerLinks.map((headerLink, key) => {
					if (headerLink.isExternalLink) {
						return (
							<a href={headerLink.link} target="_blank" rel="noreferrer" key={key}>
								<Typography color="neutral" type="p" weight="semibold" className={classes["links"]}>
									<I18n map={`${headerLink.I18nKey}`} />
								</Typography>
							</a>
						);
					}

					return (
						<NavLinkElement
							to={headerLink.link}
							name={I18n.translate(headerLink.I18nKey)}
							key={key}
							onClick={() => this.onClick(headerLink.link)}
						/>
					);
				})}
			</>
		);
	}

	private onClick(link: string) {
		if (link.startsWith("#")) {
			const targetSection = document.getElementById(link.replace("#", ""));
			if (targetSection) {
				targetSection.scrollIntoView({ behavior: "smooth" });
			}
		}
	}
}
