import { Entity } from "common/models";
import { AppAbiEntity } from "./appAbi";
import AppCollection from "Api/Back/AppCollection";

export enum ContractType {
	INCARUS_ERC20_SECONDARY = "Incarus_ERC20_Secondary",
	INCARUS_SECONDARY = "Incarus_Secondary",
	INCARUS_ERC20_WHITELIST_PRIMARY = "Incarus_ERC20_Whitelist_Primary",
	INCARUS_ERC20_PRIMARY = "Incarus_ERC20_Primary",
	INCARUS_PRIMARY = "Incarus_Primary",
	INCARUS_WHITELIST_PRIMARY = "Incarus_Whitelist_Primary",
	MARKETPLACE = "Marketplace",
	ROYALTIES = "RoyaltiesStorage",
	CREATOR_TOKEN_TRANSFER_VALIDATOR = "CreatorTokenTransferValidator",
	TREASURY = "Arca",
	ERC721 = "Collection",
	ERC20 = "ERC20",
}

export type AppContractEntity = Entity & {
	address: string;
	type: ContractType;
	chainId: number;
	appAbi?: AppAbiEntity | null;
	appCollection?: AppCollection | null;
	config?: Record<string, unknown>;
};
