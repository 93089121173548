import AModalStore, { EModal } from "./AModalStore";
import { IConfirmPaymentModalProps as IProps } from "Components/Materials/Modals/SendCryptoModals/ConfirmPaymentModal";

export default class ConfirmPaymentModalStore extends AModalStore<IProps> {
	private static ctx: ConfirmPaymentModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		ConfirmPaymentModalStore.ctx = this;
	}

	public static getInstance() {
		if (!ConfirmPaymentModalStore.ctx) return new this();
		return ConfirmPaymentModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.CONFIRM_PAYMENT, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
