import BaseApiService from "Api/BaseApiService";
import ConfigStore from "Stores/ConfigStore";
import { BurnRequest, PaymentRequest } from "common/helpers/burn";
import { EntityId } from "common/models";

export type SignRedeemBurnParams = {
	appRedeemAssetId: EntityId;
	burnRequest: BurnRequest;
	paymentRequest: PaymentRequest;
};

export type MintWertParams = {
	contractAddress: string;
	amountToMint: number;
	tokenSymbol: string;
	mintPrice: string;
};

export type InvestmentWertParams = {
	contractAddress: string;
	amountToInvest: string;
	tokenSymbol: string;
};

export type ReinvestmentWertParams = {
	tokenId: number;
	contractAddress: string;
	amountToInvest: string;
	tokenSymbol: string;
};

export default class AppWert extends BaseApiService {
	private static instance: AppWert;

	private constructor() {
		super();
		AppWert.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/wert");
	}

	public static getInstance(reset?: boolean) {
		if (!AppWert.instance || reset) return new this();
		return AppWert.instance;
	}

	public async signRedeemBurn(params: SignRedeemBurnParams): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-redeem-burn"));

		try {
			return await this.postRequest<string>(url, params);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signPublicAuction(auctionId: EntityId): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-public-auction"));

		try {
			return await this.postRequest<string>(url, { auctionId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signPrivateAuction(auctionId: EntityId): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-private-auction"));

		try {
			return await this.postRequest<string>(url, { auctionId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signOrder(orderId: EntityId): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-order"));

		try {
			return await this.postRequest<string>(url, { orderId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signPublicMint(mintParams: MintWertParams): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-public-mint"));

		try {
			return await this.postRequest<string>(url, mintParams);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signPrivateMint(mintParams: MintWertParams, collectionPhaseId: EntityId): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-private-mint"));

		try {
			return await this.postRequest<string>(url, { ...mintParams, collectionPhaseId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signPrivateAutoWhitelistMint(mintParams: MintWertParams, collectionPhaseId: EntityId): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-private-auto-whitelist-mint"));

		try {
			return await this.postRequest<string>(url, { ...mintParams, collectionPhaseId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signPrivateInvestment(mintParams: InvestmentWertParams, collectionPhaseId: EntityId): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-private-investment"));

		try {
			return await this.postRequest<string>(url, { ...mintParams, collectionPhaseId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signPrivateAutoWhitelistInvestment(mintParams: InvestmentWertParams, collectionPhaseId: EntityId): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-private-auto-whitelist-investment"));

		try {
			return await this.postRequest<string>(url, { ...mintParams, collectionPhaseId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signPrivateReinvestment(reinvestParams: ReinvestmentWertParams, collectionPhaseId: EntityId): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-private-reinvestment"));

		try {
			return await this.postRequest<string>(url, { ...reinvestParams, collectionPhaseId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signPrivateAutoWhitelistReinvestment(
		reinvestParams: ReinvestmentWertParams,
		collectionPhaseId: EntityId,
	): Promise<string> {
		const url = new URL(this.baseUrl.concat("/sign-private-auto-whitelist-reinvestment"));

		try {
			return await this.postRequest<string>(url, { ...reinvestParams, collectionPhaseId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async isTokenSupported(tokenSymbol: string): Promise<boolean> {
		const url = new URL(
			this.backUrl
				.concat("/wert")
				.concat("/supported-tokens/")
				.concat(tokenSymbol)
				.concat(
					`?wertApiUrl=${ConfigStore.getInstance().config.wert.origin}&network=${ConfigStore.getInstance().config.wert.network}`,
				),
		);

		try {
			return await this.getRequest<boolean>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
