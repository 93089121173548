import BigNumber from "Services/BigNumber";
import { ethers } from "ethers";
import BaseErc20Contract from "./BaseErc20Contract";

export default class Erc20Contract extends BaseErc20Contract {
	// Returns the amount of tokens owned by account.
	// Standard Erc20 function
	public async balanceOf(account: string): Promise<BigNumber> {
		return this.contract["balanceOf"](account);
	}

	// Returns the remaining number of tokens that spender will be allowed to spend on behalf of owner through transferFrom.
	// Standard Erc20 function
	public async getAllowance(owner: string, spender: string): Promise<BigNumber> {
		return this.contract["allowance"](owner, spender);
	}

	// Sets amount as the allowance of spender over the caller’s tokens.
	// Returns a boolean value indicating whether the operation succeeded.
	// Standard Erc20 function
	public async approve(spender: string, amount: BigNumber): Promise<ethers.providers.TransactionResponse> {
		return this.contract["approve"](spender, amount.toString(10));
	}

	// Moves amount tokens from the caller’s account to recipient.
	// Returns a boolean value indicating whether the operation succeeded.
	// Emits a Transfer event.
	// Standard Erc20 function
	public async transfer(to: string, amount: BigNumber): Promise<ethers.providers.TransactionResponse> {
		return this.contract["transfer"](to, amount.toString(10));
	}

	// /!\ NON Standard Erc20 function /!\
	public async increaseAllowance(spender: string, amount: BigNumber): Promise<ethers.providers.TransactionResponse> {
		return this.contract["increaseAllowance"](spender, amount.toString(10));
	}

	public async createTransferTx(to: string, amount: BigNumber): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["transfer"]!(to, amount.toString(10));
	}

	public async createIncreaseAllowanceTX(spender: string, amount: BigNumber): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["increaseAllowance"]!(spender, amount.toString(10));
	}

	public async createApproveTX(spender: string, amount: BigNumber): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["approve"]!(spender, amount.toString(10));
	}
}
