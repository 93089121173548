import configHelper from "common/helpers/config";
import React from "react";

import I18nStore from "Stores/I18nStore";

type IProps = {};

type IState = {};


export default class Documents extends React.PureComponent<IProps, IState> {
	private static _termsOfService: string = "";
	private static _nftSaleTermsAndConditions: string = "";
	private static _privacyPolicy: string = "";
	private static _legalNotice: string = "";


	private removeOnChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.updateDocumentsLanguage = this.updateDocumentsLanguage.bind(this);
	}

	public override render(): React.ReactNode {
		return null;
	}

	public static get termsOfService() {
		return this._termsOfService;
	}

	public static get nftSaleTermsAndConditions() {
		return this._nftSaleTermsAndConditions;
	}

	public static get privacyPolicy() {
		return this._privacyPolicy;
	}

	public static get legalNotice() {
		return this._legalNotice;
	}

	public override async componentDidMount() {
		this.removeOnChange = I18nStore.getInstance().onChange(this.updateDocumentsLanguage);
		this.updateDocumentsLanguage();
	}

	public override componentWillUnmount() {
		this.removeOnChange();
	}

	private updateDocumentsLanguage() {
		Documents._termsOfService = configHelper.getPdfSrc("terms_of_service", I18nStore.getInstance().lang);
		Documents._nftSaleTermsAndConditions = configHelper.getPdfSrc("nft_sale_terms_and_conditions", I18nStore.getInstance().lang);
		Documents._privacyPolicy = configHelper.getPdfSrc("privacy_policy", I18nStore.getInstance().lang);
		Documents._legalNotice = configHelper.getPdfSrc("legal_notice", I18nStore.getInstance().lang);
	}
}
