import { ReactNode } from "react";

import Validators from "../../Validators/Validators";
import BaseField, { IProps as IBaseFieldProps } from "../BaseField";
import classes from "./classes.module.scss";

export type IProps = IBaseFieldProps & {
	large?: boolean;
};

export default class InputField extends BaseField<IProps> {
	public override render(): ReactNode {
		let pattern;

		if (this.props.type === "number") {
			pattern = "(^[0-9]*)(\\.{0,1})([0-9]*)$";
		}

		if (this.props.pattern) {
			pattern = this.props.pattern;
		}

		if (this.props.fieldRef) {
			this.fieldRef = this.props.fieldRef;
		}

		// we always need to control the input so we need to set the value as "" by default
		const value = this.state.value ?? "";

		if (this.props.large === true) {
			return (
				<textarea
					placeholder={this.props.placeholder}
					maxLength={this.props.maxLength}
					name={this.props.name}
					required={this.props.required}
					ref={this.props.fieldRef}
					rows={4}
					value={value}
					onChange={this.onChange}
					data-has-validation-errors={this.state.errors.length > 0}
					className={this.props.className ? [classes["textarea"], this.props.className].join(" ") : classes["textarea"]}
				/>
			);
		} else {
			return (
				<input
					{...this.cleanHtmlAttributes()}
					ref={this.props.fieldRef}
					pattern={pattern}
					onChange={this.onChange}
					onBlur={this.onBlur}
					value={value}
					data-has-validation-errors={this.state.errors.length > 0}
					className={this.props.className ? [classes["input"], this.props.className].join(" ") : classes["input"]}
				/>
			);
		}
	}

	// We filter the props we'll pass to the primitive input as they're useless for it
	// It also avoids the console warning because of passing useless props to a primitive DOM element
	private cleanHtmlAttributes() {
		const htmlAttributes = { ...this.props };

		delete htmlAttributes.disableValidation;
		delete htmlAttributes.onErrors;
		delete htmlAttributes.fieldRef;
		delete htmlAttributes.className;
		delete htmlAttributes.defaultValue;
		delete (htmlAttributes as any).inputSize;
		delete (htmlAttributes as any).disabledErrorsMessages;

		for (const validator in Validators) {
			delete (htmlAttributes as { [key: string]: any })[validator];
		}

		return htmlAttributes;
	}
}
