import BaseApiService from "Api/BaseApiService";
import ConfigStore from "Stores/ConfigStore";

import { AppMessageEntity } from "Entities/appMessage";

type ICreateMessageReponse = AppMessageEntity;

type IPostMessage = {
	userAddress: string;
};

export default class AppMessage extends BaseApiService {
	private static instance: AppMessage;

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-messages");
	}

	private constructor() {
		super();
		AppMessage.instance = this;
	}

	public static getInstance(reset?: boolean) {
		if (!AppMessage.instance || reset) return new this();
		return AppMessage.instance;
	}

	public async create(params: IPostMessage): Promise<ICreateMessageReponse> {
		const url = new URL(this.baseUrl);
		try {
			return await this.postRequest<ICreateMessageReponse>(url, params);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
