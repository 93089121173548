import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modals";
import Typography from "Components/Elements/Typography";
import React from "react";
import { ReactComponent as Refresh } from "assets/images/icons/refresh.svg";
import { ReactComponent as RightArrow } from "assets/images/icons/right-arrow.svg";
import classes from "./classes.module.scss";
import CodeInputField from "./CodeInputField";
import AppAuth from "Api/Back/AppAuth";
import Toasts from "Stores/Toasts";
import ToastHelper from "common/helpers/Toast";
import AppUser from "Api/Back/AppUser";
import UserStore from "Stores/UserStore";
import CheckCircleSuccessOutlinedIcon from "Components/Elements/Icons/CheckCircleSuccessOutlinedIcon";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import PassKYCModalStore from "Stores/Modals/PassKYCModalStore";
import { UserConnectionStatus } from "common/enums/UserConnectionStatus";

export type IVerificationCodeModalProps = {
	email: string;
	firstName?: string;
	lastName?: string;
	userName?: string;
	userAddress: string;
	displayName?: string;
	onClose: () => void;
};

type IProps = IVerificationCodeModalProps;

type IState = {
	code: string;
	isValidateAccountButtonDisabled: boolean;
};

export default class VerificationCodeModal extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			code: "",
			isValidateAccountButtonDisabled: true,
		};

		this.close = this.close.bind(this);
		this.validateAccount = this.validateAccount.bind(this);
		this.completeLater = this.completeLater.bind(this);
		this.onChange = this.onChange.bind(this);
		this.toggleButtonValidateAccount = this.toggleButtonValidateAccount.bind(this);
		this.resendCode = this.resendCode.bind(this);
	}

	override render(): JSX.Element | null {
		return (
			<div className={classes["root"]}>
				<Modal isOpen header={I18n.translate("modals.verification_code.title")} closeBtn onClose={this.close}>
					<div className={classes["top"]}>
						<div className="description">
							<Typography type="p" size="small" weight="regular" color="neutral">
								<I18n map="modals.verification_code.description" vars={{ email: this.props.email }} />
							</Typography>
						</div>
					</div>
					<CodeInputField onChange={this.onChange} hasErrorInInput={this.toggleButtonValidateAccount} />
					<Button
						size={EButtonSize.MEDIUM}
						variant={EButtonVariant.TEXT}
						color={EButtonColor.NEUTRAL}
						startIcon={<Refresh />}
						onClick={this.resendCode}>
						<I18n map="modals.verification_code.resend" />
					</Button>
					<div className={classes["bottom-buttons"]}>
						<Button
							variant={EButtonVariant.CONTAINED}
							size={EButtonSize.MEDIUM}
							color={EButtonColor.PRIMARY}
							fullwidth
							onClick={this.validateAccount}
							endIcon={<RightArrow />}
							disabled={this.state.isValidateAccountButtonDisabled}>
							<I18n map="modals.verification_code.validate" />
						</Button>
						<Button
							variant={EButtonVariant.TEXT}
							size={EButtonSize.MEDIUM}
							color={EButtonColor.PRIMARY}
							onClick={this.completeLater}
							fullwidth>
							<I18n map="modals.verification_code.complete_later" />
						</Button>
					</div>
				</Modal>
			</div>
		);
	}

	private toggleButtonValidateAccount(isErrorInInput: boolean) {
		this.setState({ isValidateAccountButtonDisabled: isErrorInInput });
	}

	private close() {
		this.props.onClose();
	}

	private onChange(event: React.ChangeEvent<HTMLInputElement>) {
		const { value } = event.target;
		this.setState({ code: value });
	}

	private async resendCode() {
		const email = this.props.email;
		try {
			await AppAuth.getInstance().sendEmailPin({ email });
		} catch (error) {
			console.error(error);
		}
	}

	private async validateAccount(): Promise<void> {
		const { email, userAddress, firstName, userName, lastName, displayName } = this.props;
		try {
			await AppAuth.getInstance().verifyEmailPin({
				email,
				pin: this.state.code,
			});

			const user = await AppUser.getInstance().update(userAddress, {
				displayName: displayName || undefined,
				firstName: firstName || undefined,
				lastName: lastName || undefined,
				userName: userName || undefined,
				displayRegistrationModal: false,
			});

			UserStore.getInstance().setUser(user, UserConnectionStatus.CONNECTED);

			Toasts.getInstance().open({
				icon: <CheckCircleSuccessOutlinedIcon />,
				text: I18n.translate("toasts.success.save_information"),
				title: I18n.translate("toasts.success.title"),
				time: 2250,
				closable: true,
			});

			if (IsModuleEnabled.get().KYC.enabled) {
				PassKYCModalStore.getInstance().openModal({
					buttonText: "modals.pass_kyc.button.submit",
					message: "modals.pass_kyc.description",
				});
			}

			this.close();
		} catch (error) {
			ToastHelper.createErrorToast({
				title: I18n.translate("toasts.error.title"),
				text: I18n.translate("toasts.error.bad_pin"),
				hasButton: false,
			});
		}
	}

	private completeLater() {
		this.close();
	}
}
