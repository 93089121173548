import BaseMarketplaceContract from "./BaseMarketplaceContract";
import MarketplaceV2Contract from "../MarketplaceV2Contract";
import MarketplaceV2ContractAdapter from "../MarketplaceV2ContractAdapter";
import Marketplace290523Contract from "../Marketplace290523Contract";
import Marketplace290523ContractAdapter from "../Marketplace290523ContractAdapter";
import BaseMarketplaceContractAdapter from "./BaseMarketplaceContractAdapter";

export default abstract class MarketplaceContractAdapterFactory {
	public static build<T extends BaseMarketplaceContract>(contract: T): BaseMarketplaceContractAdapter<T> {
		if (contract.is(Marketplace290523Contract)) return new Marketplace290523ContractAdapter(contract);
		if (contract.is(MarketplaceV2Contract)) return new MarketplaceV2ContractAdapter(contract);

		throw new Error("Unknown abi type");
	}
}
