import React from "react";
import { Link } from "react-router-dom";

import Logo from "Components/Materials/Logo";

import NotificationButton from "Components/Elements/Buttons/NotificationButton";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import UserMenuStatus, { EOpeningState } from "Stores/UserMenuStatus";
import WindowStore from "Stores/Window";
import ConnectWalletButton from "../ConnectWalletButton";
import IsConnected from "../IsConnected";
import MainSearchBar from "../MainSearchBar";
import ProfileButton from "../ProfileButton";
import ThemeModeSwitcher from "../ThemeModeSwitcher";
import classes from "./classes.module.scss";
import NavLinks from "./NavLinks";
import MenuBurger from "../MenuBurger";
import classNames from "classnames";
import HeaderStatus from "Stores/HeaderStatus";
import LanguageModeSwitcher from "../LanguageModeSwitcher";
import UserStore from "Stores/UserStore";
import { AppUserEntity } from "Entities/appUser";

type IState = {
	headerStatus: EOpeningState;
	userMenuStatus: EOpeningState;
	user: AppUserEntity | null;
	isLogoLoaded: boolean;
};
type IProps = {};
export default class Header extends React.Component<IProps, IState> {
	private onScrollYDirectionChange = () => {};
	private removeOnUserMenuSwitch = () => {};
	private removeOnHeaderSwitch = () => {};
	private removeOnUserChange = () => {};

	constructor(props: IProps) {
		super(props);

		this.state = {
			headerStatus: HeaderStatus.getInstance().status,
			userMenuStatus: UserMenuStatus.getInstance().status,
			user: UserStore.getInstance().getUser(),
			isLogoLoaded: false,
		};

		this.toggleUserMenu = this.toggleUserMenu.bind(this);
		this.updateUserMenuStatus = this.updateUserMenuStatus.bind(this);
		this.updateHeaderStatus = this.updateHeaderStatus.bind(this);
		this.visibility = this.visibility.bind(this);
		this.updateUser = this.updateUser.bind(this);
		this.onResize = this.onResize.bind(this);
		this.onLogoLoaded = this.onLogoLoaded.bind(this);
	}
	public override render(): JSX.Element {
		return (
			<header className={classes["root"]} data-open={this.state.headerStatus}>
				<div
					className={classNames(classes["content"], {
						[classes["hide-shadow"]!]: this.state.userMenuStatus === EOpeningState.OPENED,
					})}>
					<div className={classes["segments"]}>
						<div className={[classes["segment"], classes["segment-left"]].join(" ")}>
							<IsModuleEnabled from={IsModuleEnabled.get().Header.props.Logo}>
								<Link className={classes["logo"]} to={IsModuleEnabled.get().pages.Home.props.path}  aria-label="Visit home page">
									<Logo onLoaded={this.onLogoLoaded} />
								</Link>
							</IsModuleEnabled>
							<NavLinks />
						</div>

						<div className={[classes["segment"], classes["segment-right"]].join(" ")}>
							{this.state.isLogoLoaded && (
								<IsModuleEnabled from={IsModuleEnabled.get().Header.props.SearchBar}>
									<div className={classes["searchbar"]}>
										<MainSearchBar />
									</div>
								</IsModuleEnabled>
							)}

							<IsConnected>
								<IsModuleEnabled from={IsModuleEnabled.get().Header.props.Notification}>
									<NotificationButton />
								</IsModuleEnabled>
							</IsConnected>

							<IsModuleEnabled from={IsModuleEnabled.get().Theme}>
								<IsModuleEnabled from={IsModuleEnabled.get().Header.props.ThemeMode}>
									<ThemeModeSwitcher />
								</IsModuleEnabled>
							</IsModuleEnabled>

							<IsModuleEnabled from={IsModuleEnabled.get().LanguageMode}>
								<IsModuleEnabled from={IsModuleEnabled.get().Header.props.LanguageMode}>
									<LanguageModeSwitcher
										user={this.state.user}
										className={classNames(classes["language-mode-switcher"], {
											[classes["connected"]!]: !!this.state.user,
										})}
									/>
								</IsModuleEnabled>
							</IsModuleEnabled>

							<IsConnected>
								<div className={classes["profileButton"]}>
									<ProfileButton size="medium" onClick={this.toggleUserMenu} />
								</div>
							</IsConnected>

							<IsConnected no>
								<div className={classes["connectButton"]}>
									<ConnectWalletButton />
								</div>
							</IsConnected>

							<div className={classes["burger"]}>
								<MenuBurger />
							</div>
						</div>
					</div>
				</div>
			</header>
		);
	}

	public override componentDidMount() {
		this.removeOnHeaderSwitch = HeaderStatus.getInstance().onSwitch(this.updateHeaderStatus);
		this.removeOnUserMenuSwitch = UserMenuStatus.getInstance().onSwitch(this.updateUserMenuStatus);
		this.onScrollYDirectionChange = WindowStore.getInstance().onScrollYDirectionChange(this.visibility);
		this.removeOnUserChange = UserStore.getInstance().onChange(this.updateUser);
		window.addEventListener("resize", this.onResize);
	}

	public override componentWillUnmount() {
		this.onScrollYDirectionChange();
		this.removeOnUserMenuSwitch();
		this.removeOnHeaderSwitch();
		this.removeOnUserChange();
		window.removeEventListener("resize", this.onResize);
	}

	private onLogoLoaded() {
		this.setState({
			isLogoLoaded: true,
		});
	}

	private onResize() {
		if (window.innerWidth > 1022 && this.state.userMenuStatus === EOpeningState.CLOSED) {
			// Screen size : M
			HeaderStatus.getInstance().status = EOpeningState.OPENED;
		}
	}

	private updateHeaderStatus(status: EOpeningState) {
		this.setState({
			headerStatus: status,
		});
	}

	private updateUserMenuStatus(status: EOpeningState) {
		document.body.setAttribute("user-menu-status", status);
		this.setState({
			userMenuStatus: status,
		});
	}

	private updateUser(user: AppUserEntity | null) {
		this.setState({
			user,
		});
	}

	private visibility(scrollYDirection: number) {
		if (window.innerWidth > 1022) {
			// Screen size : M
			return;
		}

		let headerStatus: EOpeningState = EOpeningState.OPENED;
		if (window.scrollY > 150 && scrollYDirection < 0 && Math.abs(scrollYDirection) > 8) {
			headerStatus = EOpeningState.CLOSED;
		}

		if (headerStatus !== this.state.headerStatus) {
			HeaderStatus.getInstance().status = headerStatus;
		}

		if (window.innerWidth < 767) {
			UserMenuStatus.getInstance().close();
		}
	}

	private toggleUserMenu() {
		UserMenuStatus.getInstance().toggle();
	}
}
