import { ReactComponent as Moon } from "assets/images/icons/moon.svg";
import { ReactComponent as Sun } from "assets/images/icons/sun.svg";
import IconButton from "Components/Elements/Buttons/IconButton";
import React from "react";
import { EThemeModeType } from "Stores/ThemeMode";

type IProps = {
	onClick?: () => void;
	theme: EThemeModeType;
};

export default class ThemeModeButton extends React.Component<IProps> {
	public override render(): JSX.Element {
		return <IconButton onClick={this.props.onClick} icon={this.getIcon()} />
	}

	private getIcon() {
		if (this.props.theme === EThemeModeType.LIGHT) return <Moon />;
		return <Sun />;
	}
}
