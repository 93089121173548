import React, { ReactElement } from "react";
import AddressButton from "Components/Elements/Buttons/AddressButton";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modals";
import Typography from "Components/Elements/Typography";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import { AppUserEntity } from "Entities/appUser";
import BigNumber from "Services/BigNumber";
import UserStore from "Stores/UserStore";
import WalletBalanceStore from "Stores/WalletBalanceStore";
import WalletStore from "Stores/WalletStore";
import IsModuleEnabled from "../../../Elements/IsModuleEnabled";
import classes from "./classes.module.scss";
import AppWert from "Api/Back/AppWert";
import { useModal } from "hooks/useModal";
import WertModal from "../WertModal";
import { EWidgetType } from "common/enums/Wert";

type IProps = {
	amountToPay: BigNumber;
	token: AppTokenSupportEntity;
	onClose: () => void;
};

type IPropsClass = IProps & {
	showModal: (content: ReactElement) => void;
	hideModal: () => void;
};

type IState = {
	address?: string | null;
	isTokenSupported: boolean;
};

class AddFundsClass extends React.Component<IPropsClass, IState> {
	private removeOnUserChange = () => {};

	constructor(props: IPropsClass) {
		super(props);

		this.state = {
			address: WalletStore.getInstance().walletData.userAddress,
			isTokenSupported: false,
		};

		this.close = this.close.bind(this);
		this.updateUser = this.updateUser.bind(this);
		this.addFundsByCreditCard = this.addFundsByCreditCard.bind(this);
	}

	override render(): JSX.Element | null {
		return (
			<div className={classes["root"]}>
				<Modal isOpen header={I18n.translate("modals.add_funds.title")} closeBtn onClose={this.close}>
					<div className={classes["top"]}>
						<Typography type="h6Mobile" size="small" weight="bold">
							{`${I18n.translate("modals.add_funds.need")} ${this.getAmountToPayFormated()} ${
								this.props.token.symbol
							} ${I18n.translate("modals.add_funds.plus_gaz_fee")}`}
						</Typography>
						<div className="description">
							<Typography type="p" size="small" weight="regular" color="neutral">
								{I18n.translate("modals.add_funds.description")}
							</Typography>
						</div>
					</div>
					<div className={classes["walletInfo"]}>
						<div className={classes["labelWalletInfo"]}>
							<div className={classes["labelRight"]}>
								<Typography type="p" size="small" weight="semibold">
									<>
										{I18n.translate("modals.add_funds.your")} {this.props.token.symbol}{" "}
										{I18n.translate("modals.add_funds.wallet")}
									</>
								</Typography>
							</div>
							<div className={classes["labelLeft"]}>
								<Typography type="p" size="small" weight="medium">
									<>
										{I18n.translate("modals.add_funds.balance")} {this.getCurrentBalanceFormated()}{" "}
										{this.props.token.symbol}
									</>
								</Typography>
							</div>
						</div>
						<div className={classes["adressButtonContainer"]}>
							<AddressButton address={this.state.address ?? ""} size={this.renderAddressButtonBasedOnWindowWidth()} />
						</div>
					</div>
					<div className={classes["bottomButtons"]}>
						<Button
							variant={EButtonVariant.CONTAINED}
							size={EButtonSize.MEDIUM}
							color={EButtonColor.PRIMARY}
							onClick={this.close}>
							<I18n map="modals.add_funds.continue" />
						</Button>
						<IsModuleEnabled from={IsModuleEnabled.get().PaymentCreditCard}>
							<Button
								variant={EButtonVariant.TEXT}
								size={EButtonSize.MEDIUM}
								color={EButtonColor.PRIMARY}
								onClick={this.addFundsByCreditCard}
								disabled={!this.state.isTokenSupported}>
								{I18n.translate("modals.add_funds.add_funds_with_cards")}
							</Button>
						</IsModuleEnabled>
					</div>
				</Modal>
			</div>
		);
	}

	public override async componentDidMount() {
		this.removeOnUserChange = UserStore.getInstance().onChange(this.updateUser);
		const isTokenSupported = await AppWert.getInstance().isTokenSupported(this.props.token.symbol);
		this.setState({ isTokenSupported });
	}

	public override async componentDidUpdate(prevProps: IPropsClass) {
		if (this.props.token !== prevProps.token) {
			const isTokenSupported = await AppWert.getInstance().isTokenSupported(this.props.token.symbol);
			this.setState({ isTokenSupported });
		}
	}

	public override componentWillUnmount() {
		this.removeOnUserChange();
	}

	private close() {
		this.props.onClose();
	}

	private updateUser(user: AppUserEntity | null) {
		this.setState({ address: user?.appWallet?.userAddress });
	}

	private getCurrentBalanceFormated() {
		return WalletBalanceStore.getInstance()
			.balances[this.props.token.name]?.balance.shiftLeft(this.props.token.decimals)
			.getFormatRoundFloor(4);
	}

	private getAmountToPayFormated() {
		return this.props.amountToPay.shiftLeft(this.props.token.decimals).getFormatRoundFloor(4);
	}

	private async addFundsByCreditCard() {
		this.props.showModal(
			<WertModal
				widgetType={EWidgetType.ADD_FUNDS}
				amountToPay={BigNumber.from("0")}
				token={this.props.token}
				onClose={this.props.hideModal}
			/>,
		);
	}

	private renderAddressButtonBasedOnWindowWidth() {
		if (window.innerWidth < 424) {
			return "medium";
		} else {
			return "large";
		}
	}
}

export default function AddFundsModal(props: IProps) {
	const { showModal, hideModal } = useModal();
	return <AddFundsClass {...props} showModal={showModal} hideModal={hideModal} />;
}
