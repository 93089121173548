import React from "react";

import classes from "./classes.module.scss";

type IProps = { borderRightCollapsed?: boolean; onChange?: React.ChangeEventHandler<HTMLInputElement> };

type IState = {};

export default class InputFiatAmount extends React.Component<IProps, IState> {
	public override render() {
		return (
			<div className={classes["root"]} data-border-right-collapsed={this.props.borderRightCollapsed}>
				<input onChange={this.props.onChange} placeholder={"$0.00"} type="string" />
			</div>
		);
	}
}
