import React from "react";

// Components
import Typography from "Components/Elements/Typography";

// Assets
import { ReactComponent as Cross } from "assets/images/icons/cross.svg";

// Stores
import Toasts, { IToast as ToastProps } from "Stores/Toasts";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	toast: ToastProps;
};

type IState = {
	willClose: boolean;
};

export default class ToastElement extends React.Component<IProps, IState> {
	private closeTimeout = 0;
	constructor(props: IProps) {
		super(props);

		this.state = {
			willClose: false,
		};

		this.onClose = this.onClose.bind(this);
	}

	public override render(): JSX.Element {
		const toast = this.props.toast;
		const style = {
			"--data-duration": `${toast.time}ms`,
		} as React.CSSProperties;
		return (
			<div className={classes["root"]} data-will-close={this.state.willClose}>
				{toast.time !== 0 && <div className={classes["loadbar"]} style={style} />}
				<div className={classes["header"]}>
					<div className={classes["text-icon_row"]}>
						<div className={classes["icon"]}>{toast.icon}</div>
						<div className={classes["text-container"]}>
							{this.getToastTitle(toast.title)}
							{this.getToastText(toast.text)}
						</div>
					</div>
					{toast.closable && <Cross className={classes["cross"]} onClick={this.onClose} />}
				</div>
				{toast.button && <div className={classes["button"]}>{toast.button}</div>}
			</div>
		);
	}

	public override componentDidMount() {
		if (this.props.toast.time === 0) return;

		this.closeTimeout = window.setTimeout(() => {
			this.close();
		}, this.props.toast.time);
	}

	private getToastTitle(title: string | React.ReactNode) {
		if (typeof title === "string") {
			return (
				<Typography className={classes["title"]} type="p" size="medium" weight="medium">
					{title}
				</Typography>
			);
		}
		return title;
	}

	private getToastText(text: React.ReactNode) {
		if (typeof text === "string") {
			return (
				<div className={classes["text-container"]}>
					<Typography className={classes["title"]} type="p" size="small" weight="regular">
						{text}
					</Typography>
				</div>
			);
		}
		return text;
	}

	private onClose(e: React.MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
		this.close();
	}

	private close() {
		window.clearTimeout(this.closeTimeout);
		this.setState({
			willClose: true,
		});
		setTimeout(() => {
			Toasts.getInstance().close(this.props.toast);
		}, 200);
	}
}
