import React from "react";
import { ReactComponent as LoaderIcon } from "assets/images/icons/loader.svg";
import classes from "./classes.module.scss";
import classNames from "classnames";

interface IProps {
	className?: string;
}

export default class Loader extends React.Component<IProps> {
	public override render(): JSX.Element {
		return <LoaderIcon className={classNames(classes["root"], this.props.className)} />;
	}
}
