import Typography from "Components/Elements/Typography";
import I18nStore from "Stores/I18nStore";
import classes from "./classes.module.scss";
import { ReactComponent as MaintenanceIcon } from "assets/images/icons/maintenance.svg";

export default function Maintenance(): JSX.Element {
	return (
		<div className={classes["root"]}>
			<MaintenanceIcon />
			<div className={classes["text"]}>
				<Typography type="h1" className={classes["title"]}>
					{I18nStore.getInstance().translate("maintenance.title")}
				</Typography>
				<Typography type="p">{I18nStore.getInstance().translate("maintenance.subtitle")}</Typography>
			</div>
		</div>
	);
}
