import RoyaltiesStorage290523Contract from "../RoyaltiesStorage290523Contract";
import RoyaltiesStorage290523ContractAdapter from "../RoyaltiesStorage290523ContractAdapter";
import RoyaltiesStorageContract from "../RoyaltiesStorageContract";
import RoyaltiesStorageContractAdapter from "../RoyaltiesStorageContractAdapter";
import BaseRoyaltiesContract from "./BaseRoyaltiesContract";
import BaseRoyaltiesContractAdapter from "./BaseRoyaltiesContractAdapter";

export default abstract class RoyaltiesContractAdapterFactory {
	public static build<T extends BaseRoyaltiesContract>(contract: T): BaseRoyaltiesContractAdapter<T> {
		if (contract.is(RoyaltiesStorage290523Contract)) return new RoyaltiesStorage290523ContractAdapter(contract);
		if (contract.is(RoyaltiesStorageContract)) return new RoyaltiesStorageContractAdapter(contract);

		throw new Error("Unknown abi type");
	}
}
