import React, { ReactNode } from "react";

import { ReactComponent as Cross } from "assets/images/icons/cross.svg";

import IconButton from "../Buttons/IconButton";
import Typography from "../Typography";
import classes from "./classes.module.scss";
import Footer from "./Elements/Footer";
import Header from "./Elements/Header";
import Loader from "./Elements/Loader";
import classNames from "classnames";

export enum EModalVariant {
	NORMAL = "normal",
	WERT = "wert",
}

export type IModalProps = {
	closeBtn?: boolean;
	header?: string | JSX.Element;
	footer?: JSX.Element;
	textLoader?: string | JSX.Element;
	isOpen: boolean;
	onClose: () => void;
	hasTransparentBackground?: boolean;
	hasContainerClosable?: boolean;
	withSideBackground?: boolean;
	children?: ReactNode;
	className?: string;
	variant?: EModalVariant;
};

export default class Modal<P extends IModalProps> extends React.Component<P> {
	constructor(props: P) {
		super(props);
		this.close = this.close.bind(this);
		this.closeOnBackgroundClick = this.closeOnBackgroundClick.bind(this);
	}

	public override render(): JSX.Element | null {
		if (!this.props.isOpen) return null;

		return (
			<div className={classes["root"]} data-side-background={this.props.withSideBackground}>
				<div className={classes["background"]} onClick={this.closeOnBackgroundClick} />
				<div
					className={classNames(
						classes["container"],
						{
							[classes["wert"]!]: this.props.variant === EModalVariant.WERT,
							[classes["transparant-background"]!]: this.props.hasTransparentBackground,
						},
						this.props.className,
					)}
					onClick={this.props.hasContainerClosable && this.close}>
					{this.props.closeBtn && (
						<div className={classes["cross"]} onClick={this.close}>
							<IconButton icon={<Cross />} />
						</div>
					)}
					<div className={classes["sub-container"]}>
						{this.props.header && <Header content={this.props.header} />}
						<Typography color="neutral" type="div" size="small" weight="regular">
							{this.props.children ? this.props.children : <Loader text={this.props.textLoader} />}
						</Typography>
						{this.props.children && this.props.footer && <Footer content={this.props.footer} />}
					</div>
				</div>
			</div>
		);
	}

	public override componentDidUpdate(prevProps: Readonly<P>): void {
		if (prevProps.isOpen !== this.props.isOpen) {
			this.setState({
				isOpen: this.props.isOpen,
			});
			if (this.props.isOpen) document.body.setAttribute("overlay-open", "true");
			else document.body.setAttribute("overlay-open", "false");
		}
	}

	private closeOnBackgroundClick() {
		this.close();
	}

	protected close() {
		document.body.setAttribute("overlay-open", "false");
		this.props.onClose();
	}
}
