import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import { TokenProtocol } from "common/enums/TokenProcotol";

enum EChainId {
	Ethereum = 1,
	EthereumGoerli = 5,
	Polygon = 137,
	PolygonMumbai = 80001,
	BinanceSmartChain = 56,
	BinanceSmartChainTestnet = 97,
	Sepolia = 11155111,
	Base = 84532,
}

enum ENativeTokenSymbol {
	ETH = "ETH",
	MATIC = "MATIC",
	BNB = "BNB",
}

const chainIdToNativeTokenName: Map<number, string> = new Map([
	[EChainId.Ethereum, ENativeTokenSymbol.ETH],
	[EChainId.EthereumGoerli, ENativeTokenSymbol.ETH],
	[EChainId.Polygon, ENativeTokenSymbol.MATIC],
	[EChainId.PolygonMumbai, ENativeTokenSymbol.MATIC],
	[EChainId.BinanceSmartChain, ENativeTokenSymbol.BNB],
	[EChainId.BinanceSmartChainTestnet, ENativeTokenSymbol.BNB],
	[EChainId.Sepolia, ENativeTokenSymbol.ETH],
	[EChainId.Base, ENativeTokenSymbol.ETH],
]);

function isTokenNative(token?: AppTokenSupportEntity) {
	return token?.protocol === TokenProtocol.NATIVE;
}

const TokenHelper = {
	isTokenNative,
	chainIdToNativeTokenName,
};

export default TokenHelper;
