import Typography from "Components/Elements/Typography";
import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	content: string | JSX.Element;
};

export default class Header extends React.Component<IProps> {
	public override render(): JSX.Element {
		return (
			<header className={classes["root"]}>
				<Typography type="h5" weight="bold">
					{this.props.content}
				</Typography>
			</header>
		);
	}
}
