import { JwtPair } from "Entities/accessToken";
import CookieService from "Services/CookieStorageService";
import defer from "defer-promise";

import JwtStore from "Stores/JwtStore";
import WalletStore from "Stores/WalletStore";
import ConfigStore from "Stores/ConfigStore";
import I18nStore, { SupportedLanguage } from "Stores/I18nStore";

export enum ContentType {
	JSON = "application/json",
	FORM_DATA = "multipart/form-data;",
}

export default abstract class BaseApiService {
	private static queueAllRequests: boolean = false;
	private static queuedRequests: DeferPromise.Deferred<void> | null = null;

	private get jwtStore() {
		return JwtStore.getInstance();
	}

	private get configStore() {
		return ConfigStore.getInstance();
	}

	protected get backUrl() {
		return this.configStore.config.api.back;
	}

	private get cookieStorageService() {
		return CookieService.getInstance();
	}
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected constructor() {}

	protected buildHeaders(contentType: ContentType) {
		const headers = new Headers();

		if (contentType === ContentType.JSON) {
			headers.set("Content-Type", contentType);
		}

		const token = this.jwtStore.getAccessToken();

		if (token) {
			headers.set("auth-token", token);
		}

		const lang = I18nStore.getInstance().lang;
		headers.set("lang", lang || SupportedLanguage.EN);

		return headers;
	}

	protected buildBody(body: { [key: string]: unknown }): string {
		return JSON.stringify(body);
	}

	protected async getRequest<T>(url: URL, handleTokenExpiration = true, synchroneRequest = false) {
		const request = async () => {
			return fetch(url, {
				method: "GET",
				headers: this.buildHeaders(ContentType.JSON),
			});
		};

		return this.stackSendRequest<T>(request, handleTokenExpiration, synchroneRequest);
	}

	protected async postRequest<T>(
		url: URL,
		body: { [key: string]: unknown } = {},
		handleTokenExpiration = true,
		synchroneRequest = false,
		disconnectUser = true,
	) {
		const request = async () => {
			return fetch(url, {
				method: "POST",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});
		};
		return this.stackSendRequest<T>(request, handleTokenExpiration, synchroneRequest, disconnectUser);
	}

	protected async putRequest<T>(url: URL, body: { [key: string]: unknown } = {}, handleTokenExpiration = true, synchroneRequest = false) {
		const request = async () => {
			return fetch(url, {
				method: "PUT",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});
		};

		return this.stackSendRequest<T>(request, handleTokenExpiration, synchroneRequest);
	}

	protected async patchRequest<T>(
		url: URL,
		body: { [key: string]: unknown } = {},
		handleTokenExpiration = true,
		synchroneRequest = false,
	) {
		const request = async () => {
			return fetch(url, {
				method: "PATCH",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});
		};

		return this.stackSendRequest<T>(request, handleTokenExpiration, synchroneRequest);
	}

	protected async deleteRequest<T>(
		url: URL,
		body: { [key: string]: unknown } = {},
		handleTokenExpiration = true,
		synchroneRequest = false,
	) {
		const request = async () => {
			return fetch(url, {
				method: "DELETE",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});
		};

		return this.stackSendRequest<T>(request, handleTokenExpiration, synchroneRequest);
	}

	protected async patchFormDataRequest<T>(url: URL, body: FormData, handleTokenExpiration = true, synchroneRequest = false) {
		const request = async () => {
			return fetch(url, {
				method: "PATCH",
				headers: this.buildHeaders(ContentType.FORM_DATA),
				body,
			});
		};

		return this.stackSendRequest<T>(request, handleTokenExpiration, synchroneRequest);
	}

	//
	private async stackSendRequest<T>(
		request: () => Promise<Response>,
		handleTokenExpiration = true,
		synchroneRequest = false,
		disconnectUser = true,
	): Promise<T> {
		if (BaseApiService.queueAllRequests) {
			BaseApiService.queuedRequests = BaseApiService.queuedRequests ?? defer();
			return BaseApiService.queuedRequests.promise.then(() => {
				return this.sendRequest<T>(request, handleTokenExpiration, synchroneRequest, disconnectUser);
			});
		}

		if (synchroneRequest) {
			BaseApiService.queueAllRequests = true;
		}

		const p = this.sendRequest<T>(request, handleTokenExpiration, synchroneRequest);

		p.then(() => {
			if (synchroneRequest) {
				BaseApiService.queueAllRequests = false;
				BaseApiService.queuedRequests?.resolve();
				BaseApiService.queuedRequests = null;
			}
		}).catch((err) => {
			if (synchroneRequest) {
				BaseApiService.queueAllRequests = false;
				BaseApiService.queuedRequests?.reject(err);
				BaseApiService.queuedRequests = null;
			}
		});
		return p;
	}

	private async sendRequest<T>(
		request: () => Promise<Response>,
		handleTokenExpiration = true,
		synchroneRequest = false,
		disconnectUser = true,
	): Promise<T> {
		const response = await request();
		return this.processResponse<T>(response, request, handleTokenExpiration, disconnectUser);
	}

	protected async processResponse<T>(
		response: Response,
		request: () => Promise<Response>,
		handleTokenExpiration = true,
		disconnectUser = true,
	): Promise<T> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let responseJson: any | null;
		try {
			responseJson = await response.json();
		} catch (err: unknown) {
			responseJson = null;
		}

		if (
			handleTokenExpiration &&
			responseJson?.error?.message &&
			["TOKEN_EXPIRED", "jwt expired"].includes(responseJson.error.message)
		) {
			try {
				await this.baseRefreshToken();

				const retryRequestResponse = await request();

				if (!retryRequestResponse.ok) {
					return Promise.reject(retryRequestResponse);
				}

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				let retryRequestResponseJson: any | null;
				try {
					retryRequestResponseJson = await retryRequestResponse.json();
				} catch (err: unknown) {
					retryRequestResponseJson = null;
				}

				return retryRequestResponseJson as T;
			} catch (err: unknown) {
				if (disconnectUser) {
					await WalletStore.getInstance().disconnect();
					this.cookieStorageService.items.userAddress.delete();
				}
				await this.basePresign();
			}
		} else if (response.status === 401) {
			await WalletStore.getInstance().disconnect();
			this.cookieStorageService.items.userAddress.delete();
			await this.basePresign();
		}

		if (!response.ok) {
			return Promise.reject(response);
		}

		return responseJson as T;
	}

	protected onError(error: unknown) {
		console.error(error);
	}

	private async baseRefreshToken() {
		const response = await fetch(
			this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-auth").concat("/refresh-token"),
			{
				method: "POST",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody({
					accessToken: this.jwtStore.getAccessToken(),
					refreshToken: this.jwtStore.getRefreshToken(),
				}),
			},
		);

		if (!response.ok) {
			return Promise.reject(response);
		}

		const userAddress = this.cookieStorageService.items.userAddress.get();

		const { jwtPair } = (await response.json()) as { jwtPair: JwtPair };
		this.jwtStore.setJwtPair(jwtPair, userAddress);
		return;
	}

	private async basePresign() {
		const response = await fetch(
			this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-auth").concat("/presign"),
			{
				method: "POST",
				headers: this.buildHeaders(ContentType.JSON),
			},
		);

		if (!response.ok) {
			return Promise.reject(response);
		}

		const { jwtPair } = (await response.json()) as { jwtPair: JwtPair };
		this.jwtStore.setJwtPair(jwtPair);
		return;
	}
}

export interface IResponse {
	http_status: number;
}
