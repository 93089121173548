import React from "react";
import { ReactComponent as Instagram } from "assets/images/logos/instagram.svg";
import { ReactComponent as Facebook } from "assets/images/logos/facebook.svg";
import { ReactComponent as Twitter } from "assets/images/logos/twitter.svg";
import { ReactComponent as Discord } from "assets/images/logos/discord-2.svg";
import { ReactComponent as Youtube } from "assets/images/logos/youtube.svg";
import { ReactComponent as Medium } from "assets/images/logos/medium.svg";
import { ReactComponent as Tiktok } from "assets/images/logos/tiktok.svg";
import { ReactComponent as Linkedin } from "assets/images/logos/linkedin.svg";
import { ReactComponent as Telegram } from "assets/images/logos/telegram.svg";
import { ReactComponent as Website } from "assets/images/icons/website.svg";
import { ReactComponent as Etherscan } from "assets/images/logos/etherscan.svg";

export enum ESocialMedia {
	FACEBOOK = "facebook",
	INSTAGRAM = "instagram",
	TWITTER = "twitter",
	DISCORD = "discord",
	TIKTOK = "tiktok",
	YOUTUBE = "youtube",
	LINKEDIN = "linkedin",
	MEDIUM = "medium",
	TELEGRAM = "telegram",
	EXTERNAL_WEBSITE = "externalWebsite",
	ETHERSCAN = "etherscan",
	PROJECTLINK = "projectLink",
}

type IProps = {
	name: ESocialMedia;
};

export default class SocialMediaIcon extends React.PureComponent<IProps> {
	public override render(): JSX.Element | null {
		return this.getSocialIcon(this.props.name);
	}

	private getSocialIcon(name: ESocialMedia): JSX.Element | null {
		switch (name) {
			case "facebook":
				return <Facebook />;
			case "instagram":
				return <Instagram />;
			case "twitter":
				return <Twitter />;
			case "discord":
				return <Discord />;
			case "youtube":
				return <Youtube />;
			case "medium":
				return <Medium />;
			case "tiktok":
				return <Tiktok />;
			case "telegram":
				return <Telegram />;
			case "linkedin":
				return <Linkedin />;
			case "externalWebsite":
				return <Website />;
			case "etherscan":
				return <Etherscan />;
			default:
				return null;
		}
	}
}
