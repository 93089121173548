import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	content: JSX.Element;
};

export default class Footer extends React.Component<IProps> {
	public override render(): JSX.Element {
		return <footer className={classes["root"]}>{this.props.content}</footer>;
	}
}
