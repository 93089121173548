import Typography from "Components/Elements/Typography";
import classes from "./classes.module.scss";
import { ExclamationTriangleIcon as ReasonIcon } from "@heroicons/react/24/outline";

export type IConfirmationModalReasonProps = {
	title?: string;
    description: string;
}

export default function ConfirmationModalReason(props: IConfirmationModalReasonProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["icon"]}>
				<ReasonIcon />
			</div>
			<div className={classes["content"]}>
				<Typography size="medium" weight="medium">
					{props.title}
				</Typography>
				<Typography size="small" weight="regular">
					{props.description}
				</Typography>
			</div>
		</div>
	);
}
