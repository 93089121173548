import React from "react";

import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import BigNumber from "Services/BigNumber";

import Typography from "../../../../Elements/Typography";
import TokenPrice from "../../../TokenPrice";
import classes from "./classes.module.scss";
import configHelper from "common/helpers/config";
import { AppCollectionEntity } from "Entities/appCollection";

type IProps = {
	amountToMint: number;
	appCollection: AppCollectionEntity;
	price?: {
		value?: BigNumber;
		token?: AppTokenSupportEntity;
	};
};

export default class MintModalHeader extends React.Component<IProps> {
	private readonly defaultThumbnail = configHelper.getDefaultThumbnail();
	public override render() {
		const { appCollection } = this.props;

		return (
			<div className={classes["row"]}>
				<div className={classes["left"]}>
					<div
						className={classes["nftImage"]}
						style={{
							backgroundImage: `url(${appCollection.appCollectionConfig.mintCardPicture?.url || this.defaultThumbnail})`,
						}}
					/>
					<div>
						<Typography type="p" size="small" weight="regular" color="neutral">
							{appCollection.appCollectionConfig.name} x{this.props.amountToMint}
						</Typography>
					</div>
				</div>
				{this.props.price && (
					<TokenPrice
						price={this.props.price.value}
						token={this.props.price.token}
						conversionConfig={{ direction: "column", size: "small" }}
						typo={{ type: "p", size: "large", weight: "semibold" }}
					/>
				)}
			</div>
		);
	}
}
