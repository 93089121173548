import React from "react";
import classes from "./classes.module.scss";
import Toasts, { IToast } from "Stores/Toasts";
import ToastElement from "./ToastElement";
import classNames from "classnames";
import UserMenuStatus from "Stores/UserMenuStatus";

type IProps = {};
type IState = {
	toastList: IToast[];
};
export default class ToastsContainer extends React.Component<IProps, IState> {
	private removeOnChange = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			toastList: Toasts.getInstance().toasts,
		};

		this.updateToasts = this.updateToasts.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<div
				className={classNames(
					classes["root"],
					this.state.toastList.length > 0 && classes["open"],
					UserMenuStatus.getInstance().status === "opened" && classes["user-menu-open"],
				)}>
				{this.state.toastList.map((toast) => {
					return <ToastElement toast={toast} key={toast.id} />;
				})}
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnChange = Toasts.getInstance().onChange(this.updateToasts);
	}

	public override componentWillUnmount() {
		this.removeOnChange();
	}

	private updateToasts(toastList: IToast[]) {
		this.setState({ toastList });
	}
}
