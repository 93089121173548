import { ethers } from "ethers";
import { Component } from "react";
import { TokenProtocol } from "common/enums/TokenProcotol";
import TransactionToast from "common/helpers/TransactionToast";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modals";
import GasPriceAlert from "Components/Elements/Modals/PaymentModal/GasPriceAlert";
import Typography from "Components/Elements/Typography";
import { AppAuctionEntity } from "Entities/appAuction";
import { ContractType } from "Entities/appContract";
import { AppNftEntity } from "Entities/appNft";
import { AppOrderEntity } from "Entities/appOrder";
import BigNumber from "Services/BigNumber";
import DeprecatedContractStore from "Stores/ContractStores/DeprecatedContractStore";
import { EModal } from "Stores/Modals/AModalStore";
import CancelListingModalStore from "Stores/Modals/CancelListingModalStore";
import WalletStore from "Stores/WalletStore";
import PaymentModalHeader from "../Elements/PaymentModalHeader";
import { EListingType } from "../ListingModal";
import PotentialEarningsComponent from "../ListingModal/Components/PotentialEarnings";
import SummaryComponent from "../ListingModal/Components/Summary";
import classes from "./classes.module.scss";
import assert from "assert";
import MarketplaceContractStore from "Stores/ContractStores/MarketplaceContractStore";
import RoyaltiesContractStore from "Stores/ContractStores/RoyaltiesContractStore";

export type ICancelListingModalProps = {
	nft: AppNftEntity;
	order?: AppOrderEntity;
	auction?: AppAuctionEntity;
};

type IProps = {};
type IState = {
	isOpen: boolean;
	paymentInfo?: ICancelListingModalProps;
	commissionFee: number;
	creatorRoyalty: number;
	potentialFixedPriceEarnings: BigNumber;
	potentialAuctionEarnings: BigNumber;
	gasPrice: BigNumber;
	tx?: ethers.PopulatedTransaction;
};

export default class CancelListingModal extends Component<IProps, IState> {
	private readonly _commissionFeeConvertBase = 100;
	private removeOnModalChange = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			isOpen: CancelListingModalStore.getInstance().isOpen(EModal.CANCEL_LISTING),
			paymentInfo: CancelListingModalStore.getInstance().getProps(),
			commissionFee: 0,
			creatorRoyalty: 0,
			potentialFixedPriceEarnings: BigNumber.from("0"),
			potentialAuctionEarnings: BigNumber.from("0"),
			gasPrice: BigNumber.from("0"),
		};
		this.updateModalState = this.updateModalState.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.getLastOrder = this.getLastOrder.bind(this);
		this.getLastAuction = this.getLastAuction.bind(this);
		this.setAuctionContractFee = this.setAuctionContractFee.bind(this);
		this.estimateGasPrice = this.estimateGasPrice.bind(this);
		this.setMarketplaceContractFee = this.setMarketplaceContractFee.bind(this);
		this.getPotentialEarningFixedPrice = this.getPotentialEarningFixedPrice.bind(this);
		this.cancelOrder = this.cancelOrder.bind(this);
		this.cancelAuction = this.cancelAuction.bind(this);
	}

	override render() {
		const lastOrder = this.getLastOrder();
		const lastAuction = this.getLastAuction();
		return (
			<Modal
				closeBtn
				isOpen={this.state.isOpen}
				onClose={this.closeModal}
				header={
					<span>
						{I18n.translate(lastOrder ? "modals.cancel_listing.title_fixed_price" : "modals.cancel_listing.title_auction")}
					</span>
				}
				footer={this.footer()}>
				<div className={classes["root"]}>
					<Typography size="small" type="p" weight="regular" className={classes["info"]}>
						<I18n map={lastOrder ? "modals.cancel_listing.info_fixed_price" : "modals.cancel_listing.info_auction"} />
					</Typography>
					<PaymentModalHeader nft={this.state.paymentInfo?.nft ?? null} />
					<SummaryComponent
						commissionFee={this.state.commissionFee}
						listingPrice={BigNumber.from(lastOrder ? lastOrder?.tokenSupportAmount : lastAuction?.reservePrice)}
						token={lastOrder ? lastOrder?.appTokenSupport : lastAuction?.appTokenSupport}
						creatorRoyalty={this.state.creatorRoyalty}
					/>
					<PotentialEarningsComponent
						isBuyNowEnabled={this.isBuyNowPriceEqualToZero(lastAuction)}
						potentialEarningFixedPrice={this.state.potentialFixedPriceEarnings}
						potentialEarningAuction={this.state.potentialAuctionEarnings}
						token={lastOrder ? lastOrder?.appTokenSupport : lastAuction?.appTokenSupport}
						listingType={lastOrder ? EListingType.FIXED_PRICE : EListingType.AUCTION}
					/>
					<GasPriceAlert gasPrice={this.state.gasPrice} />
				</div>
			</Modal>
		);
	}

	private footer(): JSX.Element {
		const lastOrder = this.getLastOrder();
		return (
			<div className={classes["buttons-container"]}>
				<Button
					variant={EButtonVariant.OUTLINED}
					color={EButtonColor.DESTRUCTIVE}
					size={EButtonSize.MEDIUM}
					onClick={lastOrder ? this.cancelOrder : this.cancelAuction}
					fullwidth>
					<I18n map="modals.cancel_listing.cancel_listing" />
				</Button>
				<Button variant={EButtonVariant.OUTLINED} size={EButtonSize.MEDIUM} onClick={this.closeModal} fullwidth>
					<I18n map="common.cancel" />
				</Button>
			</div>
		);
	}

	public override async componentDidMount() {
		this.removeOnModalChange = CancelListingModalStore.getInstance().onChange(this.updateModalState);
		await this.setCreatorRoyalty();
		await this.setAuctionContractFee();
		await this.setMarketplaceContractFee();
	}

	public override async componentDidUpdate(_: Readonly<IProps>, prevState: Readonly<IState>): Promise<void> {
		if (prevState.isOpen !== this.state.isOpen) {
			await this.setCreatorRoyalty();
			await this.setAuctionContractFee();
			await this.setMarketplaceContractFee();
			this.getPotentialEarningFixedPrice();
		}
		if (prevState.creatorRoyalty !== this.state.creatorRoyalty || prevState.commissionFee !== this.state.commissionFee) {
			this.getPotentialEarningFixedPrice();
		}
	}

	public override componentWillUnmount() {
		this.removeOnModalChange();
	}

	private closeModal() {
		CancelListingModalStore.getInstance().closeModal();
	}

	private updateModalState(modalsOpenedState: { [type: string]: boolean }, paymentInfo: ICancelListingModalProps) {
		this.setState(
			{
				isOpen: modalsOpenedState[EModal.CANCEL_LISTING] ?? false,
				paymentInfo,
			},
			this.estimateGasPrice,
		);
	}

	private async estimateGasPrice() {
		const lastOrder = this.getLastOrder();
		if (!lastOrder) return;

		const enrichedMarketplaceContract = await MarketplaceContractStore.getInstance().getEnrichedMarketplaceContract();

		const tx = enrichedMarketplaceContract.contractAdapter.canUse("createCancelOrderTx")
			? await enrichedMarketplaceContract.contractAdapter.createCancelOrderTx([lastOrder.order])
			: undefined;
		if (!tx) return;

		if (enrichedMarketplaceContract.contractAdapter.canUse("estimateGasPrice")) {
			const gasPrice = await enrichedMarketplaceContract.contractAdapter.estimateGasPrice(tx);
			this.setState({ gasPrice, tx });
		}
	}

	private getLastOrder(): AppOrderEntity | undefined {
		return this.state.paymentInfo?.order;
	}

	private getLastAuction(): AppAuctionEntity | undefined {
		return this.state.paymentInfo?.auction;
	}

	private isBuyNowPriceEqualToZero(lastAuction?: AppAuctionEntity) {
		if (!lastAuction) return false;
		const buyNowPrice = Number(BigNumber.from(lastAuction?.buyNowPrice).getFormatRoundFloor());
		return buyNowPrice === 0;
	}

	private async setAuctionContractFee() {
		const lastAuction = this.getLastAuction();
		if (!lastAuction) return;
		const isTokenNative = lastAuction.appTokenSupport?.protocol === TokenProtocol.NATIVE;
		const enrichedAuctionContract = await DeprecatedContractStore.getInstance().getEnrichedContractByType(
			isTokenNative ? ContractType.INCARUS_SECONDARY : ContractType.INCARUS_ERC20_SECONDARY,
		);
		if (!enrichedAuctionContract) return;

		const appContractCommissionFee = enrichedAuctionContract.contractEntity.config?.["commissionFee"];
		assert(typeof appContractCommissionFee === "number", "No commission fee in contract config");

		const commissionFee = appContractCommissionFee / this._commissionFeeConvertBase;
		this.setState({ commissionFee });
	}

	private async setCreatorRoyalty() {
		const nft = this.state.paymentInfo?.nft;
		if (!nft) return;

		const priceAsBigNumber = BigNumber.from(this.state.paymentInfo?.order?.tokenSupportAmount);
		const collectionAddress = nft.appCollection.appContract?.address;
		if (!collectionAddress) return;

		const enrichedRoyaltiesContract = await RoyaltiesContractStore.getInstance().getEnrichedRoyaltiesContract();

		const royalties = enrichedRoyaltiesContract.contractAdapter.canUse("getRoyalties")
			? await enrichedRoyaltiesContract.contractAdapter.getRoyalties(collectionAddress, nft.tokenId, priceAsBigNumber)
			: undefined;
		assert(royalties, "royalties is undefined");

		const creatorRoyaltyAmount = royalties.amounts[0];
		const creatorRoyaltyPercentage =
			creatorRoyaltyAmount && priceAsBigNumber && priceAsBigNumber.gt(BigNumber.from("0"))
				? Number(creatorRoyaltyAmount.mul(100).div(priceAsBigNumber).getFormatRoundFloor())
				: 0;

		this.setState({ creatorRoyalty: creatorRoyaltyPercentage });
	}

	private async setMarketplaceContractFee() {
		const enrichedMarketplaceContract = await MarketplaceContractStore.getInstance().getEnrichedMarketplaceContract();

		const appContractCommissionFee = (enrichedMarketplaceContract.contractEntity.config as { commissionFee: number }).commissionFee;
		assert(typeof appContractCommissionFee === "number", "No commission fee in contract config");

		const commissionFee = appContractCommissionFee / this._commissionFeeConvertBase;
		this.setState({ commissionFee });
	}

	private getPotentialEarningFixedPrice() {
		const { commissionFee, creatorRoyalty } = this.state;
		const lastOrder = this.getLastOrder();
		const fixedPrice = lastOrder?.tokenSupportAmount;
		if (!fixedPrice) {
			this.setState({
				potentialFixedPriceEarnings: BigNumber.from("0", lastOrder?.appTokenSupport.decimals),
			});
			return;
		}

		const priceAsBigNumber = BigNumber.from(fixedPrice);

		const totalPercentageFee = commissionFee + creatorRoyalty;
		const totalFee = priceAsBigNumber.mul(totalPercentageFee).div(BigNumber.from("100"));
		const potentialEarning = priceAsBigNumber.sub(totalFee);

		this.setState({
			potentialFixedPriceEarnings: BigNumber.from(potentialEarning.toString()),
		});
	}

	private async cancelOrder() {
		await TransactionToast.processTransaction(async () => WalletStore.getInstance().sendTransaction(this.state.tx));
		this.closeModal();
	}

	private async cancelAuction() {
		this.closeModal();
	}
}
