import WalletBalanceStore, { IBalance } from "Stores/WalletBalanceStore";
import { useCallback, useEffect, useState } from "react";

const useTokensBalance = () => {
	const [balances, setBalances] = useState(WalletBalanceStore.getInstance().balances);

	useEffect(() => {
		const onRemove = WalletBalanceStore.getInstance().onChange(setBalances);
		return () => {
			onRemove();
		};
	}, []);

	const getBalanceOfToken = useCallback(
		(tokenAddress: string): IBalance | null => {
			const balance = Object.values(balances).find((balance) => balance.token.address === tokenAddress);
			return balance ? balance : null;
		},
		[balances],
	);

	return { balances, getBalanceOfToken };
};

export default useTokensBalance;
