import AppAuth from "Api/Back/AppAuth";
import AppMessage from "Api/Back/AppMessage";
import { AppUserEntity } from "Entities/appUser";
import CookieStorageService from "Services/CookieStorageService";
import EventService from "Services/EventEmitter";
import JwtStore from "./JwtStore";
import UserMenuStatus from "./UserMenuStatus";
import WalletStore from "./WalletStore";
import SocketService from "Services/SocketService";
import { UserConnectionStatus } from "common/enums/UserConnectionStatus";

export default class UserStore {
	private static instance: UserStore;
	private readonly event = new EventService();
	private readonly jwtStore = JwtStore.getInstance();
	private readonly appAuth = AppAuth.getInstance();
	private readonly appMessage = AppMessage.getInstance();
	private readonly cookieStorageService = CookieStorageService.getInstance();

	private user: AppUserEntity | null = null;
	public connectionStatus: UserConnectionStatus = UserConnectionStatus.PENDING;

	private constructor() {
		UserStore.instance = this;
		WalletStore.getInstance().onChange((walletData) => {
			if (!walletData.userAddress) {
				this.disconnectUserFromWallet(true);
			}
		});
		SocketService.getInstance().onUpdatedUser(async ({ userId }) => {
			// Check if user is the same as current user because other users can be pushed by socket
			if (userId === this.user?.id) this.setUser(await AppAuth.getInstance().getAuthenticatedUser(), this.connectionStatus);
		});
	}

	public static getInstance(): UserStore {
		return this.instance ? this.instance : new this();
	}

	public getUser() {
		return this.user;
	}

	public setUser(user: AppUserEntity | null, connectionStatus: UserConnectionStatus) {
		this.user = user;
		this.event.emit("change", user, connectionStatus);
	}

	public emitOpenRegisterModalEvent() {
		this.event.emit("openRegisterModal");
	}

	public async signInWallet(userAddress: string) {
		try {
			const { message } = await this.appMessage.create({ userAddress });
			const signedMessage = await WalletStore.getInstance().signMessage(message);
			const { jwtPair } = await this.appAuth.signInWallet({
				userAddress,
				message,
				signedMessage,
			});
			this.cookieStorageService.items.userAddress.set(userAddress);
			this.jwtStore.setJwtPair(jwtPair, userAddress);
		} catch (err) {
			throw err;
		}
	}

	public async signOut(isManualAction = true) {
		try {
			this.disconnectUserFromWallet(isManualAction);
			WalletStore.getInstance().disconnect();
		} catch (err) {
			console.error(err);
		}
	}

	private async disconnectUserFromWallet(isManualAction: boolean) {
		try {
			this.setUser(null, UserConnectionStatus.NOT_CONNECTED);
			UserMenuStatus.getInstance().close();

			this.cookieStorageService.items.accessToken.deleteAll();
			this.cookieStorageService.items.refreshToken.deleteAll();
			this.cookieStorageService.items.userAddress.delete();

			const { jwtPair } = await this.appAuth.signOut();
			this.jwtStore.setJwtPair(jwtPair);
		} catch (err) {
			console.error(err);
		}
	}

	public onChange(callback: (user: AppUserEntity | null, connectionStatus: UserConnectionStatus) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public onOpenRegisterModal(callback: () => void) {
		this.event.on("openRegisterModal", callback);
		return () => {
			this.event.off("openRegisterModal", callback);
		};
	}
}
