import I18n from "Components/Elements/I18n";
import Typography from "Components/Elements/Typography";
import WalletBalanceLine from "Components/Elements/WalletBalanceLine";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import React from "react";
import BigNumber from "Services/BigNumber";
import WalletBalanceStore, { IBalances } from "Stores/WalletBalanceStore";
import classes from "./classes.module.scss";

type IProps = {
	token: AppTokenSupportEntity;
	updateDisplayAddFunds: (availableAmount: BigNumber) => void;
};

type IState = {
	balances: IBalances;
};

export default class BalanceComponent extends React.Component<IProps, IState> {
	private removeOnBalanceChange = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			balances: WalletBalanceStore.getInstance().balances,
		};
		this.updateBalances = this.updateBalances.bind(this);
		this.displayAddFunds = this.displayAddFunds.bind(this);
	}

	public override render(): React.ReactNode {
		return (
			<div className={classes["root"]}>
				<div className={classes["title"]}>
					<Typography type="p" size="small" weight="semibold">
						<I18n map="modals.complete_purchase.balance" />
					</Typography>
				</div>
				{Object.entries(this.state.balances).map(([key, balance]) => {
					if (
						// (balance.token.protocol !== "NATIVE" && balance.balance.isZero()) ||
						balance.token.address !== this.props.token.address
					) {
						return null;
					}
					return <WalletBalanceLine balance={balance} key={key} />;
				})}
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnBalanceChange = WalletBalanceStore.getInstance().onChange(this.updateBalances);
		this.displayAddFunds();
	}

	public override componentWillUnmount() {
		this.removeOnBalanceChange();
	}

	private displayAddFunds() {
		const availableAmount = Object.entries(this.state.balances).find(
			([key, balance]) => balance.token.address === this.props.token.address,
		);
		if (!availableAmount) return;
		this.props.updateDisplayAddFunds(availableAmount[1].balance);
	}

	private updateBalances(balances: IBalances) {
		this.setState(
			{
				balances,
			},
			this.displayAddFunds,
		);
	}
}
