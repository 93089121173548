import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import TokenSupports from "Stores/TokenSupports";
import { useCallback, useEffect, useState } from "react";

const useTokenSupport = () => {
	const [tokens, setTokens] = useState(TokenSupports.getInstance().getTokenSupports());

	const onTokensChange = useCallback(
		(tokenSupports: AppTokenSupportEntity[]) => {
			setTokens(tokenSupports);
		},
		[setTokens],
	);

	useEffect(() => {
		TokenSupports.getInstance().onChange(onTokensChange);
	}, [onTokensChange]);

	const getToken = useCallback(
		(tokenAddress: string) => {
			let tokenSupport: AppTokenSupportEntity | null = null;
			Object.entries(tokens).forEach(([_, value]) => {
				if (value.address === tokenAddress) {
					tokenSupport = value;
					return;
				}
			});
			return tokenSupport;
		},
		[tokens],
	);

	return { tokens, getToken };
};

export default useTokenSupport;
