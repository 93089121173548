import AModalStore, { EModal } from "./AModalStore";
import { IReceiveCryptoModalProps as IProps } from "Components/Materials/Modals/ReceiveCryptoModal";
export default class ReceiveCryptoModalStore extends AModalStore<IProps> {
	private static ctx: ReceiveCryptoModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		ReceiveCryptoModalStore.ctx = this;
	}

	public static getInstance() {
		if (!ReceiveCryptoModalStore.ctx) return new this();
		return ReceiveCryptoModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.RECEIVE_CRYPTO, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
