import BaseApiService from "Api/BaseApiService";
import ConfigStore from "Stores/ConfigStore";

import { AppTokenSupportEntity } from "Entities/appTokenSupport";

export default class AppTokenSupport extends BaseApiService {
	private static instance: AppTokenSupport;

	private constructor() {
		super();
		AppTokenSupport.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-token-supports");
	}

	public static getInstance(reset?: boolean) {
		if (!AppTokenSupport.instance || reset) return new this();
		return AppTokenSupport.instance;
	}

	public async getTokenSupports(): Promise<AppTokenSupportEntity[]> {
		const url = new URL(this.baseUrl);
		try {
			return await this.getRequest<AppTokenSupportEntity[]>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getTokenSupportByAddress(address: string): Promise<AppTokenSupportEntity | null> {
		const url = new URL(this.baseUrl.concat("/").concat(address));
		try {
			return await this.getRequest<AppTokenSupportEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
