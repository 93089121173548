import { CheckCircleIcon, PlusIcon } from "@heroicons/react/20/solid";
import React, { ReactNode } from "react";

import AlertInfo, { EAlertVariants } from "Components/Elements/Alert";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import Modal, { IModalProps } from "Components/Elements/Modals";
import PaymentModalHeader from "Components/Materials/Modals/Elements/PaymentModalHeader";
import { AppNftEntity } from "Entities/appNft";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";

import classes from "./classes.module.scss";
import Typography from "Components/Elements/Typography";

export enum EApprovedPaymentButtonState {
	APPROVED = "APPROVED",
	DISABLED = "DISABLED",
	LOADING = "LOADING",
	UNDISPLAY = "UNDISPLAY",
	CLICKABLE = "CLICKABLE",
}

type IProps = IModalProps & {
	onConfirm: () => void;
	confirmText: string | JSX.Element;
	approvePaymentText?: string | JSX.Element;
	approvePayment: () => void;
	isAddFundsDisplayed: boolean;
	openAddFundsModal: () => void;
	alert?: ReactNode;
	errorMessageAddFunds: JSX.Element;
	stateApprovePaymentButton: EApprovedPaymentButtonState;
	nft?: AppNftEntity;
	appTokenSupport?: AppTokenSupportEntity;
	isConfirmButtonDisabled?: boolean;
	onClose?: () => void;
	isConfirmTxExecuted: boolean;
	isConfirmButtonClicked?: boolean;
};

export default class PaymentModal extends React.Component<IProps> {
	public constructor(props: IProps) {
		super(props);
		this.renderApproveButton = this.renderApproveButton.bind(this);
		this.isConfirmButtonDisabled = this.isConfirmButtonDisabled.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
	}

	override render(): JSX.Element | null {
		return (
			<Modal closeBtn isOpen={this.props.isOpen} onClose={this.props.onClose} header={this.props.header} footer={this.footer()}>
				<div className={classes["root"]}>
					{this.props.nft && <PaymentModalHeader nft={this.props.nft} />}
					{this.props.children}
					{this.props.isAddFundsDisplayed && (
						<>
							<Button
								onClick={this.props.openAddFundsModal}
								color={EButtonColor.PRIMARY}
								variant={EButtonVariant.OUTLINED}
								endIcon={<PlusIcon />}>
								<I18n map="modals.place_bid.add_funds" />
							</Button>
							<AlertInfo variant={EAlertVariants.WARNING}>{this.props.errorMessageAddFunds}</AlertInfo>
						</>
					)}
					{this.props.alert}
				</div>
			</Modal>
		);
	}

	private footer() {
		return (
			<div className={classes["buttons-container"]}>
				{this.renderApproveButton()}
				<Button
					variant={EButtonVariant.CONTAINED}
					color={EButtonColor.PRIMARY}
					size={EButtonSize.MEDIUM}
					onClick={this.onConfirm}
					isLoading={this.props.isConfirmButtonClicked && !this.props.isConfirmTxExecuted}
					disabled={this.isConfirmButtonDisabled()}>
					{this.props.isConfirmTxExecuted ? <I18n map="buttons.close" /> : this.props.confirmText} {/* TODO remove unneeded close text */}
				</Button>
			</div>
		);
	}

	private onConfirm() {
		// TODO
		//   close automatically with useEffet on isConfirmTxExecuted
		//   open confirm modal
		//   check if isConfirmTxExecuted handles failed tx in parent
		// Alternative design
		//   remove isConfirmTxExecuted and let the parent close the purchase modal and open the confirm modal
		//   remove onClose from props
		//   remove private onConfirm and pass the props.onConfirm to the button directly
		if (this.props.isConfirmTxExecuted) {
			this.props.onClose?.();
			return;
		}
		this.props.onConfirm();
	}

	private isConfirmButtonDisabled() {
		if (this.props.isConfirmButtonDisabled) return true;

		const stateApproveButton = this.props.stateApprovePaymentButton;
		return !(
			stateApproveButton === EApprovedPaymentButtonState.APPROVED ||
			(stateApproveButton === EApprovedPaymentButtonState.UNDISPLAY && this.props.isAddFundsDisplayed === false)
		);
	}

	private renderApproveButton(): ReactNode | null {
		switch (this.props.stateApprovePaymentButton) {
			case EApprovedPaymentButtonState.UNDISPLAY:
				return null;
			case EApprovedPaymentButtonState.LOADING:
				return <Button color={EButtonColor.PRIMARY} isLoading fullwidth />;
			case EApprovedPaymentButtonState.APPROVED:
				return (
					<Typography type="p" className={classes["approved-payment"]}>
						<CheckCircleIcon className={classes["icon"]} />
						<I18n map="modals.place_bid.approved_payment" />
					</Typography>
					// <Button
					// 	className={classes["approved-payment"]}
					// 	variant={EButtonVariant.TEXT}
					// 	size={EButtonSize.MEDIUM}
					// 	startIcon={<CheckCircleIcon />}>
					// 	<I18n map="modals.place_bid.approved_payment" />
					// </Button>
				);
			default:
				return (
					<Button
						variant={EButtonVariant.CONTAINED}
						color={EButtonColor.PRIMARY}
						size={EButtonSize.MEDIUM}
						disabled={this.props.stateApprovePaymentButton === EApprovedPaymentButtonState.DISABLED}
						onClick={this.props.approvePayment}>
						{this.props.approvePaymentText ?? <I18n map="buttons.approve_payment" />}
					</Button>
				);
		}
	}
}
