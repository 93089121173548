import I18n from "Components/Elements/I18n";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import Documents from "Components/Materials/Documents";
import Footer from "Components/Materials/Footer";
import Header from "Components/Materials/Header";
import AllModals from "Components/Materials/Modals";
import ToastHandler from "Components/Materials/Toasts/ToastsHandler";
import TopMenu from "Components/Materials/TopMenu";
import UserMenu from "Components/Materials/UserMenu";
import { ModalProvider } from "Contexts/Modal";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { UserRegistrationBanner } from "../Materials/UserRegistrationBanner";
import classes from "./classes.module.scss";
import LazyHydrate from "react-lazy-hydration";

const DEFAULT_MAX_WIDTH = "1440px";
type IProps = PropsWithChildren & {
	title: string;
	/**
	 * @description scroll top with number or disabled with null
	 */
	scrollTop?: number;
	paddingXDisabled?: boolean;
	maxWidth?: string;
	paddingTopDisabled?: boolean;
};

export default function DefaultTemplate(props: IProps) {
	const { scrollTop = 0 } = props;

	if (scrollTop !== null) {
		window.scrollTo(0, scrollTop);
	}

	const setTitle = useCallback(() => {
		window.document.title = props.title;
	}, [props.title]);

	useEffect(() => {
		setTitle();
	}, [setTitle]);

	const maxWidth = props.maxWidth || DEFAULT_MAX_WIDTH;
	return (
		<I18n>
			<ModalProvider>
				<IsModuleEnabled from={IsModuleEnabled.get().Header}>
					<Header />
					<UserRegistrationBanner />
					<TopMenu />
					<UserMenu />
				</IsModuleEnabled>
				<div className={classes["root"]}>
					<ToastHandler />
					<AllModals />
					<div
						className={classes["content"]}
						data-padding-x-disabled={Boolean(props.paddingXDisabled)}
						data-padding-top-disabled={Boolean(props.paddingTopDisabled)}
						style={{ maxWidth: maxWidth }}>
						{props.children}
					</div>
				</div>
				<IsModuleEnabled from={IsModuleEnabled.get().Footer}>
					<LazyHydrate whenVisible>
						<Footer />
					</LazyHydrate>
				</IsModuleEnabled>
				<Documents />
			</ModalProvider>
		</I18n>
	);
}
