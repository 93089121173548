import AModalStore, { EModal } from "./AModalStore";
import { IAcceptOfferModalProps as  IProps } from "Components/Materials/Modals/AcceptOfferModal";
export default class AcceptOfferModalStore extends AModalStore<IProps> {
	private static ctx: AcceptOfferModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		AcceptOfferModalStore.ctx = this;
	}

	public static getInstance() {
		if (!AcceptOfferModalStore.ctx) return new this();
		return AcceptOfferModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.ACCEPT_OFFER, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
