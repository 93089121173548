import React from "react";

import { ReactComponent as Cloche } from "assets/images/icons/cloche.svg";
import IconButton from "Components/Elements/Buttons/IconButton";

type IProps = {
	onClick?: () => void;
};

export default class NotificationButton extends React.Component<IProps> {
	public override render(): JSX.Element {
		return <IconButton onClick={this.props.onClick} icon={<Cloche />} />;
	}
}
