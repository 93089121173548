import { TransactionResponse } from "@ethersproject/abstract-provider";
import { PopulatedTransaction, Signature } from "ethers";
import BigNumber from "Services/BigNumber";
import Erc721StandardContract from "./Erc721StandardContract";
import BaseMarketplaceContractAdapter from "./core/BaseErc721ContractAdapter";
import IErc721ContractFunctions from "./core/IErc721ContractFunctions";
import { ISecurityPolicy } from "./core/Erc721Dto";
import { PaymentRequest } from "./CollectioACERC20RB170124Contract";

export default class Erc721StandardContractAdapter<T extends Erc721StandardContract>
	extends BaseMarketplaceContractAdapter<T>
	implements IErc721ContractFunctions
{
	public constructor(contract: T) {
		super(contract, {
			estimateGasPrice: true,
			approve: false,
			setApprovalForAll: false,
			getApproved: false,
			isApprovedForAll: false,
			createApproveTx: false,
			createApproveForAllTx: false,
			getTotalSupply: false,
			getMaxTotalSupply: false,
			getMintAvailableSupply: false,
			getPrivateSalePrice: false,
			getPublicSalePrice: false,
			getPrivateSaleHardCap: false,
			getPublicSaleHardCap: false,
			getMaxPrivateSaleMintPerTx: false,
			getMaxPublicSaleMintPerTx: false,
			getPrivateSaleMintingAmountByUserAddress: false,
			getPublicSaleMintingAmountByUserAddress: false,
			getTotalSoldPublicSale: false,
			getTotalSoldPrivateSale: false,
			getTotalAvailableForAirDrop: false,
			burn: false,
			balanceOf: false,
			getPrivateMintCountForUserAddress: false,
			getPublicMintCountForUserAddress: false,
			transferFrom: false,
			createMintPrivateSaleTx: false,
			createMintPublicSaleTx: false,
			maxPrivateSaleMintPerTx: false,
			maxPublicSaleMintPerTx: false,
			getSecurityPolicy: false,
			getWhitelistedOperators: false,
			isOperatorWhitelisted: false,
			paymentAssetAddress: false,
			getMaxTotalInvestmentHardcap: false,
			getInvestmentHardcapPerInvestor: false,
			getMinimumInvestmentAmount: false,
			getPaymentToken: false,
			getTotalInvestmentAmountRaised: false,
			createInitializeInvestmentTx: false,
			createIncreaseInvestmentTx: false,
			getInvestmentAmount: false,
			redeemBurn: false,
			createRedeemBurnTx: false,
			addressToNonce: false,
		});
	}

	public async estimateGasPrice(tx: PopulatedTransaction): Promise<BigNumber> {
		return this.contract.estimateGasPrice(tx);
	}
	approve(operator: string, tokenId: number): Promise<TransactionResponse> {
		throw new Error("Method not implemented.");
	}
	setApprovalForAll(operator: string, approved: boolean): Promise<TransactionResponse> {
		throw new Error("Method not implemented.");
	}

	/**
	 * @throws {Error} Method not implemented.
	 * @param tokenId
	 */
	getApproved(tokenId: number): Promise<string> {
		throw new Error("Method not implemented.");
	}
	isApprovedForAll(owner: string, operator: string): Promise<boolean> {
		throw new Error("Method not implemented.");
	}
	createApproveTx(operator: string, tokenId: number): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}
	createApproveForAllTx(operator: string, approved: boolean): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}
	getTotalSupply(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getMaxTotalSupply(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getMintAvailableSupply(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getPrivateSalePrice(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
	getPublicSalePrice(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
	getPrivateSaleHardCap(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getPublicSaleHardCap(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getMaxPrivateSaleMintPerTx(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getMaxPublicSaleMintPerTx(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getPrivateSaleMintingAmountByUserAddress(userAddress: string): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getPublicSaleMintingAmountByUserAddress(userAddress: string): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getTotalSoldPublicSale(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getTotalSoldPrivateSale(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getTotalAvailableForAirDrop(): Promise<number> {
		throw new Error("Method not implemented.");
	}
	burn(tokenId: number): Promise<TransactionResponse> {
		throw new Error("Method not implemented.");
	}
	balanceOf(address: string): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getPrivateMintCountForUserAddress(address: string): Promise<number> {
		throw new Error("Method not implemented.");
	}
	getPublicMintCountForUserAddress(address: string): Promise<number> {
		throw new Error("Method not implemented.");
	}
	transferFrom(from: string, to: string, tokenId: number): Promise<TransactionResponse> {
		throw new Error("Method not implemented.");
	}
	createMintPrivateSaleTx(
		amountToMint: number,
		splitSignature: Signature,
		recipientAddress: string,
		value: string,
	): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}
	createMintPublicSaleTx(amountToMint: number, recipientAddress: string, value: string): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}
	maxPrivateSaleMintPerTx(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
	maxPublicSaleMintPerTx(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
	getSecurityPolicy(): Promise<ISecurityPolicy> {
		throw new Error("Method not implemented.");
	}

	getWhitelistedOperators(): Promise<string[]> {
		throw new Error("Method not implemented.");
	}
	isOperatorWhitelisted(operator: string): Promise<boolean> {
		throw new Error("Method not implemented.");
	}

	paymentAssetAddress(): Promise<string> {
		throw new Error("Method not implemented.");
	}

	public async getMaxTotalInvestmentHardcap(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}

	public async getInvestmentHardcapPerInvestor(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}

	public async getMinimumInvestmentAmount(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}

	public async getPaymentToken(): Promise<string> {
		throw new Error("Method not implemented.");
	}

	public async getTotalInvestmentAmountRaised(): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}

	public async createInitializeInvestmentTx(
		recipientAddress: string,
		amount: BigNumber,
		splitSignature: Signature,
	): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}

	public async createIncreaseInvestmentTx(tokenId: number, amount: BigNumber, splitSignature: Signature): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}

	public async getInvestmentAmount(tokenId: number): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}

	public async redeemBurn(tokenId: number, payment: PaymentRequest): Promise<TransactionResponse> {
		throw new Error("Method not implemented.");
	}

	public async createRedeemBurnTx(tokenId: number, payment: PaymentRequest): Promise<PopulatedTransaction> {
		throw new Error("Method not implemented.");
	}

	public async addressToNonce(userAddressToGetCurrentNonceFrom: string): Promise<BigNumber> {
		throw new Error("Method not implemented.");
	}
}
