import { Component } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

// Components
import Typography from "Components/Elements/Typography";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import I18n from "Components/Elements/I18n";

// Assets
import { ReactComponent as GlassIcon } from "assets/images/icons/glass.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	section?: "collections" | "nfts";
};
type IPropsClass = IProps & {
	navigate: NavigateFunction;
};
type IState = {};

class SeeAllClass extends Component<IPropsClass, IState> {
	constructor(props: IPropsClass) {
		super(props);
		this.redirectToMarketplace = this.redirectToMarketplace.bind(this);
	}

	override render() {
		return (
			<div className={classes["root"]}>
				<div className={classes["container"]} onClick={this.redirectToMarketplace}>
					<div className={classes["icon-container"]}>
						<GlassIcon className={classes["icon"]} />
					</div>

					<Typography size="medium" type="p" className={classes["text"]}>
						<I18n map={`components.see_all.${this.props.section}`} />
					</Typography>
				</div>
			</div>
		);
	}

	private redirectToMarketplace() {
		if (this.props.section === "collections") {
			this.props.navigate(IsModuleEnabled.get().pages.Market.props.pages.Collections.props.path);
			return;
		}

		if (this.props.section === "nfts") {
			this.props.navigate(IsModuleEnabled.get().pages.Market.props.pages.NFTs.props.path);
			return;
		}

		return this.props.navigate(IsModuleEnabled.get().pages.Market.props.path);
	}
}

export default function SeeAll(props: IProps) {
	const navigate = useNavigate();

	return <SeeAllClass {...props} navigate={navigate} />;
}
