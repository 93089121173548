import { Signature, ethers } from "ethers";
import MarketplaceV2Contract from "./MarketplaceV2Contract";
import { Order } from "Entities/appOrder";
import BigNumber from "Services/BigNumber";
import BaseMarketplaceContractAdapter from "./core/BaseMarketplaceContractAdapter";
import IMarketplaceContractFunctions from "./core/IMarketplaceContractFunctions";

export default class MarketplaceV2ContractAdapter<T extends MarketplaceV2Contract>
	extends BaseMarketplaceContractAdapter<T>
	implements IMarketplaceContractFunctions
{
	public constructor(contract: T) {
		super(contract, {
			estimateGasPrice: true,
			executeOrder: false,
			createExecuteOrderTx: true,
			batchExecuteOrders: false,
			cancelOrder: false,
			createCancelOrderTx: true,
		});
	}

	public async estimateGasPrice(tx: ethers.PopulatedTransaction): Promise<BigNumber> {
		return this.contract.estimateGasPrice(tx);
	}
	public async executeOrder(order: Order, signature: Signature, recipientAddress: string): Promise<unknown> {
		return this.contract.executeOrder(order, signature, recipientAddress);
	}
	public async createExecuteOrderTx(order: Order, signature: Signature, recipientAddress: string): Promise<ethers.PopulatedTransaction> {
		return this.contract.createExecuteOrderTx(order, signature, recipientAddress);
	}
	public async batchExecuteOrders(orders: Order[], signatures: string[], recipientAddress: string): Promise<unknown> {
		return this.contract.batchExecuteOrders(orders, signatures, recipientAddress);
	}
	public async cancelOrder(orders: Order[]): Promise<unknown> {
		return this.contract.cancelOrder(orders);
	}
	public async createCancelOrderTx(orders: Order[]): Promise<ethers.PopulatedTransaction> {
		return this.contract.createCancelOrderTx(orders);
	}
}
