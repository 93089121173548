import AuthConfigStore, { AuthConfigDetails } from "Stores/AuthConfigStore";
import ConfigStore from "Stores/ConfigStore";
import { PropsWithChildren, useEffect, useState } from "react";
import { IntercomProvider } from "react-use-intercom";

type IAuthConfigGuardProps = {};

type IProps = PropsWithChildren<IAuthConfigGuardProps>;

export default function AuthConfigGuard(props: IProps) {
	const [details, setDetails] = useState(AuthConfigStore.getInstance().details);

	useEffect(() => {
		const onStatusChange = (status: AuthConfigDetails) => {
			setDetails(status);
		};
		const unsubscribe = AuthConfigStore.getInstance().onChange(onStatusChange);
		return () => {
			unsubscribe();
		};
	}, []);

	if (!details.isConfigLoaded || !details.hasJwt) {
		return null;
	}

	if (ConfigStore.getInstance().config.intercom.enabled) {
		const intercomAppId = ConfigStore.getInstance().config.intercom.appId;
		return (
			<IntercomProvider appId={intercomAppId} autoBoot>
				{props.children}
			</IntercomProvider>
		);
	}

	return props.children;
}
