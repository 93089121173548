
import AModalStore, { EModal } from "./AModalStore";

type IProps = {};

export default class AddFunds2ModalStore extends AModalStore<IProps> {
	private static ctx: AddFunds2ModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		AddFunds2ModalStore.ctx = this;
	}

	public static getInstance() {
		if (!AddFunds2ModalStore.ctx) return new this();
		return AddFunds2ModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.ADD_FUNDS_2, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
