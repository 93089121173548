import EventService from "Services/EventEmitter";

export default class ConnectWalletButtonStore {
	private static ctx: ConnectWalletButtonStore;
	private readonly event = new EventService();
    private loading: boolean = false;

	private constructor() {
		ConnectWalletButtonStore.ctx = this;
	}

	public static getInstance() {
		if (!ConnectWalletButtonStore.ctx) return new this();
		return ConnectWalletButtonStore.ctx;
	}

    public get isLoading() {
        return this.loading;
    }

    public set isLoading(value: boolean) {
        this.loading = value;
        this.event.emit("change", this.loading);
    }

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (type: string) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}
}

