import { ethers, Signature } from "ethers";

import EventEmitter from "Services/EventEmitter";
import MagicUniversalWallet from "Services/Wallet/Ethereum/MagicUniversalWallet";
import Web3ModalWallet from "Services/Wallet/Ethereum/Web3ModalWallet";
import IWalletInterface, { IWalletData } from "Services/Wallet/IWalletInterface";
import WalletFactory from "Services/Wallet/WalletFactory";
import UserStore from "./UserStore";
import { UserConnexionMethod } from "common/enums/UserConnectionMethod";
import MagicDedicatedWallet from "Services/Wallet/Ethereum/MagicDedicatedWallet";
import ConnectWalletButtonStore from "./ConnectWalletButtonStore";

export enum EWalletType {
	BEACON = "beacon",
	WEB3MODAL = "web3modal",
	MAGIC_UNIVERSAL_WALLET = "magicUniversalWallet",
	MAGIC_DEDICATED_WALLET = "magicDedicatedWallet",
}

export const defaultWalletData: IWalletData = {
	userAddress: null,
	balance: null,
	// TODO check if we can use the same provider for all the app when wallet is not connected
	provider: null,
	publicKey: null,
	chainId: null,
};

export default class WalletStore {
	private static ctx: WalletStore;
	private _walletData: IWalletData = defaultWalletData;
	private _walletFactory: IWalletInterface | null = null;
	private static wallets = new Map<EWalletType, { new (): IWalletInterface }>();
	private readonly event = new EventEmitter();
	private removeOnChangeWalletFactory = () => {};
	private _userConnexionMethod = UserConnexionMethod.NONE;

	private constructor() {
		WalletStore.wallets.set(EWalletType.WEB3MODAL, Web3ModalWallet);
		WalletStore.wallets.set(EWalletType.MAGIC_UNIVERSAL_WALLET, MagicUniversalWallet);
		WalletStore.wallets.set(EWalletType.MAGIC_DEDICATED_WALLET, MagicDedicatedWallet);

		WalletStore.ctx = this;
	}

	public static getInstance() {
		if (!WalletStore.ctx) return new this();
		return WalletStore.ctx;
	}

	public get walletData() {
		return this._walletData;
	}

	public get walletFactory() {
		return this._walletFactory;
	}

	public get userConnexionMethod() {
		return this._userConnexionMethod;
	}

	public set userConnexionMethod(userConnexionMethod: UserConnexionMethod) {
		this._userConnexionMethod = userConnexionMethod;
	}

	public onChange(callback: (walletData: IWalletData) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public async connectTo(walletType: EWalletType = EWalletType.MAGIC_UNIVERSAL_WALLET, provider?: any) {
		ConnectWalletButtonStore.getInstance().isLoading = true;
		this._userConnexionMethod = UserConnexionMethod.MANUAL;
		this.removeOnChangeWalletFactory();
		this._walletFactory = WalletFactory.create(WalletStore.wallets.get(walletType)!);
		this.removeOnChangeWalletFactory = this._walletFactory.onChange((walletData) => this.setWalletData(walletData));
		try {
			await this._walletFactory.connect(provider);
		} catch (e) {
			await UserStore.getInstance().signOut(false);
			console.warn(e);
		}
	}

	public async disconnect() {
		this._userConnexionMethod = UserConnexionMethod.NONE;
		this.removeOnChangeWalletFactory();
		if (!this.walletFactory) return;
		this.removeOnChangeWalletFactory = this.walletFactory.onChange((walletData) => this.setWalletData(walletData));
		await this.walletFactory.disconnect();
	}

	public async autoConnect() {
		ConnectWalletButtonStore.getInstance().isLoading = true;
		this._userConnexionMethod = UserConnexionMethod.AUTOMATIC;

		let isAutoConnectSuccessfull = false;
		for (const walletType of WalletStore.wallets.keys()) {
			isAutoConnectSuccessfull = await this.autoConnectTo(walletType);
			if (isAutoConnectSuccessfull) {
				break;
			}
		}
		if (!isAutoConnectSuccessfull) {
			ConnectWalletButtonStore.getInstance().isLoading = false;
			if (UserStore.getInstance().getUser()) {
				await UserStore.getInstance().signOut(false);
			}
		}
	}

	private async autoConnectTo(walletType: EWalletType): Promise<boolean> {
		this.removeOnChangeWalletFactory();
		const wallectFactory = WalletFactory.create(WalletStore.wallets.get(walletType)!);
		this.removeOnChangeWalletFactory = wallectFactory.onChange((walletData) => this.setWalletData(walletData));
		const isConnectionSuccessfull = await wallectFactory.autoConnect();
		if (isConnectionSuccessfull) this._walletFactory = wallectFactory;
		return isConnectionSuccessfull;
	}

	public async signMessage(message: string): Promise<string> {
		if (!this.walletFactory) {
			await this.connectTo();
		}
		return this.walletFactory!.signMessage(message);
	}

	public async signTypedData(...params: Parameters<ethers.providers.JsonRpcSigner["_signTypedData"]>): Promise<Signature> {
		if (!this.walletFactory) {
			await this.connectTo();
		}
		return this.walletFactory!.signTypedData(...params);
	}

	public async sendTransaction(tx: any): Promise<ethers.providers.TransactionResponse> {
		if (!this.walletFactory) {
			await this.connectTo();
		}
		return this.walletFactory!.sendTransaction(tx);
	}

	public setWalletData(walletData: IWalletData) {
		this._walletData = walletData;
		this.event.emit("change", walletData);
	}

	public async getUserEmail(): Promise<string | null> {
		if (!this.walletFactory) {
			await this.connectTo();
		}
		return this.walletFactory!.getUserEmail();
	}
}
