import BigNumber from "bignumber.js";
import BaseTreasuryContract from "./core/BaseTreasuryContract";

export default class Arca160323Contract extends BaseTreasuryContract {
	public async getBasketBalance(): Promise<[string, string, BigNumber][]> {
		return this.contract["getBasketBalance"]();
	}

	public async getExitFee(): Promise<BigNumber> {
		return await this.contract["exitTaxForCollectiveTreasury"]();
	}

	public async getPreviewRedeemWithAmountForBasket(basketShareAmount: number): Promise<[string, string, BigNumber][]> {
		return await this.contract["previewRedeemWithAmountForBasket"](basketShareAmount);
	}

	public async redeemBasketForAssetAmount(tokenIds: number[]): Promise<any> {
		return await this.contract["redeemBasketForAssetAmount"](tokenIds);
	}
}
