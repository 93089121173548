import { Signature } from "ethers";

import BaseApiService from "Api/BaseApiService";
import PaginationHelper, { PaginatedResult, PaginationParam } from "common/pagination";
import ConfigStore from "Stores/ConfigStore";

import { AppOrderEntity, Order, OrderStatus, OrderType } from "Entities/appOrder";
import AppCollection from "../AppCollection";

export type CreateOrderParams = {
	orderHash: string;
	orderSignature: Signature;
	order: Order;
};

export type GetOrdersParam = {
	orderIds?: AppOrderEntity["id"][];
	statuses?: OrderStatus[];
	type?: OrderType;
	sellerAddress?: string;
	buyerAddress?: string;
	nftOwnerAddress?: string;
};

export default class AppOrder extends BaseApiService {
	private static instance: AppOrder;

	private constructor() {
		super();
		AppOrder.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-orders");
	}

	public static getInstance(reset?: boolean) {
		if (!AppOrder.instance || reset) return new this();
		return AppOrder.instance;
	}

	public async create(createOrderParams: CreateOrderParams): Promise<AppOrderEntity> {
		const url = new URL(this.baseUrl);
		try {
			return await this.postRequest<AppOrderEntity>(url, createOrderParams);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getOrders(params?: GetOrdersParam, pagination?: PaginationParam): Promise<PaginatedResult<AppOrderEntity>> {
		const url = new URL(this.baseUrl);

		PaginationHelper.populateUrl(url, pagination);
		this.populateUrl(url, params);

		try {
			const response = await this.getRequest<PaginatedResult<AppOrderEntity>>(url);
			return {
				...response,
				data: this.enrichOrdersWithCollectionConfig(response.data),
			};
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getOrdersByNft(
		collectionAddress: string,
		tokenId: number,
		type?: OrderType,
		status?: OrderStatus,
		pagination?: PaginationParam,
	): Promise<PaginatedResult<AppOrderEntity>> {
		let stringUrl = this.backUrl
			.concat(`/${ConfigStore.getInstance().config.app}`)
			.concat(`/app-collections/${collectionAddress}`)
			.concat(`/app-nfts/${tokenId}`)
			.concat(`/app-orders`);

		if (type) {
			stringUrl = stringUrl.concat(`?type=${type}&status=${status}`);
		}
		const url = new URL(stringUrl);
		PaginationHelper.populateUrl(url, pagination);
		try {
			const response = await this.getRequest<PaginatedResult<AppOrderEntity>>(url);
			return {
				...response,
				data: this.enrichOrdersWithCollectionConfig(response.data),
			};
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	private populateUrl(url: URL, param?: GetOrdersParam) {
		if (param) {
			const { statuses, type, sellerAddress, buyerAddress, nftOwnerAddress } = param;
			if (statuses) {
				url.searchParams.append("statuses", JSON.stringify(statuses));
			}

			if (type) {
				url.searchParams.append("type", type);
			}

			if (sellerAddress) {
				url.searchParams.append("sellerAddress", sellerAddress);
			}

			if (buyerAddress) {
				url.searchParams.append("buyerAddress", buyerAddress);
			}

			if (nftOwnerAddress) {
				url.searchParams.append("nftOwnerAddress", nftOwnerAddress);
			}
		}
	}

	private enrichOrdersWithCollectionConfig(orders: AppOrderEntity[]): AppOrderEntity[] {
		return orders.map((order) => {
			if (!order.appSale?.appNft) return order;

			return {
				...order,
				appSale: {
					...order.appSale,
					appNft: {
						...order.appSale.appNft,
						appCollection: AppCollection.getInstance().defineConfig(order.appSale.appNft.appCollection),
					},
				},
			};
		});
	}
}
