import { Component } from "react";
import classNames from "classnames";

// Styles
import classes from "./classes.module.scss";

export enum EIconButtonSize {
	SMALL = "small",
	MEDIUM = "medium",
	LARGE = "large",
}

export enum EIconButtonVariant {
	LIGHT = "light",
	FILLED = "filled",
	TRANSLUCIDE = "translucide",
}

type IProps = {
	icon: React.ReactNode;
	size: EIconButtonSize;
	variant: EIconButtonVariant;
	onClick?: () => void;
	disabled?: boolean;
	className?:string;
};
type IState = {};

export default class IconButton extends Component<IProps, IState> {
	static defaultProps = {
		size: EIconButtonSize.MEDIUM,
		variant: EIconButtonVariant.FILLED,
	};

	override render() {
		return (
			<button
				type="button"
				disabled={this.props.disabled}
				onClick={this.props.onClick}
				className={classNames([classes["root"], classes[this.props.size], classes[this.props.variant], this.props.className])}>
				{this.props.icon}
			</button>
		);
	}
}
