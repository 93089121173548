import AcceptOfferModalStore from "Stores/Modals/AcceptOfferModalStore";
import AddFunds2ModalStore from "Stores/Modals/AddFunds2ModalStore";
import AmountToSendModalStore from "Stores/Modals/AmountToSendModalStore";
import CancelListingModalStore from "Stores/Modals/CancelListingModalStore";
import CancelOfferModalStore from "Stores/Modals/CancelOfferModalStore";
import ChooseCryptoModalStore from "Stores/Modals/ChooseCryptoModalStore";
import ConfirmPaymentModalStore from "Stores/Modals/ConfirmPaymentModalStore";
import MakeOfferModalStore from "Stores/Modals/MakeOfferModalStore";
import ReceiveCryptoModalStore from "Stores/Modals/ReceiveCryptoModalStore";
import SettleAuctionModalStore from "Stores/Modals/SettleAuctionModalStore";

function closeAllModals() {
	MakeOfferModalStore.getInstance().closeModal();
	AcceptOfferModalStore.getInstance().closeModal();
	CancelOfferModalStore.getInstance().closeModal();
	ReceiveCryptoModalStore.getInstance().closeModal();
	ChooseCryptoModalStore.getInstance().closeModal();
	AmountToSendModalStore.getInstance().closeModal();
	ConfirmPaymentModalStore.getInstance().closeModal();
	CancelListingModalStore.getInstance().closeModal();
	SettleAuctionModalStore.getInstance().closeModal();
	AddFunds2ModalStore.getInstance().closeModal();
}

const ModalHelper = {
	closeAllModals,
};

export default ModalHelper;
