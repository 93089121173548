import BaseApiService from "Api/BaseApiService";
import { AbiType } from "common/enums/AbiType";
import { AppAbiEntity } from "Entities/appAbi";

export default class AppAbi extends BaseApiService {
	private static instance: AppAbi;

	private constructor() {
		super();
		AppAbi.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/app-abis");
	}

	public static getInstance(reset?: boolean) {
		if (!AppAbi.instance || reset) return new this();
		return AppAbi.instance;
	}

	public async getAbiByType(abiType: AbiType): Promise<AppAbiEntity> {
		const url = new URL(this.baseUrl.concat(`/${abiType}`));
		try {
			return await this.getRequest<AppAbiEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
