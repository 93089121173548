import React from "react";

// Components
import BurgerCross, { EBurgerCrossState } from "Components/Elements/BurgerCross";

// Stores
import { EOpeningState } from "Stores/TopMenuStatus";
import UserMenuStatus from "Stores/UserMenuStatus";

type IState = {
	status: EOpeningState;
};
type IProps = {};

export default class MenuBurger extends React.Component<IProps, IState> {
	private removeOnSwitch = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			status: UserMenuStatus.getInstance().status,
		};

		this.toggleUserMenu = this.toggleUserMenu.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<span onClick={this.toggleUserMenu}>
				<BurgerCross state={this.state.status === EOpeningState.CLOSED ? EBurgerCrossState.BURGER : EBurgerCrossState.CROSS} />
			</span>
		);
	}

	public override componentDidMount() {
		this.removeOnSwitch = UserMenuStatus.getInstance().onSwitch((type) => {
			this.updateUserMenuStatus();
		});
	}

	public override componentWillUnmount() {
		this.removeOnSwitch();
	}

	public getStatus() {
		return this.state.status;
	}

	private updateUserMenuStatus() {
		this.setState({
			status: UserMenuStatus.getInstance().status,
		});
	}

	private toggleUserMenu() {
		UserMenuStatus.getInstance().toggle();
	}
}
