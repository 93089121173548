import UserMenuStatus from "Stores/UserMenuStatus";
import classes from "./classes.module.scss";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import WalletSection from "../WalletSection";
import LogoutButton from "Components/Materials/LogoutButton";
import ProfileSection from "../ProfileSection";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import NavLinks from "../NavLinks";
import UserService from "Services/UserService";
import ChooseCryptoModalStore from "Stores/Modals/ChooseCryptoModalStore";
import ReceiveCryptoModalStore from "Stores/Modals/ReceiveCryptoModalStore";
import UserStore from "Stores/UserStore";
import WalletBalanceStore, { IBalances } from "Stores/WalletBalanceStore";
import WindowStore from "Stores/Window";
import { useState, useCallback, useEffect } from "react";
import classNames from "classnames";

export default function ConnectedMenu() {
	const [status, setStatus] = useState(UserMenuStatus.getInstance().status);
	const [user, setUser] = useState(UserStore.getInstance().getUser());
	const [balances, setBalances] = useState(WalletBalanceStore.getInstance().balances);

	const closeUserMenu = useCallback(() => UserMenuStatus.getInstance().close(), []);
	const openChooseCryptoModal = useCallback(() => ChooseCryptoModalStore.getInstance().openModal({}), []);
	const openReceiveCryptoModal = useCallback(() => ReceiveCryptoModalStore.getInstance().openModal({}), []);

	const isAccountCompleted = useCallback(() => {
		if (!user) return false;
		return UserService.getInstance().hasAllInfosCompleted(user);
	}, [user]);

	const closeOnResize = useCallback(
		(window: Window) => {
			if (window.innerWidth > 767 && !user) {
				closeUserMenu();
			}
		},
		[closeUserMenu, user],
	);

	const hasNoTokens = (balances: IBalances) => Object.values(balances).every((balance) => balance.balance.isZero());

	useEffect(() => UserMenuStatus.getInstance().onSwitch(setStatus), []);
	useEffect(() => UserStore.getInstance().onChange(setUser), []);
	useEffect(() => WalletBalanceStore.getInstance().onChange(setBalances), []);
	useEffect(() => WindowStore.getInstance().onResize(closeOnResize), [closeOnResize]);

	return (
		<div className={classNames(classes["root"], classes[status])}>
			<div className={classes["shadow"]} onClick={closeUserMenu} />

			<div className={classes["content"]}>
				<div className={classes["container"]}>
					<ProfileSection user={user} closeUserMenu={closeUserMenu} isAccountCompleted={isAccountCompleted} />

					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Navigation}>
						<div className={classes["nav-section"]}>
							<NavLinks user={user} />
						</div>
					</IsModuleEnabled>

					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Buttons}>
						<div className={classes["buttons-section"]}>
							<Button variant={EButtonVariant.OUTLINED} size={EButtonSize.MEDIUM} color={EButtonColor.PRIMARY}>
								<I18n map="common.new_creation" />
							</Button>

							<Button variant={EButtonVariant.CONTAINED} size={EButtonSize.MEDIUM} color={EButtonColor.PRIMARY}>
								<I18n map="common.my_creations" />
							</Button>
						</div>
					</IsModuleEnabled>

					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Wallet}>
						<WalletSection
							hasNoTokens={hasNoTokens(balances)}
							openChooseCryptoModal={openChooseCryptoModal}
							openReceiveCryptoModal={openReceiveCryptoModal}
							balances={balances}
						/>
					</IsModuleEnabled>
				</div>

				<div className={classes["logout"]}>
					<LogoutButton onClick={closeUserMenu} />
				</div>
			</div>
		</div>
	);
}
