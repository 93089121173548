import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import Modal, { IModalProps } from "Components/Elements/Modals";

import classes from "./classes.module.scss";

type IProps = IModalProps & {
	onAccept?: () => void;
	onCancel?: () => void;
	cancelText: string | JSX.Element;
	confirmText: string | JSX.Element;
	showCancelButton: boolean;
	isConfirmButtonDisabled: boolean;
};

export default class Confirm extends Modal<IProps> {
	static defaultProps: Partial<IProps> = {
		showCancelButton: true,
		cancelText: "Cancel",
		confirmText: "Confirm",
		isConfirmButtonDisabled: false,
	};

	constructor(props: IProps) {
		super(props);
		this.onCancel = this.onCancel.bind(this);
	}

	public override render(): JSX.Element | null {
		return (
			<Modal
				closeBtn={this.props.closeBtn}
				isOpen={this.props.isOpen}
				onClose={this.props.onClose}
				header={this.props.header}
				footer={this.footer()}>
				{this.props.children}
			</Modal>
		);
	}

	private footer(): JSX.Element {
		return (
			<div className={classes["buttons-container"]}>
				{this.props.showCancelButton && (
					<Button variant={EButtonVariant.OUTLINED} size={EButtonSize.MEDIUM} onClick={this.onCancel}>
						{this.props.cancelText}
					</Button>
				)}

				<Button
					variant={EButtonVariant.CONTAINED}
					color={EButtonColor.PRIMARY}
					size={EButtonSize.MEDIUM}
					onClick={this.props.onAccept}
					disabled={this.props.isConfirmButtonDisabled}>
					{this.props.confirmText}
				</Button>
			</div>
		);
	}

	private onCancel() {
		this.close();
		this.props.onCancel?.();
	}
}
