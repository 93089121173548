import Modal from "Components/Elements/Modals";
import classes from "./classes.module.scss";
import I18n from "Components/Elements/I18n";
import { useCallback, useEffect, useState } from "react";
import PassKYCModalStore from "Stores/Modals/PassKYCModalStore";
import { EModal } from "Stores/Modals/AModalStore";
import Typography from "Components/Elements/Typography";
import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import SynapsModalStore from "Stores/Modals/SynapsModalStore";

export type IPassKYCModalProps = {
	message?: string;
	buttonText?: string;
};

type IProps = IPassKYCModalProps;

export function PassKYCModal(props: IProps) {
	const [isModalOpen, setIsModalOpen] = useState(PassKYCModalStore.getInstance().isOpen(EModal.PASS_KYC));
	const [propsContent, setPropsContent] = useState(props);
	const i18nBaseTranslationKey = "modals.pass_kyc";
	const closeModal = useCallback(() => {
		PassKYCModalStore.getInstance().closeModal();
	}, []);

	const updateIsModalOpen = useCallback(
		(modalsOpenedState: { [type: string]: boolean }, propsContent: IPassKYCModalProps) => {
			setPropsContent(propsContent ?? props);
			setIsModalOpen(modalsOpenedState[EModal.PASS_KYC] ?? false);
		},
		[props],
	);

	const openSynaps = useCallback(async () => {
		SynapsModalStore.getInstance().openModal({});
		PassKYCModalStore.getInstance().closeModal();
	}, []);

	useEffect(() => {
		return PassKYCModalStore.getInstance().onChange(updateIsModalOpen);
	}, [updateIsModalOpen, propsContent]);

	const header: JSX.Element = <I18n map={`${i18nBaseTranslationKey}.title`} />;
	const footer: JSX.Element = (
		<div className={classes["footer"]}>
			<Button variant={EButtonVariant.OUTLINED} color={EButtonColor.NEUTRAL} onClick={closeModal} fullwidth>
				<I18n map={`${i18nBaseTranslationKey}.button.cancel`} />
			</Button>
			<Button
				variant={EButtonVariant.CONTAINED}
				color={EButtonColor.PRIMARY}
				endIcon={<ArrowRightIcon />}
				onClick={openSynaps}
				fullwidth>
				<I18n map={propsContent.buttonText} />
			</Button>
		</div>
	);
	return (
		<Modal header={header} footer={footer} isOpen={isModalOpen} onClose={closeModal} closeBtn>
			<Typography type="p" size="small" weight="regular" color="neutral">
				<I18n map={propsContent.message} />
			</Typography>
		</Modal>
	);
}
