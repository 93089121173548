import { createContext, useState, ReactNode, ReactElement, FC, useCallback } from "react";
import classNames from "classnames";
import classes from "./classes.module.scss";

type ModalStackItem = {
	content: ReactElement;
};

type ModalContextType = {
	showModal: (modalContent: ReactElement) => void;
	hideModal: () => void;
	hideAllModals: () => void;
};

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [modalStack, setModalStack] = useState<ModalStackItem[]>([]);

	const showModal = useCallback((content: ReactElement) => {
		setModalStack((prevStack) => [...prevStack, { content }]);
	}, []);

	const hideModal = useCallback(() => {
		setModalStack((prevStack) => prevStack.slice(0, -1));
	}, []);

	const hideAllModals = useCallback(() => {
		setModalStack([]);
	}, []);

	const renderModals = useCallback(() => {
		return modalStack.map((item, index) => (
			<div key={index} className={classNames({ [classes["hidden"]!]: index !== modalStack.length - 1 })}>
				{item.content}
			</div>
		));
	}, [modalStack]);

	return (
		<ModalContext.Provider value={{ showModal, hideModal, hideAllModals }}>
			{children}
			{renderModals()}
		</ModalContext.Provider>
	);
};
