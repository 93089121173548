import { InstanceWithExtensions, MagicSDKExtensionsOption, SDKBase } from "@magic-sdk/provider";
import { ethers, Signature } from "ethers";
import { Magic } from "magic-sdk";

import BigNumber from "Services/BigNumber";
import EventEmitter from "Services/EventEmitter";
import ConfigStore from "Stores/ConfigStore";

import IWalletInterface, { IWalletData } from "../IWalletInterface";
import { IWallet } from "./Web3ModalWallet";
import WalletStore from "Stores/WalletStore";
import { UserConnexionMethod } from "common/enums/UserConnectionMethod";

export default class MagicUniversalWallet implements IWalletInterface {
	private static magic: InstanceWithExtensions<SDKBase, MagicSDKExtensionsOption<string>>;
	private readonly magicAuthLocalStorageDBName = "MagicUniversalWalletStorageDB";
	private _walletData: IWalletData | null = null;
	private readonly event = new EventEmitter();
	private removeEvents = () => {};

	public getWalletData(): IWalletData {
		return (
			this._walletData ?? {
				userAddress: null,
				balance: null,
				provider: null,
				publicKey: null,
				chainId: null,
			}
		);
	}

	public async connect(): Promise<void> {
		if (WalletStore.getInstance().userConnexionMethod === UserConnexionMethod.MANUAL) {
			try {
				// We delete the IndexedDB in case the user has disconnected wallet outside of the platform
				// This is to prevent the user from being stuck on the "Connect Wallet" button because of desynchronous data from wallet versus Magic
				await this.deleteIndexedDb();
			} catch (err) {
				console.warn(err);
			}
		}

		const provider = await this.getMagic().wallet.getProvider();
		try {
			if (!provider.isMetaMask) {
				await this.getMagic().wallet.connectWithUI();
			}
			localStorage.setItem("MAGIC_UNIVERSAL_CACHED_PROVIDER", "ok");
		} catch (e) {
			console.error(e);
			await this.disconnect();
		} finally {
			const provider = await this.getMagic().wallet.getProvider();
			const web3Provider = new ethers.providers.Web3Provider(provider);

			if (provider.isMetaMask) {
				let eventRefresh = async () => {
					eventRefresh = async () => {};
					await this.disconnect();
					await this.connect();
				};

				provider.on("chainChanged", () => eventRefresh());

				/**
				 * If true, let chainChanged event to force new connection
				 */
				if (await this.switchOrAddNetwork(provider)) {
					return;
				}

				/**
				 * Set empty function, to prevent new connection
				 * I tried to use instance.removeAllListeners without succeed
				 */
				eventRefresh = async () => {};
				this.initEvents(provider, web3Provider);
			}
			this.updateWalletData(web3Provider);
		}
	}

	public async autoConnect(): Promise<boolean> {
		if (localStorage.getItem("MAGIC_UNIVERSAL_CACHED_PROVIDER")) {
			await this.connect();
			return true;
		}
		return false;
	}

	public async getUserEmail(): Promise<string | null> {
		const provider = await this.getMagic().wallet.getProvider();
		if (provider.isMetaMask) return null;
		const user = this.getMagic().user;
		const userInfo = await user.requestInfoWithUI({
			scope: { email: "required" },
		});

		return userInfo.email ?? null;
	}

	public async disconnect(): Promise<void> {
		if (!this._walletData?.userAddress) return;
		try {
			localStorage.removeItem("MAGIC_UNIVERSAL_CACHED_PROVIDER");
			this._walletData = null;
			await this.getMagic().user.logout();
			await this.updateWalletData(null);
			this.removeEvents();
		} catch (e) {
			console.warn(e);
		}
	}

	public onChange(callback: (web3WalletData: IWallet) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public async signMessage(message: string): Promise<string> {
		try {
			if (!this.getWalletData().userAddress) {
				Promise.reject("User connected");
			}
			const signer = this.getWalletData().provider.getSigner();
			return await signer?.signMessage(message);
		} catch (err) {
			return Promise.reject(err);
		}
	}

	public async signTypedData(...params: Parameters<ethers.providers.JsonRpcSigner["_signTypedData"]>): Promise<Signature> {
		try {
			const provider = await this.getMagic().wallet.getProvider();
			if (provider.isMetaMask) {
				const signer = this.getWalletData().provider.getSigner();
				return await signer?._signTypedData(...params);
			}
			return await this.signTypedDataMagic(params);
		} catch (err) {
			return Promise.reject(err);
		}
	}

	private async signTypedDataMagic(params: Parameters<ethers.providers.JsonRpcSigner["_signTypedData"]>): Promise<Signature> {
		const types = {
			EIP712Domain: [
				{ name: "name", type: "string" },
				{ name: "version", type: "string" },
				{ name: "chainId", type: "uint256" },
				{ name: "verifyingContract", type: "address" },
			],
			...params[1],
		};
		const signTypedDataV3Payload = { domain: params[0], types, message: params[2], primaryType: "Order" };
		try {
			const account = this.getWalletData().userAddress;
			const params = [account, signTypedDataV3Payload];

			const method = "eth_signTypedData_v4";

			const signature = await this.getMagic().rpcProvider.request({
				method,
				params,
			});

			return signature;
		} catch (err) {
			return Promise.reject(err);
		}
	}

	public async sendTransaction(
		tx: ethers.utils.Deferrable<ethers.providers.TransactionRequest>,
	): Promise<ethers.providers.TransactionResponse> {
		try {
			const provider = this._walletData?.provider as ethers.providers.Web3Provider;
			const signer = provider.getSigner();
			if (!signer) throw new Error("Missing Signer");

			return signer.sendTransaction(tx);
		} catch (err) {
			return Promise.reject(err);
		}
	}

	private getMagic(isNew: boolean = false) {
		if (MagicUniversalWallet.magic && !isNew) return MagicUniversalWallet.magic;
		MagicUniversalWallet.magic = MagicUniversalWallet.newMagic();
		return MagicUniversalWallet.magic;
	}

	private static newMagic() {
		return new Magic(ConfigStore.getInstance().config.wallet.ethereum.magicConnect.apiKey, {
			network: {
				rpcUrl: ConfigStore.getInstance().config.blockchain.ethereum.rpc,
				chainId: ConfigStore.getInstance().config.blockchain.ethereum.chainId,
			},
		});
	}

	private async updateWalletData(provider: ethers.providers.Web3Provider | null) {
		const userAddress: string | null = (await provider?.listAccounts())?.[0] ?? null;
		const chainId = (await provider?.getNetwork())?.chainId ?? null;
		let balance = null;

		if (userAddress && provider) {
			balance = BigNumber.from((await provider.getBalance(userAddress)).toString());
		}

		const walletData: IWalletData = {
			userAddress,
			chainId,
			balance,
			provider,
			publicKey: null,
		};

		this._walletData = walletData;
		this.event.emit("change", this._walletData);
	}

	private initEvents(instance: any | null, provider: ethers.providers.Web3Provider | null): void {
		this.removeEvents();
		const updateWalletData = () => this.updateWalletData(provider);

		instance.on("accountsChanged", updateWalletData);
		instance.on("connect", updateWalletData);
		instance.on("disconnect", updateWalletData);
		instance.on("chainChanged", async () => {
			await this.disconnect();
		});
		this.removeEvents = () => {
			if (instance.removeAllListeners) {
				instance.removeAllListeners();
			}
		};
	}

	private async switchOrAddNetwork(provider: any): Promise<boolean> {
		try {
			return await this.switchToNetwork(provider);
		} catch (switchError: any) {
			if (switchError.code === 4902) {
				await this.addNetwork(provider);
				return true;
			}
		}

		return false;
	}

	private async switchToNetwork(provider: any): Promise<boolean> {
		const previousChainId = provider.chainId;
		const newChainId = ConfigStore.getInstance().config.blockchain.ethereum.chainIdHexa;
		if (previousChainId === newChainId) return false;
		await provider.request({
			method: "wallet_switchEthereumChain",
			params: [{ chainId: newChainId }],
		});

		return true;
	}

	private async addNetwork(provider: any): Promise<boolean> {
		const blockchainConfig = ConfigStore.getInstance().config.blockchain;
		try {
			await provider.request({
				method: "wallet_addEthereumChain",
				params: [
					{
						chainId: blockchainConfig.ethereum.chainIdHexa,
						rpcUrls: [blockchainConfig.ethereum.rpc],
						chainName: blockchainConfig.ethereum.name,
						nativeCurrency: {
							name: blockchainConfig.ethereum.ticker,
							symbol: blockchainConfig.ethereum.ticker,
							decimals: 18,
						},
						blockExplorerUrls: [blockchainConfig.ethereum.blockExplorer],
					},
				],
			});

			return true;
		} catch (err) {
			console.warn(err);
			return false;
		}
	}

	private async deleteIndexedDb(): Promise<{ success: boolean; message: string }> {
		const magicAuthLocalStorageDBName = this.magicAuthLocalStorageDBName;
		return new Promise((resolve, reject) => {
			const req = indexedDB.deleteDatabase(magicAuthLocalStorageDBName);

			req.onsuccess = function (event) {
				resolve({ success: true, message: `${magicAuthLocalStorageDBName} IndexedDB deleted successfully` });
			};

			req.onerror = function (event) {
				reject({ success: false, message: `error deleting ${magicAuthLocalStorageDBName} IndexedDB` });
			};

			req.onblocked = function (event) {
				reject({ success: false, message: `blocked, other transactions are running on ${magicAuthLocalStorageDBName} IndexedDB` });
			};
		});
	}
}
