import { EnvelopeIcon, WalletIcon } from "@heroicons/react/24/solid";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modals";
import Typography from "Components/Elements/Typography";
import { EModal } from "Stores/Modals/AModalStore";
import AddFunds2ModalStore from "Stores/Modals/AddFunds2ModalStore";
import ConnectWalletModalStore from "Stores/Modals/ConnectWalletModalStore";
import { ReactComponent as Google } from "assets/images/logos/google.svg";
import { useModal } from "hooks/useModal";
import React, { ReactElement } from "react";
import classes from "./classes.module.scss";
import WalletStore, { EWalletType, defaultWalletData } from "Stores/WalletStore";

type IProps = {};

type IPropsClass = IProps & {
	showModal: (content: ReactElement) => void;
	hideModal: () => void;
};

type IState = {
	isOpen: boolean;
};

class ConnectWalletModalClass extends React.Component<IPropsClass, IState> {
	private removeOnModalChange = () => {};

	constructor(props: IPropsClass) {
		super(props);

		this.state = {
			isOpen: AddFunds2ModalStore.getInstance().isOpen(EModal.CONNECT_WALLET),
		};

		this.close = this.close.bind(this);
		this.updateModalState = this.updateModalState.bind(this);
	}

	override render(): JSX.Element | null {
		return (
			<div className={classes["root"]}>
				<Modal isOpen={this.state.isOpen} header={I18n.translate("modals.connect_wallet.title")} closeBtn onClose={this.close} className={classes["modal"]}>
					<div className={classes["container"]}>
						<div className={classes["social-logins-container"]}>
							<Button
								variant={EButtonVariant.CONTAINED}
								fullwidth
								size={EButtonSize.LARGE}
								color={EButtonColor.PRIMARY}
								startIcon={<Google />}
								onClick={() => {
									WalletStore.getInstance().connectTo(EWalletType.MAGIC_DEDICATED_WALLET, "google");
									ConnectWalletModalStore.getInstance().closeModal();
								}}>
								{I18n.translate("modals.connect_wallet.google")}
							</Button>
						</div>
						<div className={classes["or-container"]}>
							<div className={classes["line"]}></div>
							<Typography type="p" size="medium" weight="medium">
								<I18n map="modals.connect_wallet.or" />
							</Typography>
							<div className={classes["line"]}></div>
						</div>
						<div className={classes["external-wallets-container"]}>
							<Button
								variant={EButtonVariant.CONTAINED}
								fullwidth
								size={EButtonSize.LARGE}
								color={EButtonColor.NEUTRAL}
								startIcon={<EnvelopeIcon />}
								onClick={() => {
									WalletStore.getInstance().connectTo(EWalletType.MAGIC_DEDICATED_WALLET);
									ConnectWalletModalStore.getInstance().closeModal();
								}}>
								{I18n.translate("modals.connect_wallet.email")}
							</Button>
							<Button
								variant={EButtonVariant.CONTAINED}
								fullwidth
								size={EButtonSize.LARGE}
								color={EButtonColor.NEUTRAL}
								startIcon={<WalletIcon />}
								onClick={() => {
									WalletStore.getInstance().connectTo(EWalletType.WEB3MODAL);
									ConnectWalletModalStore.getInstance().closeModal();
								}}>
								{I18n.translate("modals.connect_wallet.connect_wallet")}
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		);
	}

	public override async componentDidMount() {
		this.removeOnModalChange = ConnectWalletModalStore.getInstance().onChange(this.updateModalState);
	}

	private updateModalState(modalsOpenedState: { [type: string]: boolean }) {
		this.setState({
			isOpen: modalsOpenedState[EModal.CONNECT_WALLET] ?? false,
		});
	}

	public override async componentDidUpdate(prevProps: IPropsClass) {}

	public override componentWillUnmount() {
		this.removeOnModalChange();
	}

	private close() {
		WalletStore.getInstance().setWalletData(defaultWalletData);
		ConnectWalletModalStore.getInstance().closeModal();
	}
}

export default function ConnectWalletModal(props: IProps) {
	const { showModal, hideModal } = useModal();
	return <ConnectWalletModalClass {...props} showModal={showModal} hideModal={hideModal} />;
}
