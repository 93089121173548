import IsConnected from "../IsConnected";
import ConnectedMenu from "./ConnectedMenu";
import NotConnectedMenu from "./NotConnectedMenu";

export default function UserMenu() {
	return (
		<div>
			<IsConnected>
				<ConnectedMenu />
			</IsConnected>
			<IsConnected no>
				<NotConnectedMenu />
			</IsConnected>
		</div>
	);
}
