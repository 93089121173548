import SeeAll from "Components/Materials/MainSearchBar/SearchPreview/SeeAll";
import React from "react";
import Typography from "../Typography";
import classes from "./classes.module.scss";
import { AppNftEntity } from "Entities/appNft";

type IProps = {
	tabType: "default" | "custom";
	items: IElementMenu[];
	nft: AppNftEntity | null;
};

type IState = {
	itemSelected: number;
	animatedOnce: boolean;
};

export type IElementMenu = {
	label: string;
	tab?: JSX.Element;
	content: JSX.Element;
};

export default class TabElementSwitcher extends React.Component<IProps, IState> {
	private itemsRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {};
	private underlineElement = React.createRef<HTMLDivElement>();
	private animatedOnce: boolean = false;

	public static defaultProps: Partial<IProps> = {
		tabType: "default",
	};

	constructor(props: IProps) {
		super(props);
		this.state = {
			itemSelected: 0,
			animatedOnce: false,
		};
		this.initRefs();
		this.onChange = this.onChange.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<div className={classes["type-selector"]}>
					{this.renderItems()}
					{this.renderUnderline()}
				</div>
				{this.props.items[this.state.itemSelected]?.content}
				{this.renderSeeAllComponent()}
			</div>
		);
	}

	public override componentDidMount() {
		this.makeUnderline(this.state.itemSelected);
	}

	public override componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
		if (prevProps.nft !== this.props.nft) {
			this.changeNFTAndSetFirstTabAsDefault();
		}
	}

	private renderSeeAllComponent() {
		const labelSelected = this.props.items[this.state.itemSelected]?.label.toLocaleLowerCase();
		if (labelSelected !== "collections" && labelSelected !== "nfts") return;

		return <SeeAll section={labelSelected} />;
	}

	private initRefs() {
		for (let i = 0; i < this.props.items.length; i++) {
			this.itemsRefs[i] = this.itemsRefs[i] ?? React.createRef();
		}
	}

	private makeUnderline(index: number) {
		if (this.animatedOnce && this.state.animatedOnce === false) {
			this.setState({ animatedOnce: true });
		}

		const current = this.itemsRefs[index]?.current;
		const parent = current?.parentElement?.parentElement;
		if (!current || !parent) return;
		const currentBoudingRects = current.getBoundingClientRect();
		const x = currentBoudingRects.x - parent.getBoundingClientRect().x;
		this.underlineElement.current!.style.transform = `translateX(${x}px)`;
		this.underlineElement.current!.style.width = `${currentBoudingRects.width}px`;

		this.animatedOnce = true;
	}

	private onChange(index: number) {
		this.setState({
			itemSelected: index,
		});
		this.makeUnderline(index);
	}

	private changeNFTAndSetFirstTabAsDefault() {
		this.setState({
			itemSelected: 0,
		});
		this.makeUnderline(0);
	}

	private renderItems() {
		if (this.props.tabType === "custom") {
			return this.props.items.map((item, index) => (
				<div
					className={classes["label"]}
					ref={this.itemsRefs[index]}
					data-selected={index === this.state.itemSelected}
					onClick={() => this.onChange(index)}
					key={item.label}>
					{item.tab}
				</div>
			));
		}

		return this.props.items.map((item, index) => (
			<div
				className={classes["label"]}
				ref={this.itemsRefs[index]}
				data-selected={index === this.state.itemSelected}
				onClick={() => this.onChange(index)}
				key={item.label}>
				<Typography color="neutral" type="p" size="medium" weight="semibold">
					{item.label}
				</Typography>
			</div>
		));
	}

	private renderUnderline() {
		if (this.props.tabType === "default") {
			return (
				<div ref={this.underlineElement} data-animated-once={this.state.animatedOnce} className={classes["selected-underline"]} />
			);
		} else {
			return <></>;
		}
	}
}
