import AppContract from "Api/Back/AppContract";
import { AppContractEntity, ContractType } from "Entities/appContract";
import ContractFactory, { EthersContract } from "Services/Contracts/Ethereum/ContractFactory";
import WalletStore from "Stores/WalletStore";
import assert from "assert";

type EnrichedContract = {
	contractEntity: AppContractEntity;
	ethersContract: EthersContract;
};

export default class DeprecatedContractStore {
	private static instance: DeprecatedContractStore;

	private enrichedContracts: EnrichedContract[] = [];

	public constructor() {
		WalletStore.getInstance().onChange((walletData) => {
			this.enrichedContracts = [];
		});
	}

	public static getInstance(): DeprecatedContractStore {
		return (this.instance = this.instance ?? new DeprecatedContractStore());
	}

	public async getEnrichedContractByAddress(contractAddress: string): Promise<EnrichedContract> {
		const enrichedContract = this.enrichedContracts.find(
			(enrichedContract) => enrichedContract.ethersContract.address === contractAddress,
		);
		if (enrichedContract) {
			return enrichedContract;
		}

		const appContractEntity = await AppContract.getInstance().getContractByAddress(contractAddress);
		assert(appContractEntity, `Contract entity not found for address: ${contractAddress}`);

		const addedEnrichedContract = await this.addContract(appContractEntity);

		return addedEnrichedContract;
	}

	public async getEnrichedContractByType(contractType: ContractType): Promise<EnrichedContract> {
		const enrichedContract = this.enrichedContracts.find(
			(enrichedContract) => enrichedContract.ethersContract.contractType === contractType,
		);
		if (enrichedContract) {
			return enrichedContract;
		}

		const appContractEntities = await AppContract.getInstance().getContracts({ filter: { type: contractType } });
		// When we get a contract by type, we get an array of contracts, but we only need one
		const appContractEntity = appContractEntities[0];
		assert(appContractEntity, `Contract entity not found for type: ${contractType}`);

		const addedEnrichedContract = await this.addContract(appContractEntity);

		return addedEnrichedContract;
	}

	private async addContract(appContractEntity: AppContractEntity): Promise<EnrichedContract> {
		const appAbi = appContractEntity.appAbi;
		assert(appAbi, `ABI not found for address: ${appContractEntity.address}`);

		const ethersContract = await ContractFactory.getInstance().build({
			address: appContractEntity.address,
			abi: appAbi.abi,
			config: appContractEntity.config,
			contractType: appContractEntity.type,
			abiType: appAbi.type,
		});

		assert(
			ethersContract.contractType !== ContractType.MARKETPLACE &&
				ethersContract.contractType !== ContractType.ERC721 &&
				ethersContract.contractType !== ContractType.ROYALTIES &&
				ethersContract.contractType !== ContractType.TREASURY,
			`ContractStore usage for ${ethersContract.contractType} is not allowed because ContractStore will be delete soon, please use the right one in 'contractStores/' folder`,
		);

		const enrichedContract = { contractEntity: appContractEntity, ethersContract };
		this.enrichedContracts.push(enrichedContract);

		return enrichedContract;
	}
}
