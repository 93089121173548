import I18n from "Components/Elements/I18n";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import NavLinkElement from "Components/Elements/NavLinkElement";
import Typography from "Components/Elements/Typography";
import UserService from "Services/UserService";
import UserStore from "Stores/UserStore";
import { ReactComponent as Wheel } from "assets/images/icons/pages/wheel.svg";
import classes from "./classes.module.scss";
import { useCallback } from "react";
import { useModal } from "hooks/useModal";
import RegisterModal from "Components/Materials/Modals/RegisterModal";

export default function SettingsNavLinkElement() {
	const { showModal, hideModal } = useModal();
	const openRegisterModal = useCallback(() => {
		showModal(<RegisterModal onClose={hideModal} />);
	}, [showModal, hideModal]);

	const currentUser = UserStore.getInstance().getUser();
	if (!currentUser) return null;
	const isUserInfosCompleted = UserService.getInstance().isUserInfosCompleted(currentUser);
	return (
		<>
			{!isUserInfosCompleted && (
				<div className={classes["route"]} data-variant="menu-item" onClick={openRegisterModal}>
					<div className={classes["content"]}>
						<Wheel />
						<Typography color="neutral" className={classes["link"]} type="p" size="small" weight="semibold">
							<I18n map="common.settings" />
						</Typography>
					</div>
				</div>
			)}
			{isUserInfosCompleted && (
				<NavLinkElement
					size="small"
					to={IsModuleEnabled.get().UserMenu.props.Navigation.props.Settings.path}
					name={I18n.translate("pages.profile.subPages.activities.menu.options.settings")}
					variant="menu-item"
					icon={<Wheel />}
				/>
			)}
		</>
	);
}
