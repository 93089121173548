import WertWidget from "@wert-io/widget-initializer";
import { AppCollectionEntity } from "Entities/appCollection";
import { AppNftEntity } from "Entities/appNft";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import BigNumber from "Services/BigNumber";
import ConfigStore from "Stores/ConfigStore";
import TokenSupports from "Stores/TokenSupports";
import UserStore from "Stores/UserStore";

export enum WertPaymentStatus {
	PENDING = "pending",
	CANCELED = "canceled",
	FAILED = "failed",
	SUCCESS = "success",
	FAILOVER = "failover",
}

export type WertPositionEventListenersParams = {
	step: string; // order-review, order-status, I did not check the rest and it's not documented
};

export type WertPaymentStatusEventListenersParams = {
	status: WertPaymentStatus;
	payment_id: string;
	order_id: string;
	tx_id?: string; // if available
};

interface ITokenSupportedList {
	commodity: string;
	network: string;
}

const styleWertConfig = {
	color_background: getComputedStyle(document.documentElement).getPropertyValue("--color-generic-background"),
	color_buttons: getComputedStyle(document.documentElement).getPropertyValue("--contained-button-background"),
	color_buttons_text: getComputedStyle(document.documentElement).getPropertyValue("--contained-button-color"),
	color_secondary_buttons: getComputedStyle(document.documentElement).getPropertyValue("--color-neutral-50"),
	color_secondary_buttons_text: getComputedStyle(document.documentElement).getPropertyValue("--outline-button-background"),
	color_main_text: getComputedStyle(document.documentElement).getPropertyValue("--color-neutral-900"),
	color_secondary_text: getComputedStyle(document.documentElement).getPropertyValue("--color-neutral-500"),
	color_icons: getComputedStyle(document.documentElement).getPropertyValue("--color-primary"),
	color_links: getComputedStyle(document.documentElement).getPropertyValue("--color-neutral-900"),
	color_success: getComputedStyle(document.documentElement).getPropertyValue("--color-success-500"),
	color_warning: getComputedStyle(document.documentElement).getPropertyValue("--color-warning-500"),
	color_error: getComputedStyle(document.documentElement).getPropertyValue("--color-error-500"),
	buttons_border_radius: getComputedStyle(document.documentElement).getPropertyValue(" --button-border-radius"),
	secondary_buttons_border_radius: getComputedStyle(document.documentElement).getPropertyValue(" --button-border-radius"),
};

async function addFunds({
	amount,
	token,
	onPositionChange,
	onPaymentStatusChange,
	onClose,
}: {
	amount?: BigNumber;
	token?: AppTokenSupportEntity;
	onPositionChange?: (data: WertPositionEventListenersParams) => void;
	onPaymentStatusChange?: (data: WertPaymentStatusEventListenersParams) => void;
	onClose: () => void;
}) {
	const wertOptions: WertWidget["options"] = {
		partner_id: ConfigStore.getInstance().config.wert.partnerId,
		container_id: "topup-widget-container",
		origin: ConfigStore.getInstance().config.wert.origin, // this option needed only in sandbox
		skip_init_navigation: false,
		currency: "USD",
		network: ConfigStore.getInstance().config.wert.network,
		commodity: token?.symbol,
		commodity_amount: (amount ?? BigNumber.from("0")).toNumber(token?.decimals),
		listeners: {
			position: onPositionChange,
			"payment-status": onPaymentStatusChange,
			close: () => onClose(),
			loaded: () => console.info("Wert Addfunds Helper loaded"),
			error: (data: any) => console.error("error", data),
		},
		address: UserStore.getInstance().getUser()?.appWallet?.userAddress,
		email: UserStore.getInstance().getUser()?.email,
		autosize: true,
		commodities: getTokenSupportedList(),
		...styleWertConfig,
	};
	try {
		const wertWidget = new WertWidget({ ...wertOptions, ...styleWertConfig });
		wertWidget.mount();
	} catch (e) {
		console.error(e);
	}
}

async function buyNft(
	signedData: any,
	nft: AppNftEntity,
	onClose: () => void,
	onPositionChange?: (data: WertPositionEventListenersParams) => void,
	onPaymentStatusChange?: (data: WertPaymentStatusEventListenersParams) => void,
) {
	const wertOptions: WertWidget["options"] = {
		partner_id: ConfigStore.getInstance().config.wert.partnerId,
		container_id: "topup-widget-container",
		origin: ConfigStore.getInstance().config.wert.origin, // this option needed only in sandbox
		skip_init_navigation: false,
		currency: "USD",
		listeners: {
			position: onPositionChange,
			"payment-status": onPaymentStatusChange,
			close: () => onClose(),
			loaded: () => console.info("Wert BuyNFT Helper loaded"),
			error: (data: any) => console.error("error", data),
		},
		...styleWertConfig,
	};
	const nftOptions = {
		extra: {
			item_info: {
				author: nft.appCollection.owner,
				image_url: nft.image?.url ?? "",
				name: nft.name,
				seller: nft.owner,
			},
		},
	};
	try {
		const wertWidget = new WertWidget({ ...wertOptions, ...styleWertConfig, ...nftOptions, ...signedData });
		wertWidget.mount();
	} catch (e) {
		console.error(e);
	}
}

async function mintNft(
	signedData: any,
	onClose: () => void,
	appCollection?: AppCollectionEntity,
	onPositionChange?: (data: WertPositionEventListenersParams) => void,
	onPaymentStatusChange?: (data: WertPaymentStatusEventListenersParams) => void,
) {
	const wertOptions: WertWidget["options"] = {
		partner_id: ConfigStore.getInstance().config.wert.partnerId,
		container_id: "topup-widget-container",
		origin: ConfigStore.getInstance().config.wert.origin, // this option needed only in sandbox
		skip_init_navigation: false,
		currency: "USD",
		listeners: {
			position: onPositionChange,
			"payment-status": onPaymentStatusChange,
			close: () => onClose(),
			loaded: () => console.info("Wert mintNft Helper loaded"),
			error: (data: any) => console.error("error", data),
		},
		...styleWertConfig,
	};
	const nftOptions = {
		extra: {
			item_info: {
				author: appCollection?.owner,
				image_url: appCollection?.appCollectionConfig.mintCardPicture?.url ?? "",
			},
		},
	};
	try {
		const wertWidget = new WertWidget({ ...wertOptions, ...styleWertConfig, ...nftOptions, ...signedData });
		wertWidget.mount();
	} catch (e) {
		console.error(e);
	}
}

function getTokenSupportedList(): string {
	const tokenSupportedNameList = TokenSupports.getInstance()
		.getTokenSupports()
		.map((token) => token.symbol);
	const tokenSupportedNameListWithNetwork: ITokenSupportedList[] = tokenSupportedNameList.map((tokenName) => {
		return {
			commodity: tokenName,
			network: ConfigStore.getInstance().config.wert.network,
		};
	});
	return JSON.stringify(tokenSupportedNameListWithNetwork);
}

const WertHelper = {
	addFunds,
	buyNft,
	mintNft,
};

export default WertHelper;
