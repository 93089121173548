import React from "react";
import { Link } from "react-router-dom";

import AddressShort from "Components/Elements/AddressShort";
import I18n from "Components/Elements/I18n";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import { AppUserEntity } from "Entities/appUser";
import UserStore from "Stores/UserStore";

import Typography from "../../Typography";
import classes from "./classes.module.scss";

type IProps = {
	user?: AppUserEntity;
};

export default class UserButton extends React.Component<IProps> {
	public constructor(props: IProps) {
		super(props);
		this.stopPropagation = this.stopPropagation.bind(this);
	}

	public override render(): JSX.Element | null {
		const { user } = this.props;
		if (!user?.appWallet?.userAddress) return null;
		return (
			<Link
				to={IsModuleEnabled.get()
					.pages.Profile.props.path.replace(":userAddress", user.appWallet?.userAddress)
					.concat("/")
					.concat(IsModuleEnabled.get().pages.Profile.props.pages.Nfts.props.path)}
				className={classes["root"]}
				onClick={this.stopPropagation}>
				{this.getUsernameOrAddress()}
			</Link>
		);
	}

	private getUsernameOrAddress() {
		const { user } = this.props;
		const me = UserStore.getInstance().getUser();

		if (!user) return null;
		if (me?.appWallet?.userAddress === user.appWallet?.userAddress) {
			return (
				<Typography type="p" size="xsmall" weight="medium">
					<I18n map="pages.profile.you" />
				</Typography>
			);
		}
		if (user.userName) {
			return (
				<Typography type="p" size="xsmall" weight="medium">
					{`@${user.userName}`}
				</Typography>
			);
		}
		return <AddressShort text={user.appWallet?.userAddress ?? ""} />;
	}

	private stopPropagation(e: React.MouseEvent) {
		e.stopPropagation();
	}
}
