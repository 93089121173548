import BigNumber from "Services/BigNumber";
import { Signature, ethers } from "ethers";
import BaseErc721Contract from "./core/BaseErc721Contract";
import { ISecurityPolicy } from "./core/Erc721Dto";

export default class Erc721ACInvestmentContract extends BaseErc721Contract {
	public async approve(operator: string, tokenId: number): Promise<ethers.providers.TransactionResponse> {
		return this.contract["approve"](operator, tokenId);
	}
	public async setApprovalForAll(operator: string, approved: boolean): Promise<ethers.providers.TransactionResponse> {
		return this.contract["setApprovalForAll"](operator, approved);
	}
	public async getApproved(tokenId: number): Promise<string> {
		return await this.contract["getApproved"](tokenId);
	}
	public async isApprovedForAll(owner: string, operator: string): Promise<boolean> {
		return await this.contract["isApprovedForAll"](owner, operator);
	}
	public async createApproveTx(operator: string, tokenId: number): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["approve"]!(operator, tokenId);
	}
	public async createApproveForAllTx(operator: string, approved: boolean): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["setApprovalForAll"]!(operator, approved);
	}
	public async getTotalSupply(): Promise<number> {
		return BigNumber.from((await this.contract["totalSupply"]()).toString(), 18).toNumber();
	}
	public async getMaxTotalSupply(): Promise<number> {
		return BigNumber.from((await this.contract["maxTotalSupply"]()).toString(), 18).toNumber();
	}
	public async getPaymentToken(): Promise<string> {
		return this.contract["paymentToken"]();
	}
	// BIGNUMBER
	public async getMaxTotalInvestmentHardcap(): Promise<BigNumber> {
		return BigNumber.from((await this.contract["maxTotalInvestmentHardcap"]()).toString());
	}
	public async getInvestmentHardcapPerInvestor(): Promise<BigNumber> {
		return BigNumber.from((await this.contract["investmentHardcapPerInvestor"]()).toString());
	}
	public async getMinimumInvestmentAmount(): Promise<BigNumber> {
		return BigNumber.from((await this.contract["minimumInvestmentAmount"]()).toString());
	}
	public async getTotalInvestmentAmountRaised(): Promise<BigNumber> {
		return BigNumber.from((await this.contract["totalInvestmentAmountRaised"]()).toString());
	}
	public async balanceOf(address: string): Promise<number> {
		return BigNumber.from((await this.contract["balanceOf"](address)).toString(), 18).toNumber();
	}
	public async transferFrom(from: string, to: string, tokenId: number): Promise<ethers.providers.TransactionResponse> {
		return this.contract["transferFrom"](from, to, tokenId);
	}
	public async createInitializeInvestmentTx(
		recipientAddress: string,
		amount: BigNumber,
		splitSignature: Signature,
	): Promise<ethers.PopulatedTransaction> {
		return await this.contract.populateTransaction["initializeInvestment_3B9EBA2"]!(
			recipientAddress,
			amount.toString(),
			splitSignature,
		);
	}
	public async createIncreaseInvestmentTx(
		tokenId: number,
		amount: BigNumber,
		splitSignature: Signature,
	): Promise<ethers.PopulatedTransaction> {
		return await this.contract.populateTransaction["increaseInvestment_840DCB"]!(tokenId, amount.toString(), splitSignature);
	}

	public async getInvestmentAmount(tokenId: number): Promise<BigNumber>{
		return  BigNumber.from((await this.contract["tokenIdToInvestmentAmount"](tokenId)).toString());
	}

	public async getSecurityPolicy(): Promise<ISecurityPolicy> {
		return this.contract["getSecurityPolicy"]();
	}
	public async getWhitelistedOperators(): Promise<string[]> {
		return this.contract["getWhitelistedOperators"]();
	}
	public async isOperatorWhitelisted(operator: string): Promise<boolean> {
		return this.contract["isOperatorWhitelisted"](operator);
	}
}
