import { CheckCircleIcon } from "@heroicons/react/24/solid";
import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import WalletOptionButton from "Components/Elements/Buttons/WalletOptionButton";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modals";
import Typography from "Components/Elements/Typography";
import { EModal } from "Stores/Modals/AModalStore";
import AddFunds2ModalStore from "Stores/Modals/AddFunds2ModalStore";
import { IBalances } from "Stores/WalletBalanceStore";
import { EWidgetType } from "common/enums/Wert";
import { WertPaymentStatus } from "common/helpers/wert";
import { useModal } from "hooks/useModal";
import useTokensBalance from "hooks/useTokenBalance";
import React, { ReactElement } from "react";
import WertModal from "../WertModal";
import classes from "./classes.module.scss";

type IProps = {};

type IPropsClass = IProps & {
	showModal: (content: ReactElement) => void;
	hideModal: () => void;
	balances: IBalances;
};

type IState = {
	isOpen: boolean;
	wertPaymentStatus: WertPaymentStatus | null;
};

class AddFundsModal2Class extends React.Component<IPropsClass, IState> {
	private removeOnModalChange = () => {};

	constructor(props: IPropsClass) {
		super(props);

		this.state = {
			isOpen: AddFunds2ModalStore.getInstance().isOpen(EModal.ADD_FUNDS_2),
			wertPaymentStatus: null,
		};

		this.close = this.close.bind(this);
		this.updateModalState = this.updateModalState.bind(this);
	}

	override render(): JSX.Element | null {
		return (
			<div className={classes["root"]}>
				<Modal
					isOpen={this.state.isOpen}
					header={
						this.state.wertPaymentStatus !== WertPaymentStatus.SUCCESS
							? I18n.translate("modals.add_funds_2.select_currency")
							: I18n.translate("modals.add_funds_2.add_funds")
					}
					closeBtn
					onClose={this.close}>
					{this.state.wertPaymentStatus !== WertPaymentStatus.SUCCESS && (
						<div className={classes["column"]}>
							{Object.entries(this.props.balances).map(([key, balance]) => {
								return (
									<WalletOptionButton
										key={key}
										token={balance.token}
										onClick={() => {
											this.props.showModal(
												<WertModal
													widgetType={EWidgetType.ADD_FUNDS}
													token={balance.token}
													onClose={this.props.hideModal}
													onPaymentStatusChange={(data) => {
														this.setState({ wertPaymentStatus: data.status });
													}}
												/>,
											);
										}}
									/>
								);
							})}
						</div>
					)}
					{this.state.wertPaymentStatus === WertPaymentStatus.SUCCESS && (
						<div className={classes["success-container"]}>
							<div className={classes["success-wrapper"]}>
								<div className={classes["success-icon"]}>
									<CheckCircleIcon />
								</div>
								<Typography type="h6" weight="semibold">
									{I18n.translate("modals.add_funds_2.purchase_confirmed")}
								</Typography>
								<Typography type="p" weight="regular">
									{I18n.translate("modals.add_funds_2.funds_available")}
								</Typography>
							</div>
							<Button
								variant={EButtonVariant.CONTAINED}
								fullwidth
								color={EButtonColor.NEUTRAL}
								onClick={() => AddFunds2ModalStore.getInstance().closeModal()}>
								{I18n.translate("modals.add_funds_2.done")}
							</Button>
						</div>
					)}
				</Modal>
			</div>
		);
	}

	public override async componentDidMount() {
		this.removeOnModalChange = AddFunds2ModalStore.getInstance().onChange(this.updateModalState);
	}

	private updateModalState(modalsOpenedState: { [type: string]: boolean }) {
		this.setState({
			isOpen: modalsOpenedState[EModal.ADD_FUNDS_2] ?? false,
		});
	}

	public override async componentDidUpdate(prevProps: IPropsClass) {}

	public override componentWillUnmount() {
		this.removeOnModalChange();
	}

	private close() {
		AddFunds2ModalStore.getInstance().closeModal();
	}
}

export default function AddFundsModal2(props: IProps) {
	const { showModal, hideModal } = useModal();
	const { balances } = useTokensBalance();
	return <AddFundsModal2Class {...props} showModal={showModal} hideModal={hideModal} balances={balances} />;
}
