import InputField, { IProps as InputFieldProps } from "Components/Elements/Form/Elements/InputField";
import React from "react";

import classes from "./classes.module.scss";
import TokenSymbol from "./TokenSymbol";

type IProps = InputFieldProps & {
	tokenSymbol?: string;
	borderRightCollapsed?: boolean;
	borderLeftCollapsed?: boolean;
	onClear?: () => void;
	decimalsQuantity?: number;
};

export default class AmountInputField extends React.Component<IProps> {
	private readonly numberMatchingDecimalsQuantityRegex = new RegExp(`^([0-9]*)(\\.{0,1})([0-9]{0,${this.props.decimalsQuantity ?? 4}})$`);

	public override render() {
		const { borderLeftCollapsed, borderRightCollapsed, tokenSymbol, onClear, onSelect, decimalsQuantity, ...rest } = this.props;
		return (
			<div
				className={classes["root"]}
				data-border-right-collapsed={borderRightCollapsed}
				data-border-left-collapsed={borderLeftCollapsed}>
				<InputField {...rest} regex={this.numberMatchingDecimalsQuantityRegex} placeholder={"0.00"} type="string" />
				{tokenSymbol && <TokenSymbol symbol={tokenSymbol} />}
			</div>
		);
	}
}
