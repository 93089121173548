import { Component } from "react";

// Components
import I18n from "Components/Elements/I18n";
import Typography from "Components/Elements/Typography";
import TokenPrice from "Components/Materials/TokenPrice";
import UserButton from "Components/Elements/Buttons/UserButton";

// Services
import BigNumber from "Services/BigNumber";

// Entities
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import { AppUserEntity } from "Entities/appUser";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	winningBid: BigNumber;
	token?: AppTokenSupportEntity;
	user?: AppUserEntity;
};
type IState = {};

export default class SummaryModalComponent extends Component<IProps, IState> {
	override render() {
		return (
			<div className={classes["root"]}>
				<Typography size="large" type="p" weight="semibold" className={classes["title"]}>
					<I18n map="modals.settle_auction.summary.title" />
				</Typography>

				<div className={classes["container"]}>
					<Typography size="small" type="p" weight="semibold">
						<I18n map="modals.settle_auction.summary.winning_bid" />
					</Typography>
					<TokenPrice
						price={this.props.winningBid}
						token={this.props.token}
						conversionConfig={{ size: "small", direction: "row" }}
						disabledTokenPicture
					/>
				</div>

				<div className={classes["container"]}>
					<Typography size="small" type="p" weight="semibold">
						<I18n map="modals.settle_auction.summary.winner" />
					</Typography>

					<UserButton user={this.props.user} />
				</div>
			</div>
		);
	}
}
