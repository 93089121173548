import React from "react";
import { Link } from "react-router-dom";
import classes from "./classes.module.scss";
import NewsLetterInput from "../NewsLetter/NewsletterInput";
import NewsLetterLink from "../NewsLetter/NewsletterLink";
import IsModuleEnabled from "../../Elements/IsModuleEnabled";
import I18n from "../../Elements/I18n";
import I18nStore from "Stores/I18nStore";
import Logo from "Components/Materials/Logo";
import SocialMediaButton from "../../Elements/SocialMediaButton";
import Typography from "Components/Elements/Typography";
import { AppUserEntity } from "Entities/appUser";
import UserStore from "Stores/UserStore";
import configHelper from "common/helpers/config";
import { AppSocialMediaEntity } from "Entities/appSocialMedia";
import { ESocialMedia } from "Components/Elements/Icons/SocialMediaIcon";
import { AppConfigEntity } from "Entities/appConfig";
import AppConfigStore from "Stores/AppConfigStore";
import FooterLink from "./FooterLink";
import LazyImage from "Components/Elements/LazyImage";

type IProps = Record<string, never>;
type IState = {
	user: AppUserEntity | null;
	socialMedias?: AppSocialMediaEntity[] | null;
};

export default class Footer extends React.Component<IProps, IState> {
	private removeOnUserChange = () => {};
	private removeOnAppConfigChange = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			user: UserStore.getInstance().getUser(),
			socialMedias: null,
		};
		this.setSocialMedia = this.setSocialMedia.bind(this);
	}

	public override render(): JSX.Element | null {
		return (
			<footer className={classes["root"]}>
				<div className={classes["content"]}>
					<div className={classes["left"]}>
						<div className={classes["logo-container"]}>
							<IsModuleEnabled from={IsModuleEnabled.get().Footer.props.Logo}>
								<Link className={classes["logo"]} to={IsModuleEnabled.get().pages.Home.props.path} aria-label="Visit home page">
									<Logo />
								</Link>
							</IsModuleEnabled>
							<IsModuleEnabled from={IsModuleEnabled.get().Footer.props.AdditionalLogo}>
								<LazyImage
									src={configHelper.getSrc(IsModuleEnabled.get().Footer.props.AdditionalLogo.path)}
									className={classes["additional-logo"]}
									alt="additional logo"
								/>
							</IsModuleEnabled>
						</div>

						<div className={classes["nav-container"]}>{this.renderFooterLinks()}</div>
					</div>

					<div className={classes["right"]}>
						<IsModuleEnabled from={IsModuleEnabled.get().Footer.props.Newsletter}>
							<IsModuleEnabled from={IsModuleEnabled.get().Footer.props.Newsletter.props.NewsletterInput}>
								<NewsLetterInput
									placeholder={I18n.translate("footer.newsletter.email_placeholder")}
									textButton={I18n.translate("footer.newsletter.subscribe")}
								/>
							</IsModuleEnabled>

							<IsModuleEnabled from={IsModuleEnabled.get().Footer.props.Newsletter.props.NewsletterLink}>
								<NewsLetterLink
									placeholder={I18n.translate("footer.newsletter.email_placeholder")}
									textButton={I18n.translate("footer.newsletter.subscribe")}
								/>
							</IsModuleEnabled>
						</IsModuleEnabled>
						<IsModuleEnabled from={IsModuleEnabled.get().Footer.props.LegalText}>
							<Typography type="p" size="small" weight="regular" color="neutral" className={classes["legal-text"]}>
								<I18n map="footer.legal_text" />
							</Typography>
						</IsModuleEnabled>
					</div>
				</div>

				<div className={classes["down-part"]}>
					<div className={classes["policies"]}>
						<Typography color="neutral" type="p" size="small" weight="medium">
							{IsModuleEnabled.get().Footer.props.footerSignature}
						</Typography>
						{this.getPoliciesElements()}
						{this.getmoreInfoLinks()}
					</div>
					<IsModuleEnabled from={IsModuleEnabled.get().Footer}>
						{this.state.socialMedias && <div className={classes["social-medias"]}>{this.renderSocialMediaButtons()}</div>}
					</IsModuleEnabled>
				</div>
			</footer>
		);
	}

	public override async componentDidMount() {
		this.removeOnAppConfigChange = AppConfigStore.getInstance().onChange(this.setSocialMedia);
		this.removeOnUserChange = UserStore.getInstance().onChange((user) => this.updateUser(user));
		this.updateUser(UserStore.getInstance().getUser());

		const initialConfig = AppConfigStore.getInstance().getConfig();
		this.setSocialMedia(initialConfig);
	}

	public override componentWillUnmount(): void {
		this.removeOnAppConfigChange();
		this.removeOnUserChange();
	}

	private renderSocialMediaButtons() {
		if (!this.state.socialMedias) return null;
		// this.state.socialMedias comes from appConfig, and has some unwanted properties (createdAt, updatedAt, id, etc..)
		// so we need to filter them. We only want social medias

		return this.state.socialMedias
			.filter((socialMedia) => Object.values(ESocialMedia).includes(socialMedia.name as ESocialMedia) && socialMedia.url)
			.map((socialMedia, index) => <SocialMediaButton key={index} name={socialMedia.name} href={socialMedia.url.toString()} />);
	}

	private updateUser(user: AppUserEntity | null) {
		this.setState({ user });
	}

	private async setSocialMedia(appConfig: AppConfigEntity | null) {
		if (!appConfig) return;
		this.setState({ socialMedias: appConfig.appSocialMedias });
	}

	private renderFooterLinks() {
		const footerLinks = IsModuleEnabled.get().Footer.props.FooterLinks as {
			I18nKey: string;
			link: string;
			enabled: boolean;
			isExternalLink: boolean;
		}[];
		if (!footerLinks) return null;

		const footerLinksElements = footerLinks.map((footerLink) => {
			return <FooterLink key={footerLink.link} footerLink={footerLink} />;
		});
		return footerLinksElements;
	}

	private getmoreInfoLinks() {
		const moreInfo = IsModuleEnabled.get().Footer.props.moreInfoLink.props as {
			[key: string]: { enabled: boolean; I18nKey: string; path: string };
		};
		const modules = Object.keys(moreInfo);
		const moreInfoElements = modules.map((module) => {
			const configModules = moreInfo[module];
			if (configModules === undefined) return null;
			return (
				<IsModuleEnabled from={configModules} key={module}>
					<a href={configModules.path} target="_blank" rel="noreferrer">
						<Typography color="neutral" type="p" size="small" weight="medium" className={classes["footer-link"]}>
							<I18n map={`policies.${configModules.I18nKey}`} />
						</Typography>
					</a>
				</IsModuleEnabled>
			);
		});
		return moreInfoElements;
	}

	private getPoliciesElements() {
		const policies = IsModuleEnabled.get().Footer.props.policies as {
			enabled: boolean;
			props: {
				PoliciesLinks: {
					I18nKey: string;
					link: string;
					enabled: boolean;
					isExternalLink: boolean;
				}[];
			};
		};

		if (!policies.props.PoliciesLinks) return null;

		const policiesLinksElements = policies.props.PoliciesLinks.map((policyLink) => {
			const link = policyLink.isExternalLink
				? policyLink.link
				: configHelper.getDocument(policyLink.link, I18nStore.getInstance().lang);

			return (
				<IsModuleEnabled from={policyLink} key={policyLink.link}>
					<a href={link} target="_blank" rel="noreferrer">
						<Typography color="neutral" type="p" size="small" weight="medium" className={classes["footer-link"]}>
							<I18n map={`policies.${policyLink.I18nKey}`} />
						</Typography>
					</a>
				</IsModuleEnabled>
			);
		});

		return <IsModuleEnabled from={policies}>{policiesLinksElements}</IsModuleEnabled>;
	}
}
