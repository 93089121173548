import { InstanceWithExtensions, SDKBase } from "@magic-sdk/provider";
import { Signature, ethers } from "ethers";
import { Magic } from "magic-sdk";

import BigNumber from "Services/BigNumber";
import EventEmitter from "Services/EventEmitter";
import ConfigStore from "Stores/ConfigStore";

import { OAuthExtension } from "@magic-ext/oauth";
import WalletStore from "Stores/WalletStore";
import { UserConnexionMethod } from "common/enums/UserConnectionMethod";
import IWalletInterface, { IWalletData } from "../IWalletInterface";
import { IWallet } from "./Web3ModalWallet";

export default class MagicDedicatedWallet implements IWalletInterface {
	private static magic: InstanceWithExtensions<SDKBase, OAuthExtension[]>;
	private _walletData: IWalletData | null = null;
	private readonly event = new EventEmitter();
	private removeEvents = () => {};

	public getWalletData(): IWalletData {
		return (
			this._walletData ?? {
				userAddress: null,
				balance: null,
				provider: null,
				publicKey: null,
				chainId: null,
			}
		);
	}

	public async connect(provider?: any): Promise<void> {
		if (provider) {
			await this.connectWithProvider(provider);
			return;
		}

		await this.connectWithoutProvider();
	}

	private async connectWithoutProvider() {
		let hasError = false;
		try {
			const isLoggedIn = await this.getMagic().user.isLoggedIn();

			if (!isLoggedIn) {
				await this.getMagic().wallet.connectWithUI();
			}
		} catch (e) {
			console.info("Error while connecting to Magic", e);
			console.error(e);
			hasError = true;
			await this.disconnect();
		} finally {
			const isLoggedIn = await this.getMagic().user.isLoggedIn();

			if (!hasError && !isLoggedIn) {
				// Resolve a bug with Magic where if I connect with magic a second time, it doesn't work
				const provider = await this.getMagic().wallet.getProvider();
				const web3Provider = new ethers.providers.Web3Provider(provider);
				this.updateWalletData(web3Provider);
				await new Promise((resolve) => setTimeout(resolve, 2000));
				await this.connectWithoutProvider();
				return;
			}

			const provider = await this.getMagic().wallet.getProvider();

			const web3Provider = new ethers.providers.Web3Provider(provider);
			this.updateWalletData(web3Provider);

			localStorage.setItem("MAGIC_DEDICATED_CACHED_PROVIDER", "ok");
		}
	}

	private async connectWithProvider(provider: any) {
		try {
			const isLoggedIn = await this.getMagic().user.isLoggedIn();

			if (!isLoggedIn) {
				localStorage.setItem("MAGIC_DEDICATED_CACHED_PROVIDER", "ok");

				if (!isLoggedIn) {
					await this.getMagic().oauth.loginWithRedirect({
						provider,
						redirectURI: window.location.origin,
					});
				}
			}
		} catch (e) {
			console.error(e);
			await this.disconnect();
		} finally {
			if (await this.getMagic().user.isLoggedIn()) {
				const provider = await this.getMagic().wallet.getProvider();
				const web3Provider = new ethers.providers.Web3Provider(provider);
				this.updateWalletData(web3Provider);
			}
		}
	}

	public async autoConnect(): Promise<boolean> {
		if (localStorage.getItem("MAGIC_DEDICATED_CACHED_PROVIDER")) {
			if (await this.getMagic().user.isLoggedIn()) {
				await this.connect();
				return true;
			}

			try {
				await this.getMagic().oauth.getRedirectResult();
				WalletStore.getInstance().userConnexionMethod = UserConnexionMethod.MANUAL;
				if (await this.getMagic().user.isLoggedIn()) {
					await this.connect();
					return true;
				}

				localStorage.removeItem("MAGIC_DEDICATED_CACHED_PROVIDER");

				return false;
			} catch (err) {
				localStorage.removeItem("MAGIC_DEDICATED_CACHED_PROVIDER");
				return false;
			}
		}
		return false;
	}

	public async getUserEmail(): Promise<string | null> {
		const provider = await this.getMagic().wallet.getProvider();
		if (provider.isMetaMask) return null;
		const user = this.getMagic().user;
		const userInfo = await user.getInfo();
		return userInfo.email;
	}

	public async disconnect(): Promise<void> {
		if (!this._walletData?.userAddress) return;
		try {
			localStorage.removeItem("MAGIC_DEDICATED_CACHED_PROVIDER");
			this._walletData = null;
			await this.getMagic().user.logout();
			await this.updateWalletData(null);
			this.removeEvents();
		} catch (e) {
			console.warn(e);
		}
	}

	public onChange(callback: (web3WalletData: IWallet) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public async signMessage(message: string): Promise<string> {
		try {
			if (!this.getWalletData().userAddress) {
				Promise.reject("User connected");
			}
			const signer = this.getWalletData().provider.getSigner();
			return await signer?.signMessage(message);
		} catch (err) {
			return Promise.reject(err);
		}
	}

	public async signTypedData(...params: Parameters<ethers.providers.JsonRpcSigner["_signTypedData"]>): Promise<Signature> {
		try {
			const provider = await this.getMagic().wallet.getProvider();

			if (provider.isMetaMask) {
				const signer = this.getWalletData().provider.getSigner();
				return await signer?._signTypedData(...params);
			}
			return await this.signTypedDataMagic(params);
		} catch (err) {
			return Promise.reject(err);
		}
	}

	private async signTypedDataMagic(params: Parameters<ethers.providers.JsonRpcSigner["_signTypedData"]>): Promise<Signature> {
		const types = {
			EIP712Domain: [
				{ name: "name", type: "string" },
				{ name: "version", type: "string" },
				{ name: "chainId", type: "uint256" },
				{ name: "verifyingContract", type: "address" },
			],
			...params[1],
		};
		const signTypedDataV3Payload = { domain: params[0], types, message: params[2], primaryType: "Order" };
		try {
			const account = this.getWalletData().userAddress;
			const params = [account, signTypedDataV3Payload];

			const method = "eth_signTypedData_v4";

			const signature = await this.getMagic().rpcProvider.request({
				method,
				params,
			});

			return signature;
		} catch (err) {
			return Promise.reject(err);
		}
	}

	public async sendTransaction(
		tx: ethers.utils.Deferrable<ethers.providers.TransactionRequest>,
	): Promise<ethers.providers.TransactionResponse> {
		try {
			const provider = this._walletData?.provider as ethers.providers.Web3Provider;
			const signer = provider.getSigner();
			if (!signer) throw new Error("Missing Signer");

			return signer.sendTransaction(tx);
		} catch (err) {
			return Promise.reject(err);
		}
	}

	private getMagic(isNew: boolean = false) {
		if (MagicDedicatedWallet.magic && !isNew) return MagicDedicatedWallet.magic;
		MagicDedicatedWallet.magic = MagicDedicatedWallet.newMagic();
		return MagicDedicatedWallet.magic;
	}

	private static newMagic() {
		return new Magic(ConfigStore.getInstance().config.wallet.ethereum.magicConnect.apiKey, {
			network: {
				rpcUrl: ConfigStore.getInstance().config.blockchain.ethereum.rpc,
				chainId: ConfigStore.getInstance().config.blockchain.ethereum.chainId,
			},
			extensions: [new OAuthExtension()],
		});
	}

	private async updateWalletData(provider: ethers.providers.Web3Provider | null) {
		const userAddress: string | null = (await provider?.listAccounts())?.[0] ?? null;
		const chainId = (await provider?.getNetwork())?.chainId ?? null;
		let balance = null;

		if (userAddress && provider) {
			balance = BigNumber.from((await provider.getBalance(userAddress)).toString());
		}

		const walletData: IWalletData = {
			userAddress,
			chainId,
			balance,
			provider,
			publicKey: null,
		};

		this._walletData = walletData;
		this.event.emit("change", this._walletData);
	}
}
