import { AppUserEntity } from "Entities/appUser";
import classes from "./classes.module.scss";
import { useCallback, useEffect, useState } from "react";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import { SynapsVerificationStatus } from "common/enums/KYC";
import UserService from "Services/UserService";
import SynapsModalStore from "Stores/Modals/SynapsModalStore";
import UserStore from "Stores/UserStore";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { BannerContent } from "./Content";
import { UserCircleIcon, ExclamationCircleIcon, ArrowPathIcon, XCircleIcon } from "@heroicons/react/20/solid";
import UserRegistrationBannerStore from "Stores/UserRegistrationBanner";
import HeaderStatus, { EOpeningState } from "Stores/HeaderStatus";
import classNames from "classnames";
import { useModal } from "hooks/useModal";
import RegisterModal from "../Modals/RegisterModal";

export function UserRegistrationBanner() {
	const { showModal, hideModal } = useModal();
	const i18nBaseTranslationKey = "components.user_menu.user_registration_infos";
	const [status, setStatus] = useState<EOpeningState>(HeaderStatus.getInstance().status);
	const [isOpen, setIsOpen] = useState(UserRegistrationBannerStore.getInstance().isOpen);
	const [user, setUser] = useState<AppUserEntity | null>(UserStore.getInstance().getUser());
	const [hasUserInfosCompleted, setHasUserInfosCompleted] = useState(false);
	const [userKYCStatus, setUserKYCStatus] = useState(user?.appKYC?.verificationStatus);


	const openRegisterModal = useCallback(() => {
		showModal(<RegisterModal onClose={hideModal} />);
	}, [showModal, hideModal]);

	useEffect(() => UserStore.getInstance().onOpenRegisterModal(() => openRegisterModal()), [openRegisterModal]);

	const onCompleteUserInfosClick = useCallback(() => {
		showModal(<RegisterModal onClose={hideModal} />);
	}, [showModal, hideModal]);

	const openSynaps = useCallback(async () => {
		SynapsModalStore.getInstance().openModal({});
	}, []);

	const closeBanner = useCallback(() => {
		UserRegistrationBannerStore.getInstance().close();
	}, []);

	const areAllUserInfosCompleted = useCallback(() => {
		if (!IsModuleEnabled.get().KYC.enabled) return hasUserInfosCompleted;

		const hasUserCompletedKYC = userKYCStatus === SynapsVerificationStatus.APPROVED;
		return hasUserInfosCompleted && hasUserCompletedKYC;
	}, [hasUserInfosCompleted, userKYCStatus]);

	useEffect(() => HeaderStatus.getInstance().onSwitch((status) => setStatus(status)), []);

	useEffect(() => UserStore.getInstance().onChange((user) => setUser(user)), []);

	useEffect(() => UserRegistrationBannerStore.getInstance().onChange((isOpen) => setIsOpen(isOpen)), []);

	useEffect(() => setUserKYCStatus(user?.appKYC?.verificationStatus), [user]);

	useEffect(() => {
		if (isOpen && areAllUserInfosCompleted()) {
			closeBanner();
		}
	}, [areAllUserInfosCompleted, isOpen, closeBanner]);

	useEffect(() => {
		if (!user) return;
		setHasUserInfosCompleted(UserService.getInstance().isUserInfosCompleted(user));
	}, [user]);

	if (!user || !isOpen) return null;

	return (
		<div
			className={classNames(classes["root"], {
				[classes["header-closed"]!]: status === EOpeningState.CLOSED,
			})}>
			<div /> {/* need this div for justify-content: space-between */}
			{!hasUserInfosCompleted && (
				<BannerContent
					icon={<ExclamationCircleIcon className={classes["icon"]} />}
					contentText={`${i18nBaseTranslationKey}.complete_account.title`}
					button={{ onClick: onCompleteUserInfosClick, text: `${i18nBaseTranslationKey}.complete_account.button` }}
				/>
			)}
			{hasUserInfosCompleted && (!userKYCStatus || userKYCStatus === SynapsVerificationStatus.SUBMISSION_REQUIRED) && (
				<BannerContent
					icon={<UserCircleIcon className={classes["icon"]} />}
					contentText={`${i18nBaseTranslationKey}.verify_kyc.body`}
					button={{ onClick: openSynaps, text: `${i18nBaseTranslationKey}.verify_kyc.verify_your_identity` }}
				/>
			)}
			{hasUserInfosCompleted && userKYCStatus === SynapsVerificationStatus.PENDING_VERIFICATION && (
				<BannerContent
					icon={<ArrowPathIcon className={classes["icon"]} />}
					contentText={`${i18nBaseTranslationKey}.pending_kyc.title`}
				/>
			)}
			{hasUserInfosCompleted &&
				(userKYCStatus === SynapsVerificationStatus.RESUBMISSION_REQUIRED || userKYCStatus === SynapsVerificationStatus.RESET) && (
					<BannerContent
						icon={<ExclamationCircleIcon className={classes["icon"]} />}
						contentText={`${i18nBaseTranslationKey}.issue_kyc.banner`}
						button={{ onClick: openSynaps, text: `${i18nBaseTranslationKey}.issue_kyc.button` }}
					/>
				)}
			{hasUserInfosCompleted && userKYCStatus === SynapsVerificationStatus.REJECTED && (
				<BannerContent
					icon={<XCircleIcon className={classes["icon"]} />}
					contentText={`${i18nBaseTranslationKey}.rejected_kyc.title`}
					button={{ onClick: openSynaps, text: `${i18nBaseTranslationKey}.rejected_kyc.button` }}
				/>
			)}
			<XMarkIcon className={classNames(classes["close-icon"], "close-icon")} onClick={closeBanner} />
		</div>
	);
}
