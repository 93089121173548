import QRCode from "qrcode";
import React from "react";

import AddressButton from "Components/Elements/Buttons/AddressButton";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modals";
import Typography from "Components/Elements/Typography";
import { AppUserEntity } from "Entities/appUser";
import UserStore from "Stores/UserStore";

import classes from "./classes.module.scss";
import ReceiveCryptoModalStore from "Stores/Modals/ReceiveCryptoModalStore";
import { EModal } from "Stores/Modals/AModalStore";

export type IReceiveCryptoModalProps = {};

type IProps = {};

type IState = {
	user?: AppUserEntity | null;
	qrCode: string | null;
	isOpen: boolean;
	paymentInfo?: IReceiveCryptoModalProps;
	isLessThanMediumViewport: boolean;
};

export default class ReceiveCryptoModal extends React.Component<IProps, IState> {
	private removeOnUserChange = () => {};
	private removeOnModalChange = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			user: UserStore.getInstance().getUser(),
			qrCode: null,
			isOpen: ReceiveCryptoModalStore.getInstance().isOpen(EModal.RECEIVE_CRYPTO),
			paymentInfo: ReceiveCryptoModalStore.getInstance().getProps(),
			isLessThanMediumViewport: this.isLessThanMediumViewport(),
		};
		this.updateUser = this.updateUser.bind(this);
		this.updateQrCode = this.updateQrCode.bind(this);
		this.updateModalState = this.updateModalState.bind(this);
		this.close = this.close.bind(this);
		this.onResize = this.onResize.bind(this);
	}

	override render(): JSX.Element | null {
		const header: JSX.Element = <span>{I18n.translate("modals.receive.title")}</span>;

		return (
			<Modal
				isOpen={this.state.isOpen}
				header={header}
				footer={
					<Typography type="p" size="small" weight="regular" color="neutral">
						<I18n map={"modals.receive.warning"}/>
					</Typography>
				}
				closeBtn
				onClose={this.close}>
				<div className={classes["root"]}>
					{this.state.qrCode && <img src={this.state.qrCode} alt="User QR code" className={classes["qrcode"]} />}
					{this.state.user?.appWallet && (
						<AddressButton
							size={this.state.isLessThanMediumViewport ? "medium" : "large"}
							address={this.state.user.appWallet.userAddress}
						/>
					)}
				</div>
			</Modal>
		);
	}

	public override componentDidMount() {
		this.removeOnUserChange = UserStore.getInstance().onChange(this.updateUser);
		this.updateQrCode(this.state.user?.appWallet?.userAddress);
		this.removeOnModalChange = ReceiveCryptoModalStore.getInstance().onChange(this.updateModalState);
		this.updateModalState(ReceiveCryptoModalStore.getInstance().modalsOpenedState, ReceiveCryptoModalStore.getInstance().getProps());
		window.addEventListener("resize", this.onResize);
	}

	public override componentWillUnmount() {
		this.removeOnModalChange();
		this.removeOnUserChange();
		window.removeEventListener("resize", this.onResize);
	}

	private close() {
		ReceiveCryptoModalStore.getInstance().closeModal();
	}

	private updateModalState(modalsOpenedState: { [type: string]: boolean }, paymentInfo?: IReceiveCryptoModalProps) {
		this.setState({ isOpen: modalsOpenedState[EModal.RECEIVE_CRYPTO] ?? false, paymentInfo });
	}

	private updateUser(user: AppUserEntity | null) {
		this.setState({ user });
		this.updateQrCode(user?.appWallet?.userAddress);
	}

	private async updateQrCode(value?: string) {
		if (!value) return;

		const qrCode = await QRCode.toDataURL(value, {
			errorCorrectionLevel: "H",
			type: "image/jpeg",
			margin: 1,
			color: {
				dark: "FFF",
				light: "#030408",
			},
		});
		this.setState({ qrCode });
	}

	private isLessThanMediumViewport() {
		return window.innerWidth < 767;
	}

	private onResize() {
		const isLessThanMediumViewport = this.isLessThanMediumViewport();
		if (isLessThanMediumViewport !== this.state.isLessThanMediumViewport) {
			this.setState({ isLessThanMediumViewport });
		}
	}
}
