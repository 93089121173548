import { ethers, Signature } from "ethers";
import { EventEmitter } from "events";

// import Toasts from "Stores/Toasts";
import BigNumber from "Services/BigNumber";
import ThemeMode from "Stores/ThemeMode";

import { createWeb3Modal, defaultConfig, Web3Modal } from "@web3modal/ethers5";
import IWalletInterface, { IWalletData } from "../IWalletInterface";
import ConfigStore from "Stores/ConfigStore";
import configHelper from "common/helpers/config";

export interface IWallet {
	userAddress: string | null;
	balance: BigNumber | null;
	chainId: number | null;
	provider: ethers.providers.Web3Provider | null;
}

export default class Web3ModalWallet implements IWalletInterface {
	private static ctx: Web3ModalWallet;
	private static web3Modal: Web3Modal;
	private isConnecting = false;
	private removeEvents = () => {};

	private walletData: IWalletData = {
		userAddress: null,
		balance: null,
		provider: null,
		publicKey: null,
		chainId: null,
	};
	private readonly event = new EventEmitter();

	public constructor() {
		if (Web3ModalWallet.ctx) return Web3ModalWallet.ctx;
		Web3ModalWallet.ctx = this;
		return Web3ModalWallet.ctx;
	}

	public getWalletData(): IWalletData {
		return this.walletData;
	}

	public static getInstance() {
		if (!Web3ModalWallet.ctx) return new this();
		return Web3ModalWallet.ctx;
	}

	public async connect(): Promise<Web3ModalWallet> {
		this.isConnecting = true;
		const instance = this.getWeb3Modal();

		instance.getIsConnected();

		await new Promise((resolve) => {
			setTimeout(() => {
				resolve(true);
			}, 1000);
		});

		if (!instance.getIsConnected()) {
			instance.open();
		} else {
			const instanceProvider = instance.getWalletProvider();
			if (instanceProvider) {
				const provider = new ethers.providers.Web3Provider(instanceProvider);
				this.updateWalletData(provider);
			}
		}

		return this;
	}

	public async getUserEmail(): Promise<string | null> {
		return null;
	}

	private setupProvider(instance: Web3Modal) {
		instance.subscribeProvider(async (state) => {
			if (!state.provider) {
				if (this.walletData.userAddress) {
					this.disconnect();
				}
				return;
			}
			const provider = new ethers.providers.Web3Provider(state.provider);
			if (state.provider && this.walletData.userAddress && state.address !== this.walletData.userAddress) {
				const instance = this.getWeb3Modal();
				instance.disconnect();
				return;
			}
			if (state.provider && state.address === this.walletData.userAddress) {
				const provider = new ethers.providers.Web3Provider(state.provider);
				const walletData = await this.getNewWalletData(provider);
				if (this.hasWalletDataChanged(walletData)) {
					this.updateWalletData(provider);
				}
				return;
			}

			localStorage.setItem("WEB3_MODAL_CACHED_PROVIDER", "ok");
			this.updateWalletData(provider);
		});
	}

	public async disconnect() {
		try {
			this.updateWalletData(null);
			const instance = this.getWeb3Modal();
			localStorage.removeItem("WEB3_MODAL_CACHED_PROVIDER");
			if (instance.getIsConnected()) {
				instance.disconnect();
			}

			this.removeEvents();
			return;
		} catch (e) {
			console.warn(e);
		}
	}

	public onChange(callback: (web3WalletData: IWallet) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public async autoConnect(): Promise<boolean> {
		if (localStorage.getItem("WEB3_MODAL_CACHED_PROVIDER")) {
			const instance = this.getWeb3Modal();
			await new Promise((resolve) => {
				setTimeout(() => {
					resolve(true);
				}, 1000);
			});

			if (instance.getIsConnected()) {
				return true;
			}
			localStorage.removeItem("WEB3_MODAL_CACHED_PROVIDER");
			return false;
		}

		return false;
	}

	public async signMessage(message: string): Promise<string> {
		try {
			if (!this.getWalletData().userAddress) {
				Promise.reject("User connected");
			}
			const signer = this.getWalletData().provider.getSigner();
			return await signer?.signMessage(message);
		} catch (err) {
			return Promise.reject(err);
		}
	}

	public async signTypedData(...params: Parameters<ethers.providers.JsonRpcSigner["_signTypedData"]>): Promise<Signature> {
		try {
			const signer = this.walletData?.provider.getSigner();
			return await signer?._signTypedData(...params);
		} catch (err) {
			return Promise.reject(err);
		}
	}

	public async sendTransaction(
		tx: ethers.utils.Deferrable<ethers.providers.TransactionRequest>,
	): Promise<ethers.providers.TransactionResponse> {
		try {
			const provider = this.walletData?.provider as ethers.providers.Web3Provider;
			const signer = provider.getSigner();
			if (!signer) throw new Error("Missing Signer");

			return signer.sendTransaction(tx);
		} catch (err) {
			return Promise.reject(err);
		}
	}

	private async getNewWalletData(provider: ethers.providers.Web3Provider | null) {
		const userAddress: string | null = (await provider?.listAccounts())?.[0] ?? null;
		const chainId = (await provider?.getNetwork())?.chainId ?? null;
		let balance = null;

		if (userAddress && provider) {
			balance = BigNumber.from((await provider.getBalance(userAddress)).toString());
		}

		const walletData: IWalletData = {
			userAddress,
			chainId,
			balance,
			provider,
			publicKey: null,
		};

		return walletData;
	}

	private async updateWalletData(provider: ethers.providers.Web3Provider | null) {
		const walletData = await this.getNewWalletData(provider);
		this.walletData = walletData;
		this.event.emit("change", this.walletData);
	}

	private static newWeb3Modal() {
		const config = ConfigStore.getInstance().config;
		// 1. Get projectId at https://cloud.walletconnect.com
		const projectId = config.wallet.ethereum.web3modal.projectId;

		// // 2. Set chains
		const chain = {
			chainId: config.blockchain.ethereum.chainId,
			name: config.blockchain.ethereum.name,
			currency: config.blockchain.ethereum.ticker,
			explorerUrl: config.blockchain.ethereum.blockExplorer,
			rpcUrl: config.blockchain.ethereum.rpc,
		};

		// 3. Create your application's metadata object
		const metadata = {
			name: config.head.title,
			description: config.head.description,
			url: window.location.origin, // url must match your domain & subdomain
			icons: [`${configHelper.getSrc("assets/images/logos/logo192.webp")}`],
		};

		// 4. Create Ethers config
		const ethersConfig = defaultConfig({
			/*Required*/
			metadata,
		});

		// 5. Create a Web3Modal instance
		const modal = createWeb3Modal({
			ethersConfig,
			chains: [chain],
			projectId,
			themeMode: ThemeMode.getInstance().type,
			featuredWalletIds: config.wallet.ethereum.web3modal.featuredWalletIds
			// includeWalletIds: ["fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa"],
		});

		return modal;
	}

	private getWeb3Modal(isNew: boolean = false) {
		if (Web3ModalWallet.web3Modal && !isNew) return Web3ModalWallet.web3Modal;
		Web3ModalWallet.web3Modal = Web3ModalWallet.newWeb3Modal();

		const instance = Web3ModalWallet.web3Modal;
		this.setupProvider(instance);

		this.listeningToModalClose(instance);

		return Web3ModalWallet.web3Modal;
	}

	private listeningToModalClose(instance: Web3Modal) {
		instance.subscribeEvents((state) => {
			if (state.data.event === "CONNECT_SUCCESS") {
				this.isConnecting = false;
			}
			if (state.data.event === "CONNECT_ERROR") {
				this.isConnecting = false;
			}
			if (state.data.event === "MODAL_CLOSE") {
				if (!instance.getIsConnected() && this.isConnecting) {
					this.isConnecting = false;
					this.event.emit("change", this.walletData);
				}
			}
		});
	}

	private hasWalletDataChanged(newWalletData: IWalletData): boolean {
		// Check each property
		if (this.walletData.userAddress !== newWalletData.userAddress) return true;
		if (this.walletData.chainId !== newWalletData.chainId) return true;

		// For BigNumber, use the `eq` method for comparison
		if (this.walletData.balance !== null && newWalletData.balance !== null && !this.walletData.balance.eq(newWalletData.balance))
			return true;

		// Assuming your provider has a meaningful way to be compared,
		// you might need a custom comparison method. For simplicity, let's skip this.
		// if (this.walletData.provider !== newWalletData.provider) return true;

		if (this.walletData.publicKey !== newWalletData.publicKey) return true;

		// If none of the conditions are met, the data hasn't changed
		return false;
	}
}
