import React from "react";

import { ReactComponent as GlassIcon } from "assets/images/icons/glass.svg";
import IconButton from "Components/Elements/Buttons/IconButton";
import I18n from "Components/Elements/I18n";
import SearchBar from "Components/Elements/SearchBar";
import Typography from "Components/Elements/Typography";
import WindowStore from "Stores/Window";

import classes from "./classes.module.scss";
import SearchPreview from "./SearchPreview";
import AppSearch, { GetSearchResponse } from "Api/Back/AppSearch";
import RouterLocation from "Stores/RouterLocation";
import SearchPreviewStore from "Stores/SearchPreviewStore";
import { ErrorBoundary } from "react-app-error-boundary";
import classNames from "classnames";

type IProps = {};

type IState = {
	searchInputValue: string;
	searchBarExtended: boolean;
	results: GetSearchResponse | null;
	isPreviewDisplayed: boolean | null;
};
/**
 * @description Allow search users, collections and nfts.
 * With search preview.
 */
export default class MainSearchBar extends React.Component<IProps, IState> {
	private ref: React.RefObject<HTMLDivElement> = React.createRef();
	private removeOnClickOutside = () => {};
	private removeOnResize = () => {};
	private removeOnClickItem = () => {};
	private removeOnHandleSearchPreviewChange = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			searchInputValue: "",
			searchBarExtended: true,
			results: null,
			isPreviewDisplayed: SearchPreviewStore.getInstance().isPreviewShowed,
		};

		this.toggleSearchBar = this.toggleSearchBar.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleOnResize = this.handleOnResize.bind(this);
		this.clearSearchAndToggleSearchBar = this.clearSearchAndToggleSearchBar.bind(this);
		this.updateStringSearchOnSelect = this.updateStringSearchOnSelect.bind(this);
		this.toggleSearchBarAndPreview = this.toggleSearchBarAndPreview.bind(this);
		this.handleSearchPreviewChange = this.handleSearchPreviewChange.bind(this);
		document.body.setAttribute("header-search-bar-extended", this.state.searchBarExtended.toString());
	}

	public override render(): JSX.Element {
		const searchBarExtended = this.state.searchBarExtended;
		return (
			<div className={classes["root"]} ref={this.ref} data-search-bar-extended={searchBarExtended}>
				<div className={classes["glass-outside"]}>
					<IconButton icon={<GlassIcon />} size="medium" onClick={() => this.toggleSearchBar(true)} />
				</div>

				<div className={classes["searchbar"]}>
					<SearchBar
						value={this.state.searchInputValue}
						placeholder={I18n.translate("common.main_searchbar_placeholder")}
						onChange={this.handleSearch}
						onClear={this.clearSearchAndToggleSearchBar}
					/>
				</div>

				<div
					className={classes["cancel-button-searchbar"]}
					data-show-cancel-button={searchBarExtended}
					onClick={() => this.toggleSearchBarAndPreview(false)}>
					<Typography color="neutral" type="p" size="small" weight="medium">
						{I18n.translate("common.cancel")}
					</Typography>
				</div>

				{this.state.results && this.state.isPreviewDisplayed === true && (
					<div className={classNames(classes["search-preview"], "search-preview")}>
						<ErrorBoundary>
							<SearchPreview results={this.state.results} togglePreview={this.handleSearchPreviewChange} />
						</ErrorBoundary>
					</div>
				)}
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnClickOutside = WindowStore.getInstance().onClick((e: MouseEvent) => this.handleClickOutside(e));
		this.removeOnClickItem = RouterLocation.getInstance().onChange(() => this.handleSearchPreviewChange(false));
		this.removeOnHandleSearchPreviewChange = SearchPreviewStore.getInstance().onChange(this.handleSearchPreviewChange);
		this.removeOnResize = WindowStore.getInstance().onResize(this.handleOnResize);
		this.setSearchBarBehaviorOnLargeScreen(window);
	}

	public override componentWillUnmount() {
		this.removeOnResize();
		this.removeOnClickOutside();
		this.removeOnClickItem();
		this.removeOnHandleSearchPreviewChange();
	}

	private handleSearch(value: string) {
		this.setState({ searchInputValue: value }, this.setStateResults);
	}

	private handleClickOutside(event: MouseEvent) {
		if (!this.ref.current?.contains(event.target as Node)) {
			SearchPreviewStore.getInstance().switch(false);
		}
	}

	private handleOnResize(window: Window) {
		if (window.innerWidth <= 286) {
			SearchPreviewStore.getInstance().isPreviewShowed && this.handleSearchPreviewChange(false);
		}
	}

	private setSearchBarBehaviorOnLargeScreen(window: Window) {
		if (window.innerWidth <= 786) {
			this.toggleSearchBar(false);
		} else {
			if (this.state.searchBarExtended === true) this.toggleSearchBar(false);
			this.toggleSearchBar(true);
		}
	}

	private clearSearchAndToggleSearchBar() {
		this.clearSearch();
		this.handleSearchPreviewChange(false);
	}

	private clearSearch() {
		this.setState({
			searchInputValue: "",
			results: null,
		});
	}

	private toggleSearchBarAndPreview(searchBarExtended: boolean) {
		this.toggleSearchBar(searchBarExtended);
		this.handleSearchPreviewChange(searchBarExtended);
	}

	private toggleSearchBar(searchBarExtended: boolean) {
		this.setState({ searchBarExtended });
		document.body.setAttribute("header-search-bar-extended", searchBarExtended.toString());
	}

	private handleSearchPreviewChange(isShowPreviewDisplayed: boolean) {
		this.setState({ isPreviewDisplayed: isShowPreviewDisplayed });
	}

	private async setStateResults() {
		const results = await this.getResults();

		this.setState({
			results,
		});
		if (this.state.searchInputValue === "") {
			SearchPreviewStore.getInstance().switch(false);
			return;
		}
		if (results.collections.length > 0 || results.nfts.length > 0 || results.users.length > 0) {
			SearchPreviewStore.getInstance().switch(true);
		} else {
			SearchPreviewStore.getInstance().switch(false);
		}
	}

	private updateStringSearchOnSelect(selectedValue: any) {
		this.setState({ searchInputValue: selectedValue.name });
	}

	private async getResults(): Promise<GetSearchResponse> {
		return await AppSearch.getInstance().search(this.state.searchInputValue);
	}
}
