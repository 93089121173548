import Toasts from "Stores/Toasts";
import { XCircleIcon } from "@heroicons/react/24/outline";

// Components
import Typography from "Components/Elements/Typography";
import Button, { EButtonVariant } from "Components/Elements/Buttons/Button";

// Styles
import classes from "./classes.module.scss";
import I18n from "Components/Elements/I18n";

type IErrorToastArgs = {
	title: string;
	text?: string;
	hasButton?: boolean;
	buttonText?: string;
	onClickButton?: () => void;
};

function createErrorToast({ title, text, hasButton, buttonText, onClickButton }: IErrorToastArgs) {
	Toasts.getInstance().open({
		icon: <XCircleIcon className={classes["error"]} />,
		title: (
			<Typography className={classes["error"]} type="p" size="medium" weight="medium">
				{title}
			</Typography>
		),
		button: hasButton && (
			<Button variant={EButtonVariant.OUTLINED} fullwidth onClick={onClickButton}>
				{buttonText}
			</Button>
		),
		text,
		closable: true,
	});
}

function createAnErrorOccuredToast(id?: number, time?: number) {
	Toasts.getInstance().open({
		id,
		icon: <XCircleIcon className={classes["error"]} />,
		text: I18n.translate("toasts.error.an_error_occured"),
		title: I18n.translate("toasts.error.title"),
		time: time ?? 2250,
		closable: true,
	});
}

const ToastHelper = {
	createErrorToast,
	createAnErrorOccuredToast,
};
export default ToastHelper;
