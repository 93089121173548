import EventService from "Services/EventEmitter";

export enum EModal {
	ADD_FUNDS = "ADD_FUNDS",
	ADD_FUNDS_2 = "ADD_FUNDS_2",
	COMPLETE_PURCHASE = "COMPLETE_PURCHASE",
	COMPLETE_MINT = "COMPLETE_MINT",
	COMPLETE_INVEST = "COMPLETE_INVEST",
	MAKE_OFFER = "MAKE_OFFER",
	ACCEPT_OFFER = "ACCEPT_OFFER",
	CANCEL_OFFER = "CANCEL_OFFER",
	PLACE_BID = "PLACE_BID",
	RECEIVE_CRYPTO = "RECEIVE_CRYPTO",
	CHOOSE_CRYPTO = "CHOOSE_CRYPTO",
	AMOUNT_TO_SEND = "AMOUNT_TO_SEND",
	CONFIRM_PAYMENT = "CONFIRM_PAYMENT",
	CANCEL_LISTING = "CANCEL_LISTING",
	SETTLE_AUCTION = "SETTLE_AUCTION",
	EDIT_MAIL = "EDIT_MAIL",
	VERIFICATION_CODE = "VERIFICATION_CODE",
	WERT_WIDGET = "WERT_WIDGET",
	CLAIM_REWARDS = "CLAIM_REWARDS",
	PASS_KYC = "PASS_KYC",
	SYNAPS = "SYNAPS",
	CONNECT_WALLET = "CONNECT_WALLET",
}

export default abstract class AModalStore<T> {
	private _props?: T;

	private static defaultState = {
		ADD_FUNDS: false,
		ADD_FUNDS_2: false,
		COMPLETE_PURCHASE: false,
		MAKE_OFFER: false,
		ACCEPT_OFFER: false,
		CANCEL_OFFER: false,
		PLACE_BID: false,
		RECEIVE_CRYPTO: false,
		CHOOSE_CRYPTO: false,
		AMOUNT_TO_SEND: false,
		CONFIRM_PAYMENT: false,
		SETTLE_AUCTION: false,
		CANCEL_LISTING: false,
		VERIFICATION_CODE: false,
		WERT_WIDGET: false,
		CLAIM_REWARDS: false,
		PASS_KYC: false,
		SYNAPS: false,
		CONNECT_WALLET: false
	};

	private _modalsOpenedState: { [type: string]: boolean } = { ...AModalStore.defaultState };

	private readonly event = new EventService();

	protected constructor() {}

	public get modalsOpenedState() {
		return this._modalsOpenedState;
	}

	public isOpen(type: EModal): boolean {
		return this.modalsOpenedState[type] ?? false;
	}

	public onChange(callback: (modalsOpenedState: { [type: string]: boolean }, props: T) => void | Promise<void>) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	protected open(type: EModal, modalProps: T) {
		this.close();
		this._modalsOpenedState[type] = true;
		this.event.emit("change", this.modalsOpenedState, modalProps);
		this.setProps(modalProps);
	}

	private setProps(props: T) {
		this.event.emit("change", this.modalsOpenedState, props);
	}

	public getProps() {
		return this._props;
	}

	protected close() {
		this._modalsOpenedState = { ...AModalStore.defaultState };
		this.event.emit("change", this.modalsOpenedState);
		this._props = undefined;
	}
}
