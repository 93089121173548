import AModalStore, { EModal } from "./AModalStore";
import { ECollectionPhaseName } from "Entities/appCollectionPhase";
import { EntityId } from "common/models";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import { AppCollectionEntity } from "Entities/appCollection";
import { AppNftEntity } from "Entities/appNft";
import BigNumber from "Services/BigNumber";

export type ICompleteInvestModalProps = {
	phaseId: EntityId;
	phaseName: ECollectionPhaseName;
	amountToInvest: BigNumber;
	appCollection: AppCollectionEntity;
	collectionPhaseId: EntityId;
	appTokenSupport: AppTokenSupportEntity;
	onCompleteMint?: () => void;
	nft?: AppNftEntity;
};

export default class CompleteInvestModalStore extends AModalStore<ICompleteInvestModalProps> {
	private static ctx: CompleteInvestModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		CompleteInvestModalStore.ctx = this;
	}

	public static getInstance() {
		if (!CompleteInvestModalStore.ctx) return new this();
		return CompleteInvestModalStore.ctx;
	}

	public openModal(props: ICompleteInvestModalProps) {
		this.close();
		this.open(EModal.COMPLETE_INVEST, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
