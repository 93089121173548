
import AModalStore, { EModal } from "./AModalStore";

type IProps = {};

export default class ConnectWalletModalStore extends AModalStore<IProps> {
	private static ctx: ConnectWalletModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		ConnectWalletModalStore.ctx = this;
	}

	public static getInstance() {
		if (!ConnectWalletModalStore.ctx) return new this();
		return ConnectWalletModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.CONNECT_WALLET, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
