import AModalStore, { EModal } from "./AModalStore";
import { IChooseCryptoModalProps as IProps } from "Components/Materials/Modals/SendCryptoModals/ChooseTokenModal";



export default class ChooseCryptoModalStore extends AModalStore<IProps> {
	private static ctx: ChooseCryptoModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		ChooseCryptoModalStore.ctx = this;
	}

	public static getInstance() {
		if (!ChooseCryptoModalStore.ctx) return new this();
		return ChooseCryptoModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.CHOOSE_CRYPTO, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
