import { SupportedLanguage } from "Stores/I18nStore";

export default class LocalStorageService {
	private static instance: LocalStorageService;

	private constructor() {
		LocalStorageService.instance = this;
	}

	public static getInstance() {
		if (!LocalStorageService.instance) return new this();
		return LocalStorageService.instance;
	}

	public items = {
		accessToken: {
			get: () => this.getValue<string>("accessToken"),
			set: (item: string) => this.setValue("accessToken", item),
			delete: () => this.delete("accessToken"),
		},
		refreshToken: {
			get: () => this.getValue<string>("refreshToken"),
			set: (item: string) => this.setValue("refreshToken", item),
			delete: () => this.delete("refreshToken"),
		},
		userAddress: {
			get: () => this.getValue<string>("userAddress"),
			set: (item: string) => this.setValue("userAddress", item),
			delete: () => this.delete("userAddress"),
		},
		lang: {
			get: () => this.getValue<SupportedLanguage>("lang"),
			set: (item: SupportedLanguage) => this.setValue("lang", item),
			delete: () => this.delete("lang"),
		},
	};

	private getValue<T>(key: keyof typeof this.items): T | null {
		const item = localStorage.getItem(key);
		if (!item) {
			return null;
		}

		const isArrayOrObject = item.match(/[{[]/);

		return (isArrayOrObject ? JSON.parse(item) : item) as T;
	}

	private setValue<T>(key: keyof typeof this.items, item: T) {
		localStorage.setItem(key, typeof item === "string" ? item : JSON.stringify(item));
	}

	private delete(key: keyof typeof this.items) {
		localStorage.removeItem(key);
	}
}
