import AppAbi from "Api/Back/AppAbi";
import AppTokenSupport from "Api/Back/AppTokenSupport";
import { AppAbiEntity } from "Entities/appAbi";
import { ContractType } from "Entities/appContract";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import ContractFactory from "Services/Contracts/Ethereum/ContractFactory";
import Erc20Contract from "Services/Contracts/Ethereum/erc20/Erc20Contract";
import WalletStore from "Stores/WalletStore";
import assert from "assert";
import { AbiType } from "common/enums/AbiType";

// Don't export this interface
interface EnrichedContract {
	contractEntity: AppTokenSupportEntity;
	ethersContract: Erc20Contract;
}

export default class Erc20ContractStore {
	private static instance: Erc20ContractStore;

	private appAbi: AppAbiEntity | null = null;

	private pendingRequests: Map<string, Promise<EnrichedContract>> = new Map();
	private enrichedContracts: EnrichedContract[] = [];

	public constructor() {
		WalletStore.getInstance().onChange((walletData) => {
			this.enrichedContracts = [];
		});
	}

	public static getInstance(): Erc20ContractStore {
		return (this.instance = this.instance ?? new Erc20ContractStore());
	}

	public async getEnrichedContractByAddress(tokenSupportAddress: string): Promise<EnrichedContract> {
		const enrichedContract = this.enrichedContracts.find(
			(enrichedContract) => enrichedContract.contractEntity.address === tokenSupportAddress,
		);
		if (enrichedContract) {
			return enrichedContract;
		}

		if (this.pendingRequests.has(tokenSupportAddress)) {
			return await this.pendingRequests.get(tokenSupportAddress)!;
		}

		const requestPromise = (async () => {
			const addedEnrichedContract = await this.addContract(tokenSupportAddress);
			this.pendingRequests.delete(tokenSupportAddress);
			return addedEnrichedContract;
		})();

		this.pendingRequests.set(tokenSupportAddress, requestPromise);

		return await requestPromise;
	}

	private async addContract(tokenSupportAddress: string): Promise<EnrichedContract> {
		const appTokenSupportEntity = await AppTokenSupport.getInstance().getTokenSupportByAddress(tokenSupportAddress);
		assert(appTokenSupportEntity, `Token support entity not found for address: ${tokenSupportAddress}`);

		if (this.appAbi === null) {
			this.appAbi = await AppAbi.getInstance().getAbiByType(AbiType.ERC20);
		}

		const ethersContract = await ContractFactory.getInstance().build({
			address: tokenSupportAddress,
			abi: this.appAbi.abi,
			abiType: AbiType.ERC20,
			contractType: ContractType.ERC20,
		});

		assert(ethersContract.is(Erc20Contract), "Ethers contract is not an ERC20 contract");

		const enrichedContract = {
			contractEntity: appTokenSupportEntity,
			ethersContract: ethersContract,
		};

		this.enrichedContracts.push(enrichedContract);

		return enrichedContract;
	}
}
