import { ArrowRightIcon } from "@heroicons/react/24/solid";
import React from "react";

import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import Form from "Components/Elements/Form";
import { IError } from "Components/Elements/Form/Elements/BaseField";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modals";
import AmountFieldMaterial from "Components/Materials/Inputs/AmountFieldMaterial";
import InputFieldMaterial from "Components/Materials/Inputs/InputFieldMaterial";
import BigNumber from "Services/BigNumber";

import classes from "./classes.module.scss";
import AmountToSendModalStore from "Stores/Modals/AmountToSendModalStore";
import { EModal } from "Stores/Modals/AModalStore";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import ChooseCryptoModalStore from "Stores/Modals/ChooseCryptoModalStore";
import ConfirmPaymentModalStore from "Stores/Modals/ConfirmPaymentModalStore";
import assert from "assert";
import Typography from "Components/Elements/Typography";
import BalanceHelper from "common/helpers/balance";
import WalletBalanceStore, { IBalances } from "Stores/WalletBalanceStore";
import ToastHelper from "common/helpers/Toast";

export type IAmountToSendModalProps = {
	token: AppTokenSupportEntity;
};

type IProps = {};

type IState = {
	errorObj: { [key: string]: { errors: IError[] } };
	isOpen: boolean;
	amountToSend: string;
	paymentInfo?: IAmountToSendModalProps;
	currentBalance: BigNumber;
	balances: IBalances;
};

export default class AmountToSendModal extends React.Component<IProps, IState> {
	private removeOnModalChange = () => {};
	private removeOnWalletBalanceChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			errorObj: {},
			amountToSend: "",
			isOpen: AmountToSendModalStore.getInstance().isOpen(EModal.AMOUNT_TO_SEND),
			paymentInfo: AmountToSendModalStore.getInstance().getProps(),
			currentBalance: BigNumber.from("0"),
			balances: WalletBalanceStore.getInstance().balances,
		};

		this.submit = this.submit.bind(this);
		this.onErrors = this.onErrors.bind(this);
		this.onChange = this.onChange.bind(this);
		this.updateModalState = this.updateModalState.bind(this);
		this.close = this.close.bind(this);
		this.openChooseTokenModal = this.openChooseTokenModal.bind(this);
		this.setValueToMax = this.setValueToMax.bind(this);
		this.updateBalances = this.updateBalances.bind(this);
		this.updateAvailableAmount = this.updateAvailableAmount.bind(this);
	}

	override render(): JSX.Element | null {
		return (
			<Modal isOpen={this.state.isOpen} header={this.state.paymentInfo?.token?.name} closeBtn onClose={this.close}>
				{this.state.currentBalance.toString() === "0" ? (
					<div className={classes["no-token"]}>
						<Typography size="medium" weight="semibold">
							<I18n map="modals.send.alert_no_token_available" />
						</Typography>
						<Typography size="small" weight="regular">
							<I18n map="modals.send.buy_token" />
						</Typography>
					</div>
				) : (
					<Form onSubmit={this.submit}>
						<div className={classes["root"]}>
							<InputFieldMaterial
								placeholder={I18n.translate("inputs.placeholder.wallet")}
								label={I18n.translate("modals.send.send_to")}
								name="address"
								required
								isValidAddress
								onErrors={(errors) => this.onErrors("address", errors)}
							/>
							<AmountFieldMaterial
								token={this.state.paymentInfo?.token}
								name="amount"
								label={I18n.translate("modals.send.amount")}
								displayConvertedAmount
								displayCurrentBalanceTip
								value={this.state.amountToSend}
								onChange={this.onChange}
								onSetValueToMax={this.setValueToMax}
								required
								onErrors={(errors) => this.onErrors("amount", errors)}
							/>

							<div className={classes["buttonRow"]}>
								<Button variant={EButtonVariant.OUTLINED} onClick={this.openChooseTokenModal}>
									<I18n map="buttons.back" />
								</Button>
								<Button
									variant={EButtonVariant.CONTAINED}
									color={EButtonColor.PRIMARY}
									endIcon={<ArrowRightIcon />}
									type="submit"
									disabled={this.isAnyError(this.state.errorObj)}>
									<I18n map="buttons.next" />
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Modal>
		);
	}

	public override componentDidMount() {
		this.removeOnModalChange = AmountToSendModalStore.getInstance().onChange(this.updateModalState);
		this.removeOnWalletBalanceChange = WalletBalanceStore.getInstance().onChange(this.updateBalances);
		this.updateBalances(WalletBalanceStore.getInstance().balances);
		this.updateModalState(AmountToSendModalStore.getInstance().modalsOpenedState, AmountToSendModalStore.getInstance().getProps());
	}

	public override componentDidUpdate(prevProps: IProps, prevState: IState) {
		if (prevState.isOpen !== this.state.isOpen) {
			this.setState({ amountToSend: "", currentBalance: BigNumber.from("0") });
			this.updateBalances(WalletBalanceStore.getInstance().balances);
		}
	}

	public override componentWillUnmount() {
		this.removeOnModalChange();
		this.removeOnWalletBalanceChange();
	}

	private close() {
		AmountToSendModalStore.getInstance().closeModal();
	}

	private updateBalances(balances: IBalances) {
		this.setState({ balances }, this.updateAvailableAmount);
	}

	private updateAvailableAmount() {
		const tokenSymbol = this.state.paymentInfo?.token?.symbol;
		const currentBalance = BalanceHelper.getTokenBalanceOfConnectedUser(this.state.balances, tokenSymbol);
		this.setState({ currentBalance });
	}

	private openChooseTokenModal() {
		this.close();
		ChooseCryptoModalStore.getInstance().openModal({});
	}

	private updateModalState(modalsOpenedState: { [type: string]: boolean }, paymentInfo?: IAmountToSendModalProps) {
		this.setState({ isOpen: modalsOpenedState[EModal.AMOUNT_TO_SEND] ?? false, paymentInfo });
	}

	private setValueToMax(value: string) {
		this.setState({ amountToSend: value });
	}

	private submit(e: React.FormEvent<HTMLFormElement> | null, values: { [key: string]: string }) {
		const receiverAddress = values["address"];
		const amountToSend = values["amount"];
		const token = this.state.paymentInfo?.token;
		try {
			assert(
				token && amountToSend && receiverAddress,
				"Cannot open confirm payment modal cause token, amount or receiver address not found",
			);
			ConfirmPaymentModalStore.getInstance().openModal({
				amountToSend: BigNumber.from(amountToSend, token.decimals),
				tokenSupportEntity: token,
				receiverAddress,
			});
			this.close();
		} catch (e) {
			console.error("error", e);
			ToastHelper.createAnErrorOccuredToast();
		}
	}

	private onErrors(key: string, errors: IError[]) {
		const errorObj = this.state.errorObj;
		errorObj[key] = { errors };
		this.setState({ errorObj });
	}

	private isAnyError(errorObj: { [key: string]: { errors: IError[] } }): boolean {
		const errors = Object.entries(errorObj).map(([key, value]) => value.errors.length > 0);
		return errors.some((v) => v === true);
	}

	private onChange(e: React.ChangeEvent<HTMLInputElement>) {
		const value = e.target.value;
		this.setState({ amountToSend: value });
	}
}
