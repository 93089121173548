import configHelper from "common/helpers/config";

export default class Theme {
	private static ctx: Theme;
	private theme: any | null = null;
	private inited: boolean = false;
	private constructor() {
		Theme.ctx = this;
	}

	public static getInstance() {
		if (!Theme.ctx) return new this();
		return Theme.ctx;
	}

	public init() {
		if (this.inited) return;
		try {
			this.fetchTheme();
		} catch (e) {
			console.error(e);
		}
		this.inited = true;
	}

	public get() {
		return this.theme;
	}

	private fetchTheme() {
		configHelper.addLinkElement("stylesheet", "theme.css");
		configHelper.addLinkElement("manifest", "manifest.json");
		configHelper.addLinkElement("apple-touch-icon", "assets/images/logos/logo192.webp");
		configHelper.addLinkElement("icon", "assets/images/logos/favicon.svg");
	}
}
