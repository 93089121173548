import React from "react";

import { IError } from "Components/Elements/Form/Elements/BaseField";
import InputField, { IProps as InputFieldProps } from "Components/Elements/Form/Elements/InputField";
import I18n from "Components/Elements/I18n";
import Typography from "Components/Elements/Typography";

import classes from "./classes.module.scss";

type IProps = InputFieldProps & {
	className?: string;
	label?: string | JSX.Element;
	errors?: IError[];
	startIconUrl?: JSX.Element;
	isTextarea?: boolean;
	disabledErrorsMessages?: boolean;
	inputClassName?: string;
	labelClassName?: string;
	inputContainerClassName?: string;
};
type IState = {
	errors: IError[];
	hasBeenFocused: boolean;
};

export default class InputFieldMaterial extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.onErrors = this.onErrors.bind(this);
		this.onFocus = this.onFocus.bind(this);

		this.state = {
			errors: this.props.errors ?? [],
			hasBeenFocused: false,
		};
	}

	public override render() {
		const { startIconUrl, ...rest } = this.props;

		return (
			<div className={[classes["root"], this.props.className ?? ""].join(" ")} onClick={this.onFocus}>
				<div className={classes["content"]}>
					{this.props.label && (
						<Typography
							type="p"
							size="small"
							weight="medium"
							className={[classes["label"], this.props.labelClassName ?? ""].join(" ")}>
							{this.props.label}
							{this.props.required && "*"}
						</Typography>
					)}

					<div className={[classes["input-container"], this.props.inputContainerClassName].join(" ")} data-large={this.props.isTextarea}>
						{this.props.startIconUrl && this.props.startIconUrl}
						<InputField
							{...rest}
							onChange={this.props.onChange}
							onErrors={this.onErrors}
							disableValidation={this.props.errors?.length! > 0}
							large={this.props.isTextarea}
							className={this.props.inputClassName}
						/>
					</div>
					{!this.props.disabledErrorsMessages && this.state.errors.length >= 1 && this.renderErrors()?.[0]}
				</div>
			</div>
		);
	}

	public override componentDidUpdate(prevProps: IProps) {
		if (JSON.stringify(this.props.errors) !== JSON.stringify(prevProps.errors)) {
			this.setState({
				errors: [...(this.props.errors ?? [])],
			});
		}
	}

	private renderErrors(): JSX.Element[] | null {
		if (this.state.errors.length === 0) return null;
		return this.state.errors.map((error) => {
			const vars: { [key: number]: any } = { 0: error.value };
			error.args.forEach((arg, index) => {
				vars[index + 1] = arg;
			});

			return (
				<I18n
					map={error.message}
					key={error.message}
					vars={vars}
					content={(localizedErrorMessage) =>
						localizedErrorMessage.map((text) => (
							<Typography color="error" type="p" size="xsmall" weight="regular" key={text}>
								{text}
							</Typography>
						))
					}
				/>
			);
		});
	}

	private onErrors(errors: IError[]) {
		this.setState({
			errors,
		});
		if (this.props.onErrors) this.props.onErrors(errors);
	}

	private onFocus() {
		this.setState({
			hasBeenFocused: true,
		});
	}
}
