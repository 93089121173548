import classes from "./classes.module.scss";
import classNames from "classnames";
import { useCallback } from "react";
import { match } from "ts-pattern";
import { CheckCircleIcon as SuccessIcon } from "@heroicons/react/24/solid";
import { XCircleIcon as FailureIcon } from "@heroicons/react/24/solid";
import { EConfirmationStatus } from "..";
import Typography from "Components/Elements/Typography";
import LazyImage from "Components/Elements/LazyImage";

export type IConfirmationModalHeaderProps = {
	status: EConfirmationStatus;
	imageUrl: string;
	message: string | JSX.Element;
};

export default function ConfirmationModalHeader(props: IConfirmationModalHeaderProps) {
	const getIconByStatus = useCallback(
		() =>
			match(props.status)
				.with(EConfirmationStatus.SUCCESS, () => <SuccessIcon />)
				.with(EConfirmationStatus.FAILURE, () => <FailureIcon />)
				.exhaustive(),
		[props.status],
	);

	const getIconClassNameByStatus = useCallback(
		() =>
			match(props.status)
				.with(EConfirmationStatus.SUCCESS, () => "success")
				.with(EConfirmationStatus.FAILURE, () => "failure")
				.exhaustive(),
		[props.status],
	);

	return (
		<div className={classes["root"]}>
			<div className={classes["image-container"]}>
				<LazyImage src={props.imageUrl} className={classes["image"]} alt={"image"}/>
			</div>
			<div className={classes["message-container"]}>
				<Typography type="h5" color="primary">
					{props.message}
				</Typography>
				<div className={classNames(classes["message-status"], classes[getIconClassNameByStatus()])}>{getIconByStatus()}</div>
			</div>
		</div>
	);
}
