import AModalStore, { EModal } from "./AModalStore";
import { IPassKYCModalProps as IProps } from "Components/Materials/Modals/PassKYCModal";
export default class PassKYCModalStore extends AModalStore<IProps> {
	private static ctx: PassKYCModalStore;

	public constructor() {
		super();
		PassKYCModalStore.ctx = this;
	}

	public static getInstance() {
		if (!PassKYCModalStore.ctx) return new this();
		return PassKYCModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.PASS_KYC, props);
	}

	public closeModal() {
		this.close();
	}
}
