import React from "react";
import { EModal } from "Stores/Modals/AModalStore";
import classes from "./classes.module.scss";
import SynapsModalStore from "Stores/Modals/SynapsModalStore";
import { SynapsService } from "Services/SynapsService";
import Cross from "Components/Elements/Icons/Cross";
import IconButton from "Components/Elements/IconButton";

type IProps = Record<string, never>;

type IState = {
	isOpen: boolean;
};

export default class SynapsModal extends React.Component<IProps, IState> {
	private removeOnModalChange = () => {};
	private isSynapsInitialized = false;
	public constructor(props: IProps) {
		super(props);

		this.state = {
			isOpen: SynapsModalStore.getInstance().isOpen(EModal.SYNAPS),
		};
		this.updateModalState = this.updateModalState.bind(this);
		this.close = this.close.bind(this);
	}

	public override render(): React.ReactNode {
		if (!this.state.isOpen) return null;

		return (
			<div className={classes["root"]} onClick={this.close}>
				<IconButton icon={<Cross />} className={classes["cross"]} onClick={this.close} />
				<div id="synaps-wrapper" className={classes["content"]} />
			</div>
		);
	}

	public override async componentDidMount() {
		this.removeOnModalChange = SynapsModalStore.getInstance().onChange(this.updateModalState);
	}

	public override componentWillUnmount() {
		this.removeOnModalChange();
	}

	private close() {
		SynapsModalStore.getInstance().closeModal();
	}

	private async updateModalState(modalsOpenedState: { [type: string]: boolean }) {
		this.setState(
			{
				isOpen: modalsOpenedState[EModal.SYNAPS] ?? false,
			},
			() => {
				if (modalsOpenedState[EModal.SYNAPS] === false) {
					this.isSynapsInitialized = false;
					return;
				}

				if (!this.isSynapsInitialized) SynapsService.getInstance().init();
				this.isSynapsInitialized = true;
			},
		);
	}
}
