import React from "react";
import BalanceHelper from "common/helpers/balance";
import AmountInputField from "Components/Elements/Form/Elements/AmountInputField";
import ConvertedAmount from "Components/Elements/Form/Elements/AmountInputField/ConvertedAmount";
import InputFiatAmount from "Components/Elements/Form/Elements/AmountInputField/InputFiatAmount";
import { IError } from "Components/Elements/Form/Elements/BaseField";
import { IProps as InputFieldProps } from "Components/Elements/Form/Elements/InputField";
import I18n from "Components/Elements/I18n";
import Select, { IOption } from "Components/Elements/Select";
import Typography from "Components/Elements/Typography";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import BigNumber from "Services/BigNumber";
import TokenSupports from "Stores/TokenSupports";
import WalletBalanceStore, { IBalances } from "Stores/WalletBalanceStore";
import classes from "./classes.module.scss";

type IProps = InputFieldProps & {
	className?: string;
	label?: string | JSX.Element;
	errors?: IError[];
	leftTip?: string | JSX.Element;
	withInputFiatAmout?: boolean;
	displayConvertedAmount?: boolean;
	displayCurrentBalanceTip?: boolean;
	onChangeAvailableAmount?: (availableAmount: BigNumber) => void;
	onSetValueToMax?: (value: string) => void;
	token?: AppTokenSupportEntity;
	/**
	 * @description
	 * I TokenSelect is defined it will show a selector of tokens.
	 * By Default token options will be the ones from the user balances,
	 * but it can be override with the options parameter.
	 */
	tokenSelect?: {
		tokens?: AppTokenSupportEntity[];
		onTokenChange?: (token: AppTokenSupportEntity) => void;
	};
};

type IState = {
	errors: IError[];
	hasBeenFocused: boolean;
	currentBalance: BigNumber;
	balances: IBalances;
};

export default class AmountFieldMaterial extends React.Component<IProps, IState> {
	private removeOnWalletBalanceChange = () => {};

	public constructor(props: IProps) {
		super(props);
		this.onErrors = this.onErrors.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleTokenChange = this.handleTokenChange.bind(this);
		this.setValueToMax = this.setValueToMax.bind(this);
		this.updateBalances = this.updateBalances.bind(this);
		this.getCurrentBalanceTip = this.getCurrentBalanceTip.bind(this);

		this.state = {
			errors: this.props.errors ?? [],
			hasBeenFocused: false,
			currentBalance: BigNumber.from("0"),
			balances: WalletBalanceStore.getInstance().balances,
		};
	}

	public override render() {
		const attributes = { ...this.props };
		delete attributes.className;
		delete attributes.token;
		delete attributes.leftTip;
		delete attributes.withInputFiatAmout;
		delete attributes.displayConvertedAmount;
		delete attributes.displayCurrentBalanceTip;
		delete attributes.onChangeAvailableAmount;
		delete attributes.tokenSelect;
		delete attributes.onSetValueToMax;

		return (
			<div className={[classes["root"], classes[this.props.className ?? ""]].join(" ")} onClick={this.onFocus}>
				<div className={classes["content"]}>
					<Typography type="p" size="small" weight="medium">
						{this.props.label}
						{this.props.required && "*"}
					</Typography>
					<div className={classes["amount-input"]}>
						{this.props.tokenSelect && (
							<Select
								onChange={this.handleTokenChange}
								selectedOption={this.getOptionFromToken(this.props.token)}
								options={this.getTokenOptions(this.state.balances)}
								borderRightCollapsed={!!this.props.tokenSelect}
							/>
						)}
						<AmountInputField
							{...attributes}
							defaultValue={this.props.defaultValue}
							value={this.props.value}
							borderLeftCollapsed={!!this.props.tokenSelect}
							borderRightCollapsed={this.props.withInputFiatAmout || this.props.displayConvertedAmount}
							checked
							tokenSymbol={this.props.token?.symbol}
							onChange={this.onChange}
							onErrors={this.onErrors}
							decimalsQuantity={50}
							maxAmount={{ args: [this.state.currentBalance, this.props.token?.decimals] }}
						/>
						{this.props.withInputFiatAmout && <InputFiatAmount borderRightCollapsed={this.props.displayConvertedAmount} />}
						{this.props.displayConvertedAmount && (
							<ConvertedAmount amountToConvert={this.props.value?.toString()} token={this.props.token} />
						)}
					</div>
					{this.state.errors.length >= 1 && this.renderErrors()}
				</div>
				{this.props.displayCurrentBalanceTip && (
					<div className={classes["tipRow"]}>
						<Typography type="p" size="xsmall" weight="regular">
							{this.props.leftTip}
						</Typography>
						<div onClick={this.setValueToMax} style={{ cursor: "pointer" }}>
							<Typography type="p" size="xsmall" weight="regular">
								{this.getCurrentBalanceTip()}
							</Typography>
						</div>
					</div>
				)}
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnWalletBalanceChange = WalletBalanceStore.getInstance().onChange(this.updateBalances);
		this.updateBalances(WalletBalanceStore.getInstance().balances);
	}

	public override componentWillUnmount() {
		this.removeOnWalletBalanceChange();
	}

	public override componentDidUpdate(prevProps: IProps, prevState: IState) {
		if (JSON.stringify(this.props.errors) !== JSON.stringify(prevProps.errors)) {
			this.setState({
				errors: [...(this.props.errors ?? [])],
			});
		}

		if (this.props.token !== prevProps.token) {
			this.updateBalances(WalletBalanceStore.getInstance().balances);
		}
	}

	private getCurrentBalanceTip() {
		if (!this.props.displayCurrentBalanceTip) return;
		if (!this.props.token) return;
		return `${this.state.currentBalance.shiftLeft(this.props.token.decimals).getFormatRoundFloor(4)} ${
			this.props.token.symbol
		} ${I18n.translate("inputs.tips.available")}`;
	}

	private setValueToMax() {
		if (!this.props.token) return;
		if (this.props.onSetValueToMax) {
			const value = this.state.currentBalance.shiftLeft(this.props.token.decimals).getFormatRoundFloor(4);
			this.props.onSetValueToMax(value!);
		}
	}

	private onChange(e: React.ChangeEvent<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement>) {
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	}

	private getTokenOptions(balance: IBalances): IOption[] {
		if (this.props.tokenSelect?.tokens)
			return this.props.tokenSelect.tokens.map((token) => {
				return {
					label: token.name,
					value: token.symbol,
					icon: <img alt="token symbol" src={token.picture} />,
				};
			});

		return Object.entries(balance)
			.map(([key, balance]) => {
				if (balance.balance.isZero()) return null;
				return {
					label: balance.token.name,
					value: balance.token.symbol,
					icon: <img alt="token symbol" src={balance.token.picture} />,
				} as IOption;
			})
			.filter((option) => option !== null) as IOption[];
	}

	private getOptionFromToken(token: AppTokenSupportEntity | undefined): IOption | undefined {
		const option = this.getTokenOptions(this.state.balances).find(
			(option) =>
				option.value === token?.symbol ?? this.props.tokenSelect?.tokens?.[0]?.symbol ?? this.state.balances[0]?.token?.symbol,
		);
		return option;
	}

	private getTokenFromOption(option: IOption | undefined): AppTokenSupportEntity | undefined {
		return TokenSupports.getInstance()
			.getTokenSupports()
			.find((token) => token.symbol === option?.value);
	}

	private isErrorMaxAmount(error: IError): boolean {
		return error.validator === "maxAmount";
	}

	private isInputWithinMaxAmount(token: AppTokenSupportEntity): boolean {
		const value = BigNumber.from(this.props.value as string, token.decimals);
		const currentBalance = BalanceHelper.getTokenBalanceOfConnectedUser(this.state.balances, token.symbol);

		return value.lte(currentBalance);
	}

	private renderErrors(): (JSX.Element | null)[] | null {
		if (this.state.errors.length === 0) return null;
		return this.state.errors.map((error) => {
			const vars: { [key: number]: any } = { 0: error.value };
			error.args.forEach((arg, index) => {
				vars[index + 1] = arg;
			});

			// If the error is maxAmount and the input is within the max amount, we don't display the error
			// this.props.token is here just to make sure that the token is loaded for isInputWithinMaxAmount to work
			if (this.props.token && this.isErrorMaxAmount(error) && this.isInputWithinMaxAmount(this.props.token)) return null;

			return (
				<I18n
					map={error.message}
					key={error.message}
					vars={vars}
					content={(localizedErrorMessage) =>
						localizedErrorMessage.map((text) => (
							<Typography color="error" key={text} type="p" size="xsmall" weight="regular">
								{text}
							</Typography>
						))
					}
				/>
			);
		});
	}

	private onErrors(errors: IError[]) {
		this.setState({ errors });
		if (this.props.onErrors) this.props.onErrors(errors);
	}

	private onFocus() {
		this.setState({ hasBeenFocused: true });
	}

	private handleTokenChange(selectOption: IOption) {
		const token = this.getTokenFromOption(selectOption);
		if (!token) return;
		this.updateAvailableAmount();
		this.props.tokenSelect?.onTokenChange?.(token);
	}

	private updateBalances(balances: IBalances) {
		this.setState({ balances }, () => this.updateAvailableAmount());
	}

	private updateAvailableAmount() {
		const tokenSymbol = this.props.token?.symbol;
		const currentBalance = BalanceHelper.getTokenBalanceOfConnectedUser(this.state.balances, tokenSymbol);
		this.setState({ currentBalance });
		this.props.onChangeAvailableAmount?.(currentBalance);
	}
}
