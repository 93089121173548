import Typography from "Components/Elements/Typography";
import React from "react";

import classes from "./classes.module.scss";

type IProps = {
	symbol: string;
};

type IState = {};

export default class TokenSymbol extends React.Component<IProps, IState> {
	public override render() {
		return (
			<div className={classes["root"]}>
				<Typography type="p" weight="regular" size="small">
					{this.props.symbol}
				</Typography>
			</div>
		);
	}
}
