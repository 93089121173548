import AModalStore, { EModal } from "./AModalStore";
import { IMakeOfferModalProps as IProps } from "Components/Materials/Modals/MakeOfferModal";
export default class MakeOfferModalStore extends AModalStore<IProps> {
	private static ctx: MakeOfferModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		MakeOfferModalStore.ctx = this;
	}

	public static getInstance() {
		if (!MakeOfferModalStore.ctx) return new this();
		return MakeOfferModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.MAKE_OFFER, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
