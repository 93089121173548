import BaseApiService from "Api/BaseApiService";
import ConfigStore from "Stores/ConfigStore";

import { AppConfigEntity } from "Entities/appConfig";

export default class AppConfig extends BaseApiService {
	private static instance: AppConfig;

	private constructor() {
		super();
		AppConfig.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-config");
	}

	public static getInstance(reset?: boolean) {
		if (!AppConfig.instance || reset) return new this();
		return AppConfig.instance;
	}

	public async getConfig(): Promise<AppConfigEntity | null> {
		const url = new URL(this.baseUrl);

		return this.getRequest<AppConfigEntity>(url);
	}
}
