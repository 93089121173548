import I18n from "Components/Elements/I18n";
import classes from "./classes.module.scss";
import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import Typography from "Components/Elements/Typography";

export type IPurchaseConfirmationContinueButtonProps = {
	onClick?: () => void;
}

export function PurchaseConfirmationContinueButton(props: IPurchaseConfirmationContinueButtonProps) {
	return (
		<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} fullwidth onClick={props.onClick}>
			<div className={classes["root"]}>
				<Typography size="large" weight="semibold">
					{I18n.translate("modals.confirmation.purchase.success.continue")}
				</Typography>
			</div>
		</Button>
	);
}
