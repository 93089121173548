import classes from "./classes.module.scss";
import Modal, { IModalProps } from "Components/Elements/Modals";
import { useMemo } from "react";
import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";

import ConfirmationModalHeader from "./ConfirmationModalHeader";
import { match } from "ts-pattern";
import ConfirmationModalReason from "./ConfirmationModalReason";

import { PurchaseConfirmationRetryButton } from "./PurchaseConfirmationRetryButton";
import { PurchaseConfirmationContinueButton } from "./PurchaseConfirmationContinueButton";
import I18n from "Components/Elements/I18n";
import Typography from "Components/Elements/Typography";

export enum EConfirmationStatus {
	SUCCESS = "SUCCESS",
	FAILURE = "FAILURE",
}

export type IConfirmationModalProps = Pick<IModalProps, "isOpen" | "header" | "onClose"> & {
	headerImageUrl: string;
	headerMessage: string;
	status: EConfirmationStatus;
	summary?: JSX.Element;
	reason?: JSX.Element;
	customButton?: JSX.Element;
};

export type IPurchaseConfirmationModalProps = Omit<IConfirmationModalProps, "headerMessage"> & {
	onRetry?: () => Promise<void> | void;
	onContinue?: () => void;
	summary?: JSX.Element;
};

export type IListingConfirmationModalProps = Omit<IConfirmationModalProps, "headerMessage"> & {
	onRetry?: () => Promise<void> | void;
	summary?: JSX.Element;
};

export type IOfferConfirmationModalProps = Omit<IConfirmationModalProps, "headerMessage"> & {
	onRetry?: () => Promise<void> | void;
	summary?: JSX.Element;
};

export type IAcceptOfferConfirmationModalProps = Omit<IConfirmationModalProps, "headerMessage"> & {
	onRetry?: () => Promise<void> | void;
	summary?: JSX.Element;
};

export function PurchaseConfirmationModal(props: IPurchaseConfirmationModalProps) {
	const message = match(props.status)
		.with(EConfirmationStatus.SUCCESS, () => I18n.translate("modals.confirmation.purchase.success.message"))
		.with(EConfirmationStatus.FAILURE, () => I18n.translate("modals.confirmation.purchase.failure.message"))
		.exhaustive();

	const reason = match(props.status)
		.with(EConfirmationStatus.SUCCESS, () => undefined)
		.with(EConfirmationStatus.FAILURE, () => (
			<ConfirmationModalReason
				title={I18n.translate("modals.confirmation.purchase.failure.reason.title")}
				description={I18n.translate("modals.confirmation.purchase.failure.reason.description")}
			/>
		))
		.exhaustive();

	const customButton = match(props.status)
		.with(EConfirmationStatus.SUCCESS, () => <PurchaseConfirmationContinueButton onClick={props.onContinue} />)
		.with(EConfirmationStatus.FAILURE, () =>
			match(props.onRetry)
				.with(undefined, () => undefined)
				.otherwise(() => <PurchaseConfirmationRetryButton onClick={props.onRetry} />),
		)
		.exhaustive();

	return <ConfirmationModal {...props} headerMessage={message} reason={reason} customButton={customButton} />;
}

export function ListingConfirmationModal(props: IListingConfirmationModalProps) {
	const message = match(props.status)
		.with(EConfirmationStatus.SUCCESS, () => I18n.translate("modals.confirmation.listing.success.message"))
		.with(EConfirmationStatus.FAILURE, () => I18n.translate("modals.confirmation.listing.failure.message"))
		.exhaustive();

	const reason = match(props.status)
		.with(EConfirmationStatus.SUCCESS, () => undefined)
		.with(EConfirmationStatus.FAILURE, () => (
			<ConfirmationModalReason
				title={I18n.translate("modals.confirmation.listing.failure.reason.title")}
				description={I18n.translate("modals.confirmation.listing.failure.reason.description")}
			/>
		))
		.exhaustive();

	return <ConfirmationModal {...props} headerMessage={message} reason={reason} />;
}

export function OfferConfirmationModal(props: IOfferConfirmationModalProps) {
	const message = match(props.status)
		.with(EConfirmationStatus.SUCCESS, () => I18n.translate("modals.confirmation.offer.success.message"))
		.with(EConfirmationStatus.FAILURE, () => I18n.translate("modals.confirmation.offer.failure.message"))
		.exhaustive();

	const reason = match(props.status)
		.with(EConfirmationStatus.SUCCESS, () => undefined)
		.with(EConfirmationStatus.FAILURE, () => (
			<ConfirmationModalReason
				title={I18n.translate("modals.confirmation.offer.failure.reason.title")}
				description={I18n.translate("modals.confirmation.offer.failure.reason.description")}
			/>
		))
		.exhaustive();

	return <ConfirmationModal {...props} headerMessage={message} reason={reason} />;
}

export function AcceptOfferConfirmationModal(props: IAcceptOfferConfirmationModalProps) {
	const message = match(props.status)
		.with(EConfirmationStatus.SUCCESS, () => I18n.translate("modals.confirmation.accept_offer.success.message"))
		.with(EConfirmationStatus.FAILURE, () => I18n.translate("modals.confirmation.accept_offer.failure.message"))
		.exhaustive();

	const reason = match(props.status)
		.with(EConfirmationStatus.SUCCESS, () => undefined)
		.with(EConfirmationStatus.FAILURE, () => (
			<ConfirmationModalReason
				title={I18n.translate("modals.confirmation.accept_offer.failure.reason.title")}
				description={I18n.translate("modals.confirmation.accept_offer.failure.reason.description")}
			/>
		))
		.exhaustive();

	return <ConfirmationModal {...props} headerMessage={message} reason={reason} />;
}

export default function ConfirmationModal(props: IConfirmationModalProps) {
	const footer = useMemo(
		() => (
			<div className={classes["button-container"]}>
				<div className={classes["close-button"]}>
					<Button variant={EButtonVariant.OUTLINED} color={EButtonColor.NEUTRAL} fullwidth onClick={props.onClose}>
						<Typography size="large" weight="semibold">
							{I18n.translate("buttons.close")}
						</Typography>
					</Button>
				</div>
				{props.customButton && <div className={classes["custom-button"]}>{props.customButton}</div>}
			</div>
		),
		[props.customButton, props.onClose],
	);

	return (
		<div className={classes["root"]}>
			<Modal closeBtn isOpen={props.isOpen} onClose={props.onClose} footer={footer}>
				<ConfirmationModalHeader status={props.status} imageUrl={props.headerImageUrl} message={props.headerMessage} />
				{props.summary}
				{props.reason}
			</Modal>
		</div>
	);
}
