import Button, { EButtonVariant, EButtonColor, EButtonSize } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import Typography from "Components/Elements/Typography";
import classes from "./classes.module.scss";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

type IProps = {
	icon: JSX.Element;
	contentText: string; // i18n key
	button?: {
		text: string; // i18n key
		onClick: () => void;
	};
};

export function BannerContent(props: IProps) {
	return (
		<div className={classes["root"]}>
			{props.icon}
			<Typography type="p" size="small" weight="regular">
				<I18n map={props.contentText} />
			</Typography>
			{props.button && (
				<Button
					size={EButtonSize.SMALL}
					className={classes["button"]}
					variant={EButtonVariant.TEXT}
					color={EButtonColor.PRIMARY}
					endIcon={<ArrowRightIcon />}
					onClick={props.button.onClick}>
					<I18n map={props.button.text} />
				</Button>
			)}
		</div>
	);
}
