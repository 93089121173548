import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { FormEvent, ReactNode } from "react";

import WindowStore from "Stores/Window";

import Typography from "../Typography";
import classes from "./classes.module.scss";

type IProps = {
	selectedOption?: IOption;
	defaultOption?: IOption;
	onChange: (selectedOption: IOption) => void;
	options: IOption[];
	borderRightCollapsed?: boolean;
	placeholder?: string;
	className?: string;
	isDisabled?: boolean;
	size?: "small" | "medium";
};

export type IOption = {
	value: unknown;
	label: string;
	icon?: ReactNode;
};

type IState = {
	open: boolean;
	listWidth: number;
	listHeight: number;
};

export default class Select extends React.Component<IProps, IState> {
	private contentRef = React.createRef<HTMLUListElement>();
	private rootRef = React.createRef<HTMLDivElement>();
	private removeOnresize = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			open: false,
			listHeight: 0,
			listWidth: 0,
		};
		this.toggle = this.toggle.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	public override render(): JSX.Element {
		const selectedOption = this.props.selectedOption ?? this.props.defaultOption;
		return (
			<div
				className={classNames(
					classes["root"],
					classes[this.props.size ?? "medium"],
					this.props.className,
					this.props.isDisabled ? classes["disabled"] : "",
				)}
				ref={this.rootRef}>
				<label
					className={classNames(classes["container-label"])}
					data-open={this.state.open}
					onClick={this.toggle}
					data-border-right-collapsed={this.props.borderRightCollapsed}>
					<div className={classNames(classes["container-input"])}>
						<Typography type="span" size="small" weight="bold">
							<>
								{selectedOption?.icon && (
									<span className={classNames(classes["icon"], classes["token-icon"])}>{selectedOption?.icon}</span>
								)}
								<span className={classes["text"]}>{selectedOption?.label ?? this.props.placeholder ?? ""}</span>
							</>
						</Typography>
					</div>
					<ChevronDownIcon className={classes["chevron-icon"]} data-open={this.state.open} />
				</label>

				<ul
					className={classes["container-ul"]}
					data-open={this.state.open}
					ref={this.contentRef}
					style={{
						height: this.state.listHeight + "px",
					}}>
					{this.props.options.map((option, index) => (
						<li key={`${index}-${option.value}`} className={classes["container-li"]} onClick={(e) => this.onSelect(option, e)}>
							{option.icon && <div className={classes["token-icon"]}>{option.icon}</div>}
							<Typography type="span" size="small" weight="regular">
								{option.label}
							</Typography>
						</li>
					))}
				</ul>
			</div>
		);
	}

	static getDerivedStateFromProps(props: IProps, state: IState) {
		if (props.selectedOption?.value) {
			return {
				value: props.selectedOption?.value,
			};
		}
		return null;
	}

	public override componentDidMount(): void {
		this.onResize();
		this.removeOnresize = WindowStore.getInstance().onResize(() => this.onResize());
		document.addEventListener("click", this.handleClickOutside);
	}

	public override componentWillUnmount() {
		this.removeOnresize();
		document.removeEventListener("click", this.handleClickOutside);
	}

	private onResize() {
		let listHeight = 0;
		let listWidth = 0;
		listWidth = this.rootRef.current?.scrollWidth ?? 0;
		if (this.state.listHeight) {
			listHeight = this.contentRef.current?.scrollHeight ?? 0;
		}
		this.setState({ listHeight, listWidth });
	}

	private toggle(e?: FormEvent) {
		if (this.props.isDisabled) return;
		e?.preventDefault();
		let listHeight = 0;
		let listWidth = this.rootRef.current?.scrollWidth ?? 0;

		if (!this.state.listHeight) {
			listHeight = this.contentRef.current?.scrollHeight ?? 0;
		}

		this.setState((state) => {
			return { open: !state.open, listHeight, listWidth };
		});

	}

	private onSelect(option: IOption, e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
		const resetSelectedOption =
			this.props.selectedOption?.label === option.label ||
			(this.props.defaultOption?.label === option.label && !this.props.selectedOption);

		this.props.onChange && this.props.onChange(resetSelectedOption ? ({} as IOption) : option);
		this.toggle(e);
	}

	private handleClickOutside(event: MouseEvent) {
		if (this.rootRef.current && !this.rootRef.current.contains(event.target as Node)) {
			this.state.open && this.toggle();
		}
	}
}
