import AppUser from "Api/Back/AppUser";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import { AppUserEntity } from "Entities/appUser";
import UserStore from "Stores/UserStore";
import WalletStore from "Stores/WalletStore";
import { SynapsVerificationStatus } from "common/enums/KYC";
import { UserConnectionStatus } from "common/enums/UserConnectionStatus";

export default class UserService {
	private static instance: UserService;

	private constructor() {
		UserService.instance = this;
		UserStore.getInstance().onChange((user) => this.onUserChange(user));
	}

	public static getInstance() {
		if (!UserService.instance) return new this();
		return UserService.instance;
	}

	public isUserInfosCompleted(user: AppUserEntity) {
		const fieldConfiguration = IsModuleEnabled.get().Modals.props.RegisterModal.props.fields;
		const isFieldDisabled = (fieldName: keyof typeof fieldConfiguration) => !fieldConfiguration[fieldName].enabled;
		const isFieldOptional = (fieldName: keyof typeof fieldConfiguration) => !fieldConfiguration[fieldName].props.required;

		return Boolean(
			(isFieldDisabled("email") || isFieldOptional("email") || user.email) &&
				(isFieldDisabled("displayName") || isFieldOptional("displayName") || user.displayName) &&
				(isFieldDisabled("firstName") || isFieldOptional("firstName") || user.firstName) &&
				(isFieldDisabled("lastName") || isFieldOptional("lastName") || user.lastName) &&
				(isFieldDisabled("userName") || isFieldOptional("userName") || user.userName),
		);
	}

	public hasKYCCompleted(user: AppUserEntity) {
		return user.appKYC?.verificationStatus === SynapsVerificationStatus.APPROVED;
	}

	public hasAllInfosCompleted(user: AppUserEntity) {
		const isKYCMandatory = IsModuleEnabled.get().KYC.enabled;
		if (!isKYCMandatory) return this.isUserInfosCompleted(user);
		return this.isUserInfosCompleted(user) && this.hasKYCCompleted(user);
	}

	private async syncFromWalletData(user: AppUserEntity) {
		if (user.email) return;

		const email = await WalletStore.getInstance().getUserEmail();
		console.log("email", email)
		const userAddress = WalletStore.getInstance().walletData.userAddress;

		if (user.displayRegistrationModal) UserStore.getInstance().emitOpenRegisterModalEvent();
		if (!userAddress || !email) return;

		const updatedUser = await AppUser.getInstance().updateEmail(userAddress, email);
		UserStore.getInstance().setUser(updatedUser, UserConnectionStatus.CONNECTED);
	}

	private async onUserChange(user: AppUserEntity | null) {
		if (!user) return;
		await this.syncFromWalletData(user);
	}
}
