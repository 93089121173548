import BaseMarketplaceContract from "./BaseMarketplaceContract";
import IMarketplaceContractAdapter from "./IMarketplaceContractAdapter";
import IMarketplaceContractFunctions from "./IMarketplaceContractFunctions";

export default abstract class BaseMarketplaceContractAdapter<T extends BaseMarketplaceContract> implements IMarketplaceContractAdapter {
	public constructor(public contract: T, public readonly usableFunctions: Record<keyof IMarketplaceContractFunctions, boolean>) {}

	public canUse<K extends keyof IMarketplaceContractFunctions>(contractFunc: K): this is this & Pick<IMarketplaceContractFunctions, K> {
		return !!this.usableFunctions[contractFunc];
	}

	public useOrThrow<K extends keyof IMarketplaceContractFunctions>(
		contractFunc: K,
	): (...args: Parameters<IMarketplaceContractFunctions[K]>) => ReturnType<IMarketplaceContractFunctions[K]> {
		if (this.canUse(contractFunc)) {
			const func = this[contractFunc];
			if (typeof func !== "function") {
				throw new Error(`Function ${contractFunc} not supported by Marketplace contract ${this.contract.address}`);
			}

			return (this[contractFunc] as Function).bind(this);
		}

		throw new Error("Method not implemented.");
	}
}
