import AModalStore, { EModal } from "./AModalStore";
import { ICancelListingModalProps as  IProps } from "Components/Materials/Modals/CancelListingModal";

export default class CancelListingModalStore extends AModalStore<IProps> {
	private static ctx: CancelListingModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		CancelListingModalStore.ctx = this;
	}

	public static getInstance() {
		if (!CancelListingModalStore.ctx) return new this();
		return CancelListingModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.CANCEL_LISTING, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
