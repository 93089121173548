import { GetSearchResponse } from "Api/Back/AppSearch";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import TabElementSwitcher, { IElementMenu } from "Components/Elements/TabElementSwitcher";
import { AppCollectionEntity } from "Entities/appCollection";
import { AppNftEntity } from "Entities/appNft";
import { AppUserEntity } from "Entities/appUser";
import React from "react";
import classes from "./classes.module.scss";
import SearchPreviewContent from "./Content";
import { ISearchPreviewItem } from "./Item";
import configHelper from "common/helpers/config";

type IProps = {
	results: GetSearchResponse;
	togglePreview: (showPreview: boolean) => void;
};

type IState = {};

export default class SearchPreview extends React.Component<IProps, IState> {
	private readonly defaultThumbnail = configHelper.getDefaultThumbnail();
	private readonly defaultAvatar = configHelper.getDefaultAvatar();

	public override render(): JSX.Element | null {
		return (
			<div className={classes["root"]}>
				<TabElementSwitcher items={this.getTabItems()} />
			</div>
		);
	}

	private getTabItems(): IElementMenu[] {
		const { results } = this.props;
		let items: IElementMenu[] = [];
		if (results.collections.length > 0) {
			items.push({
				label: "Collections",
				content: this.getSearchPreviewContent("Collections"),
			});
		}

		if (results.nfts.length > 0) {
			items.push({
				label: "NFTs",
				content: this.getSearchPreviewContent("NFTs"),
			});
		}

		if (results.users.length > 0) {
			items.push({
				label: "Users",
				content: this.getSearchPreviewContent("Users"),
			});
		}

		return items;
	}

	private getSearchPreviewContent(label: "Collections" | "NFTs" | "Users"): JSX.Element {
		const { results } = this.props;
		let searchPreviewItems: ISearchPreviewItem[];
		switch (label) {
			case "Collections":
				searchPreviewItems = this.convertCollection(results.collections);
				break;
			case "NFTs":
				searchPreviewItems = this.convertNfts(results.nfts);
				break;
			case "Users":
				searchPreviewItems = this.convertUsers(results.users);
				break;
			default:
				searchPreviewItems = [];
				break;
		}
		return <SearchPreviewContent label={label} items={searchPreviewItems} togglePreview={this.props.togglePreview} />;
	}

	private convertUsers(users: AppUserEntity[]) {
		const searchPreviewItems: ISearchPreviewItem[] = users.map((user) => {
			let item: ISearchPreviewItem;
			item = {
				id: user.id,
				title: user.userName ?? "",
				subtitle: user.appWallet?.userAddress ?? "",
				image: user.picture?.url || this.defaultAvatar,
				redirectLink: IsModuleEnabled.get().pages.Profile.props.path.replace(":userAddress", user.appWallet?.userAddress ?? ""),
			};
			return item;
		});

		return searchPreviewItems;
	}

	private convertCollection(collections: AppCollectionEntity[]) {
		const searchPreviewItems: ISearchPreviewItem[] = collections.map((collection) => {
			let item: ISearchPreviewItem;
			item = {
				id: collection.id,
				title: collection.appCollectionConfig.name,
				subtitle: collection.totalSupply?.toString().concat(" NFTs"),
				image: collection.appCollectionConfig.picture?.url || this.defaultThumbnail,
				redirectLink: IsModuleEnabled.get()
					.pages.Collection.props.path.replace(":collectionAddress", collection.appContract?.address ?? "")
					.concat("/")
					.concat(IsModuleEnabled.get().pages.Collection.props.pages.Nfts.props.path),
			};
			return item;
		});

		return searchPreviewItems;
	}

	private convertNfts(nfts: AppNftEntity[]) {
		const searchPreviewItems: ISearchPreviewItem[] = nfts.map((nft) => {
			let item: ISearchPreviewItem;
			item = {
				id: nft.id,
				title: nft.name,
				subtitle: nft.appCollection.appCollectionConfig.name,
				image: nft.image?.url || this.defaultThumbnail,
				redirectLink: IsModuleEnabled.get()
					.pages.NFT.props.path.replace(":collectionAddress", nft.appCollection.appContract?.address ?? "")
					.replace(":tokenId", nft.tokenId.toString()),
			};
			return item;
		});

		return searchPreviewItems;
	}
}
