import Event from "events";

class EventEmitter extends Event {}

export default class UserRegistrationBannerStore {
	private static ctx: UserRegistrationBannerStore;
	private readonly event = new EventEmitter();
	private _isOpen: boolean = true;

	private constructor() {
		UserRegistrationBannerStore.ctx = this;
	}

	public static getInstance() {
		if (!UserRegistrationBannerStore.ctx) return new this();
		return UserRegistrationBannerStore.ctx;
	}

	public get isOpen() {
		return this._isOpen;
	}

	public onChange(callback: (isOpen: boolean) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public close() {
		this._isOpen = false;
		this.event.emit("change", this._isOpen);
	}
}
