import { ethers } from "ethers";
import AppAuction from "Api/Back/AppAuction";
import { AppAuctionEntity } from "Entities/appAuction";
import { AppContractEntity } from "Entities/appContract";
import BigNumber from "Services/BigNumber";
import WalletStore from "Stores/WalletStore";
import Erc20ContractStore from "Stores/ContractStores/Erc20ContractStore";
import DeprecatedContractStore from "Stores/ContractStores/DeprecatedContractStore";
import IncarusWhitelistContract from "Services/Contracts/Ethereum/auction/IncarusWhitelistContract";
import IncarusErc20WhitelistContract from "Services/Contracts/Ethereum/auction/IncarusErc20WhitelistContract";
import IncarusErc20Contract from "Services/Contracts/Ethereum/auction/IncarusErc20Contract";
import IncarusContract from "Services/Contracts/Ethereum/auction/IncarusContract";
import { EthersContract } from "Services/Contracts/Ethereum/ContractFactory";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import assert from "assert";
import Erc721ContractStore from "Stores/ContractStores/Erc721ContractStore";

export default class TransactionHelper {
	// ERC 721
	public static async checkApproveForNft(
		erc721ContractAddress: string,
		tokenId: number,
		contractAddressToApprouve: string,
	): Promise<boolean> {
		const userAddress = WalletStore.getInstance().walletData.userAddress;
		if (!userAddress) return false;

		const enrichedErc721Contract = await Erc721ContractStore.getInstance().getEnrichedContractByAddress(erc721ContractAddress);

		const isApprovedForAll =
			enrichedErc721Contract.contractAdapter.canUse("isApprovedForAll") &&
			(await enrichedErc721Contract.contractAdapter.isApprovedForAll(userAddress, contractAddressToApprouve));
		if (isApprovedForAll) return true;

		const isApproved =
			enrichedErc721Contract.contractAdapter.canUse("getApproved") &&
			(await enrichedErc721Contract.contractAdapter.getApproved(tokenId));

		return isApproved === contractAddressToApprouve;
	}

	public static async approveAllNft(
		erc721ContractAddress: string,
		contractAddressToApprove: string,
	): Promise<ethers.providers.TransactionResponse> {
		const enrichedErc721Contract = await Erc721ContractStore.getInstance().getEnrichedContractByAddress(erc721ContractAddress);

		return enrichedErc721Contract.contractAdapter.useOrThrow("setApprovalForAll")(contractAddressToApprove, true);
	}

	public static async createApproveAllNftTx(
		erc721ContractAddress: string,
		contractAddressToApprove: string,
	): Promise<ethers.PopulatedTransaction | undefined> {
		const enrichedErc721Contract = await Erc721ContractStore.getInstance().getEnrichedContractByAddress(erc721ContractAddress);

		if (!enrichedErc721Contract.contractAdapter.canUse("createApproveForAllTx")) return;

		return await enrichedErc721Contract.contractAdapter.createApproveForAllTx(contractAddressToApprove, true);
	}

	// ERC 20
	public static async checkAllowance(tokenAddress: string, contractAddress: string, amount: BigNumber) {
		const userAddress = WalletStore.getInstance().walletData.userAddress;
		if (!userAddress) return;

		const enrichedErc20Contract = await Erc20ContractStore.getInstance().getEnrichedContractByAddress(tokenAddress);

		const allowance = BigNumber.from(
			(await enrichedErc20Contract.ethersContract.getAllowance(userAddress, contractAddress)).toString(),
		);
		if (!allowance.lt(amount)) return;

		const tx = await enrichedErc20Contract.ethersContract.increaseAllowance(contractAddress, amount.sub(allowance));
		await tx.wait();
	}

	public static async checkCurrentAllowance(tokenSupportEntity: AppTokenSupportEntity, spender: string) {
		const userAddress = WalletStore.getInstance().walletData.userAddress;
		if (!userAddress) return;

		const enrichedErc20Contract = await Erc20ContractStore.getInstance().getEnrichedContractByAddress(tokenSupportEntity.address);

		const allowance = BigNumber.from((await enrichedErc20Contract.ethersContract.getAllowance(userAddress, spender)).toString());
		return allowance;
	}

	public static async createApprovePaymentTx(contractAddress: string, tokenSupportEntity: AppTokenSupportEntity, amount?: BigNumber) {
		if (!amount) amount = BigNumber.from("100000000", tokenSupportEntity.decimals);

		const enrichedErc20Contract = await Erc20ContractStore.getInstance().getEnrichedContractByAddress(tokenSupportEntity.address);

		const tx = await enrichedErc20Contract.ethersContract.createIncreaseAllowanceTX(contractAddress, amount);
		return tx;
	}

	// Bid on auction
	public static async createBidOrderTx(
		contract: AppContractEntity,
		auction: AppAuctionEntity,
		amount: BigNumber,
		isAuctionWhitelisted: boolean,
		isAuctionNative: boolean,
	) {
		return isAuctionWhitelisted
			? await TransactionHelper.createBidOrderTxWithWhitelist(contract, auction, amount, isAuctionNative)
			: await TransactionHelper.createBidOrderTxOnPublicAuction(contract, auction, amount, isAuctionNative);
	}

	public static async createBidOrderTxWithWhitelist(
		appContract: AppContractEntity,
		auction: AppAuctionEntity,
		amount: BigNumber,
		isAuctionNative: boolean,
	) {
		if (!isAuctionNative) {
			await TransactionHelper.checkAllowance(auction.appTokenSupport.address, appContract.address, amount);
		}

		const enrichedContract = await DeprecatedContractStore.getInstance().getEnrichedContractByAddress(appContract.address);

		const { data, signature } = await AppAuction.getInstance().bidOnAuction(auction.id);

		assert(
			enrichedContract.ethersContract.is(IncarusWhitelistContract) ||
				enrichedContract.ethersContract.is(IncarusErc20WhitelistContract),
			TransactionHelper.createNotSupportedError(
				TransactionHelper.createBidOrderTxWithWhitelist.name,
				enrichedContract.ethersContract,
			),
		);

		return enrichedContract.ethersContract.createBidOrderTxWithWhitelist(
			auction.onchainId,
			amount,
			signature,
			data.uuid,
			data.deadline,
		);
	}

	public static async createBidOrderTxOnPublicAuction(
		appContract: AppContractEntity,
		auction: AppAuctionEntity,
		amount: BigNumber,
		isAuctionNative: boolean,
	) {
		if (!isAuctionNative) await TransactionHelper.checkAllowance(auction.appTokenSupport.address, appContract.address, amount);
		const enrichedAuctionContract = await DeprecatedContractStore.getInstance().getEnrichedContractByAddress(appContract.address);
		assert(
			enrichedAuctionContract.ethersContract.is(IncarusContract) || enrichedAuctionContract.ethersContract.is(IncarusErc20Contract),
			TransactionHelper.createNotSupportedError(
				TransactionHelper.createBidOrderTxOnPublicAuction.name,
				enrichedAuctionContract.ethersContract,
			),
		);

		return enrichedAuctionContract.ethersContract.createBidOrderTx(auction.onchainId, amount);
	}

	// Buy now auction
	public static async createBuyNowAuctionTx(
		contract: AppContractEntity,
		auction: AppAuctionEntity,
		amount: BigNumber,
		isAuctionWhitelisted: boolean,
		isAuctionNative: boolean,
	): Promise<ethers.PopulatedTransaction> {
		return isAuctionWhitelisted
			? await TransactionHelper.createBuyNowAuctionTxWithWhitelist(contract, auction, amount, isAuctionNative)
			: await TransactionHelper.createBuyNowPublicAuctionTx(contract, auction, amount, isAuctionNative);
	}

	public static async createBuyNowAuctionTxWithWhitelist(
		appContract: AppContractEntity,
		auction: AppAuctionEntity,
		amount: BigNumber,
		isAuctionNative: boolean,
	): Promise<ethers.PopulatedTransaction> {
		if (!isAuctionNative) await TransactionHelper.checkAllowance(auction.appTokenSupport.address, appContract.address, amount);

		const userAddress = WalletStore.getInstance().walletData.userAddress;
		if (!userAddress) throw new Error("userAddress is not defined");

		const enrichedContract = await DeprecatedContractStore.getInstance().getEnrichedContractByAddress(appContract.address);

		assert(
			enrichedContract.ethersContract.is(IncarusWhitelistContract) ||
				enrichedContract.ethersContract.is(IncarusErc20WhitelistContract),
			TransactionHelper.createNotSupportedError(
				TransactionHelper.createBuyNowAuctionTxWithWhitelist.name,
				enrichedContract.ethersContract,
			),
		);

		const { data, signature } = await AppAuction.getInstance().bidOnAuction(auction.id);
		return enrichedContract.ethersContract.createBuyNowTxWithWhitelist(
			auction.onchainId,
			amount,
			signature,
			data.uuid,
			data.deadline,
			userAddress,
		);
	}

	public static async createBuyNowPublicAuctionTx(
		appContract: AppContractEntity,
		auction: AppAuctionEntity,
		amount: BigNumber,
		isAuctionNative: boolean,
	): Promise<ethers.PopulatedTransaction> {
		if (!isAuctionNative) await TransactionHelper.checkAllowance(auction.appTokenSupport.address, appContract.address, amount);

		const userAddress = WalletStore.getInstance().walletData.userAddress;
		if (!userAddress) throw new Error("userAddress is not defined");

		const enrichedContract = await DeprecatedContractStore.getInstance().getEnrichedContractByAddress(appContract.address);

		assert(
			enrichedContract.ethersContract.is(IncarusErc20Contract) || enrichedContract.ethersContract.is(IncarusContract),
			TransactionHelper.createNotSupportedError(TransactionHelper.createBuyNowPublicAuctionTx.name, enrichedContract.ethersContract),
		);

		return enrichedContract.ethersContract.createExecuteBuyNowTx(auction.onchainId, amount, userAddress);
	}

	public static async createFinalizeAuctionTx(appContract: AppContractEntity, auctionId: number): Promise<ethers.PopulatedTransaction> {
		const enrichedContract = await DeprecatedContractStore.getInstance().getEnrichedContractByAddress(appContract.address);

		// prettier-ignore
		assert(
				enrichedContract.ethersContract.is(IncarusContract) ||
				enrichedContract.ethersContract.is(IncarusWhitelistContract) ||
				enrichedContract.ethersContract.is(IncarusErc20Contract) ||
				enrichedContract.ethersContract.is(IncarusErc20WhitelistContract),
			TransactionHelper.createNotSupportedError(TransactionHelper.createFinalizeAuctionTx.name, enrichedContract.ethersContract),
		);

		return enrichedContract.ethersContract.createFinalizeAuctionTx(auctionId);
	}

	// TODO check if feature is used because in whole codebase there is no usage of this function
	// Marketplace
	// public static async executeOrder(marketPlaceContractAddress: string, order: any, signature: string, recipientAddress: string) {
	// 	const splitSignature = ethers.utils.splitSignature(signature);

	// 	const marketplaceContract = await ContractStore.getInstance().getEthersContractByAddress(marketPlaceContractAddress);
	// 	const marketplaceContractFacade = MarketplaceContractFacade.build(marketplaceContract);

	// 	return marketplaceContractFacade.executeOrder(order, splitSignature, recipientAddress);
	// }

	private static createNotSupportedError(methodName: string, contract: EthersContract) {
		return new Error(`${methodName} is not supported for ${contract.abiType}`);
	}
}
