import { OrderByDirection } from "common/enums/OrderByDirection";
import { Entity } from "common/models";
import { BigNumberString } from "Services/BigNumber";

import { AppContractEntity } from "./appContract";
import { AppSaleEntity } from "./appSale";
import { AppTokenSupportEntity } from "./appTokenSupport";
import { AppUserEntity } from "./appUser";

export enum OrderType {
	SELL = "SELL",
	ASK = "ASK",
}

export enum OrderStatus {
	LIVE = "LIVE",
	BOUGHT = "BOUGHT",
	PENDING = "PENDING",
	CANCELLED = "CANCELLED",
	EXPIRED = "EXPIRED",
}

export enum EAssetClass {
	ETH,
	ERC20,
	ERC1155,
	ERC721,
}

export type Order = {
	sellerAddress: string;
	sellTokenAddress: string;
	sellTokenId: number;
	sellTokenAmount: BigNumberString;
	askTokenAddress: string;
	askTokenId: number;
	askTokenAmount: BigNumberString;
	startTime: number;
	expirationTime: number;
	salt: number;
	sellAssetClass: EAssetClass;
	askAssetClass: EAssetClass;
};

export type AppOrderEntity = Entity & {
	seller: AppUserEntity;
	buyer?: AppUserEntity | null;
	appSale?: AppSaleEntity | null;
	appTokenSupport: AppTokenSupportEntity;
	expirationTime: Date;
	startTime: Date;
	tokenSupportAmount: BigNumberString;
	orderHash: string;
	orderSignature: string;
	transactionHash: string;
	type: OrderType;
	salt: number;
	status: OrderStatus;
	order: Order;
	appContract: AppContractEntity;
};

export type AppOrderOrderBy = {
	appSale?: { appNft?: { tokenId?: OrderByDirection; owner?: { appWallet?: { userAddress?: OrderByDirection } } } };
	tokenSupportAmount?: OrderByDirection;
	expirationTime?: OrderByDirection;
	seller?: { appWallet?: { userAddress?: OrderByDirection } };
	startTime?: OrderByDirection;
};
