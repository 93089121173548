import { Component } from "react";

// Components
import I18n from "Components/Elements/I18n";
import Confirm from "Components/Elements/Modals/Confirm";
import PaymentModalHeader from "../Elements/PaymentModalHeader";
import SummaryModalComponent from "./Summary";
import AlertInfo, { EAlertVariants } from "Components/Elements/Alert";

// Entities
import { AppAuctionEntity, AuctionStatus } from "Entities/appAuction";
import { isAuction } from "Entities/appLastSale";

// Services
import BigNumber from "Services/BigNumber";

// Helpers
import TransactionToast from "common/helpers/TransactionToast";
import TransactionHelper from "common/helpers/transaction";

// Styles
import classes from "./classes.module.scss";
import { ethers } from "ethers";
import GasPriceAlert from "Components/Elements/Modals/PaymentModal/GasPriceAlert";
import WalletStore from "Stores/WalletStore";
import SettleAuctionModalStore from "Stores/Modals/SettleAuctionModalStore";
import { EModal } from "Stores/Modals/AModalStore";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import { AppNftEntity } from "Entities/appNft";
import DeprecatedContractStore from "Stores/ContractStores/DeprecatedContractStore";

export type ISettleAuctionModalProps = {
	nft: AppNftEntity;
	token: AppTokenSupportEntity;
	auction: AppAuctionEntity;
};

type IProps = {};
type IState = {
	isOpen: boolean;
	paymentInfo?: ISettleAuctionModalProps;
	platformFee: number;
	creatorRoyalties: number;
	winningBid: string;
	gasPrice: BigNumber;
	tx?: ethers.PopulatedTransaction;
};

export default class SettleAuctionModal extends Component<IProps, IState> {
	private removeOnModalChange = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			isOpen: SettleAuctionModalStore.getInstance().isOpen(EModal.SETTLE_AUCTION),
			paymentInfo: SettleAuctionModalStore.getInstance().getProps(),
			winningBid: "",
			platformFee: 0,
			creatorRoyalties: 0,
			gasPrice: BigNumber.from("0"),
		};
		this.updateModalState = this.updateModalState.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.estimateGasPrice = this.estimateGasPrice.bind(this);
		this.settleAuction = this.settleAuction.bind(this);
		this.updateState = this.updateState.bind(this);
	}
	override render() {
		return (
			<Confirm
				isOpen={this.state.isOpen}
				onClose={this.closeModal}
				closeBtn
				onAccept={this.settleAuction}
				header={<span>{I18n.translate("modals.settle_auction.title")}</span>}
				confirmText={I18n.translate("modals.settle_auction.accept_button")}>
				<div className={classes["root"]}>
					<PaymentModalHeader nft={this.state.paymentInfo?.nft || null} />
					<SummaryModalComponent
						winningBid={BigNumber.from(this.state.winningBid)}
						user={this.getHighestBid()?.bidder}
						token={this.state.paymentInfo?.token}
					/>
					<AlertInfo variant={EAlertVariants.IMPORTANT}>
						<I18n map="modals.settle_auction.info" />
					</AlertInfo>
					<GasPriceAlert gasPrice={this.state.gasPrice} />
				</div>
			</Confirm>
		);
	}

	public override componentDidMount() {
		this.removeOnModalChange = SettleAuctionModalStore.getInstance().onChange(this.updateModalState);
		this.setWinningBid();
	}

	public override componentWillUnmount() {
		this.removeOnModalChange();
	}

	private getLastAuction(): AppAuctionEntity | undefined {
		const auction = this.state.paymentInfo?.auction;
		if (auction) return auction;

		const lastSale = this.state.paymentInfo?.nft?.appLastSale;
		if (!lastSale) return;
		return isAuction(lastSale) ? lastSale?.appAuction : undefined;
	}

	private async estimateGasPrice() {
		const lastAuction = this.getLastAuction();
		if (!lastAuction || lastAuction.status !== AuctionStatus.FINALIZING) return;
		const onchainId = lastAuction.onchainId;
		const auctionContractEntity = lastAuction.appContract;
		if (!auctionContractEntity) return;

		const tx = await TransactionHelper.createFinalizeAuctionTx(auctionContractEntity, onchainId);
		if (tx) {
			const enrichedAuctionContract = await DeprecatedContractStore.getInstance().getEnrichedContractByAddress(
				auctionContractEntity.address,
			);
			const gasPrice = await enrichedAuctionContract.ethersContract.estimateGasPrice(tx);
			this.setState({ tx, gasPrice });
		}
	}

	private settleAuction() {
		TransactionToast.processTransaction(() => WalletStore.getInstance().sendTransaction(this.state.tx));
		this.closeModal();
	}

	private closeModal() {
		SettleAuctionModalStore.getInstance().closeModal();
	}

	private updateModalState(modalsOpenedState: { [type: string]: boolean }, paymentInfo: ISettleAuctionModalProps) {
		this.setState({ isOpen: modalsOpenedState[EModal.SETTLE_AUCTION] ?? false, paymentInfo }, this.updateState);
	}

	private updateState() {
		this.estimateGasPrice();
		this.setWinningBid();
	}

	private setWinningBid() {
		const winningBid = this.getWinningBid();
		if (!winningBid) return;

		this.setState({ winningBid });
	}

	private getWinningBid() {
		return this.getHighestBid()?.amount;
	}

	private getHighestBid() {
		const lastAuction = this.getLastAuction();
		return lastAuction?.highestBid;
	}
}
