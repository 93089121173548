import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactComponent as GlassIcon } from "assets/images/icons/glass.svg";
import classes from "./classes.module.scss";
import IconButton from "../Buttons/IconButton";

export type IProps = {
	value: string;
	placeholder?: string;
	onChange?: (value: string) => void;
	onClear?: () => void;
};

type IState = {};

export default class SearchBar extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<GlassIcon />
				<div className={classes["input-container"]}>
					<input
						type="text"
						value={this.props.value}
						onChange={(e) => this.props.onChange?.(e.currentTarget.value ?? "")}
						placeholder={this.props.placeholder}
					/>
					<div className="cross">
						{this.props.value.length > 0 && <IconButton size="small" icon={<XMarkIcon />} onClick={this.props.onClear} />}
					</div>
				</div>
			</div>
		);
	}
}
