import BaseApiService from "Api/BaseApiService";
import ConfigStore from "Stores/ConfigStore";

import { AppCollectionEntity } from "Entities/appCollection";
import PaginationHelper, { PaginatedResult, PaginationParam } from "common/pagination";
import { ECollectionStatus } from "common/enums/CollectionStatus";
import { NameSearchType } from "common/enums/NameSearchType";
import { EntityId } from "common/models";
import { CollectionDisplayIn } from "common/enums/CollectionDisplayIn";
import { AppCollectionConfigEntity } from "Entities/appCollectionConfig";
import { CollectionVisibility } from "common/enums/CollectionVisibility";

export type ICollectionsFilter = {
	collectionIds?: EntityId[];
	randomizeCollections?: boolean;
	userAddressesOwningRelatedNfts?: string[];
	name?: {
		searchType: NameSearchType;
		searchValue: string;
	};
	status?: ECollectionStatus;
	isMintCardDisplayed?: boolean;
	displayIn?: CollectionDisplayIn;
	collectionVisibility?: CollectionVisibility;
};

export type CollectionDto = Omit<AppCollectionEntity, "appCollectionConfig"> & {
	appCollectionConfigs?: AppCollectionConfigEntity[];
};

export default class AppCollection extends BaseApiService {
	private static instance: AppCollection;

	private constructor() {
		super();
		AppCollection.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-collections");
	}

	public static getInstance() {
		if (!AppCollection.instance) return new this();
		return AppCollection.instance;
	}

	public async getCollections({
		pagination,
		filter,
	}: {
		pagination?: PaginationParam;
		filter?: ICollectionsFilter;
	}): Promise<PaginatedResult<AppCollectionEntity>> {
		const url = new URL(this.baseUrl);

		PaginationHelper.populateUrl(url, pagination);

		if (filter) {
			url.searchParams.append("filter", JSON.stringify(filter));
		}

		try {
			const collectionDtoResults = await this.getRequest<PaginatedResult<CollectionDto>>(url);

			return {
				...collectionDtoResults,
				data: collectionDtoResults.data.map((collectionDto) => this.defineConfig(collectionDto)),
			};
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getCollectionByAddress(address: string, pagination?: PaginationParam): Promise<AppCollectionEntity> {
		const url = new URL(this.baseUrl.concat("/").concat(address));

		PaginationHelper.populateUrl(url, pagination);

		try {
			const collectionDto = await this.getRequest<CollectionDto>(url);

			return this.defineConfig(collectionDto);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getUniqueHoldersNumber(address: string): Promise<{ uniqueHolders: number }> {
		const url = new URL(this.baseUrl.concat("/").concat(address).concat("/unique-holders"));

		try {
			return await this.getRequest<{ uniqueHolders: number }>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public defineConfig(collectionDto: CollectionDto): AppCollectionEntity {
		const config = collectionDto.appCollectionConfigs?.[0];
		if (!config) {
			console.error(`Collection config not found for collection with contract address: ${collectionDto.appContract?.address} on project: ${
				ConfigStore.getInstance().config.app
			}`)
		}

		const collectionEntity = { ...collectionDto };
		delete collectionEntity.appCollectionConfigs;

		return {
			...collectionEntity,
			appCollectionConfig: config ?? {
				name: "",
				description: "",
				picture: null,
				banner: null,
				visibility: CollectionVisibility.PUBLIC,
	
			},
		};
	}
}
