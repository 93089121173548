import classes from "./classes.module.scss";
import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import { ArrowPathIcon as RetryIcon } from "@heroicons/react/24/outline";
import I18n from "Components/Elements/I18n";
import Typography from "Components/Elements/Typography";
import { useCallback, useState } from "react";

export type IPurchaseConfirmationRetryButtonProps = {
	onClick?: () => Promise<void> | void;
}

export function PurchaseConfirmationRetryButton(props: IPurchaseConfirmationRetryButtonProps) {
	const [isLoading, setIsLoading] = useState(false);

	const onClick = useCallback(async () => {
		if (props.onClick) {
			setIsLoading(true);
			await props.onClick();
			setIsLoading(false);
		}
	}, [props]);

	return (
		<Button variant={EButtonVariant.CONTAINED} isLoading={isLoading} color={EButtonColor.PRIMARY} fullwidth onClick={onClick}>
			<div className={classes["root"]}>
				<div className={classes["retry-icon"]}>
					<RetryIcon />
				</div>
				<Typography size="large" weight="semibold">
					{I18n.translate("modals.confirmation.purchase.failure.retry")}
				</Typography>
			</div>
		</Button>
	);
}
