import { Component } from "react";
import TransactionToast from "common/helpers/TransactionToast";
import I18n from "Components/Elements/I18n";
import Typography from "Components/Elements/Typography";
import { ethers } from "ethers";
import BigNumber from "Services/BigNumber";
import WalletStore from "Stores/WalletStore";
import GasPriceAlert from "Components/Elements/Modals/PaymentModal/GasPriceAlert";
import CancelOfferModalStore from "Stores/Modals/CancelOfferModalStore";
import { EModal } from "Stores/Modals/AModalStore";
import { AppOrderEntity } from "Entities/appOrder";
import Modal from "Components/Elements/Modals";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import classes from "./classes.module.scss";
import MarketplaceContractStore from "Stores/ContractStores/MarketplaceContractStore";

export type ICancelOfferModalProps = {
	order: AppOrderEntity;
};

type IProps = {};
type IState = {
	isOpen: boolean;
	paymentInfo?: ICancelOfferModalProps;
	royalties?: number;
	platformFees?: number;
	gasPrice: BigNumber;
	tx?: ethers.PopulatedTransaction;
};

export default class CancelOfferModal extends Component<IProps, IState> {
	private removeOnModalChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			isOpen: CancelOfferModalStore.getInstance().isOpen(EModal.CANCEL_OFFER),
			paymentInfo: CancelOfferModalStore.getInstance().getProps(),
			gasPrice: BigNumber.from("0"),
		};
		this.updateModalState = this.updateModalState.bind(this);
		this.estimateGasPrice = this.estimateGasPrice.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.cancelOffer = this.cancelOffer.bind(this);
	}

	override render() {
		return (
			<Modal
				closeBtn
				isOpen={this.state.isOpen}
				onClose={this.closeModal}
				header={<span>{I18n.translate("modals.cancel_offer.title")}</span>}
				footer={this.footer()}>
				<div>
					<Typography type="p" color="neutral" size="small">
						<I18n map="modals.cancel_offer.cancel_offer" />
					</Typography>
				</div>
				<GasPriceAlert gasPrice={this.state.gasPrice} />
			</Modal>
		);
	}

	public override async componentDidMount() {
		this.removeOnModalChange = CancelOfferModalStore.getInstance().onChange(this.updateModalState);
	}

	public override componentWillUnmount() {
		this.removeOnModalChange();
	}

	private async estimateGasPrice() {
		const offer = this.state.paymentInfo?.order;
		if (!offer) return;

		const enrichedMarketplaceContract = await MarketplaceContractStore.getInstance().getEnrichedMarketplaceContract();
		const tx = enrichedMarketplaceContract.contractAdapter.canUse("createCancelOrderTx")
			? await enrichedMarketplaceContract.contractAdapter.createCancelOrderTx([offer.order])
			: undefined;
		if (!tx) return;

		if (enrichedMarketplaceContract.contractAdapter.canUse("estimateGasPrice")) {
			const gasPrice = await enrichedMarketplaceContract.contractAdapter.estimateGasPrice(tx);
			this.setState({ tx, gasPrice });
		}
	}

	private closeModal() {
		CancelOfferModalStore.getInstance().closeModal();
	}

	private async updateModalState(modalsOpenedState: { [type: string]: boolean }, paymentInfo: ICancelOfferModalProps) {
		this.setState(
			{
				isOpen: modalsOpenedState[EModal.CANCEL_OFFER] ?? false,
				paymentInfo,
			},
			this.estimateGasPrice,
		);
	}

	private footer(): JSX.Element {
		return (
			<div className={classes["buttons-container"]}>
				<Button
					variant={EButtonVariant.OUTLINED}
					color={EButtonColor.DESTRUCTIVE}
					size={EButtonSize.MEDIUM}
					onClick={this.cancelOffer}
					fullwidth>
					<I18n map="modals.cancel_offer.action" />
				</Button>
				<Button variant={EButtonVariant.OUTLINED} size={EButtonSize.MEDIUM} onClick={this.closeModal} fullwidth>
					<I18n map="common.cancel" />
				</Button>
			</div>
		);
	}

	private cancelOffer() {
		TransactionToast.processTransaction(() => WalletStore.getInstance().sendTransaction(this.state.tx));
		this.closeModal();
	}
}
