import React from "react";

import WalletOptionButton from "Components/Elements/Buttons/WalletOptionButton";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modals";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import WalletBalanceStore from "Stores/WalletBalanceStore";

import classes from "./classes.module.scss";
import ChooseCryptoModalStore from "Stores/Modals/ChooseCryptoModalStore";
import { EModal } from "Stores/Modals/AModalStore";
import AmountToSendModalStore from "Stores/Modals/AmountToSendModalStore";

export type IChooseCryptoModalProps = {};

type IProps = {};

type IState = {
	isOpen: boolean;
	paymentInfo?: IChooseCryptoModalProps;
};

export default class ChooseTokenModal extends React.Component<IProps, IState> {
	private removeOnModalChange = () => {};

	constructor(props: IProps) {
		super(props);

		this.state = {
			isOpen: ChooseCryptoModalStore.getInstance().isOpen(EModal.CHOOSE_CRYPTO),
			paymentInfo: ChooseCryptoModalStore.getInstance().getProps(),
		};

		this.close = this.close.bind(this);
		this.openSendCryptoModal = this.openSendCryptoModal.bind(this);
		this.updateModalState = this.updateModalState.bind(this);
	}

	override render(): JSX.Element | null {
		return (
			<Modal isOpen={this.state.isOpen} header={I18n.translate("modals.send.title")} closeBtn onClose={this.close}>
				<div className={classes["column"]}>
					{Object.entries(WalletBalanceStore.getInstance().balances).map(([key, balance]) => {
						if (balance.token.protocol !== "NATIVE" && balance.balance.isZero()) {
							return null;
						}
						return (
							<WalletOptionButton key={key} token={balance.token} onClick={() => this.openSendCryptoModal(balance.token)} />
						);
					})}
				</div>
			</Modal>
		);
	}
	public override componentDidMount() {
		this.removeOnModalChange = ChooseCryptoModalStore.getInstance().onChange(this.updateModalState);
		this.updateModalState(ChooseCryptoModalStore.getInstance().modalsOpenedState, ChooseCryptoModalStore.getInstance().getProps() ?? {});
	}

	public override componentWillUnmount() {
		this.removeOnModalChange();
	}

	private close() {
		ChooseCryptoModalStore.getInstance().closeModal();
	}

	private updateModalState(modalsOpenedState: { [type: string]: boolean }, paymentInfo: IChooseCryptoModalProps) {
		this.setState({ isOpen: modalsOpenedState[EModal.CHOOSE_CRYPTO] ?? false, paymentInfo });
	}

	private openSendCryptoModal(token: AppTokenSupportEntity) {
		this.close();
		AmountToSendModalStore.getInstance().openModal({ token });
	}
}
