import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import React from "react";
import IsConnected from "../IsConnected";

import UserStore from "Stores/UserStore";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ArrowLeftOnRectangleOutlinedIcon from "Components/Elements/Icons/ArrowLeftOnRectangleOutlinedIcon";

type IProps = {
	onClick?: () => void;
	navigate: NavigateFunction;
};

class Component extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);

		this.disconnect = this.disconnect.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<IsConnected>
				<Button
					variant={EButtonVariant.OUTLINED}
					color={EButtonColor.NEUTRAL}
					size={EButtonSize.MEDIUM}
					onClick={this.disconnect}
					startIcon={<ArrowLeftOnRectangleOutlinedIcon />}>
					{I18n.translate("common.logout")}
				</Button>
			</IsConnected>
		);
	}

	private async disconnect() {
		if (this.props.onClick) this.props.onClick();
		await UserStore.getInstance().signOut();
	}
}

export default function LogoutButton(props: Omit<IProps, "navigate">) {
	const navigate = useNavigate();
	return <Component {...props} navigate={navigate} />;
}
