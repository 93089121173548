import { AppContractEntity, ContractType } from "Entities/appContract";
import assert from "assert";
import ContractFactory from "Services/Contracts/Ethereum/ContractFactory";
import AppContract from "Api/Back/AppContract";
import BaseRoyaltiesContract from "Services/Contracts/Ethereum/royalties/core/BaseRoyaltiesContract";
import RoyaltiesContractAdapterFactory from "Services/Contracts/Ethereum/royalties/core/RoyaltiesContractAdapterFactory";
import WalletStore from "Stores/WalletStore";

// Don't export this interface
interface EnrichedContract {
	contractEntity: AppContractEntity;
	contractAdapter: ReturnType<typeof RoyaltiesContractAdapterFactory.build>;
}

export default class RoyaltiesContractStore {
	private static instance: RoyaltiesContractStore;

	private pendingRequests: Map<string, Promise<EnrichedContract>> = new Map();
	private enrichedContract: EnrichedContract | null = null;

	public constructor() {
		WalletStore.getInstance().onChange((walletData) => {
			this.enrichedContract = null;
		});
	}

	public static getInstance(): RoyaltiesContractStore {
		if (!RoyaltiesContractStore.instance) {
			RoyaltiesContractStore.instance = new RoyaltiesContractStore();
		}

		return RoyaltiesContractStore.instance;
	}

	public async getEnrichedRoyaltiesContract(): Promise<EnrichedContract> {
		if (this.enrichedContract) {
			return this.enrichedContract;
		}

		if (this.pendingRequests.has(ContractType.MARKETPLACE)) {
			return await this.pendingRequests.get(ContractType.MARKETPLACE)!;
		}

		const requestPromise = (async () => {
			const addedEnrichedContract = await this.addContract();
			this.pendingRequests.delete(ContractType.ROYALTIES);
			return addedEnrichedContract;
		})();

		this.pendingRequests.set(ContractType.ROYALTIES, requestPromise);

		return await requestPromise;
	}

	private async addContract(): Promise<EnrichedContract> {
		const appContractEntities = await AppContract.getInstance().getContracts({ filter: { type: ContractType.ROYALTIES } });

		// When we get a contract by type, we get an array of contracts, but we only need one so it should be unique as for
		// A project has only one contract of a type RoyaltiesStorage, Royalties, etc.
		const appContractEntity = appContractEntities[0];
		assert(appContractEntity, `Contract entity not found for contract type: ${ContractType.ROYALTIES}`);

		const appAbi = appContractEntity.appAbi;
		assert(appAbi, `ABI not found for address: ${appContractEntity.address}`);

		const ethersContract = await ContractFactory.getInstance().build({
			address: appContractEntity.address,
			abi: appAbi.abi,
			config: appContractEntity.config,
			contractType: appContractEntity.type,
			abiType: appAbi.type,
		});

		assert(ethersContract.is(BaseRoyaltiesContract), `Contract is not of type ${BaseRoyaltiesContract.name}`);
		const contractAdapter = RoyaltiesContractAdapterFactory.build(ethersContract);

		const enrichedContract = { contractEntity: appContractEntity, contractAdapter };
		this.enrichedContract = enrichedContract;

		return enrichedContract;
	}
}
