import assert from "assert";
import BaseErc721Contract from "./BaseErc721Contract";
import IErc721ContractAdapter from "./IErc721ContractAdapter";
import IErc721ContractFunctions from "./IErc721ContractFunctions";

export default abstract class BaseErc721ContractAdapter<T extends BaseErc721Contract> implements IErc721ContractAdapter {
	public constructor(public contract: T, public readonly usableFunctions: Record<keyof IErc721ContractFunctions, boolean>) {}

	public canUse<K extends keyof IErc721ContractFunctions>(contractFunc: K): this is this & Pick<IErc721ContractFunctions, K> {
		return this.usableFunctions[contractFunc];
	}

	public useOrThrow<K extends keyof IErc721ContractFunctions>(
		contractFunc: K,
	): (...args: Parameters<IErc721ContractFunctions[K]>) => ReturnType<IErc721ContractFunctions[K]> {
		if (this.canUse(contractFunc)) {
			const func = this[contractFunc];
			assert(typeof func === "function", `Function ${contractFunc} not supported by Erc721 contract ${this.contract.address}`);

			return (this[contractFunc] as Function).bind(this);
		}

		throw new Error("Method not implemented.");
	}
}
