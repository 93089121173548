import React from "react";
import classes from "./classes.module.scss";

import CopyClipboard from "../../CopyClipboard";
import AddressShort from "../../AddressShort";
import Typography from "../../Typography";

import { ReactComponent as CopyIcon } from "assets/images/icons/copy.svg";
import { ReactComponent as CheckIcon } from "assets/images/icons/check.svg";

type IProps = {
	address: string;
	size: "medium" | "large";
};

type IState = {
	copied: boolean;
};

export default class AddressButton extends React.Component<IProps, IState> {
	static defaultProps = {
		size: "medium",
	};

	constructor(props: IProps) {
		super(props);
		this.state = {
			copied: false,
		};
		this.showAndHideCopyCheckMark = this.showAndHideCopyCheckMark.bind(this);
	}

	public override render(): JSX.Element {
		const { address } = this.props;
		return (
			<div className={classes["root"]} data-size={this.props.size}>
				<div className={classes["content"]} data-size={this.props.size} >
					<CopyClipboard value={address} onClick={this.showAndHideCopyCheckMark} openToaster={false}>
						{this.props.size === "large" ? (
							<Typography type="p" size="small" weight="medium">
								{address}
							</Typography>
						) : (
							<AddressShort text={address} type="p" size="xsmall" weight="medium" />
						)}
						<div className={classes["icon"]}>
							{this.state.copied ? <CheckIcon className={classes["check-icon"]} /> : <CopyIcon />}
						</div>
					</CopyClipboard>
				</div>
			</div>
		);
	}

	private showAndHideCopyCheckMark() {
		this.toggleCopiedState();
		setTimeout(() => {
			this.toggleCopiedState();
		}, 4000);
	}

	private toggleCopiedState() {
		this.setState((prevState) => ({
			copied: !prevState.copied,
		}));
	}
}
