import Cookies from "js-cookie";

export default class CookieStorageService {
	private static instance: CookieStorageService;

	private constructor() {
		CookieStorageService.instance = this;
	}

	public static getInstance(reset?: boolean) {
		if (!CookieStorageService.instance || reset) return new this();
		return CookieStorageService.instance;
	}

	public items = {
		accessToken: {
			get: (userAddress: string | null) => this.getValue<string>(`${userAddress}_accessToken`),
			set: (userAddress: string | null, item: string) => this.setValue(`${userAddress}_accessToken`, item),
			delete: (userAddress: string | null) => this.delete(`${userAddress}_accessToken`),
			deleteAll: () => {
				getAllAccessTokenCookies().forEach((cookieName: string) => {
					Cookies.remove(cookieName);
				});
			},
		},
		refreshToken: {
			get: (userAddress: string | null) => this.getValue<string>(`${userAddress}_refreshToken`),
			set: (userAddress: string | null, item: string) => this.setValue(`${userAddress}_refreshToken`, item),
			delete: (userAddress: string | null) => this.delete(`${userAddress}_refreshToken`),
			deleteAll: () => {
				getAllRefreshTokenCookies().forEach((cookieName: string) => {
					Cookies.remove(cookieName);
				});
			},
		},
		userAddress: {
			get: () => this.getValue<string>("userAddress"),
			set: (item: string) => this.setValue("userAddress", item),
			delete: () => this.delete("userAddress"),
		},
	};

	private getValue<T>(key: string): T | null {
		const item = Cookies.get(key);
		if (!item) {
			return null;
		}

		const isArrayOrObject = item.match(/[{[]/);

		return (isArrayOrObject ? JSON.parse(item) : item) as T;
	}

	private setValue<T>(key: string, item: T) {
		Cookies.set(key, typeof item === "string" ? item : JSON.stringify(item));
	}

	private delete(key: string) {
		Cookies.remove(key);
	}
}

function getAllAccessTokenCookies(): string[] {
	// Get all cookies
	const allCookies: string[] = document.cookie.split(";");

	// Array to store matching cookie names
	const matchingCookies: string[] = [];

	// Iterate over each cookie to check if it matches the pattern *_accessToken
	allCookies.forEach((cookie: string) => {
		const cookieParts: string[] = cookie.trim().split("=");

		// Ensure we have a valid name before proceeding
		if (cookieParts.length > 0) {
			const name: string = cookieParts[0]!;

			// Check if the cookie name matches the pattern
			if (name.endsWith("_accessToken")) {
				matchingCookies.push(name);
			}
		}
	});

	return matchingCookies;
}

function getAllRefreshTokenCookies(): string[] {
	// Get all cookies
	const allCookies: string[] = document.cookie.split(";");

	// Array to store matching cookie names
	const matchingCookies: string[] = [];

	// Iterate over each cookie to check if it matches the pattern *_refreshToken
	allCookies.forEach((cookie: string) => {
		const cookieParts: string[] = cookie.trim().split("=");

		// Ensure we have a valid name before proceeding
		if (cookieParts.length > 0) {
			const name: string = cookieParts[0]!;

			// Check if the cookie name matches the pattern
			if (name.endsWith("_refreshToken")) {
				matchingCookies.push(name);
			}
		}
	});

	return matchingCookies;
}
