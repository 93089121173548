import { EAssetClass, Order } from "Entities/appOrder";
import { ethers, Signature } from "ethers";
import BaseMarketplaceContract from "./core/BaseMarketplaceContract";

export default class MarketplaceV2Contract extends BaseMarketplaceContract {
	/**
	 * Execute an order
	 * @param order Order - order to execute
	 * @param signature string - signature of the order (orderSignature in database)
	 * @param recipientAddress string - address of the recipient of the order
	 **/
	public async executeOrder(order: any, signature: Signature, recipientAddress: string) {
		return this.contract["executeOrder"](order, signature, recipientAddress, { value: order.askTokenAmount });
	}

	public async createExecuteOrderTx(order: any, signature: Signature, recipientAddress: string): Promise<ethers.PopulatedTransaction> {
		// If the order is in Native currency, we need to add the value to the transaction
		if (order.askAssetClass === EAssetClass.ETH)
			return this.contract.populateTransaction["executeOrder"]!(order, signature, recipientAddress, { value: order.askTokenAmount });
		return this.contract.populateTransaction["executeOrder"]!(order, signature, recipientAddress);
	}

	/**
	 * Execute batch of orders
	 * @param orders Order[] - orders to execute
	 * @param signatures string[] - signatures of the orders (orderSignature in database)
	 * @param recipientAddress string - address of the recipient of the order
	 **/
	public async batchExecuteOrders(orders: Order[], signatures: string[], recipientAddress: string) {
		return this.contract["batchExecuteOrders"](orders, signatures, recipientAddress);
	}

	/**
	 * Cancel an order
	 * @param orders Order[] - orders to cancel
	 **/
	public async cancelOrder(orders: Order[]) {
		return this.contract["cancelOrder"](orders);
	}

	public async createCancelOrderTx(orders: Order[]): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["cancelOrder"]!(orders);
	}
}
