import EventService from "Services/EventEmitter";
import DefaultConfig from "Configs/Config/default.json";
import configHelper from "common/helpers/config";
import assert from "assert";

type BucketConfigsUrls = {
	project: string;
	urls: string[];
};

export default class ConfigStore {
	private static instance: ConfigStore;
	private readonly event = new EventService();
	private _configPath = "/localhost";
	private _config = DefaultConfig;

	private constructor() {
		ConfigStore.instance = this;
	}

	public static getInstance() {
		if (!ConfigStore.instance) return new this();
		return ConfigStore.instance;
	}

	public get config() {
		return this._config;
	}

	public set config(config: ConfigStore["_config"]) {
		this._config = config;
	}

	public get configPath() {
		return this._configPath;
	}

	public set configPath(path: string) {
		this._configPath = path;
	}

	public getConfigFilePath() {
		const environment = process.env["REACT_APP_ENV_NAME"] || "development";
		return `configs/${environment}.json`;
	}

	public onChange(callback: (config: ConfigStore["_config"]) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	private findProjectByHostname(configs: BucketConfigsUrls[], hostname: string) {
		for (const config of configs) {
			if (config.urls.includes(hostname)) {
				return config.project;
			}
		}
		return null;
	}

	public async init() {
		const reactAppConfigBaseUrl = process.env["REACT_APP_CONFIG_BASE_URL"];

		if (!reactAppConfigBaseUrl) {
			console.warn("REACT_APP_CONFIG_BASE_URL is not defined, using default config");

			if (process.env["REACT_APP_ENV_NAME"] !== "development") {
				assert(reactAppConfigBaseUrl, "REACT_APP_CONFIG_BASE_URL is not defined");
			}

			await this.load();
			return;
		}

		const bucketUrlsPath = `${reactAppConfigBaseUrl}/urls/config.json`;
		const reactAppConfigBaseUrlResponse = await fetch(bucketUrlsPath);
		const { configs: projectConfigsUrls } = await reactAppConfigBaseUrlResponse.json();

		const project = this.findProjectByHostname(projectConfigsUrls, window.location.hostname);

		const bucketProjectPath = `${reactAppConfigBaseUrl}${project}`;
		this._configPath = bucketProjectPath;

		await this.load();
	}

	public async load(): Promise<void> {
		try {
			this.config = await configHelper.fetchJson(this.getConfigFilePath());
			this.fetchHtmlMetadata();
			this.event.emit("change", this.config);
		} catch (e) {
			console.error(e);
		}
	}

	private fetchHtmlMetadata() {
		configHelper.addMetaElement("description", this.config.head.description);
		configHelper.addTitleElement(this.config.head.title);
		configHelper.addScriptElement(this.config.googleMapsApi.url.replace("{{apiKey}}", this.config.googleMapsApi.apiKey));
	}
}
