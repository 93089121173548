import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "Components/Elements/ErrorComponent";

type IProps = {
	pathIntoDynamicallyRenderedComponentsFolder: string;
	props?: Record<string, unknown>;
};

export default function DynamicComponentLoader({ pathIntoDynamicallyRenderedComponentsFolder, props = {} }: IProps) {
	const [Component, setComponent] = useState<React.ComponentType<any> | null>(null);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		import("Components/DynamicallyRenderedComponents/".concat(pathIntoDynamicallyRenderedComponentsFolder))
			.then((module) => setComponent(() => module.default))
			.catch((err) => setError(err));
	}, [pathIntoDynamicallyRenderedComponentsFolder]);

	if (error) {
		return <ErrorComponent error={`Error loading component: ${pathIntoDynamicallyRenderedComponentsFolder}`} />;
	}

	return (
		<ErrorBoundary fallback={<ErrorComponent error={`Error loading component: ${pathIntoDynamicallyRenderedComponentsFolder}`} />}>
			{Component ? <Component {...props} /> : <div>Loading...</div>}
		</ErrorBoundary>
	);
}
