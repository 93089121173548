import { Component } from "react";
import ReceiveCryptoModal from "./ReceiveCryptoModal";
import AmountToSendModal from "./SendCryptoModals/AmountToSendModal";
import ChooseTokenModal from "./SendCryptoModals/ChooseTokenModal";
import ConfirmPaymentModal from "./SendCryptoModals/ConfirmPaymentModal";
import CancelFixedPriceListingModal from "./CancelListingModal";
import SettleAuctionModal from "./SettleAuctionModal";
import CancelOfferModal from "./CancelOfferModal";
import CompleteMintModal from "./CompleteMintModal";
import { PassKYCModal } from "./PassKYCModal";
import SynapsModal from "./SynapsModal";
import ModuleStore from "Stores/ModuleStore";
import { ErrorBoundary } from "react-app-error-boundary";
import CompleteInvestModal from "./CompleteInvestModal";
import AddFundsModal2 from "./AddFundsModal2";
import ConnectWalletModal from "./ConnectWalletModal";

type IProps = Record<string, never>;

export default class AllModals extends Component<IProps> {
	override render() {
		return (
			<>
				<ErrorBoundary>
					<CompleteMintModal />
				</ErrorBoundary>

				<ErrorBoundary>
					<CancelOfferModal />
				</ErrorBoundary>

				<ErrorBoundary>
					<ChooseTokenModal />
				</ErrorBoundary>

				<ErrorBoundary>
					<AmountToSendModal />
				</ErrorBoundary>

				<ErrorBoundary>
					<ConfirmPaymentModal />
				</ErrorBoundary>

				<ErrorBoundary>
					<ReceiveCryptoModal />
				</ErrorBoundary>

				<ErrorBoundary>
					<CancelFixedPriceListingModal />
				</ErrorBoundary>

				<ErrorBoundary>
					<SettleAuctionModal />
				</ErrorBoundary>

				<ErrorBoundary>
					<CompleteInvestModal />
				</ErrorBoundary>

				<ErrorBoundary>
					<AddFundsModal2 />
				</ErrorBoundary>

				<ErrorBoundary>
					<ConnectWalletModal />
				</ErrorBoundary>

				{ModuleStore.getInstance().module.modules.KYC.enabled && (
					<>
						<ErrorBoundary>
							<PassKYCModal />
						</ErrorBoundary>

						<ErrorBoundary>
							<SynapsModal />
						</ErrorBoundary>
					</>
				)}
			</>
		);
	}
}
