import { SocketEvent } from "common/enums/SocketEvent";
import { AppAuctionEntity } from "Entities/appAuction";
import { AppNftEntity } from "Entities/appNft";
import { AppOrderEntity } from "Entities/appOrder";
import { AppUserEntity } from "Entities/appUser";
import { io, Socket } from "socket.io-client";
import ConfigStore from "Stores/ConfigStore";

export default class SocketService {
	private static instance: SocketService;

	private readonly socket: Socket | null = null;

	private constructor() {
		if (!ConfigStore.getInstance().config.socket.url) return;
		this.socket = io(ConfigStore.getInstance().config.socket.url, {
			extraHeaders: {
				project: ConfigStore.getInstance().config.app,
			},
		});
		SocketService.instance = this;
	}

	public static getInstance(reset?: boolean) {
		if (!SocketService.instance || reset) return new this();
		return SocketService.instance;
	}

	public onUpdatedNft(callback: ({ nftId }: { nftId: AppNftEntity["id"] }) => Promise<void>) {
		this.socket?.on(SocketEvent.UpdatedNft, callback);
	}

	public onUpdatedOffers(
		callback: ({ nftId, offerIds }: { nftId: AppNftEntity["id"]; offerIds: AppOrderEntity["id"][] }) => Promise<void>,
	) {
		this.socket?.on(SocketEvent.UpdatedOffers, callback);
	}

	public onUpdatedAuction(callback: ({ nftId, auctionId }: { nftId: number; auctionId: AppAuctionEntity["id"] }) => Promise<void>) {
		this.socket?.on(SocketEvent.UpdatedAuction, callback);
	}

	public onUpdatedUser(callback: ({ userId }: { userId: AppUserEntity["id"] }) => Promise<void>) {
		this.socket?.on(SocketEvent.UpdatedUser, callback);
	}
}
