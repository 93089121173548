import React from "react";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import IconButton from "Components/Elements/Buttons/IconButton";

type IProps = {
	onClick?: () => void;
};

export default class LanguageModeButton extends React.Component<IProps> {
	public override render(): JSX.Element {
		return <IconButton size="large" onClick={this.props.onClick} icon={this.getIcon()} />;
	}

	private getIcon() {
		return <GlobeAltIcon />;
	}
}
