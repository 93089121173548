import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import React from "react";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy.svg";
import classes from "./classes.module.scss";
import InputFieldMaterial from "Components/Materials/Inputs/InputFieldMaterial";
import { IError } from "Components/Elements/Form/Elements/BaseField";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";

type IProps = {
	onChange: ((event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement>) => void) | undefined;
	hasErrorInInput: (hasErrorInInput: boolean) => void;
};

export default function CodeInputField({ onChange, hasErrorInInput }: IProps) {
	const [verificationCode, setVerificationCode] = React.useState("");

	function onErrors(errors: IError[]) {
		if (errors.length > 0) {
			hasErrorInInput(true);
		} else {
			hasErrorInInput(false);
		}
	}

	async function pasteCode() {
		setVerificationCode("");
		const userInput = await navigator.clipboard.readText();
		setVerificationCode(userInput);
	}

	return (
		<div className={classes["root"]}>
			<div className={classes["input-field"]}>
				<InputFieldMaterial
					numbersOnly
					minFieldLength={{ args: [6] }}
					maxFieldLength={{ args: [6] }}
					value={verificationCode}
					name="VerificationCode"
					label={I18n.translate("modals.verification_code.subtitle")}
					onChange={onChange}
					onErrors={onErrors}
				/>
			</div>
			<IsModuleEnabled from={IsModuleEnabled.get().Modals.props.verificationModal.props.copyPasteButton}>
				<Button
					className={classes["paste-button"]}
					onClick={pasteCode}
					variant={EButtonVariant.OUTLINED}
					size={EButtonSize.MEDIUM}
					color={EButtonColor.PRIMARY}
					startIcon={<CopyIcon />}>
					<I18n map="buttons.paste" />
				</Button>
			</IsModuleEnabled>
		</div>
	);
}
