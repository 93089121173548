import AModalStore, { EModal } from "./AModalStore";
import { ICancelOfferModalProps as IProps } from "Components/Materials/Modals/CancelOfferModal";

export default class CancelOfferModalStore extends AModalStore<IProps> {
	private static ctx: CancelOfferModalStore;

	private removeOnWalletBalanceChange = () => {};

	public constructor() {
		super();
		CancelOfferModalStore.ctx = this;
	}

	public static getInstance() {
		if (!CancelOfferModalStore.ctx) return new this();
		return CancelOfferModalStore.ctx;
	}

	public openModal(props: IProps) {
		this.close();
		this.open(EModal.CANCEL_OFFER, props);
	}

	public closeModal() {
		this.close();
		this.removeOnWalletBalanceChange();
	}
}
