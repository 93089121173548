import BaseApiService from "Api/BaseApiService";
import PaginationHelper, { PaginatedResult, PaginationParam } from "common/pagination";
import ConfigStore from "Stores/ConfigStore";

import { AppOrderEntity, OrderStatus, OrderType } from "Entities/appOrder";
import { AppUserEntity } from "Entities/appUser";
import AppCollection from "../AppCollection";

export type GetUsersQuery = {
	email?: string;
	userName?: string;
};

export type UpdateUserParams = {
	firstName?: string;
	lastName?: string;
	userName?: string;
	displayName?: string;
	email?: string;
	description?: string;
	picture?: File | string;
	twitter?: string;
	discord?: string;
	telegram?: string;
	linkedin?: string;
	instagram?: string;
	facebook?: string;
	medium?: string;
	website?: string;
	isUpdatingPicture?: boolean;
	displayRegistrationModal?: boolean;
};

export default class AppUser extends BaseApiService {
	private static instance: AppUser;

	private constructor() {
		super();
		AppUser.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-users");
	}

	public static getInstance() {
		if (!AppUser.instance) return new this();
		return AppUser.instance;
	}

	public async getUsers(filters: GetUsersQuery, pagination?: PaginationParam): Promise<PaginatedResult<AppUserEntity>> {
		const url = new URL(this.baseUrl);

		if (filters.email) {
			url.searchParams.append("email", filters.email);
		}
		if (filters.userName) {
			url.searchParams.append("userName", filters.userName);
		}
		try {
			return await this.getRequest<PaginatedResult<AppUserEntity>>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getUserByAddress(address: string): Promise<AppUserEntity> {
		const url = new URL(this.baseUrl.concat("/").concat(address));
		try {
			return await this.getRequest<AppUserEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async update(userAddress: string, params: UpdateUserParams): Promise<AppUserEntity> {
		const url = new URL(this.baseUrl.concat("/").concat(userAddress));
		try {
			const formData = new FormData();
			Object.entries(params).forEach(([k, v]) => {
				if (v !== undefined) {
					if (typeof v === "string" || v instanceof File) {
						formData.append(k, v);
						return;
					}
					formData.append(k, JSON.stringify(v));
				}
			});

			return await this.patchFormDataRequest<AppUserEntity>(url, formData);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async updateEmail(userAddress: string, email: string): Promise<AppUserEntity> {
		const url = new URL(this.baseUrl.concat("/").concat(userAddress).concat("/email"));
		try {
			return await this.postRequest<AppUserEntity>(url, { email });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getOrdersByUserAddress(
		userAddress: string,
		statuses?: OrderStatus[],
		type?: OrderType,
		pagination?: PaginationParam,
	): Promise<PaginatedResult<AppOrderEntity>> {
		const url = new URL(this.baseUrl.concat("/").concat(userAddress).concat("/app-orders"));

		PaginationHelper.populateUrl(url, pagination);

		if (type) {
			url.searchParams.append("type", type);
		}

		if (statuses) {
			url.searchParams.append("statuses", JSON.stringify(statuses));
		}

		try {
			const response = await this.getRequest<PaginatedResult<AppOrderEntity>>(url);
			return {
				...response,
				data: this.enrichOrdersWithCollectionConfig(response.data),
			};
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	private enrichOrdersWithCollectionConfig(orders: AppOrderEntity[]): AppOrderEntity[] {
		return orders.map((order) => {
			if (!order.appSale?.appNft) return order;

			return {
				...order,
				appSale: {
					...order.appSale,
					appNft: {
						...order.appSale.appNft,
						appCollection: AppCollection.getInstance().defineConfig(order.appSale.appNft.appCollection),
					},
				},
			};
		});
	}
}
