import React, { ReactNode } from "react";

import I18n from "Components/Elements/I18n";

import classes from "./classes.module.scss";
import classNames from "classnames";

export enum EChooseButtonSelected {
	LEFT = "left",
	RIGHT = "right",
}

type IProps = {
	startIconLeft: ReactNode;
	startIconRight: ReactNode;
	leftText?: string;
	rightText?: string;
	onChange?: (selected: EChooseButtonSelected) => void;
	disabledLeftButton?: boolean;
	disabledRightButton?: boolean;
	unDisplayRightButton?: boolean;
	unDisplayLeftButton?: boolean;
	selected: EChooseButtonSelected;
};

type IState = {};

export default class SelectorBar extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.onChooseLeft = this.onChooseLeft.bind(this);
		this.onChooseRight = this.onChooseRight.bind(this);
	}

	public override render(): JSX.Element {
		const { startIconLeft, selected, startIconRight, leftText, rightText } = this.props;
		return (
			<div className={classes["root"]}>
				{!this.props.unDisplayLeftButton && (
					<button
						onClick={this.onChooseLeft}
						disabled={this.props.disabledLeftButton}
						className={classNames(classes["button"], {
							[classes["selected"]!]: selected === "left",
						})}>
						<>
							<div className={classes["start-icon"]}>{startIconLeft}</div>
							<I18n map={leftText ?? "common.crypto"} />
						</>
					</button>
				)}
				{!this.props.unDisplayRightButton && (
					<button
						onClick={this.onChooseRight}
						disabled={this.props.disabledRightButton}
						className={classNames(classes["button"], {
							[classes["selected"]!]: selected === "right",
						})}>
						<>
							<div className={classes["start-icon"]}>{startIconRight}</div>
							<I18n map={rightText ?? "common.credit_card"} />
						</>
					</button>
				)}
			</div>
		);
	}

	private onChooseLeft() {
		this.props.onChange?.(EChooseButtonSelected.LEFT);
	}
	private onChooseRight() {
		this.props.onChange?.(EChooseButtonSelected.RIGHT);
	}
}
