import ConfigStore from "Stores/ConfigStore";

function addLinkElement(rel: string, href: string) {
	try {
		const configPath = ConfigStore.getInstance().configPath;
		const linkElement = window.document.createElement("link");
		linkElement.setAttribute("rel", rel);
		linkElement.setAttribute("href", configPath.concat("/", href));
		document.head.appendChild(linkElement);
	} catch (error) {
		console.error(error);
	}
}

function addScriptElement(src: string) {
	try {
		const srcElement = window.document.createElement("script");
		srcElement.setAttribute("src", src);
		srcElement.setAttribute("defer", "");
		document.head.appendChild(srcElement);
	} catch (error) {
		console.error(error);
	}
}

function addMetaElement(name: string, content: string) {
	const metaElement = window.document.createElement("meta");
	metaElement.setAttribute("name", name);
	metaElement.setAttribute("content", content);
	document.head.appendChild(metaElement);
}

function addTitleElement(title: string) {
	const titleElement = window.document.createElement("title");
	titleElement.textContent = title;
	document.head.appendChild(titleElement);
}

function getSrc(path: string) {
	try {
		const configPath = ConfigStore.getInstance().configPath;
		return `${configPath}/${path}`;
	} catch (error) {
		console.error(error);
		return path;
	}
}

function getDefaultAvatar() {
	return getSrc("assets/images/default/avatar.webp");
}

function getDefaultThumbnail() {
	return getSrc("assets/images/default/thumbnail.webp");
}

function getDefaultBanner() {
	return getSrc("assets/images/default/banner.webp");
}

function getPdfSrc(fileName: string, lang: string) {
	return getSrc(`assets/documents/${lang}/${fileName}.pdf`);
}

function getDocument(path: string, lang: string) {
	return getSrc(`assets/documents/${lang}` + path);
}

async function fetchJson(path: string): Promise<any> {
	try {
		const url = getSrc(path);

		return fetch(url).then((response) => {
			if (!response.ok) {
				throw new Error("Bad request");
			}
			return response.json();
		});
	} catch (error) {
		console.error(error);
		return Promise.resolve({});
	}
}

async function fetchSvg(path: string): Promise<string | null> {
	try {
		const url = getSrc(path);

		return fetch(url).then((response) => {
			if (!response.ok) {
				throw new Error("Bad request");
			}
			return response.text();
		});
	} catch (error) {
		console.error(error);
		return Promise.resolve("");
	}
}

const configHelper = {
	addLinkElement,
	addMetaElement,
	addTitleElement,
	getSrc,
	getDefaultAvatar,
	getDefaultThumbnail,
	getDefaultBanner,
	getDocument,
	getPdfSrc,
	addScriptElement,
	fetchJson,
	fetchSvg,
};

export default configHelper;
