import { Lang, Synaps } from "@synaps-io/verify-sdk";
import AppKYC from "Api/Back/AppKYC";
import I18nStore from "Stores/I18nStore";

export class SynapsService {
	private static instance: SynapsService;

	public static getInstance(): SynapsService {
		if (!SynapsService.instance) {
			SynapsService.instance = new this();
		}

		return SynapsService.instance;
	}

	public async init() {
		let sessionId = (await AppKYC.getInstance().getOrCreate()).sessionId;

		Synaps.init({
			lang: I18nStore.getInstance().lang as Lang,
			mode: "embed",
			onFinish: () => {
				AppKYC.getInstance().refreshVerificationStatus(sessionId);
			},
			sessionId,
			service: "individual",
		});
	}
}
