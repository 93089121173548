import React from "react";
import { AppUserEntity } from "Entities/appUser";

import classes from "./classes.module.scss";
import classNames from "classnames";
import UserStore from "Stores/UserStore";
import configHelper from "common/helpers/config";

type IProps = {
	onClick?: () => void;
	size?: "large" | "medium" | "small";
};

type IState = {
	user: AppUserEntity | null;
};

export default class ProfileButton extends React.Component<IProps, IState> {
	private readonly defaultAvatar = configHelper.getDefaultAvatar();
	private removeOnUseChange = () => {};
	constructor(props: IProps) {
		super(props);
		this.state = {
			user: UserStore.getInstance().getUser() ?? null,
		};
		this.updateUser = this.updateUser.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<div className={classNames([classes["root"], classes[this.props.size ?? "medium"]])} onClick={this.props.onClick}>
				<img src={this.getSrc()} alt="avatar" />
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnUseChange = UserStore.getInstance().onChange(this.updateUser);
	}

	public override componentWillUnmount() {
		this.removeOnUseChange();
	}

	private updateUser(user: AppUserEntity | null) {
		this.setState({ user });
	}

	private getSrc() {
		if (!this.state.user) return this.defaultAvatar;
		return this.state.user.picture?.url || this.defaultAvatar;
	}
}
