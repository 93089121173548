import BigNumber from "Services/BigNumber";
import { ethers } from "ethers";
import BaseAuctionContract from "./BaseAuctionContract";

export type ISetAuctionProps = {
	assetAddress: string;
	assetTokenId: number;
	biddingAssetAddress: string;
	reservePrice: BigNumber;
	buyNowPrice: BigNumber;
	auctionBiddingPeriod: number;
	auctionExpirationTimestamp: number;
	assetClass: number;
};

export default class IncarusErc20Contract extends BaseAuctionContract {
	public async setAuction({
		assetAddress,
		assetTokenId,
		biddingAssetAddress,
		reservePrice,
		buyNowPrice,
		auctionBiddingPeriod,
		auctionExpirationTimestamp,
		assetClass,
	}: ISetAuctionProps): Promise<ethers.providers.TransactionResponse> {
		return this.contract["setAuction"](
			assetAddress,
			assetTokenId,
			biddingAssetAddress,
			reservePrice.toString(10),
			buyNowPrice.toString(10),
			auctionBiddingPeriod,
			auctionExpirationTimestamp,
			assetClass,
		);
	}

	public get platformFee(): number {
		return Number(this.config?.["platformFee"]) || 0;
	}

	public async setBidOrder(auctionId: number, amount: BigNumber): Promise<ethers.providers.TransactionResponse> {
		return this.contract["setBidOrder"](auctionId, amount.toString(10));
	}

	public async createBidOrderTx(auctionId: number, amount: BigNumber): Promise<ethers.PopulatedTransaction> {
		return await this.contract.populateTransaction["setBidOrder"]!(auctionId, amount.toString(10));
	}

	public async cancelAuction(auctionId: number): Promise<ethers.providers.TransactionResponse> {
		return this.contract["cancelAuction"](auctionId);
	}

	public async executeBuyNow(auctionId: number, amount: BigNumber, buyerAddress: string): Promise<ethers.providers.TransactionResponse> {
		return this.contract["executeBuyNow"](auctionId, amount.toString(10), buyerAddress);
	}

	public async createExecuteBuyNowTx(auctionId: number, amount: BigNumber, buyerAddress: string): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["executeBuyNow"]!(auctionId, amount.toString(10), buyerAddress);
	}

	public async createFinalizeAuctionTx(auctionId: number): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["finalizeAuction"]!(auctionId);
	}

	public async finalizeAuction(auctionId: number): Promise<ethers.providers.TransactionResponse> {
		return this.contract["finalizeAuction"](auctionId);
	}

	public async updateAuctionBiddingPeriod(
		auctionId: number,
		auctionBiddingPeriod: number,
	): Promise<ethers.providers.TransactionResponse> {
		return this.contract["updateAuctionExpirationTimestamp"](auctionId, auctionBiddingPeriod);
	}
	public async updateAuctionExpirationTimestamp(
		auctionId: number,
		auctionExpirationTimestamp: number,
	): Promise<ethers.providers.TransactionResponse> {
		return this.contract["updateAuctionExpirationTimestamp"](auctionId, auctionExpirationTimestamp);
	}

	public async updateReservePriceAuction(auctionId: number, reservePrice: BigNumber): Promise<ethers.providers.TransactionResponse> {
		return this.contract["updateReservePriceAuction"](auctionId, reservePrice.toString(10));
	}
	public async updateAuctionBuyNowPrice(auctionId: number, buyNowPrice: BigNumber): Promise<ethers.providers.TransactionResponse> {
		return this.contract["updateAuctionBuyNowPrice"](auctionId, buyNowPrice.toString(10));
	}
}
