import BaseApiService from "Api/BaseApiService";
import PaginationHelper, { PaginatedResult, PaginationParam } from "common/pagination";
import { IFilter } from "Components/Materials/FilterMenu";
import ConfigStore from "Stores/ConfigStore";
import { AuctionStatus, AuctionType } from "Entities/appAuction";
import { AppNftEntity } from "Entities/appNft";
import { OrderStatus, OrderType } from "Entities/appOrder";
import AppCollection from "../AppCollection";

export enum IStatusFilter {
	ON_AUCTION = "ON_AUCTION",
	ON_FIXED_PRICE = "ON_FIXED_PRICE",
	BUY_NOW = "BUY_NOW",
	NEW = "NEW",
	HAS_OFFER = "HAS_OFFER",
	NOT_ON_SALE = "NOT_ON_SALE",
}

export type ValidNftsQuery = {
	filter?: IFilter;
	sortOption?: string;
};

type ILastSalePopulateOption = {
	auction?: {
		type?: AuctionType;
		statuses?: AuctionStatus[];
		isValid?: boolean;
	};
	order?: {
		type?: OrderType;
		statuses?: OrderStatus[];
		isValid?: boolean;
	};
};

type IPopulateOption = {
	lastSale?: ILastSalePopulateOption;
	collectionTreasury?:
		| {
				appCollectionTreasuryValues?: boolean;
				appContract?: boolean;
		  }
		| boolean;
};

export const defaultPopulateOption: IPopulateOption = {
	lastSale: {
		auction: {
			statuses: [AuctionStatus.BIDDING, AuctionStatus.BOUGHT, AuctionStatus.FINALIZED, AuctionStatus.FINALIZING, AuctionStatus.LIVE],
			isValid: true,
		},
		order: {
			statuses: [OrderStatus.BOUGHT, OrderStatus.LIVE],
			type: OrderType.SELL,
			isValid: true,
		},
	},
};

export default class AppNft extends BaseApiService {
	private static instance: AppNft;

	private constructor() {
		super();
		AppNft.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-nfts");
	}

	public static getInstance(reset?: boolean) {
		if (!AppNft.instance || reset) return new this();
		return AppNft.instance;
	}

	public async getNfts(
		filter?: IFilter | null,
		pagination?: PaginationParam,
		populateOption?: IPopulateOption,
	): Promise<PaginatedResult<AppNftEntity>> {
		const queryString: { filter?: IFilter; populateOption: IPopulateOption } = {
			populateOption: populateOption || defaultPopulateOption,
		};
		if (filter) {
			queryString.filter = filter;
		}

		const stringUrl = this.baseUrl.concat(`?queryString=${JSON.stringify(queryString)}`);
		const url = new URL(stringUrl);
		PaginationHelper.populateUrl(url, pagination);
		try {
			const paginatedResultOfNfts = await this.getRequest<PaginatedResult<AppNftEntity>>(url);

			return {
				...paginatedResultOfNfts,
				data: this.enrichNftsWithCollectionConfig(paginatedResultOfNfts.data),
			};
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getNftsByOwnerAddress(ownerAddress: string, pagination?: PaginationParam): Promise<PaginatedResult<AppNftEntity>> {
		const filter: IFilter = { userAddresses: [ownerAddress] };
		const url = new URL(`${this.baseUrl}?queryString=${JSON.stringify({ filter })}`);
		PaginationHelper.populateUrl(url, pagination);
		try {
			const paginatedResultOfNfts = await this.getRequest<PaginatedResult<AppNftEntity>>(url);

			return {
				...paginatedResultOfNfts,
				data: this.enrichNftsWithCollectionConfig(paginatedResultOfNfts.data),
			};
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getNftByCollectionAddressAndTokenId(
		collectionAddress: string,
		tokenId: number,
		populateOption?: IPopulateOption,
	): Promise<AppNftEntity> {
		const queryString: { populateOption: IPopulateOption } = {
			populateOption: populateOption || defaultPopulateOption,
		};

		const url = new URL(
			this.backUrl
				.concat(`/${ConfigStore.getInstance().config.app}`)
				.concat(`/app-collections/${collectionAddress}`)
				.concat(`/app-nfts/${tokenId}`)
				.concat(`?queryString=${JSON.stringify(queryString)}`),
		);

		try {
			const nft = await this.getRequest<AppNftEntity>(url);

			return this.enrichNftWithCollectionConfig(nft);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getNftById(nftId: number, populateOption?: IPopulateOption): Promise<AppNftEntity> {
		const queryString: { populateOption: IPopulateOption } = {
			populateOption: populateOption || defaultPopulateOption,
		};

		const url = new URL(
			this.backUrl
				.concat(`/${ConfigStore.getInstance().config.app}`)
				.concat(`/app-nfts/${nftId}`)
				.concat(`?queryString=${JSON.stringify(queryString)}`),
		);

		try {
			const nft = await this.getRequest<AppNftEntity>(url);

			return this.enrichNftWithCollectionConfig(nft);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getNftsByCollectionAddress(
		collectionAddress: string,
		pagination?: PaginationParam,
	): Promise<PaginatedResult<AppNftEntity>> {
		const filter: IFilter = { collections: [{ address: collectionAddress }] };
		const url = new URL(`${this.baseUrl}?queryString=${JSON.stringify({ filter })}`);
		PaginationHelper.populateUrl(url, pagination);

		try {
			const paginatedResultOfNfts = await this.getRequest<PaginatedResult<AppNftEntity>>(url);

			return {
				...paginatedResultOfNfts,
				data: this.enrichNftsWithCollectionConfig(paginatedResultOfNfts.data),
			};
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async countNftsByMarketType(filter?: IFilter | null): Promise<number[]> {
		let stringUrl = this.baseUrl;
		if (filter) {
			stringUrl = stringUrl.concat(`/count-by-market-type?queryString=${JSON.stringify({ filter })}`);
		}
		const url = new URL(stringUrl);
		try {
			return await this.getRequest<number[]>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public enrichNftWithCollectionConfig(appNft: AppNftEntity): AppNftEntity {
		if (!appNft.appCollection) {
			return appNft;
		}

		const appCollection = AppCollection.getInstance().defineConfig(appNft.appCollection);

		return { ...appNft, appCollection };
	}

	public enrichNftsWithCollectionConfig(appNfts: AppNftEntity[]): AppNftEntity[] {
		return appNfts.map((appNft) => {
			if (!appNft.appCollection) return appNft;
			const appCollection = AppCollection.getInstance().defineConfig(appNft.appCollection);

			return appCollection ? { ...appNft, appCollection } : appNft;
		});
	}
}
