import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import classes from "./classes.module.scss";
import { Link } from "react-router-dom";
import ProfileButton from "Components/Materials/ProfileButton";
import Typography from "Components/Elements/Typography";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import LanguageModeSwitcher from "Components/Materials/LanguageModeSwitcher";
import AddressButton from "Components/Elements/Buttons/AddressButton";
import IconButton from "Components/Elements/Buttons/IconButton";
import { ReactComponent as Cross } from "assets/images/icons/cross.svg";
import { AppUserEntity } from "Entities/appUser";

type IProps = {
	user: AppUserEntity | null;
	closeUserMenu: () => void;
	isAccountCompleted: () => boolean;
};

export default function ProfileSection(props: IProps) {
	return (
		<section className={classes["root"]}>
			<div className={classes["profile"]}>
				<IsModuleEnabled from={IsModuleEnabled.get().Header}>
					<IsModuleEnabled from={IsModuleEnabled.get().pages.Profile}>
						<Link
							to={IsModuleEnabled.get()
								.pages.Profile.props.path.replace(":userAddress", props.user?.appWallet?.userAddress ?? "")
								.concat(`/${IsModuleEnabled.get().pages.Profile.props.pages.Nfts.props.path}`)}>
							<ProfileButton size="large" />
						</Link>
					</IsModuleEnabled>
				</IsModuleEnabled>

				<div className={classes["username-address"]}>
					<Link
						className={classes["username"]}
						to={IsModuleEnabled.get()
							.pages.Profile.props.path.replace(":userAddress", props.user?.appWallet?.userAddress ?? "")
							.concat("/")
							.concat(IsModuleEnabled.get().pages.Profile.props.pages.Nfts.props.path)}>
						<Typography type="p" size="large" weight="semibold">
							{props.user?.displayName}
						</Typography>

						<IsModuleEnabled from={IsModuleEnabled.get().KYC}>
							{props.isAccountCompleted() && <CheckBadgeIcon className={classes["check-badge"]} />}
						</IsModuleEnabled>
					</Link>
					<AddressButton size="medium" address={props.user?.appWallet?.userAddress ?? ""} />
				</div>

				<IsModuleEnabled from={IsModuleEnabled.get().LanguageMode}>
					<IsModuleEnabled from={IsModuleEnabled.get().Header.props.LanguageMode}>
						<LanguageModeSwitcher user={props.user} className={classes["language-mode-switcher"]} />
					</IsModuleEnabled>
				</IsModuleEnabled>
			</div>

			<div className={classes["cross"]}>
				<IconButton onClick={props.closeUserMenu} size="medium" icon={<Cross />} />
			</div>
		</section>
	);
}
