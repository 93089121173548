import ConnectWalletButton from "Components/Materials/ConnectWalletButton";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import UserMenuStatus from "Stores/UserMenuStatus";
import WindowStore from "Stores/Window";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import NavLinks from "../NavLinks";
import UserStore from "Stores/UserStore";

export default function NotConnectedMenu() {
	const [status, setStatus] = useState(UserMenuStatus.getInstance().status);
	const [user, setUser] = useState(UserStore.getInstance().getUser());

	const closeUserMenu = useCallback(() => UserMenuStatus.getInstance().close(), []);
	const closeOnResize = useCallback(
		(window: Window) => {
			if (window.innerWidth > 767) closeUserMenu();
		},
		[closeUserMenu],
	);

	useEffect(() => UserMenuStatus.getInstance().onSwitch(setStatus), []);
	useEffect(() => WindowStore.getInstance().onResize(closeOnResize), [closeOnResize]);
	useEffect(() => UserStore.getInstance().onChange(setUser), []);

	return (
		<div className={classNames(classes["not-connected-menu"], classes[status])}>
			<div className={classes["content"]}>
				<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Navigation}>
					<div className={classes["nav-section"]}>
						<NavLinks user={user} />
					</div>
				</IsModuleEnabled>
				<div className={classes["connect"]}>
					<ConnectWalletButton fullwidth />
				</div>
			</div>
		</div>
	);
}
