import BaseRoyaltiesContract from "./BaseRoyaltiesContract";
import IRoyaltiesContractAdapter from "./IRoyaltiesContractAdapter";
import IRoyaltiesContractFunctions from "./IRoyaltiesContractFunctions";

export default abstract class BaseRoyaltiesContractAdapter<T extends BaseRoyaltiesContract> implements IRoyaltiesContractAdapter {
	public constructor(public contract: T, public readonly usableFunctions: Record<keyof IRoyaltiesContractFunctions, boolean>) {}

	public canUse<K extends keyof IRoyaltiesContractFunctions>(contractFunc: K): this is this & Pick<IRoyaltiesContractFunctions, K> {
		return !!this.usableFunctions[contractFunc];
	}

	public useOrThrow<K extends keyof IRoyaltiesContractFunctions>(
		contractFunc: K,
	): (...args: Parameters<IRoyaltiesContractFunctions[K]>) => ReturnType<IRoyaltiesContractFunctions[K]> {
		if (this.canUse(contractFunc)) {
			const func = this[contractFunc];
			if (typeof func !== "function") {
				throw new Error(`Function ${contractFunc} not supported by Royalties contract ${this.contract.address}`);
			}

			return (this[contractFunc] as Function).bind(this);
		}

		throw new Error("Method not implemented.");
	}
}
