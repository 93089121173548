import I18n from "Components/Elements/I18n";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import classes from "./classes.module.scss";
import DynamicComponentLoader from "Components/DynamicallyRenderedComponents/DynamicComponentLoader";

type IProps = {
	props?: Record<string, unknown>;
	components: {
		enabled: boolean;
		componentName: string;
		props?: Record<string, unknown>;
	}[];
};

export default function LandingPageTemplate({ props, components }: IProps) {
	if (props?.["paddingXDisabled"] !== undefined && !isValidPaddingXDiasbled(props?.["paddingXDisabled"])) {
		throw new Error("LandingPageTemplate: paddingXDisabled must be a boolean");
	}

	if(props?.["maxWidth"] !== undefined && typeof props?.["maxWidth"] !== "string") {
		throw new Error("LandingPageTemplate: maxWidth must be a string");
	}

	if(props?.["paddingTopDisabled"] !== undefined && typeof props?.["paddingTopDisabled"] !== "boolean") {
		throw new Error("LandingPageTemplate: paddingTopDisabled must be a boolean");
	}

	return (
		<DefaultTemplate title={I18n.translate("pages.home.title")} paddingXDisabled={props?.["paddingXDisabled"]} maxWidth={props?.["maxWidth"]} paddingTopDisabled={props?.["paddingTopDisabled"]}>
			<div className={classes["root"]}>
				{components.map((component, index) => (
					<DynamicComponentLoader
						key={index}
						pathIntoDynamicallyRenderedComponentsFolder={component.componentName}
						props={component.props}
					/>
				))}
			</div>
		</DefaultTemplate>
	);
}

function isValidPaddingXDiasbled(value: unknown): value is boolean {
	return typeof value === "boolean";
}
