import Loader from "Components/Elements/Loader";
import Typography from "Components/Elements/Typography";
import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	text?: string | JSX.Element;
};

export default class PopUpLoader extends React.Component<IProps> {
	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<Loader />
				<Typography type="p" size="small" weight="regular">
					{this.props.text}
				</Typography>
			</div>
		);
	}
}
