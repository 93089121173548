import I18n from "Components/Elements/I18n";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import React from "react";
import NavLinkElement from "../../../Elements/NavLinkElement";
import classes from "./classes.module.scss";
import { ReactComponent as LaunchpadIcon } from "assets/images/icons/pages/launchpad.svg";
import { ReactComponent as Profile } from "assets/images/icons/pages/profile.svg";
import { ReactComponent as MarketIcon } from "assets/images/icons/pages/market.svg";
import { ReactComponent as HistoryIcon } from "assets/images/icons/pages/history.svg";
import { ReactComponent as MyListing } from "assets/images/icons/pages/mylisting.svg";
import { ReactComponent as MyOrders } from "assets/images/icons/pages/myorders.svg";
import { TruckIcon } from "@heroicons/react/24/outline";
import { AppUserEntity } from "Entities/appUser";
import SettingsNavLinkElement from "./NavLinkElementSettings";
import IsConnected from "Components/Materials/IsConnected";

type IProps = {
	user: AppUserEntity | null;
};

type IState = {
	isSmallViewport: boolean;
};

export default class NavLinks extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isSmallViewport: this.isSmallViewport(),
		};
		this.onResize = this.onResize.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<>
				<IsModuleEnabled from={IsModuleEnabled.get().pages.Launchpad}>
					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Navigation.props.Launchpad}>
						<div className={classes["firstRow"]}>
							<NavLinkElement
								size="small"
								to={IsModuleEnabled.get().pages.Launchpad.props.path}
								name={I18n.translate("header.nav_links.launches")}
								variant="menu-item"
								icon={<LaunchpadIcon />}
							/>
						</div>
					</IsModuleEnabled>
				</IsModuleEnabled>
				<IsModuleEnabled from={IsModuleEnabled.get().pages.Market}>
					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Navigation.props.Market}>
						<div className={classes["firstRow"]}>
							<NavLinkElement
								size="small"
								to={IsModuleEnabled.get().pages.Market.props.path}
								name={I18n.translate("header.nav_links.market")}
								variant="menu-item"
								icon={<MarketIcon />}
							/>
						</div>
					</IsModuleEnabled>
				</IsModuleEnabled>

				<IsModuleEnabled from={IsModuleEnabled.get().pages.Profile}>
					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Navigation.props.Profile}>
						<IsConnected>
							<NavLinkElement
								size="small"
								to={IsModuleEnabled.get()
									.pages.Profile.props.path.replace(":userAddress", this.props.user?.appWallet?.userAddress ?? "")
									.concat("/")
									.concat(IsModuleEnabled.get().pages.Profile.props.pages.Nfts.props.path)}
								name={I18n.translate("header.nav_links.my_parsells")}
								variant="menu-item"
								icon={<Profile />}
							/>
						</IsConnected>
					</IsModuleEnabled>
				</IsModuleEnabled>

				<IsModuleEnabled from={IsModuleEnabled.get().pages.Profile.props.pages.Activities.props.Menu.options.Other.props.History}>
					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Navigation.props.History}>
						<IsConnected>
							<NavLinkElement
								size="small"
								to={`${IsModuleEnabled.get().pages.Profile.props.path.replace(
									":userAddress",
									this.props.user?.appWallet?.userAddress ?? "",
								)}
						/${IsModuleEnabled.get().pages.Profile.props.pages.Activities.props.path}
						/${IsModuleEnabled.get().pages.Profile.props.pages.Activities.props.Menu.options.Other.props.History.props.path}`}
								name={I18n.translate("header.nav_links.history")}
								variant="menu-item"
								icon={<HistoryIcon />}
							/>
						</IsConnected>
					</IsModuleEnabled>
				</IsModuleEnabled>

				<IsModuleEnabled from={IsModuleEnabled.get().pages.Settings}>
					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Navigation.props.Settings}>
						<IsConnected>
							<SettingsNavLinkElement />
						</IsConnected>
					</IsModuleEnabled>
				</IsModuleEnabled>

				<IsModuleEnabled
					from={IsModuleEnabled.get().pages.Profile.props.pages.Activities.props.Menu.options.Listings.props.FixedPrice}>
					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Navigation.props.MyListings}>
						<IsConnected>
							<NavLinkElement
								size="small"
								to={`${IsModuleEnabled.get().pages.Profile.props.path.replace(
									":userAddress",
									this.props.user?.appWallet?.userAddress ?? "",
								)}
						/${IsModuleEnabled.get().pages.Profile.props.pages.Activities.props.path}
						/${IsModuleEnabled.get().pages.Profile.props.pages.Activities.props.Menu.options.Listings.props.FixedPrice.props.path}`}
								name={I18n.translate("header.nav_links.listings")}
								variant="menu-item"
								icon={<MyListing />}
							/>
						</IsConnected>
					</IsModuleEnabled>
				</IsModuleEnabled>

				<IsModuleEnabled from={IsModuleEnabled.get().pages.Profile.props.pages.Activities.props.Menu.options.Orders.props.Offers}>
					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Navigation.props.MyOrders}>
						<IsConnected>
							<NavLinkElement
								size="small"
								to={`${IsModuleEnabled.get().pages.Profile.props.path.replace(
									":userAddress",
									this.props.user?.appWallet?.userAddress ?? "",
								)}
						/${IsModuleEnabled.get().pages.Profile.props.pages.Activities.props.path}
						/${IsModuleEnabled.get().pages.Profile.props.pages.Activities.props.Menu.options.Orders.props.Offers.props.path}`}
								name={I18n.translate("header.nav_links.orders")}
								variant="menu-item"
								icon={<MyOrders />}
							/>
						</IsConnected>
					</IsModuleEnabled>
				</IsModuleEnabled>

				<IsModuleEnabled from={IsModuleEnabled.get().pages.Deliveries}>
					<IsModuleEnabled from={IsModuleEnabled.get().UserMenu.props.Navigation.props.Deliveries}>
						<IsConnected>
							<NavLinkElement
								size="small"
								to={IsModuleEnabled.get().pages.Deliveries.props.path}
								name={I18n.translate("header.nav_links.deliveries")}
								variant="menu-item"
								icon={<TruckIcon width={16} height={16} />}
							/>
						</IsConnected>
					</IsModuleEnabled>
				</IsModuleEnabled>

				{this.state.isSmallViewport &&
					IsModuleEnabled.get().UserMenu.props.Navigation.props.ExtraLinks.map((link, index) => {
						return (
							<NavLinkElement
								key={link.link}
								size="small"
								to={link.link}
								name={I18n.translate(link.I18nKey)}
								variant="menu-item"
								icon={<LaunchpadIcon />}
							/>
						);
					})}
			</>
		);
	}

	public override componentDidMount(): void {
		window.addEventListener("resize", this.onResize);
	}

	public override componentWillUnmount(): void {
		window.removeEventListener("resize", this.onResize);
	}

	private onResize(): void {
		this.setState({ isSmallViewport: this.isSmallViewport() });
	}

	private isSmallViewport(): boolean {
		return window.innerWidth <= 768;
	}
}
