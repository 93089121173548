import { Entity } from "common/models";
import { AppRoleEntity } from "./appRole";

export type AppRuleEntity = Entity & {
	name: RuleNames;
	action: RuleActions;
	roles?: AppRoleEntity[];
};

export enum RuleActions {
	SIGNIN_SELF = "signin-self",
	SIGNOUT_SELF = "signout-self",
	CREATE_SELF = "create-self",
	READ = "read",
	READ_SELF = "read-self",
	UPDATE_SELF = "update-self",
	CREATE = "create",
	BID_CREATE = "create", // Assuming you want separate actions for orders, bids, etc., despite the same string value "create"
	BID_READ = "read", // Similar assumption as above
	MESSAGE_CREATE = "create",
	REDEEM_ASSET_READ = "read",
	REDEEM_ASSET_CREATE = "create",
  }

  export enum RuleNames {
	USERS = "users",
	COLLECTIONS = "collections",
	NFTS = "nfts",
	TOKEN_SUPPORTS = "token-supports",
	AUCTIONS = "auctions",
	ORDERS = "orders",
	BIDS = "bids",
	MESSAGES = "messages",
	CONTRACTS = "contracts",
	CONFIGS = "configs",
	ACTIVITIES = "activities",
	REDEEM_ASSETS = "redeem-assets",
  }