import React, { ReactNode } from "react";

// Components
import I18n from "Components/Elements/I18n";

// Stores
import Toasts from "Stores/Toasts";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	onClick?: () => void;
	value: string;
	children?: ReactNode;
	openToaster: boolean;
};
type IState = {};

export default class CopyClipboard extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<div className={classes["root"]} onClick={this.onClick}>
				{this.props.children}
			</div>
		);
	}

	private async onClick(e: React.MouseEvent<HTMLSpanElement>) {
		this.props.onClick?.();
		e.preventDefault();
		e.stopPropagation();
		try {
			await navigator.clipboard.writeText(this.props.value);
			if (this.props.openToaster) {
				Toasts.getInstance().open({
					title: I18n.translate("toasts.success.copy_success"),
					text: this.props.value,
					time: 2000,
				});
			}
		} catch (err) {
			console.warn(err);
		}
	}
}
