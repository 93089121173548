import React from "react";

import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import { AppUserEntity } from "Entities/appUser";
import WalletStore, { EWalletType } from "Stores/WalletStore";

import ConfigStore from "Stores/ConfigStore";
import ConnectWalletButtonStore from "Stores/ConnectWalletButtonStore";
import ConnectWalletModalStore from "Stores/Modals/ConnectWalletModalStore";
import { satisfiesEnum } from "common/helpers/enum";
import IsConnected from "../IsConnected";

type IProps = {
	fullwidth?: boolean;
};
type IState = {
	isLoading: boolean;
};

export default class ConnectWalletButton extends React.Component<IProps, IState> {
	
	private removeOnConnectWalletButtonChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			isLoading: ConnectWalletButtonStore.getInstance().isLoading,
		};
		this.connect = this.connect.bind(this);
		this.updateLoadingState = this.updateLoadingState.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<IsConnected no>
				<Button
					variant={EButtonVariant.CONTAINED}
					color={EButtonColor.PRIMARY}
					onClick={this.connect}
					fullwidth={this.props.fullwidth}
					isLoading={this.state.isLoading}
					disabled={this.state.isLoading}>
					<I18n map="common.connect_wallet" />
				</Button>
			</IsConnected>
		);
	}

	override componentDidMount(): void {
		
		this.removeOnConnectWalletButtonChange = ConnectWalletButtonStore.getInstance().onChange(() => {
			this.setState({ isLoading: ConnectWalletButtonStore.getInstance().isLoading });
		});
	}

	override componentWillUnmount(): void {
		this.removeOnConnectWalletButtonChange();
	}

	private updateLoadingState(user: AppUserEntity | null) {
		this.setState({ isLoading: false });
	}

	private async connect() {
		ConnectWalletButtonStore.getInstance().isLoading = true;
		try {
			const walletType = ConfigStore.getInstance().config.wallet.walletType;

			if (!satisfiesEnum(walletType, EWalletType)) {
				throw new Error("Invalid wallet type");
			}
			if (walletType === EWalletType.MAGIC_DEDICATED_WALLET) {
				ConnectWalletModalStore.getInstance().openModal({});
			} else {
				await WalletStore.getInstance().connectTo(walletType as EWalletType);
			}
		} catch (error) {
			ConnectWalletButtonStore.getInstance().isLoading = false;
			console.error(error);
		}
	}
}
