import { ECollectionPhaseStatus } from "common/enums/CollectionPhaseStatus";
import { Entity } from "common/models";
import BigNumber from "Services/BigNumber";

import { AppTokenSupportEntity } from "./appTokenSupport";
import { AppWhitelistEntity } from "./appWhitelist";

export type AppCollectionPhaseEntity = Entity & {
	startDate: Date;
	endDate: Date;
	name: ECollectionPhaseName;
	whitelistLink: string;
	hasOpenWhitelist: boolean;
	appWhitelist: AppWhitelistEntity;
	isKYCMandatory: boolean;
};

export enum ECollectionPhaseName {
	FREEMINT = "FREEMINT",
	PRIVATE_SALE = "PRIVATE_SALE",
	PUBLIC_SALE = "PUBLIC_SALE",
	ON_AUCTION = "ON_AUCTION",
	PRIVATE_AUTO_WHITELIST_SALE = "PRIVATE_AUTO_WHITELIST_SALE",
}

export type IMintInfo = AppCollectionPhaseEntity & {
	price: BigNumber;
	hardcapPerWallet: number;
	token: AppTokenSupportEntity;
	status: ECollectionPhaseStatus;
};

export type IInvestmentInfo = AppCollectionPhaseEntity & {
	token: AppTokenSupportEntity;
	maxTotalInvestmentHardcap: BigNumber;
	maxTotalSupply: number;
	investmentHardcapPerInvestor: BigNumber;
	minimumInvestmentAmount: BigNumber;
	totalAmountInvested: BigNumber;
	status: ECollectionPhaseStatus;
};
