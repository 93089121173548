import React from "react";

// Components
import Typography from "Components/Elements/Typography";

// Helpers
import NumberHelper from "common/helpers/numbers";

import classes from "./classes.module.scss";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";

type IProps = {
	amountToConvert?: string;
	token?: AppTokenSupportEntity;
};

type IState = {
	convertedAmount: number;
};

export default class ConvertedAmount extends React.Component<IProps, IState> {
	public static defaultProps: IProps = {
		amountToConvert: "0",
	};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			convertedAmount: 0,
		};
	}

	public override render() {
		return (
			<div className={classes["root"]}>
				<Typography type="p" weight="medium" size="medium">
					{`$${this.state.convertedAmount || "0.00"}`}
				</Typography>
			</div>
		);
	}

	public override async componentDidMount() {
		const convertedAmount = await this.convertToDollar();
		this.setState({ convertedAmount });
	}

	public override async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
		if (prevProps.amountToConvert !== this.props.amountToConvert) {
			const convertedAmount = await this.convertToDollar();
			this.setState({ convertedAmount });
		}
	}

	private async convertToDollar(): Promise<number> {
		if (!this.props.amountToConvert || !this.props.token) return 0;

		const currencyValue = this.props.token.USDRatio;
		return NumberHelper.getRoundedNumber(+this.props.amountToConvert * currencyValue, 2);
	}
}
