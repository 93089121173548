import BaseRoyaltiesContract from "./core/BaseRoyaltiesContract";
import BigNumberJs from "bignumber.js";
import BigNumber from "Services/BigNumber";

type RoyaltiesInfo = {
	amounts: BigNumber[];
	recipients: string[];
};

export default class RoyaltiesStorage290523Contract extends BaseRoyaltiesContract {
	/// @dev Return Royalty details for a specific NFT or a contract collection.
	/// The marketplace can call this method as well to confirm the amount of royalties the user will pay.
	/// @param tokenAddress address of the NFT
	/// @param tokenId tokenId of the NFT (if not a contract collection to retrieve fee for)
	/// @param salePrice Price of the sale we need to compute the royalties with.
	/// @return royaltyFeeAmounts array of royalty fee amounts
	/// @return royaltyRecipients array of royalty reciptients
	public async getRoyalties(tokenAddress: string, tokenId: number, salePrice: BigNumber): Promise<RoyaltiesInfo> {
		const res = await this.contract["getRoyalties"](tokenAddress, tokenId, salePrice.toString(10));

		return {
			amounts: res[0].map((amount: BigNumberJs) => BigNumber.from(amount.toString())),
			recipients: res[1],
		};
	}
}
