function getRoundedNumber(number: number, decimals: number) {
	return Math.floor(number * 10 ** decimals) / 10 ** decimals;
}

function countZerosAfterDot(str: string) {
	let count = 0;
	for (let i = 0; i < str.length; i++) {
		if (str[i] === "0") count++;
		else break;
	}
	return count;
}

function insertSpaceBetweenEach3Digits(str: string) {
	let result = "";
	for (let i = 0; i < str.length; i++) {
		if ((str.length - i) % 3 === 0 && i !== 0) result += ",";
		result += str[i];
	}
	return result;
}

const NumberHelper = {
	getRoundedNumber,
	countZerosAfterDot,
	insertSpaceBetweenEach3Digits,
};
export default NumberHelper;
