import classNames from "classnames";
import React, { ReactNode } from "react";
import classes from "./classes.module.scss";
import { animated } from "@react-spring/web";

const allowedElementTypes = ["h1", "h2", "h3", "h4", "h5", "h6", "p", "span", "div"];
export type typoPreDefinedColors = "primary" | "secondary" | "error" | "warning" | "success" | "neutral";

export type IProps = {
	children: ReactNode;
	type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "div" | "overline" | "h6Mobile";
	size?: "large" | "medium" | "small" | "xsmall";
	weight?: "bold" | "semibold" | "medium" | "extrabold" | "regular";
	color?: typoPreDefinedColors | string;
	className?: string;
	style?: React.CSSProperties;
	id?: string;
};

type IState = {};

export default class Typography extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	public override render(): React.ReactNode {
		const type = this.props.type ?? "p";
		const size = this.props.size ?? "medium";
		const weight = this.props.weight ?? "regular";
		const color = this.props.color ?? "default";
		const elementType = allowedElementTypes.includes(type) ? type : "span";

		return React.createElement(elementType, {
			className: classNames(
				classes["root"],
				classes[type],
				classes[`size-${size}`],
				classes[`weight-${weight}`],
				classes[`color-${color}`],
				this.props.className,
			),
			style: this.props.style,
			children: this.props.children,
			id: this.props.id,
		});
	}
}

export const AnimatedTypography = animated(Typography);
