import React from "react";

// Components
import ToastsContainer from "Components/Materials/Toasts/ToastsContainer";

type IProps = {};
type IState = {};

export default class ToastHandler extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		return (
			<>
				<ToastsContainer />
			</>
		);
	}
}
