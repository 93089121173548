import BaseApiService from "Api/BaseApiService";
import ConfigStore from "Stores/ConfigStore";

import { JwtPair } from "Entities/accessToken";
import { AppUserEntity } from "Entities/appUser";

type IPresignResponse = {
	jwtPair: JwtPair;
};

type IPostSignInOAuth = {
	oAuthToken: string;
};

type IPostSignInWallet = {
	userAddress: string;
	message: string;
	signedMessage: string;
};

type ISignInResponse = {
	user: AppUserEntity;
	jwtPair: JwtPair;
	userCreated: boolean;
	displayRegistrationModal: boolean;
};

type ILoggedInResponse = {
	status: boolean;
};

type ISignOutResponse = {
	jwtPair: JwtPair;
};

type IRefreshTokenResponse = {
	jwtPair: JwtPair;
};

type SendEmailPin = {
	email: string;
};

type VerifyEmailPin = {
	email: string;
	pin: string;
};

export default class AppAuth extends BaseApiService {
	private static instance: AppAuth;

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-auth");
	}

	private constructor() {
		super();
		AppAuth.instance = this;
	}

	public static getInstance(reset?: boolean) {
		if (!AppAuth.instance || reset) return new this();
		return AppAuth.instance;
	}

	public async presign(): Promise<IPresignResponse> {
		const url = new URL(this.baseUrl.concat("/presign"));
		try {
			return await this.postRequest<IPresignResponse>(url, {}, false, true);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async verifyToken(disconnectUser = true): Promise<ILoggedInResponse> {
		const url = new URL(this.baseUrl.concat("/verify-logged-in"));
		try {
			return await this.postRequest<ILoggedInResponse>(url, {}, true, true, disconnectUser);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signInWithOAuth(params: IPostSignInOAuth): Promise<ISignInResponse> {
		const url = new URL(this.baseUrl.concat("/sign-in-o-auth"));
		try {
			return await this.postRequest<ISignInResponse>(url, params);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signInWallet(params: IPostSignInWallet): Promise<ISignInResponse> {
		const url = new URL(this.baseUrl.concat("/sign-in-wallet"));
		try {
			return await this.postRequest<ISignInResponse>(url, params);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getAuthenticatedUser(): Promise<AppUserEntity> {
		const url = new URL(this.baseUrl.concat("/authenticated-user"));
		try {
			return await this.getRequest<AppUserEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signOut(): Promise<ISignOutResponse> {
		const url = new URL(this.baseUrl.concat("/sign-out"));
		try {
			return await this.patchRequest<ISignOutResponse>(url, {}, false, true);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async sendEmailPin(params: SendEmailPin): Promise<null> {
		const url = new URL(this.baseUrl.concat("/send-email-pin"));
		try {
			return await this.postRequest<null>(url, params);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async verifyEmailPin(params: VerifyEmailPin): Promise<boolean> {
		const url = new URL(this.baseUrl.concat("/verify-email-pin"));
		try {
			return await this.postRequest<boolean>(url, params);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async refreshToken(): Promise<IRefreshTokenResponse> {
		const url = new URL(this.baseUrl.concat("/refresh-token"));
		try {
			return await this.postRequest<IRefreshTokenResponse>(url, {}, false, true);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
