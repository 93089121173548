import React from "react";

import I18n from "Components/Elements/I18n";
import Typography from "Components/Elements/Typography";
import TokenPrice from "Components/Materials/TokenPrice";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import BigNumber from "Services/BigNumber";

import { EListingType } from "../..";
import classes from "./classes.module.scss";

type IProps = {
	listingType: EListingType;
	token?: AppTokenSupportEntity;
	potentialEarningAuction: BigNumber;
	potentialEarningFixedPrice: BigNumber;
	isBuyNowEnabled: boolean;
};

export default class PotentialEarningsComponent extends React.Component<IProps> {
	override render(): JSX.Element | null {
		return (
			<div className={classes["root"]}>
				{this.renderPotentialEarnings(
					this.isFixedPrice() ? this.props.potentialEarningFixedPrice : this.props.potentialEarningAuction,
				)}
				{this.isAuctionAndBuyNowEnabled() && (
					<div className={classes["padding"]}>
						<Typography type="p" size="small" weight="medium">
							<I18n map="common.or" />
						</Typography>
					</div>
				)}
				{this.isAuctionAndBuyNowEnabled() && this.renderPotentialEarnings(this.props.potentialEarningFixedPrice)}
			</div>
		);
	}

	private renderPotentialEarnings(potentialEarning: BigNumber) {
		return (
			<div className={classes["potential-earnings-row"]}>
				<div className={classes["left"]}>
					<Typography type="p" size="large" weight="semibold">
						<I18n map="modals.list_item.potential_earnings.title" />
					</Typography>
					{this.isAuctionAndBuyNowEnabled() && (
						<Typography type="p" size="small" weight="medium">
							<I18n map="modals.list_item.potential_earnings.auction" />
						</Typography>
					)}
				</div>
				<div className={classes["right"]}>
					<TokenPrice
						price={potentialEarning}
						token={this.props.token}
						conversionConfig={{ size: "small", direction: "column" }}
					/>
				</div>
			</div>
		);
	}

	private isAuctionAndBuyNowEnabled(): boolean {
		return this.isAuction() && this.props.isBuyNowEnabled;
	}

	private isFixedPrice(): boolean {
		return this.props.listingType === EListingType.FIXED_PRICE;
	}

	private isAuction(): boolean {
		return this.props.listingType === EListingType.AUCTION;
	}
}
