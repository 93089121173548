import React from "react";
import { Link } from "react-router-dom";

import IsModuleEnabled from "Components/Elements/IsModuleEnabled";

import TopMenuStatus, { EOpeningState } from "../../../Stores/TopMenuStatus";
import NavLinks from "../Header/NavLinks";
import Logo from "../Logo";
import MenuBurger from "../MenuBurger";
import ThemeModeSwitcher from "../ThemeModeSwitcher";
import classes from "./classes.module.scss";

type IState = {
	status: EOpeningState;
};
type IProps = {};

export default class TopMenu extends React.Component<IProps, IState> {
	private removeOnSwitch = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			status: TopMenuStatus.getInstance().status,
		};

		this.updateStatus = this.updateStatus.bind(this);
		this.close = this.close.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<div className={classes["root"]} {...this.state}>
				<div className={classes["shadow"]} onClick={this.close} />
				<div className={classes["nav"]} {...this.state}>
					<div className={classes["top-menu-burger"]}>
						<MenuBurger />
					</div>
					<nav className={classes["top-menu-nav"]}>
						<IsModuleEnabled from={IsModuleEnabled.get().Header.props.Logo}  aria-label="Visit home page">
							<Link className={classes["logo"]} to="/" aria-label="Visit Home page">
								<Logo />
							</Link>
						</IsModuleEnabled>
						<NavLinks />
						<IsModuleEnabled from={IsModuleEnabled.get().Theme}>
							<IsModuleEnabled from={IsModuleEnabled.get().Header.props.ThemeMode}>
								<ThemeModeSwitcher />
							</IsModuleEnabled>
						</IsModuleEnabled>
					</nav>
				</div>
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnSwitch = TopMenuStatus.getInstance().onSwitch(this.updateStatus);
		this.updateStatus(TopMenuStatus.getInstance().status);
	}

	public override componentWillUnmount() {
		this.removeOnSwitch();
	}

	private updateStatus(status: EOpeningState) {
		document.body.setAttribute("top-menu-status", status);
		this.setState({
			status,
		});
	}

	private close() {
		TopMenuStatus.getInstance().close();
	}
}
