import ConfigStore from "Stores/ConfigStore";
import WalletStore from "Stores/WalletStore";
import { AbiType } from "common/enums/AbiType";
import { ethers } from "ethers";
import { ContractType } from "Entities/appContract";
import Erc20Contract from "./erc20/Erc20Contract";
import CollectioADel030723Contract from "./erc721/CollectioADel030723Contract";
import CollectioADelERC20210823Contract from "./erc721/CollectioADelERC20210823Contract";
import CollectioAC300823Contract from "./erc721/CollectioAC300823Contract";
import MarketplaceV2Contract from "./marketplace/MarketplaceV2Contract";
import Marketplace290523Contract from "./marketplace/Marketplace290523Contract";
import CollectioWithFreeMintContract from "./erc721/CollectioWithFreeMintContract";
import Erc721StandardContract from "./erc721/Erc721StandardContract";
import IncarusErc20WhitelistContract from "./auction/IncarusErc20WhitelistContract";
import IncarusWhitelistContract from "./auction/IncarusWhitelistContract";
import IncarusErc20Contract from "./auction/IncarusErc20Contract";
import IncarusContract from "./auction/IncarusContract";
import RoyaltiesStorageContract from "./royalties/RoyaltiesStorageContract";
import RoyaltiesStorage290523Contract from "./royalties/RoyaltiesStorage290523Contract";
import Arca160323Contract from "./treasuries/Arca160323Contract";
import Erc721ACInvestmentContract from "./erc721/Erc721ACInvestmentContract";
import CollectioACERC20RB170124Contract from "./erc721/CollectioACERC20RB170124Contract";
import CollectioACERC20150124Contract from "./erc721/CollectioACERC20150124Contract";

export type EthersContract =
	| Erc20Contract
	| IncarusErc20Contract
	| IncarusErc20WhitelistContract
	| IncarusContract
	| IncarusWhitelistContract
	| RoyaltiesStorageContract
	| RoyaltiesStorage290523Contract
	| Arca160323Contract
	| MarketplaceV2Contract
	| Marketplace290523Contract
	| CollectioWithFreeMintContract
	| Erc721StandardContract
	| CollectioADel030723Contract
	| CollectioADelERC20210823Contract
	| CollectioAC300823Contract
	| Erc721ACInvestmentContract;

export default class ContractFactory {
	private static instance: ContractFactory;

	private constructor() {}

	public static getInstance(): ContractFactory {
		if (!this.instance) {
			this.instance = new ContractFactory();
		}
		return this.instance;
	}

	public async build({
		address,
		config,
		abi,
		contractType,
		abiType,
	}: {
		address: string;
		abi: ethers.ContractInterface;
		config?: Record<string, unknown>;
		contractType: ContractType;
		abiType: AbiType;
	}): Promise<EthersContract> {
		const walletData = WalletStore.getInstance().walletData;
		// TODO If provider is null we get the default provider from the config
		// check if it's necessary to make a new provider by calling the node again and again
		const provider =
			walletData.provider ?? new ethers.providers.JsonRpcProvider(ConfigStore.getInstance().config.blockchain.ethereum.rpc);
		const signer = walletData.provider?.getSigner() ?? null;

		const ethersContract = signer
			? new ethers.Contract(address, abi, provider).connect(signer)
			: new ethers.Contract(address, abi, provider);

		return this.buildBaseContract({
			address,
			abiType,
			ethersContract,
			config,
			contractType,
		});
	}

	private buildBaseContract(params: {
		address: string;
		abiType: AbiType;
		ethersContract: ethers.Contract;
		config?: Record<string, unknown>;
		contractType: ContractType;
	}): EthersContract {
		switch (params.abiType) {
			case AbiType.ERC20:
				return new Erc20Contract(params);
			case AbiType.AUCTION_PRIMARY_ERC20:
				return new IncarusErc20Contract(params);
			case AbiType.AUCTION_PRIMARY_ERC20_WHITELIST:
				return new IncarusErc20WhitelistContract(params);
			case AbiType.AUCTION_PRIMARY_NATIVE:
				return new IncarusContract(params);
			case AbiType.AUCTION_PRIMARY_NATIVE_WHITELIST:
				return new IncarusWhitelistContract(params);
			case AbiType.MARKETPLACE_V2:
				return new MarketplaceV2Contract(params);
			case AbiType.MARKETPLACE_29_05_23:
				return new Marketplace290523Contract(params);
			case AbiType.COLLECTIO_WITH_FREE_MINT:
				return new CollectioWithFreeMintContract(params);
			case AbiType.ARCA_16_03_23:
				return new Arca160323Contract(params);
			case AbiType.ROYALTIES_STORAGE:
				return new RoyaltiesStorageContract(params);
			case AbiType.ROYALTIES_STORAGE_29_05_23:
				return new RoyaltiesStorage290523Contract(params);
			case AbiType.ERC_721_STANDARD:
				return new Erc721StandardContract(params);
			case AbiType.COLLECTIO_A_DEL_03_07_23:
				return new CollectioADel030723Contract(params);
			case AbiType.COLLECTIO_A_DEL_ERC20_21_08_23:
				return new CollectioADelERC20210823Contract(params);
			case AbiType.COLLECTIO_A_C_30_08_23:
			case AbiType.COLLECTIO_A_C_SBT_17_01_24:
				return new CollectioAC300823Contract(params);
			case AbiType.OXELTA_SALE_17_11_23:
				return new Erc721ACInvestmentContract(params);
			case AbiType.COLLECTIO_A_C_ERC20_RB_17_01_24:
				return new CollectioACERC20RB170124Contract(params);
			case AbiType.COLLECTIO_A_C_ERC20_15_01_24:
				return new CollectioACERC20150124Contract(params);
			case AbiType.AUCTION_SECONDARY_NATIVE:
			case AbiType.AUCTION_SECONDARY_ERC20:
			case AbiType.CREATOR_TOKEN_TRANSFER_VALIDATOR_31_08_23:
				throw new Error(`${params.abiType} is not implemented`);

			default:
				throw new Error(`Invalid abi type: ${params.abiType}`);
		}
	}
}
