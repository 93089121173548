import BaseApiService from "Api/BaseApiService";
import ConfigStore from "Stores/ConfigStore";

import { AppCollectionEntity } from "Entities/appCollection";
import { AppNftEntity } from "Entities/appNft";
import { AppUserEntity } from "Entities/appUser";
import AppCollection from "../AppCollection";
import AppNft from "../AppNft";

export type GetSearchResponse = {
	collections: AppCollectionEntity[];
	users: AppUserEntity[];
	nfts: AppNftEntity[];
};

export default class AppSearch extends BaseApiService {
	private static instance: AppSearch;

	private constructor() {
		super();
		AppSearch.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-search");
	}

	public static getInstance(reset?: boolean) {
		if (!AppSearch.instance || reset) return new this();
		return AppSearch.instance;
	}

	public async search(name?: string): Promise<GetSearchResponse> {
		const url = new URL(this.baseUrl);
		if (name) url.searchParams.append("name", name);
		try {
			const response = await this.getRequest<GetSearchResponse>(url);

			return {
				...response,
				collections: response.collections.map((collection) => AppCollection.getInstance().defineConfig(collection)),
				nfts: await AppNft.getInstance().enrichNftsWithCollectionConfig(response.nfts),
			};
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
