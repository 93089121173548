import EventService from "Services/EventEmitter";
import { Location } from "react-router-dom";

export default class RouterLocation {
	private static ctx: RouterLocation;

	private readonly event = new EventService();

	private _location: Location | null = null;
    
	public get location() {
		return this._location;
	}

	private constructor() {
		RouterLocation.ctx = this;
	}

	public static getInstance() {
		if (!RouterLocation.ctx) return new this();
		return RouterLocation.ctx;
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (location: Location) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public change(location: Location) {
		if (location.pathname === this._location?.pathname) return;
        this._location = location;
		this.event.emit("change", location);
	}

	public preSet(location: Location) {
		if (this._location) return;
        this._location = location;
		this.event.emit("change", location);
	}
}

