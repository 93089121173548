import { AppUserEntity } from "Entities/appUser";
import React from "react";
import UserStore from "Stores/UserStore";

type IProps = {
	no?: boolean;
	children?: React.ReactNode;
};
type IState = {
	userAddress: string | null;
};

class IsConnected extends React.Component<IProps, IState> {
	private removeOnWalletChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			userAddress: UserStore.getInstance().getUser()?.appWallet?.userAddress ?? null,
		};
		this.onUserChange = this.onUserChange.bind(this);
	}

	public override render() {
		const isConnected = !!this.state.userAddress;

		if (this.props.no) {
			return !isConnected ? this.props.children : null;
		} else {
			return isConnected ? this.props.children : null;
		}
	}

	public override componentDidMount() {
		this.removeOnWalletChange = UserStore.getInstance().onChange(this.onUserChange);
	}

	public override componentWillUnmount() {
		this.removeOnWalletChange();
	}

	private onUserChange(user: AppUserEntity | null) {
		this.setState({
			userAddress: user?.appWallet?.userAddress ?? null,
		});
	}
}

export default IsConnected;
