import Event from "events";

export enum EOpeningState {
	CLOSED = "closed",
	OPENED = "opened",
}

class EventEmitter extends Event {}

export default class UserMenuStatus {
	private static ctx: UserMenuStatus;

	private _status: EOpeningState = (localStorage.getItem("user-menu-status") as EOpeningState) ?? EOpeningState.CLOSED;
	private readonly event = new EventEmitter();

	private constructor() {
		UserMenuStatus.ctx = this;
		this.switch(this.status);
	}

	public static getInstance() {
		if (!UserMenuStatus.ctx) return new this();
		return UserMenuStatus.ctx;
	}

	public get status() {
		return this._status;
	}

	/**
	 * @returns removelistener callback
	 */
	public onSwitch(callback: (status: EOpeningState) => void) {
		this.event.on("switch-top-menu", callback);
		return () => {
			this.event.off("switch-top-menu", callback);
		};
	}

	public toggle() {
		if (this.status === EOpeningState.CLOSED) {
			this.switch(EOpeningState.OPENED);
			return;
		}
		this.switch(EOpeningState.CLOSED);
		return;
	}

	public close() {
		this.switch(EOpeningState.CLOSED);
	}

	private switch(status: EOpeningState) {
		if (status === this.status) return;
		this._status = status;
		localStorage.setItem("user-menu-state", this._status);
		this.event.emit("switch-top-menu", this._status);
	}
}
