import React, { ReactNode } from "react";

import I18n from "Components/Elements/I18n";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import Select, { IOption } from "Components/Elements/Select";
import I18nStore from "Stores/I18nStore";
import LanguageHelper from "common/helpers/language";

export type IProps = {
	onChange: (selectOption: IOption) => void;
	duration?: IOption;
	children?: ReactNode;
};

type IState = {
	durationOptions: IOption[];
};

export default class SelectDuration extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			durationOptions: IsModuleEnabled.get().Modals.props.durationOptions.map((duration) => {
				const lang = I18nStore.getInstance().lang;
				const supportedLang = LanguageHelper.getSupportedLanguage(lang);
				const label = duration.label[supportedLang];
				return {
					value: duration.value,
					label,
				};
			}),
		};
	}

	override render(): React.ReactNode {
		return (
			<Select
				selectedOption={this.props.duration}
				onChange={this.props.onChange}
				placeholder={I18n.translate("common.duration")}
				options={this.state.durationOptions}
			/>
		);
	}
}
