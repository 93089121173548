import { DecodedAccessToken, JwtPair } from "Entities/accessToken";
import { jwtDecode } from "jwt-decode";
import CookieStorageService from "Services/CookieStorageService";

import BaseStore from "./BaseStore";

export default class JwtStore extends BaseStore {
	private static instance: JwtStore;
	private readonly cookieStorageService = CookieStorageService.getInstance();
	private userAddress: string | null = this.cookieStorageService.items.userAddress.get();
	private accessToken: string | null = this.cookieStorageService.items.accessToken.get(this.userAddress);
	private refreshToken: string | null = this.cookieStorageService.items.refreshToken.get(this.userAddress);

	private constructor() {
		super();
	}

	public static getInstance(): JwtStore {
		return (this.instance = this.instance ?? new JwtStore());
	}

	public getAccessToken() {
		return this.accessToken;
	}

	public getRefreshToken() {
		return this.refreshToken;
	}

	public getPair() {
		return {
			accessToken: this.accessToken,
			refreshToken: this.refreshToken,
		};
	}

	public setJwtPair(jwtPair: JwtPair, userAddress: string | null = null) {
		this.accessToken = jwtPair.accessToken;
		this.cookieStorageService.items.accessToken.set(userAddress, jwtPair.accessToken);

		this.refreshToken = jwtPair.refreshToken;

		this.cookieStorageService.items.refreshToken.set(userAddress, jwtPair.refreshToken);

		this.event.emit("change", jwtPair);
	}

	public hasRule(name: string, action: string) {
		if (!this.accessToken) {
			return false;
		}
		const decodedToken = jwtDecode(this.accessToken) as DecodedAccessToken;
		return !!decodedToken?.roles?.some((role) => role.rules.some((rule) => rule.action === action && rule.name === name));
	}
}
