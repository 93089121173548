import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import ModuleStore from "Stores/ModuleStore";

type IProps = {
	isPage?: boolean;
	from: {
		enabled: boolean;
		props?: {
			[key: string]: any;
		};
	};
	children?: ReactNode;
	no?: boolean;
};

export default class IsModuleEnabled extends React.Component<IProps> {
	public override render(): React.ReactNode | null {
		if (this.props.no) {
			if (this.props.from.enabled) return this.props.isPage ? <Navigate to={"/"} /> : null;
			return <>{this.props.children}</>;
		} else {
			if (!this.props.from.enabled) return this.props.isPage ? <Navigate to={"/"} /> : null;
			return <>{this.props.children}</>;
		}
	}

	public static get() {
		return ModuleStore.getInstance().module.modules;
	}
}
