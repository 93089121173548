import BaseErc721Contract from "Services/Contracts/Ethereum/erc721/core/BaseErc721Contract";
import { AppContractEntity } from "Entities/appContract";
import assert from "assert";
import ContractFactory from "Services/Contracts/Ethereum/ContractFactory";
import Erc721ContractAdapterFactory from "Services/Contracts/Ethereum/erc721/core/Erc721ContractAdapterFactory";
import AppContract from "Api/Back/AppContract";
import WalletStore from "Stores/WalletStore";

// Don't export this interface
interface EnrichedContract {
	contractEntity: AppContractEntity;
	contractAdapter: ReturnType<typeof Erc721ContractAdapterFactory.build>;
}

export default class Erc721ContractStore {
	private static instance: Erc721ContractStore;

	private pendingRequests: Map<string, Promise<EnrichedContract>> = new Map();
	private enrichedContracts: EnrichedContract[] = [];

	public constructor() {
		WalletStore.getInstance().onChange(() => {
			this.enrichedContracts = [];
			this.pendingRequests = new Map();
		});
	}

	public static getInstance(): Erc721ContractStore {
		if (!Erc721ContractStore.instance) {
			Erc721ContractStore.instance = new Erc721ContractStore();
		}

		return Erc721ContractStore.instance;
	}

	public async getEnrichedContractByAddress(contractAddress: string): Promise<EnrichedContract> {
		const enrichedContract = this.enrichedContracts.find(
			(enrichedContract) => enrichedContract.contractAdapter.contract.address === contractAddress,
		);
		if (enrichedContract) {
			return enrichedContract;
		}

		if (this.pendingRequests.has(contractAddress)) {
			return await this.pendingRequests.get(contractAddress)!;
		}

		const requestPromise = (async () => {
			const addedEnrichedContract = await this.addContract(contractAddress);
			this.pendingRequests.delete(contractAddress);
			return addedEnrichedContract;
		})();

		this.pendingRequests.set(contractAddress, requestPromise);

		return await requestPromise;
	}

	private async addContract(contractAddress: string): Promise<EnrichedContract> {
		const appContractEntity = await AppContract.getInstance().getContractByAddress(contractAddress);
		assert(appContractEntity, `Contract entity not found for address: ${contractAddress}`);

		const appAbi = appContractEntity.appAbi;
		assert(appAbi, `ABI not found for address: ${appContractEntity.address}`);

		const ethersContract = await ContractFactory.getInstance().build({
			address: appContractEntity.address,
			abi: appAbi.abi,
			config: appContractEntity.config,
			contractType: appContractEntity.type,
			abiType: appAbi.type,
		});

		assert(ethersContract.is(BaseErc721Contract), `Contract is not of type ${BaseErc721Contract.name}`);
		const contractAdapter = Erc721ContractAdapterFactory.build(ethersContract);

		const enrichedContract = { contractEntity: appContractEntity, contractAdapter };
		this.enrichedContracts.push(enrichedContract);

		return enrichedContract;
	}
}
