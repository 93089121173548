import I18n from "Components/Elements/I18n";
import NavLinkElement from "Components/Elements/NavLinkElement";
import Typography from "Components/Elements/Typography";
import classes from "./classes.module.scss";

type IProps = {
	footerLink: {
		I18nKey: string;
		link: string;
		enabled: boolean;
		isExternalLink: boolean;
	};
};

export default function FooterLink(props: IProps): JSX.Element | null {
	if (props.footerLink.enabled === false) return null;
	if (props.footerLink.isExternalLink) {
		return (
			<a href={props.footerLink.link} target="_blank" rel="noreferrer">
				<Typography color="neutral" type="p" size="small" weight="medium" className={classes["links"]}>
					<I18n map={`${props.footerLink.I18nKey}`} />
				</Typography>
			</a>
		);
	}
	return <NavLinkElement size="small" name={I18n.translate(props.footerLink.I18nKey)} to={props.footerLink.link} />;
}
