import { ReactComponent as Discord } from "assets/images/logos/discord.svg";
import { ReactComponent as Etherscan } from "assets/images/logos/etherscan.svg";
import { ReactComponent as Facebook } from "assets/images/logos/facebook.svg";
import { ReactComponent as Instagram } from "assets/images/logos/instagram.svg";
import { ReactComponent as Linkedin } from "assets/images/logos/linkedin.svg";
import { ReactComponent as Medium } from "assets/images/logos/medium.svg";
import { ReactComponent as Telegram } from "assets/images/logos/telegram.svg";
import { ReactComponent as Tiktok } from "assets/images/logos/tiktok.svg";
import { ReactComponent as Twitter } from "assets/images/logos/twitter.svg";
import { ReactComponent as Youtube } from "assets/images/logos/youtube.svg";
import { ReactComponent as ExternalWebsite } from "assets/images/icons/website.svg";
import { ReactComponent as Terms } from "assets/images/logos/terms.svg";
import { useRef } from "react";

export const socialMediaIcons = {
	discord: Discord,
	etherscan: Etherscan,
	terms: Terms,
	facebook: Facebook,
	instagram: Instagram,
	linkedin: Linkedin,
	medium: Medium,
	telegram: Telegram,
	tiktok: Tiktok,
	twitter: Twitter,
	youtube: Youtube,
	externalWebsite: ExternalWebsite,
};

export type SocialMediaIconName = keyof typeof socialMediaIcons;

export function useSocialMediaIcon(name: SocialMediaIconName) {
	// If there is an error like "check the render method of `Component`" or something like this
	// it means that name is not a valid key of socialMediaIcons
	// so you should add it to the type SocialMediaIconName or you did something wrong (probably :wink: :wink:)
	const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
	ImportedIconRef.current = socialMediaIcons[name];
	return ImportedIconRef.current;
}
