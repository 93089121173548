import { ethers } from "ethers";
import BigNumber from "Services/BigNumber";
import BaseErc721Contract from "./core/BaseErc721Contract";
import { ISecurityPolicy } from "./core/Erc721Dto";

export default class CollectioACERC20150124Contract extends BaseErc721Contract {
	public async approve(operator: string, tokenId: number): Promise<ethers.providers.TransactionResponse> {
		return this.contract["approve"](operator, tokenId);
	}
	public async setApprovalForAll(operator: string, approved: boolean): Promise<ethers.providers.TransactionResponse> {
		return this.contract["setApprovalForAll"](operator, approved);
	}
	public async getApproved(tokenId: number): Promise<string> {
		return await this.contract["getApproved"](tokenId);
	}
	public async isApprovedForAll(owner: string, operator: string): Promise<boolean> {
		return await this.contract["isApprovedForAll"](owner, operator);
	}
	public async createApproveTx(operator: string, tokenId: number): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["approve"]!(operator, tokenId);
	}
	public async createApproveForAllTx(operator: string, approved: boolean): Promise<ethers.PopulatedTransaction> {
		return this.contract.populateTransaction["setApprovalForAll"]!(operator, approved);
	}
	public async getTotalSupply(): Promise<number> {
		return BigNumber.from((await this.contract["totalSupply"]()).toString(), 18).toNumber();
	}
	public async getMaxTotalSupply(): Promise<number> {
		return BigNumber.from((await this.contract["maxTotalSupply"]()).toString(), 18).toNumber();
	}
	public async getMintAvailableSupply(): Promise<number> {
		const availableForSale = BigNumber.from((await this.contract["availableForSale"]()).toString(), 18);
		const availableForAirdrop = BigNumber.from((await this.contract["availableForAirdrop"]()).toString(), 18);
		return availableForSale.sub(availableForAirdrop).toNumber();
	}
	public async getPrivateSalePrice(): Promise<BigNumber> {
		return BigNumber.from((await this.contract["privateSalePrice"]()).toString());
	}
	public async getPublicSalePrice(): Promise<BigNumber> {
		return BigNumber.from((await this.contract["publicSalePrice"]()).toString());
	}
	public async getPrivateSaleHardCap(): Promise<number> {
		return BigNumber.from((await this.contract["privateSaleHardcap"]()).toString(), 18).toNumber();
	}
	public async getPublicSaleHardCap(): Promise<number> {
		return BigNumber.from((await this.contract["publicSaleHardcap"]()).toString(), 18).toNumber();
	}
	public async getMaxPrivateSaleMintPerTx(): Promise<number> {
		return BigNumber.from((await this.contract["maxPrivateSaleMintPerTx"]()).toString(), 18).toNumber();
	}
	public async getMaxPublicSaleMintPerTx(): Promise<number> {
		return BigNumber.from((await this.contract["maxPublicSaleMintPerTx"]()).toString(), 18).toNumber();
	}
	public async getPrivateSaleMintingAmountByUserAddress(userAddress: string): Promise<number> {
		return BigNumber.from((await this.contract["addressToPrivateSaleMintingAmount"](userAddress)).toString(), 18).toNumber();
	}
	public async getPublicSaleMintingAmountByUserAddress(userAddress: string): Promise<number> {
		return BigNumber.from((await this.contract["addressToPublicSaleMintingAmount"](userAddress)).toString(), 18).toNumber();
	}
	public async createMintPrivateSaleTx(
		amountToMint: number,
		splitSignature: ethers.Signature,
		recipientAddress: string,
		value: string,
	): Promise<ethers.PopulatedTransaction> {
		return await this.contract.populateTransaction["mintPrivateSale"]!(amountToMint, splitSignature, recipientAddress);
	}
	public async createMintPublicSaleTx(
		amountToMint: number,
		recipientAddress: string,
		value: string,
	): Promise<ethers.PopulatedTransaction> {
		return await this.contract.populateTransaction["mintPublicSale"]!(amountToMint, recipientAddress);
	}
	public async getTotalSoldPublicSale(): Promise<number> {
		return BigNumber.from((await this.contract["totalSoldInPublicSale"]()).toString(), 18).toNumber();
	}
	public async getTotalSoldPrivateSale(): Promise<number> {
		return BigNumber.from((await this.contract["totalSoldInPrivateSale"]()).toString(), 18).toNumber();
	}
	public async getTotalAvailableForAirDrop(): Promise<number> {
		return BigNumber.from((await this.contract["availableForAirdrop"]()).toString(), 18).toNumber();
	}
	public async burn(tokenId: number): Promise<ethers.providers.TransactionResponse> {
		return this.contract["burn"](tokenId);
	}
	public async balanceOf(address: string): Promise<number> {
		return BigNumber.from((await this.contract["balanceOf"](address)).toString(), 18).toNumber();
	}
	public async getPrivateMintCountForUserAddress(address: string): Promise<number> {
		return BigNumber.from((await this.contract["getPrivateMintCountForUserAddress"](address)).toString(), 18).toNumber();
	}
	public async getPublicMintCountForUserAddress(address: string): Promise<number> {
		return BigNumber.from((await this.contract["getPublicMintCountForUserAddress"](address)).toString(), 18).toNumber();
	}
	public async transferFrom(from: string, to: string, tokenId: number): Promise<ethers.providers.TransactionResponse> {
		return this.contract["transferFrom"](from, to, tokenId);
	}
	public async maxPublicSaleMintPerTx(): Promise<BigNumber> {
		return this.contract["maxPublicSaleMintPerTx"]();
	}
	public async maxPrivateSaleMintPerTx(): Promise<BigNumber> {
		return this.contract["maxPrivateSaleMintPerTx"]();
	}

	public async getSecurityPolicy(): Promise<ISecurityPolicy> {
		throw new Error("Method not implemented.");
	}

	public async getWhitelistedOperators(): Promise<string[]> {
		throw new Error("Method not implemented.");
	}

	public async isOperatorWhitelisted(operator: string): Promise<boolean> {
		throw new Error("Method not implemented.");
	}

	public async paymentAssetAddress(): Promise<string> {
		return this.contract["paymentAssetAddress"]();
	}
}
