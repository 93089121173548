import ThemeModeButton from "Components/Elements/Buttons/ThemeModeButton";
import React from "react";

import ThemeMode, { EThemeModeType } from "Stores/ThemeMode";

type IProps = {};

type IState = {
	theme: EThemeModeType;
};

export default class ThemeModeSwitcher extends React.Component<IProps, IState> {
	private removeOnSwitch = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			theme: ThemeMode.getInstance().type,
		};
		this.toggle = this.toggle.bind(this);
	}

	public override render(): JSX.Element {
		return <ThemeModeButton theme={this.state.theme} onClick={this.toggle} />;
	}

	public override componentDidMount() {
		this.removeOnSwitch = ThemeMode.getInstance().onSwitch((theme) => this.setState({ theme }));
	}

	public override componentWillUnmount() {
		this.removeOnSwitch();
	}

	private toggle(): void {
		ThemeMode.getInstance().toggle();
	}
}
