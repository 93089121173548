import { AppContractEntity, ContractType } from "Entities/appContract";
import assert from "assert";
import ContractFactory from "Services/Contracts/Ethereum/ContractFactory";
import AppContract from "Api/Back/AppContract";
import BaseMarketplaceContract from "Services/Contracts/Ethereum/marketplace/core/BaseMarketplaceContract";
import MarketplaceContractAdapterFactory from "Services/Contracts/Ethereum/marketplace/core/MarketplaceContractAdapterFactory";
import WalletStore from "Stores/WalletStore";

// Don't export this interface
interface EnrichedContract {
	contractEntity: AppContractEntity;
	contractAdapter: ReturnType<typeof MarketplaceContractAdapterFactory.build>;
}

export default class MarketplaceContractStore {
	private static instance: MarketplaceContractStore;

	private pendingRequests: Map<string, Promise<EnrichedContract>> = new Map();
	private enrichedContract: EnrichedContract | null = null;

	public constructor() {
		WalletStore.getInstance().onChange((walletData) => {
			this.enrichedContract = null;
		});
	}

	public static getInstance(): MarketplaceContractStore {
		if (!MarketplaceContractStore.instance) {
			MarketplaceContractStore.instance = new MarketplaceContractStore();
		}

		return MarketplaceContractStore.instance;
	}

	public async getEnrichedMarketplaceContract(): Promise<EnrichedContract> {
		if (this.enrichedContract) {
			return this.enrichedContract;
		}

		if (this.pendingRequests.has(ContractType.MARKETPLACE)) {
			return this.pendingRequests.get(ContractType.MARKETPLACE)!;
		}

		const requestPromise = (async () => {
			const addedEnrichedContract = await this.addContract();
			this.pendingRequests.delete(ContractType.MARKETPLACE);
			return addedEnrichedContract;
		})();

		this.pendingRequests.set(ContractType.MARKETPLACE, requestPromise);

		return requestPromise;
	}

	private async addContract(): Promise<EnrichedContract> {
		const appContractEntities = await AppContract.getInstance().getContracts({ filter: { type: ContractType.MARKETPLACE } });

		// When we get a contract by type, we get an array of contracts, but we only need one so it should be unique as for
		// A project has only one contract of a type RoyaltiesStorage, Marketplace, etc.
		const appContractEntity = appContractEntities[0];
		assert(appContractEntity, `Contract entity not found for contract type: ${ContractType.MARKETPLACE}`);

		const appAbi = appContractEntity.appAbi;
		assert(appAbi, `ABI not found for address: ${appContractEntity.address}`);

		const ethersContract = await ContractFactory.getInstance().build({
			address: appContractEntity.address,
			abi: appAbi.abi,
			config: appContractEntity.config,
			contractType: appContractEntity.type,
			abiType: appAbi.type,
		});

		assert(ethersContract.is(BaseMarketplaceContract), `Contract is not of type ${BaseMarketplaceContract.name}`);
		const contractAdapter = MarketplaceContractAdapterFactory.build(ethersContract);

		const enrichedContract = { contractEntity: appContractEntity, contractAdapter };
		this.enrichedContract = enrichedContract;

		return enrichedContract;
	}
}
