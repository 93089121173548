import AppConfig from "Api/Back/AppConfig";
import { AppConfigEntity } from "Entities/appConfig";
import EventService from "Services/EventEmitter";

export default class AppConfigStore {
	private static instance: AppConfigStore;
	private readonly event = new EventService();
	private readonly appConfig = AppConfig.getInstance();

	private config: AppConfigEntity | null = null;

	private constructor() {
		AppConfigStore.instance = this;
	}

	public static getInstance() {
		if (!AppConfigStore.instance) return new this();
		return AppConfigStore.instance;
	}

	public getConfig() {
		return this.config;
	}

	public setConfig(config: AppConfigEntity | null) {
		this.config = config;
		this.event.emit("change", config);
	}

	public async fetchConfig() {
		const config = await this.appConfig.getConfig();
		this.setConfig(config);
	}

	public onChange(callback: (config: AppConfigEntity | null) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}
}
