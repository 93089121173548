import React from "react";

import SearchPreviewItem, { ISearchPreviewItem } from "../Item";
import classes from "./classes.module.scss";

type IProps = {
	label: "Collections" | "NFTs" | "Users";
	items: ISearchPreviewItem[];
	togglePreview: (showPreview: boolean) => void;
};

type IState = {};

export default class SearchPreviewContent extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<div className={classes["items"]}>
					{this.props.items.map((item, index) => (
						<SearchPreviewItem item={item} label={this.props.label} key={item.id} togglePreview={this.props.togglePreview} />
					))}
				</div>
				<div className={classes["see-all"]}></div>
			</div>
		);
	}
}
