import { CheckCircleIcon as DarkModeIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon as LightModeIcon } from "@heroicons/react/24/solid";
import React from 'react'
import classes from "./classes.module.scss";
import ThemeMode, { EThemeModeType } from "Stores/ThemeMode";

interface IProps {}
 
interface IState {
	theme: EThemeModeType;
}
 
class CheckCircleSuccessOutlinedIcon extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			theme: ThemeMode.getInstance().type,
		};
	}

	public override render() { 
		return (
			<div className={classes["root"]}>{this.getIconAccordingToTheme()}</div>
		)
	}

	private getIconAccordingToTheme() {
		if (this.state.theme === EThemeModeType.LIGHT) return <LightModeIcon />
		return <DarkModeIcon />
	}
}
 
export default CheckCircleSuccessOutlinedIcon;
