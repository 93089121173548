import assert from "assert";
import React from "react";
import WertHelper from "common/helpers/wert";
import { IWertModalProps } from "Components/Materials/Modals/WertModal";
import classes from "./classes.module.scss";
import TokenSupports from "Stores/TokenSupports";
import { ETokenSymbol } from "common/enums/currency";
import { EWidgetType } from "common/enums/Wert";

interface IProps {
	paymentInfo: IWertModalProps;
}

interface IState {}

class WidgetContainer extends React.Component<IProps, IState> {
	public override render() {
		return <div id="topup-widget-container" className={classes["root"]} />;
	}

	public override async componentDidMount() {
		await this.mountWertWidgetContainer();
	}
	public override async componentDidUpdate() {
		await this.mountWertWidgetContainer();
	}
	private async mountWertWidgetContainer() {
		try {
			const { paymentInfo } = this.props;

			if (paymentInfo.widgetType === EWidgetType.ADD_FUNDS) {
				const { amountToPay, token, onPaymentStatusChange, onPositionChange } = paymentInfo;
				const nativeToken = TokenSupports.getInstance().getTokenByName(ETokenSymbol.MATIC);

				await WertHelper.addFunds({
					amount: amountToPay,
					token: token ?? nativeToken,
					onPaymentStatusChange,
					onPositionChange,
					onClose: paymentInfo.onClose,
				});
				return;
			}

			if (paymentInfo.widgetType === EWidgetType.BUY_NFT) {
				const { signature, nft, onPaymentStatusChange, onPositionChange } = paymentInfo;
				assert(nft, "nft is required");

				await WertHelper.buyNft(signature, nft, paymentInfo.onClose, onPositionChange, onPaymentStatusChange);
				return;
			}

			if (paymentInfo.widgetType === EWidgetType.MINT) {
				const { signature, appCollection, onPaymentStatusChange, onPositionChange } = paymentInfo;

				await WertHelper.mintNft(signature, paymentInfo.onClose, appCollection, onPositionChange, onPaymentStatusChange);
				return;
			}
		} catch (error) {
			console.error(error);
		}
	}
}

export default WidgetContainer;
