import React from "react";
import Typography, { typoPreDefinedColors } from "../Typography";

type IProps = {
	text: string;
	type?: "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
	size?: "small" | "xsmall" | "large" | "medium";
	weight?: "medium" | "bold" | "semibold" | "extrabold" | "regular";
	color?: typoPreDefinedColors | string;
	classname?: string;
};
type IState = {};

export default class AddressShort extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		return (
			<Typography
				color={this.props.color ?? "neutral"}
				type={this.props.type ?? "p"}
				size={this.props.size ?? "xsmall"}
				weight={this.props.weight ?? "medium"}>
				<span title={this.props.text} className={this.props.classname}>
					{this.formatAddress(this.props.text)}
				</span>
			</Typography>
		);
	}

	private formatAddress(address: string) {
		let length = address.length;
		return address.substring(0, 6) + "..." + address.substring(length - 4, length);
	}
}
