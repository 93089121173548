import { TokenProtocol } from "common/enums/TokenProcotol";
import BigNumber from "Services/BigNumber";
import EventEmitter from "Services/EventEmitter";
import ConfigStore from "Stores/ConfigStore";

import { AppTokenSupportEntity } from "./../Entities/appTokenSupport";
import TokenSupports from "./TokenSupports";
import WalletStore from "./WalletStore";
import Erc20ContractStore from "./ContractStores/Erc20ContractStore";

export interface IBalance {
	token: AppTokenSupportEntity;
	balance: BigNumber;
	usdBalance: number;
}

export type IBalances = Record<string, IBalance>;

export default class WalletBalanceStore {
	private static ctx: WalletBalanceStore | null = null;
	private event = new EventEmitter();
	private _balances: IBalances = {};
	private _totalUsdBalance: number = 0;

	private constructor() {
		WalletBalanceStore.ctx = this;
		this.updateBalances(TokenSupports.getInstance().getTokenSupports());
	}

	public static getInstance() {
		if (!WalletBalanceStore.ctx) return new this();
		return WalletBalanceStore.ctx;
	}

	public get balances() {
		return this._balances;
	}

	public get totalUsdBalance() {
		return this._totalUsdBalance;
	}

	private set balances(balances: IBalances) {
		this._balances = balances;
		this.event.emit("changed", balances);
	}

	public onChange(callback: (balances: IBalances) => void) {
		this.event.on("changed", callback);
		return () => {
			this.event.off("changed", callback);
		};
	}

	public async updateBalances(tokens: AppTokenSupportEntity[]) {
		const walletData = WalletStore.getInstance().walletData;
		if (!walletData.userAddress || this.isWrongChain()) {
			this._balances = {};
			this._totalUsdBalance = 0;
			return;
		}
		let totalUsdBalance = 0;

		const newobj: IBalances = { ...this.balances };
		for (const token of tokens) {
			if (token.protocol !== TokenProtocol.NATIVE && token.protocol !== TokenProtocol.ERC20) continue;
			let balance: BigNumber;
			if (token.protocol === TokenProtocol.NATIVE) {
				balance = BigNumber.from((await walletData.provider?.getBalance(walletData.userAddress)).toString());
			} else {
				const enrichedErc20Contract = await Erc20ContractStore.getInstance().getEnrichedContractByAddress(token.address);
				balance = BigNumber.from((await enrichedErc20Contract.ethersContract.balanceOf(walletData.userAddress)).toString());
			}
			const usdBalance = BigNumber.from(balance.toString()).toNumber(token.decimals) * token.USDRatio;
			totalUsdBalance += usdBalance;

			newobj[token.name] = {
				token: token,
				balance,
				usdBalance,
			};

			this._totalUsdBalance = totalUsdBalance;
		}
		this.balances = newobj;
	}

	private isWrongChain() {
		const rightChain = ConfigStore.getInstance().config.blockchain.ethereum.chainId;
		const actualChain = WalletStore.getInstance().walletData?.chainId;

		return rightChain !== actualChain;
	}
}
