import I18n from "Components/Elements/I18n";
import classes from "./classes.module.scss";
import Typography from "Components/Elements/Typography";

export type IListingConfirmationSummaryProps = {
	usdAmount: string;
	tokenAmount: string;
	tokenSymbol: string;
	duration: string;
}

export default function ListingConfirmationSummary(props: IListingConfirmationSummaryProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["amount-container"]}>
				<Typography size="medium" weight="semibold" className={classes["label"]}>
					{I18n.translate("modals.confirmation.listing.success.summary.label")}:
				</Typography>
				<Typography size="small" weight="semibold">
					{props.tokenAmount} {props.tokenSymbol}
				</Typography>
				<Typography size="small" weight="regular">
					(${props.usdAmount})
				</Typography>
			</div>
			<div className={classes["duration-container"]}>
				<Typography size="medium" weight="semibold" className={classes["label"]}>
					{I18n.translate("modals.confirmation.listing.success.summary.duration.label")}
				</Typography>
				<Typography size="medium" weight="semibold">
					{props.duration}
				</Typography>
			</div>
		</div>
	);
}
