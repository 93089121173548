import AlertInfo, { EAlertVariants } from "Components/Elements/Alert";
import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import React, { useCallback, useEffect } from "react";
import BigNumber from "Services/BigNumber";
import { PlusIcon } from "@heroicons/react/20/solid";
import TokenSupports from "Stores/TokenSupports";
import WalletBalanceStore from "Stores/WalletBalanceStore";
import { TokenProtocol } from "common/enums/TokenProcotol";
import { ETokenSymbol } from "common/enums/currency";
import classes from "./classes.module.scss";
import assert from "assert";
import { useModal } from "hooks/useModal";
import AddFunds from "Components/Materials/Modals/AddFundsModal";

type IProps = {
	gasPrice: BigNumber;
};

export default function GasPriceAlert(props: IProps) {
	const { showModal, hideModal } = useModal();
	const [isDisplayed, setIsDisplayed] = React.useState(false);

	const getNativeBalance = useCallback(() => {
		const nativeBalance = Object.entries(WalletBalanceStore.getInstance().balances).find(
			([key, balance]) => balance.token.protocol === TokenProtocol.NATIVE,
		);
		return nativeBalance?.[1].balance;
	}, []);

	const openAddFundsGasPriceModal = useCallback(() => {
		const nativeToken = TokenSupports.getInstance().getTokenByName(ETokenSymbol.MATIC);

		assert(nativeToken, "Native token is undefined in PaymentModalStore (gas price alert)");
		showModal(<AddFunds amountToPay={props.gasPrice} token={nativeToken} onClose={hideModal} />);
	}, [props.gasPrice, showModal, hideModal]);

	useEffect(() => {
		const isGasPriceAlertDisplayed = getNativeBalance()?.lt(props.gasPrice);
		if (isGasPriceAlertDisplayed) setIsDisplayed(isGasPriceAlertDisplayed);
	}, [props.gasPrice, getNativeBalance]);

	if (!isDisplayed) return null;

	return (
		<div className={classes["root"]}>
			<Button
				onClick={openAddFundsGasPriceModal}
				color={EButtonColor.PRIMARY}
				variant={EButtonVariant.OUTLINED}
				endIcon={<PlusIcon />}>
				<I18n map="modals.place_bid.add_funds" />
			</Button>
			<AlertInfo variant={EAlertVariants.WARNING}>
				<I18n map="modals.gas_price" vars={{ tokenName: ETokenSymbol.MATIC }} />
				<strong>
					<I18n map="modals.place_bid.add_funds" />
				</strong>
				.
			</AlertInfo>
		</div>
	);
}
