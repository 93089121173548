import BigNumber from "Services/BigNumber";
import { IBalances } from "Stores/WalletBalanceStore";

function getTokenBalanceOfConnectedUser(balances: IBalances, token: string | undefined): BigNumber {
	let availableAmount = BigNumber.from("0");

	Object.entries(balances).forEach(([key, balance]) => {
		if (balance.token.symbol === token) {
			availableAmount = balance.balance;
		}
	});

	return availableAmount;
}

const BalanceHelper = {
	getTokenBalanceOfConnectedUser,
};

export default BalanceHelper;
